import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { ManagementContext } from "../../../management.context";
import { RemoveAssetData } from "../remove-asset-modal.types";

import styles from "./delete-asset-form.module.scss";

export const DeleteAssetForm = () => {
  const { t } = useTranslation();

  const { assetsData } = useContext(ManagementContext);
  const { setFieldValue } = useFormikContext<RemoveAssetData>();

  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const handleSelect = (item: number) => {
    const values = selectedValues;
    values.push(item);

    setSelectedValues(values);
  };

  const handleDeselect = (item: number) => {
    const values = selectedValues.filter((value) => value !== item);
    setSelectedValues(values);
  };

  useDidUpdate(
    () => {
      setFieldValue("assets", selectedValues);
    },
    [selectedValues],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.textFieldWrapper}>
        <Typography className={styles.label}>{t("assets.removeAssetModal.asset")}</Typography>
        <Select
          className={styles.select}
          onDeselect={handleDeselect}
          popupClassName={styles.popup}
          mode="multiple"
          onSelect={handleSelect}
        >
          {assetsData?.map((asset) => (
            <Select.Option key={asset.id} value={asset.id}>
              {asset.type}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};
