import React from "react";
import { SchemaOf } from "yup";
import { TFunction } from "i18next";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";

import {
  AdditionalServicesData,
  CaptainData,
  CurrencyType,
  ExtraChargesData,
  InstructorData,
  OfferData,
  RateType,
  ServicesDataServer,
  SportType,
} from "pages/offers/create/create-offer.types";
import { Details } from "./details/details";
import { Location } from "./location/location";
import { Services } from "./services/services";
import { Preview } from "./preview/preview";
import { detailsSchema, detailsSchemaBoat } from "./details/details.constants";
import { CreateOfferData } from "server";
import { locationSchema } from "./location/location.constants";
import { OfferModel } from "models";
import { LocationData } from "server/location/location.types";
import { CategorySelect } from "./category-select/category-select";
import { BaseInfo } from "./base-info/base-info";
import { categorySelectSchema } from "./category-select/category-select.constants";
import { baseInfoSchema } from "./base-info/base-info.constants";

dayjs.extend(utcPlugin);

export const currencyOptions: CurrencyType[] = ["USD", "PLN", "EUR"];
export const pricePerOptions: RateType[] = [
  "HALF_HOUR",
  "HOUR",
  "DAY",
  "WEEK",
  "HALF_HOUR/PERSON",
  "WEEK/PERSON",
  "DAY/PERSON",
  "HOUR/PERSON",
];

export const tooltipConfig = {
  tooltip: {
    sx: {
      bgcolor: "#fff",
      color: "#474CAE",
      border: "2px solid #474CAE",
    },
  },
};

const CAPTAIN = "captain";
const INSTRUCTOR = "instructor";

export const mapOfferToEditData = (offerData: OfferModel): OfferData => {
  const captains: CaptainData[] = offerData.services
    .filter((item) => item.title === CAPTAIN)
    .map((item) => {
      const data: CaptainData = {
        guests: 0,
        isFixed: item.fixed_price,
        price: item.price,
        isRequired: item.required,
        id: item.id.toString(),
      };

      return data;
    });

  const instructors = offerData.services
    .filter((item) => item.title === INSTRUCTOR)
    .map((item) => {
      const data: InstructorData = {
        price: item.price,
        guests: 0,
        isFixed: item.fixed_price,
        id: item.id.toString(),
        isRequired: item.required,
      };

      return data;
    });

  const additionalServices: AdditionalServicesData[] = [];
  const extraCharges: ExtraChargesData[] = [];

  offerData.services
    .filter((item) => item.title !== INSTRUCTOR)
    .filter((item) => item.title !== CAPTAIN)
    .forEach((item) => {
      if (item.required) {
        const extraCharge: ExtraChargesData = {
          id: item.price.toString(),
          isRequired: item.required,
          name: item.title,
          price: item.price,
          isFixed: item.fixed_price,
        };

        extraCharges.push(extraCharge);
      } else {
        const additionalService: ExtraChargesData = {
          id: item.price.toString(),
          isRequired: item.required,
          name: item.title,
          price: item.price,
          isFixed: item.fixed_price,
        };

        additionalServices.push(additionalService);
      }
    });

  const handleRateType = (): RateType => {
    if (offerData.rate_per_person) {
      switch (offerData.rate_type) {
        case "DAY":
          return "DAY/PERSON";
        case "HOUR":
          return "HOUR/PERSON";
        case "HALF_HOUR":
          return "HALF_HOUR/PERSON";
        case "WEEK":
          return "WEEK/PERSON";

        default:
          return "HOUR";
      }
    } else {
      return offerData?.rate_type as RateType;
    }
  };

  const format = "HH:mm";
  // const formatDate = "YYYY-MM-DD";

  const startTime = dayjs("08:00", format);
  const endTime = dayjs("17:00", format);

  const start = dayjs(new Date());
  const end = dayjs(new Date()).add(1, "month");

  return {
    equipmentDetails: {
      price: {
        currency: offerData.currency as CurrencyType,
        rateType: handleRateType(),
        price: offerData.price,
        pricePerPerson: offerData.rate_per_person,
      },
      sportType: offerData.watersport.name.toUpperCase() as SportType,
      deposit: 0,
      guests: offerData.num_of_opportunities,
    },
    baseInfo: {
      availability: {
        days: [],
        start,
        end,
        endTime,
        months: [],
        startTime,
      },
      included: {
        included: [],
        notIncluded: [],
      },
      pricing: {
        basePrice: 0,
        rateType: "HOUR",
        deposit: 0,
        currency: "EUR",
        maxGuests: 0,
      },
    },
    categorySelect: {
      category: "RENTAL",
      sportType: "JETSKI",
    },
    keyDetails: {
      title: offerData.title || offerData.organization.name,
      description: offerData.description || offerData.organization.description,
      additionalDetails: {
        boatLength: offerData.parameters.length,
        manufacturer: offerData.parameters.manufacturer,
        productionYear: offerData.parameters.production_year,
      },
      offerPhotos: [...offerData.offer_images],
      photos: [],
    },
    services: {
      captains,
      instructors,
      additionalServices,
      extraCharges,
    },
    location: {
      location_id: offerData.location.id,
      temporary_id: null,
      locationData: {} as LocationData,
    },
  };
};

const format = "HH:mm";
// const formatDate = "YYYY-MM-DD";

const startTime = dayjs("08:00", format);
const endTime = dayjs("17:00", format);

const start = dayjs(new Date());
const end = dayjs(new Date()).add(1, "month");

export const offerInitialValues: OfferData = {
  categorySelect: {
    category: "RENTAL",
    sportType: null,
  },
  equipmentDetails: {
    price: { currency: "PLN", rateType: "HOUR", price: 0, pricePerPerson: false },
    sportType: "WINDSURFING",
    deposit: 0,
    guests: 0,
  },
  baseInfo: {
    availability: {
      days: [],
      start,
      end,
      endTime,
      months: [],
      startTime,
    },
    pricing: {
      basePrice: 0,
      currency: "EUR",
      deposit: 0,
      maxGuests: 0,
      rateType: "HOUR",
    },
    included: {
      included: [],
      notIncluded: [],
    },
  },
  keyDetails: {
    additionalDetails: {
      boatLength: 0,
      manufacturer: "",
      productionYear: 0,
    },
    description: "",
    title: "",
    photos: [],
    offerPhotos: [],
  },
  services: {
    additionalServices: [],
    extraCharges: [],
    captains: [],
    instructors: [],
  },
  location: {
    location_id: null,
    temporary_id: null,
    locationData: {
      address: "",
      description: "",
      city: "",
      country: "",
      name: "",
      latitude: "",
      longitude: "",
      phone_number: "",
      postal_code: "",
      region: "",
    },
  },
};

type RateTypeMap = {
  [rateType in RateType]: string;
};

export const rateTypeTranslated = (t: TFunction, rateType: RateType) => {
  const rateTypeMap: RateTypeMap = {
    "DAY/PERSON": t("createOffer.rateType.readable.dayPerson"),
    DAY: t("createOffer.rateType.readable.day"),
    "HOUR/PERSON": t("createOffer.rateType.readable.hourPerson"),
    HOUR: t("createOffer.rateType.readable.hour"),
    HALF_HOUR: t("createOffer.rateType.readable.halfHour"),
    "HALF_HOUR/PERSON": t("createOffer.rateType.readable.halfHourPerson"),
    "WEEK/PERSON": t("createOffer.rateType.readable.weekPerPerson"),
    WEEK: t("createOffer.rateType.readable.week"),
  };

  return rateTypeMap[rateType];
};

const RATE_TYPE_PERSON = {
  personPerHour: "HOUR/PERSON",
  personPerDay: "DAY/PERSON",
  personPerWeek: "WEEK/PERSON",
  personPerHalfHour: "HALF_HOUR/PERSON",
};

type SelectTypeOptions<T> = { value: T; label: string };

export const rateTypeSelectOptions = (t: TFunction): SelectTypeOptions<RateType>[] => [
  { value: "HALF_HOUR", label: t("createOffer.pricePerHalfHour") },
  { value: "HOUR", label: t("createOffer.pricePerHour") },
  { value: "DAY", label: t("createOffer.pricePerDay") },
  { value: "WEEK", label: t("createOffer.pricePerWeek") },
];

export const pricePerSelectOptions = (t: TFunction): SelectTypeOptions<RateType>[] => [
  { value: "HALF_HOUR", label: t("createOffer.rateType.readable.halfHour") },
  { value: "HOUR", label: t("createOffer.rateType.readable.hour") },
  { value: "DAY", label: t("createOffer.rateType.readable.day") },
  { value: "WEEK", label: t("createOffer.rateType.readable.week") },
  { value: "HALF_HOUR/PERSON", label: t("createOffer.rateType.readable.halfHourPerson") },
  { value: "HOUR/PERSON", label: t("createOffer.rateType.readable.hourPerson") },
  { value: "DAY/PERSON", label: t("createOffer.rateType.readable.dayPerson") },
  { value: "WEEK/PERSON", label: t("createOffer.rateType.readable.weekPerPerson") },
];

export const pricePerSelectOptionsNumber = (t: TFunction): SelectTypeOptions<RateType>[] => [
  { value: "HALF_HOUR", label: `30 ${t("createOffer.rateType.readable.number.min")}` },
  { value: "HOUR", label: `1 ${t("createOffer.rateType.readable.number.hour")}` },
  { value: "DAY", label: `1 ${t("createOffer.rateType.readable.number.day")}` },
  { value: "WEEK", label: `1 ${t("createOffer.rateType.readable.number.week")}` },
  { value: "HALF_HOUR/PERSON", label: `30 ${t("createOffer.rateType.readable.number.minPerson")}` },
  { value: "HOUR/PERSON", label: `1 ${t("createOffer.rateType.readable.number.hourPerson")}` },
  { value: "DAY/PERSON", label: `1 ${t("createOffer.rateType.readable.number.dayPerson")}` },
  { value: "WEEK/PERSON", label: `1 ${t("createOffer.rateType.readable.number.weekPerson")}` },
];
export const currencySelectOptions: SelectTypeOptions<CurrencyType>[] = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "PLN", label: "PLN" },
];

type Step = {
  component: React.ReactNode;
  schema: SchemaOf<unknown> | null;
  name: string;
};

export const checkIsBoatStep = (sport: SportType) => {
  switch (sport) {
    case "MOTORBOAT":
      return true;

    case "SAILING":
      return true;

    case "JETSKI":
      return true;

    default:
      return false;
  }
};

const checkSchema = (t: TFunction, sport: SportType, useOrganizationImages: boolean, numOfPhotos: number) => {
  if (checkIsBoatStep(sport)) return detailsSchemaBoat(t, useOrganizationImages, numOfPhotos);

  return detailsSchema(t, useOrganizationImages, numOfPhotos);
};

export const steps = (t: TFunction, sport: SportType, useOrganizationImages: boolean, numOfPhotos = 5): Step[] => [
  { component: <CategorySelect />, schema: categorySelectSchema(), name: t("createOffer.steps.addEquipment") },
  { component: <BaseInfo />, schema: baseInfoSchema(), name: t("createOffer.steps.baseInfo") },
  // { component: <EquipmentDetails />, schema: equipmentDetailsSchema(t), name: t("createOffer.steps.addEquipment") },
  {
    component: <Details />,
    schema: checkSchema(t, sport, useOrganizationImages, numOfPhotos),
    name: t("createOffer.steps.addDetails"),
  },
  { component: <Services />, schema: null, name: t("createOffer.steps.addServices") },
  { component: <Location />, schema: locationSchema(t), name: t("createOffer.steps.addLocation") },
  { component: <Preview />, schema: null, name: t("createOffer.steps.preview") },
];

export const mapServicesData = (data: OfferData): ServicesDataServer[] => {
  const servicesData: ServicesDataServer[] = [];

  const services = [...data.services.additionalServices, ...data.services.extraCharges];

  services.map((service) => {
    const serviceData = {
      title: service.name || "title",
      description: "description",
      fixed_price: service.isFixed,
      price: service.price,
      required: service.isRequired,
    };

    return servicesData.push(serviceData);
  });

  const captains: CaptainData[] = [];

  if (data.services.captains.length !== 0) {
    const numOfCaptains = data.services.captains.length;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < numOfCaptains; index++) {
      const captain = data.services.captains[index];
      captains.push(captain);
    }
  }

  const instructors: InstructorData[] = [];

  if (data.services.instructors.length !== 0) {
    const numOfInstructors = data.services.instructors.length;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < numOfInstructors; index++) {
      const instructor = data.services.instructors[index];
      instructors.push(instructor);
    }
  }

  captains.map((captain) => {
    const captainsData = {
      title: "captain",
      description: "description",
      price: captain.price,
      required: captain.isRequired,
      fixed_price: captain.isFixed,
    };

    return servicesData.push(captainsData);
  });

  instructors.map((instructor) => {
    const instructorData = {
      title: "instructor",
      description: "description",
      price: instructor.price,
      fixed_price: instructor.isFixed,
      required: instructor.isRequired,
    };

    return servicesData.push(instructorData);
  });

  return servicesData;
};

const checkPricePerPerson = (data: OfferData) => {
  const {
    baseInfo: {
      pricing: { rateType },
    },
  } = data;

  if (
    rateType === RATE_TYPE_PERSON.personPerHour ||
    rateType === RATE_TYPE_PERSON.personPerDay ||
    rateType === RATE_TYPE_PERSON.personPerWeek ||
    rateType === RATE_TYPE_PERSON.personPerHalfHour
  )
    return true;
  return false;
};

const checkRateType = (data: OfferData) => {
  const {
    baseInfo: {
      pricing: { rateType },
    },
  } = data;

  switch (rateType) {
    case RATE_TYPE_PERSON.personPerHalfHour:
      return "HALF_HOUR";

    case RATE_TYPE_PERSON.personPerDay:
      return "DAY";

    case RATE_TYPE_PERSON.personPerHour:
      return "HOUR";

    case RATE_TYPE_PERSON.personPerWeek:
      return "WEEK";

    default:
      return rateType;
  }
};

type OfferDataArguments = {
  data: OfferData;
  watersportId: number;
  organizationId: number;
  useOrganizationImages: boolean;
  projectId: string;
};

export const mapOfferData = ({
  data,
  watersportId,
  organizationId,
  useOrganizationImages,
  projectId,
}: OfferDataArguments): CreateOfferData => {
  const images: string[] = [...data.keyDetails.photos.map((item) => item.name)];

  return {
    location: data.location.location_id ? data.location.location_id : 0,
    organization: organizationId,
    watersport: watersportId,
    title: data.keyDetails.title === "" ? null : data.keyDetails.title,
    description: data.keyDetails.description === "" ? null : data.keyDetails.description,
    price: data.baseInfo.pricing.basePrice,
    currency: data.baseInfo.pricing.currency,
    rate_type: checkRateType(data),
    rate_per_person: checkPricePerPerson(data),
    services: mapServicesData(data),
    project_id: projectId,
    parameters: {
      length: data.keyDetails.additionalDetails.boatLength !== 0 ? data.keyDetails.additionalDetails.boatLength : null,
      production_year:
        data.keyDetails.additionalDetails.productionYear !== 0
          ? data.keyDetails.additionalDetails.productionYear
          : null,
      manufacturer: data.keyDetails.additionalDetails.manufacturer || null,
      deposit: data.baseInfo.pricing.deposit || null,
      num_of_captains: data.services.captains.length || 0,
      num_of_instructor: data.services.instructors.length || 0,
    },
    num_of_opportunities: data.baseInfo.pricing.maxGuests,
    image_names: useOrganizationImages ? [] : images,
    is_simple_offer: false,
    event_type: data.categorySelect.category,
    start_time: dayjs(data.baseInfo.availability.startTime).format("HH:mm"),
    end_time: dayjs(data.baseInfo.availability.endTime).format("HH:mm"),
    start: dayjs(data.baseInfo.availability.start).toISOString(),
    end: dayjs(data.baseInfo.availability.end).toISOString(),
    days_of_week:
      data.baseInfo.availability.days.length > 0 ? data.baseInfo.availability.days.map((day) => day.toUpperCase()) : [],
    Months:
      data.baseInfo.availability.months.length > 0
        ? data.baseInfo.availability.months.map((month) => month.toUpperCase())
        : [],
  };
};
