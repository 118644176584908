import React, { useContext, useState, useCallback, useEffect, Context } from "react";
import moment from "moment";
import "moment/locale/pl";
import { Button, Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { Slots as SlotsType } from "../../../../server/availability/availability.server";
import { SlotsViewContextType } from "../../wrapper/slots-view.context";
import { Calendar } from "./calendar/calendar";
import { ReservationsContext } from "../../reservations-modal.context";
import { getViewContext } from "../../wrapper/wrapper.constants";

import styles from "./slots.module.scss";

moment.locale("pl");

const getSlotKey = (slot: SlotsType) => {
  const startDateTime = moment(slot.dateTimeFrom);
  return `${startDateTime.format("YYYY-MM-DD")}-${startDateTime.format("HH:mm")}-${moment(slot.dateTimeTo).format(
    "HH:mm",
  )}`;
};

export const Slots: React.FC = () => {
  const { isOwnReservation, isCalendarView } = useContext(ReservationsContext);
  const { t } = useTranslation();

  const { guests, setSelectedSlots, slots, selectedSlots, selectedDate, slotsSubmitting } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>,
  );

  const [availableSlots, setAvailableSlots] = useState<SlotsType[]>([]);

  const handleSlotSelect = useCallback(
    (slot: SlotsType) => {
      const slotKey = getSlotKey(slot);
      const isAlreadySelected = selectedSlots.some((selectedSlot) => getSlotKey(selectedSlot) === slotKey);

      if (isAlreadySelected) {
        setSelectedSlots(selectedSlots.filter((selectedSlot) => getSlotKey(selectedSlot) !== slotKey));
      } else if (slot.quantity >= guests) {
        setSelectedSlots([...selectedSlots, slot]);
      }
    },
    [guests, selectedSlots, setSelectedSlots],
  );

  const isSlotSelected = useCallback(
    (slot: SlotsType) => {
      const slotKey = getSlotKey(slot);
      return selectedSlots.some((selectedSlot) => getSlotKey(selectedSlot) === slotKey);
    },
    [selectedSlots],
  );

  useEffect(() => {
    if (selectedDate && slots) {
      setAvailableSlots(slots.filter((slot) => moment(slot.dateTimeFrom).isSame(selectedDate, "day")));
    }
  }, [selectedDate, slots]);

  const renderSlots = () => {
    if (slotsSubmitting) {
      return Array.from({ length: 8 }, (_, i) => (
        <Skeleton.Button key={i} active className={styles.slotSkeleton} shape="square" />
      ));
    }

    const slotsForSelectedDate = availableSlots.filter((slot) => moment(slot.dateTimeFrom).isSame(selectedDate, "day"));

    if (slotsForSelectedDate.length === 0) {
      return (
        <div className={styles.noAvailableSlotsDiv}>
          <p>{t("reservationsModal.noAvailableSlots")}</p>
        </div>
      );
    }

    const now = moment();

    return slotsForSelectedDate.map((slot) => {
      const dateTimeFrom = moment.utc(slot.dateTimeFrom).format("HH:mm");
      const dateTimeTo = moment.utc(slot.dateTimeTo).format("HH:mm");
      const isPastTime = selectedDate.isSame(now, "day") && moment(slot.dateTimeFrom).isBefore(now);

      return (
        <Button
          key={getSlotKey(slot)}
          className={`${styles.slotButton} ${isSlotSelected(slot) ? styles.selected : ""}`}
          onClick={() => handleSlotSelect(slot)}
          disabled={slot.quantity < guests || !slot || isPastTime}
        >
          <span>{dateTimeFrom}</span>
          <span>-</span>
          <span>{dateTimeTo}</span>
        </Button>
      );
    });
  };

  return (
    <div className={styles.slotPicker}>
      <Calendar />
      <div className={styles.slotsBody}>{renderSlots()}</div>
    </div>
  );
};
