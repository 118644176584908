export const reservationsModal = {
  booking: "Rezerwacja",
  error: "Błąd",
  book: "Zarezerwuj",
  selectOffer: "Wybierz ofertę",
  noAvailableSlots: "Brak miejsca dla wybranego dnia",
  reservationSent: "Twoja rezerwacja została poprawnie wysłana",
  details: {
    offer: "Oferta:",
    guests: "Goście:",
    selectedServices: "Wybrane usługi:",
    additionalServices: "Dodatkowe usługi:",
    changeWorker: "Zmień pracownika",
  },
  contactPerson: {
    contactPerson: "Osoba do kontaktu",
    name: "Imię",
    surname: "Nazwisko",
    telephone: "Numer telefonu",
    schema: {
      nameIsRequired: "Imię jest wymagane",
      surnameIsRequired: "Nazwisko jest wymagane",
      emailIsRequired: "Email jest wymagany",
      telephoneIsRequired: "Numer telefonu jest wymagany",
    },
  },
  summary: {
    offer: "Oferta",
    price: "Cena",
    selectedSlots: "Wybrane terminy:",
    guests: {
      guests: "Goście",
    },
    services: {
      services: "Usługi",
      moreDetails: "Więcej szczegółów",
      title: "Tytuł:",
      description: "Opis:",
      price: "Cena:",
      required: "Usługa wymagana:",
      edit: "Edytuj",
      yes: "Tak",
      no: "Nie",
    },
    policy: {
      byClickingTheButton: "Klikając poniższy przycisk, wyrażam zgodę na",
      rentalRules: "Zasady wynajmu",
      seaEasyTerms: "Regulamin SeaEasy",
      cancellationPolicy: "Polityka anulowania,",
      paymentTerms: "Warunki płatności.",
    },
  },
};
