import React from "react";
import { Helmet } from "react-helmet";

import { OffersBmHelmetTypes } from "./meta-data.types";

export const MetaData: React.FC<OffersBmHelmetTypes> = ({ meta }) => {
  return (
    <Helmet>
      <meta name="description" content={meta.metaDescription} key="description" />
      <meta name="keywords" content={meta.keywords} />
      <meta property="og:title" content={meta.metaTitle} key="og:title" />
      <meta property="og:description" content={meta.metaDescription} key="og:description" />
      <meta name="robots" content={meta.metaRobots ? meta.metaRobots : "all"} />
    </Helmet>
  );
};
