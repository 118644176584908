export const contact = {
  aboutUs: "O nas",
  contactUs: "Kontakt",
  name: "Imię",
  howCanWeHelpYou: "Jak możemy ci pomóc?",
  send: "Wyślij",
  bySendingMessage: "Wysyłając wiadomość, zgadzasz się z naszą",
  privacyPolicy: "Polityką prywatności",
  cookiesPolicy: "Ciasteczkami",
  and: "i",
  termsOfUse: "Regulaminem",
  nameIsRequired: "Imię jest wymagane",
  emailIsRequired: "Email jest wymagany",
  valueMustBeEmail: "Wartość musi być poprawnym adresem email",
  messageIsRequired: "Wiadomość jest wymagana",
  sentSuccessfully: "Wysłano poprawnie",
  sendError: "Błąd podczas wysyłania",
};
