export const pl = {
  schedule: {
    day: "Dzień",
    week: "Tydzień",
    workWeek: "Tydzień pracy",
    month: "Miesiąc",
    year: "Rok",
    agenda: "Plan",
    weekAgenda: "Plan tygodniowy",
    workWeekAgenda: "Plan Tygodnia Pracy",
    monthAgenda: "Plan miesiąca",
    today: "Dzisiaj",
    noEvents: "Brak rezerwacji",
    emptyContainer: "Na ten dzień nie zaplanowano żadnych rezerwacji.",
    allDay: "Cały dzień",
    start: "Początek",
    end: "Koniec",
    more: "więcej",
    close: "Zamknij",
    cancel: "Anuluj",
    noTitle: "(Bez tytułu)",
    delete: "Usuń",
    deleteEvent: "Wydarzenie",
    deleteMultipleEvent: "Usuń wiele rezerwacji",
    selectedItems: "Wybrane elementy",
    deleteSeries: "Cała seria",
    edit: "Edytuj",
    editSeries: "Cała seria",
    editEvent: "Wydarzenie",
    createEvent: "Stwórz",
    subject: "Przedmiot",
    addTitle: "Dodaj tytuł",
    moreDetails: "Więcej szczegółów",
    save: "Zapisać",
    editContent: "Jak chciałbyś zmienić spotkanie w serialu?",
    deleteContent: "Czy na pewno chcesz usunąć to wydarzenie?",
    deleteMultipleContent: "Czy na pewno chcesz usunąć wybrane wydarzenia?",
    newEvent: "Nowe wydarzenie",
    title: "Tytuł",
    location: "Lokalizacja",
    description: "Opis",
    timezone: "Strefa czasowa",
    startTimezone: "Uruchom strefę czasową",
    endTimezone: "Koniec strefy czasowej",
    repeat: "Powtarzać",
    saveButton: "Zapisać",
    cancelButton: "Anuluj",
    deleteButton: "Usunąć",
    recurrence: "Nawrót",
    wrongPattern: "Wzorzec powtarzania się jest nieprawidłowy.",
    seriesChangeAlert:
      "Czy chcesz anulować zmiany wprowadzone w określonych wystąpieniach tej serii i ponownie dopasować ją do całej serii?",
    createError:
      "Czas trwania wydarzenia musi być krótszy niż częstotliwość jego występowania. Skróć czas trwania lub zmień wzorzec cyklu w edytorze zdarzeń cyklicznych.",
    sameDayAlert: "Dwa wystąpienia tego samego zdarzenia nie mogą wystąpić tego samego dnia.",
    occurenceAlert:
      "Nie można przełożyć wystąpienia spotkania cyklicznego, jeśli pomija późniejsze wystąpienie tego samego spotkania.",
    editRecurrence: "Edytuj cykl",
    repeats: "Powtarza się",
    alert: "Alarm",
    startEndError: "Wybrana data końcowa występuje przed datą początkową.",
    invalidDateError: "Wprowadzona wartość daty jest nieprawidłowa.",
    blockAlert: "Zdarzenia nie mogą być zaplanowane w zablokowanym przedziale czasowym.",
    ok: "Dobrze",
    yes: "tak",
    no: "Nie",
    occurrence: "Występowanie",
    series: "Seria",
    previous: "Poprzedni",
    next: "Kolejny",
    timelineDay: "Dzień na osi czasu",
    timelineWeek: "Tydzień na osi czasu",
    timelineWorkWeek: "Tydzień roboczy osi czasu",
    timelineMonth: "Miesiąc osi czasu",
    timelineYear: "Rok na osi czasu",
    editFollowingEvent: "Następujące wydarzenia",
    deleteTitle: "Usuń wydarzenie",
    editTitle: "Edytuj wydarzenie",
    beginFrom: "Zacząć od",
    endAt: "Koniec o",
    expandAllDaySection: "Rozwiń sekcję całodniową",
    collapseAllDaySection: "Zwiń sekcję całodniową",
    searchTimezone: "Wyszukaj strefę czasową",
    noRecords: "Nic nie znaleziono",
  },
  recurrenceeditor: {
    none: "Żaden",
    daily: "Codziennie",
    weekly: "Co tydzień",
    monthly: "Miesięczny",
    month: "Miesiąc",
    yearly: "Rocznie",
    never: "Nigdy",
    until: "Aż do",
    count: "Liczyć",
    first: "Pierwszy",
    second: "druga",
    third: "Trzeci",
    fourth: "Czwarty",
    last: "Ostatni, ubiegły, zeszły",
    repeat: "Powtarzać",
    repeatEvery: "Powtórz każdy",
    on: "Powtórz wł",
    end: "Koniec",
    onDay: "Dzień",
    days: "Dni)",
    weeks: "Tydzień (y)",
    months: "Miesięcy)",
    years: "Rok",
    every: "każdy",
    summaryTimes: "czasy)",
    summaryOn: "na",
    summaryUntil: "aż do",
    summaryRepeat: "Powtarza się",
    summaryDay: "dni)",
    summaryWeek: "tydzień",
    summaryMonth: "miesięcy)",
    summaryYear: "rok (lata)",
    monthWeek: "Miesiąc Tydzień",
    monthPosition: "Pozycja miesiąca",
    monthExpander: "Expander miesięcy",
    yearExpander: "Expander roku",
    repeatInterval: "Powtarzaj interwał",
  },

  calendar: {
    today: "Dzisiaj",
  },
};
