import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { FormTextField, Loader } from "components";
import { loginSchema, initialValues, sx, inputProps } from "./login-form.constants";
import { LoginData } from "server/auth/auth.types";
import { STORAGE_FIELDS } from "constants/storage.constants";
import { login } from "server";
import { FORGOT_PASSWORD_PAGE, REGISTER_PAGE } from "constants/routes.constants";
import { setToken } from "store";
import { useRoutesPath } from "hooks";

import styles from "./login-form.module.scss";

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { localePath, locale } = useRoutesPath();

  const { submit, submitting, onSubmitSuccess, onSubmitError } = useSubmit(login);
  onSubmitSuccess(({ response }) => {
    dispatch(setToken(response.access));
    localStorage.setItem(STORAGE_FIELDS.token, response.access);
    localStorage.setItem(STORAGE_FIELDS.refreshToken, response.refresh);
    navigate(-1);
  });
  onSubmitError(() => {
    enqueueSnackbar(t("login.loginError"), { variant: "error" });
  });

  const handleLogin = async (values: LoginData, { setSubmitting }: FormikHelpers<LoginData>) => {
    await submit({ data: values });
    setSubmitting(true);
  };

  const localePl = locale === "pl-PL";

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema(t)} onSubmit={handleLogin}>
      <Form className={styles.container}>
        {submitting && <Loader className={styles.loader} />}
        <Typography className={styles.title} variant="h2" style={{ fontSize: localePl ? "45px" : "70px" }}>
          {t("login.loginTitle")}
        </Typography>
        <div className={styles.content}>
          <div className={styles.inputBox}>
            <Typography ml="10px" mb="-10px" fontFamily="Chillax-Regular">
              {t("login.emailLabel")}
            </Typography>
            <FormTextField name="email" type="email" variant="outlined" sx={sx} inputProps={inputProps} />
          </div>
          <div className={styles.inputBox}>
            <Typography ml="10px" mb="-10px" fontFamily="Chillax-Regular">
              {t("login.passwordLabel")}
            </Typography>
            <FormTextField name="password" type="password" sx={sx} InputProps={inputProps} />
          </div>
          <Typography fontFamily="Chillax-Regular" sx={{ ml: "10px" }}>
            {t("login.registerLabel")}{" "}
            <Link to={localePath({ route: REGISTER_PAGE })} className={styles.link}>
              {t("login.createAccount")}
            </Link>
            <Link to={localePath({ route: FORGOT_PASSWORD_PAGE })} className={styles.link} style={{ float: "right" }}>
              {t("login.forgotPasswordLabel")}
            </Link>
          </Typography>
          <Button type="submit" className={styles.button} variant="contained" disabled={submitting}>
            {t("login.login")}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
