import React, { useRef, useState } from "react";
import { IconButton, ListItem, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { getWatersportTranslation } from "utils/watersports.utils";
import { ARROW_WIDTH, ITEM_WIDTH } from "../filters";
import { WatersportsProps } from "./watersports.types";
import { useQueryParams, useWindowSize } from "hooks";
import { WatersportsData } from "server";

import styles from "../filters.module.scss";

export const Watersports: React.FC<WatersportsProps> = ({ options, wrapperRef, setData }) => {
  const watersportsInnerWrapperRef = useRef<null | HTMLDivElement>(null);

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const {
    updateQueryParams,
    query: { watersport: watersportQuery },
  } = useQueryParams();

  const [showWatersportsArrows, setShowWatersportsArrows] = useState<boolean>(false);
  const [numOfItemsToShow, setNumOfItemsToShow] = useState<number>(0);
  const [indexFrom, setIndexFrom] = useState<number>(0);
  const [indexTo, setIndexTo] = useState<number>(0);

  const handleWatersport = (watersport: WatersportsData) => {
    setData([]);

    if (watersport.name === watersportQuery) {
      updateQueryParams({ watersport: "" });
    } else {
      updateQueryParams({ watersport: watersport.name });
    }
  };

  const handleNextItem = () => {
    const indexDiff = indexTo - indexFrom;
    const newIndexTo = indexTo + 1;
    const newIndexFrom = newIndexTo - indexDiff;

    if (newIndexTo - 1 === options.length) return;

    setIndexTo(newIndexTo);
    setIndexFrom(newIndexFrom);
  };

  const handlePrevItem = () => {
    const indexDiff = indexTo - indexFrom;
    const newIndexTo = indexTo - 1;
    const newIndexFrom = newIndexTo - indexDiff;

    if (newIndexTo === 0) return;

    setIndexTo(newIndexTo);
    setIndexFrom(newIndexFrom);
  };

  const rightArrowDisabled = indexTo === options.length;
  const leftArrowDisabled = indexFrom === 0;

  useDidUpdate(
    () => {
      setIndexFrom(0);

      if (options.length >= numOfItemsToShow) return setIndexTo(numOfItemsToShow);
      if (options.length < numOfItemsToShow) return setIndexTo(options.length);
    },
    [options, numOfItemsToShow],
    true,
  );

  useDidUpdate(
    () => {
      const itemsWidth = options.length * ITEM_WIDTH;

      if (wrapperRef.current?.offsetWidth) {
        if (wrapperRef?.current?.offsetWidth < itemsWidth) {
          setShowWatersportsArrows(true);
        } else {
          setShowWatersportsArrows(false);
        }
      }
    },
    [width, options],
    true,
  );

  useDidUpdate(
    () => {
      if (wrapperRef.current?.offsetWidth && wrapperRef.current) {
        const wrapperWidth: number = wrapperRef.current?.offsetWidth;
        const num: number = (wrapperWidth - ARROW_WIDTH * 2) / ITEM_WIDTH;

        setNumOfItemsToShow(Math.floor(num));
      }
    },
    [wrapperRef.current?.offsetWidth],
    true,
  );

  return (
    <ul
      className={styles.itemsContainer}
      style={{ gridTemplateColumns: showWatersportsArrows ? `${ARROW_WIDTH}px 1fr ${ARROW_WIDTH}px` : "1fr" }}
    >
      {showWatersportsArrows && (
        <IconButton className={styles.arrowIcon} disabled={leftArrowDisabled} onClick={handlePrevItem}>
          {"<"}
        </IconButton>
      )}
      <div
        ref={watersportsInnerWrapperRef}
        className={styles.innerWrapper}
        style={{
          gridTemplateColumns: `repeat(${
            numOfItemsToShow <= options.length ? numOfItemsToShow : options.length
          },  ${ITEM_WIDTH}px)`,
        }}
      >
        {options.map((watersport, index) => {
          const isActive = watersport.name === watersportQuery;

          if (index < indexFrom) return null;
          if (index + 1 > indexTo) return null;

          return (
            <ListItem
              onClick={() => handleWatersport(watersport)}
              className={classNames(styles.itemElement, { [styles.itemElementActive]: isActive })}
            >
              <ReactSVG src={watersport.thumbnail_url} className={styles.itemIcon} />
              <Typography className={styles.itemName}>{getWatersportTranslation(t, watersport.name)}</Typography>
            </ListItem>
          );
        })}
      </div>
      {showWatersportsArrows && (
        <IconButton className={styles.arrowIcon} disabled={rightArrowDisabled} onClick={handleNextItem}>
          {">"}
        </IconButton>
      )}
    </ul>
  );
};
