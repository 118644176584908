import { array, mixed, number, object, string } from "yup";
import { TFunction } from "i18next";

export const detailsSchema = (t: TFunction, useOrganizationImages: boolean, numOfPhotos: number) =>
  object().shape({
    keyDetails: object().shape({
      title: string().required(t("createOffer.details.validation.title")),
      description: string().required(t("createOffer.details.validation.description")),
      photos: useOrganizationImages
        ? array()
        : array().of(mixed()).min(numOfPhotos, t("createOffer.details.validation.picturesMin5")).required(),
    }),
  });

const currentYear = new Date().getFullYear();
export const detailsSchemaBoat = (t: TFunction, useOrganizationImages: boolean, numOfPhotos: number) =>
  object().shape({
    keyDetails: object().shape({
      title: string().required(t("createOffer.details.validation.title")),
      description: string().required(t("createOffer.details.validation.description")),
      photos: useOrganizationImages
        ? array()
        : array().min(numOfPhotos, t("createOffer.details.validation.picturesMin5")).required(),
      additionalDetails: object().shape({
        boatLength: number()
          .required(t("createOffer.details.validation.boatLength"))
          .positive(t("createOffer.details.validation.boatLengthPositive")),
        manufacturer: string().required(t("createOffer.details.validation.manufacturer")),
        productionYear: number()
          .required(t("createOffer.details.validation.productionYear"))
          .min(1700, t("createOffer.details.validation.yearMin"))
          .max(currentYear, t("createOffer.details.validation.yearMax")),
      }),
    }),
  });

export const inputStyles = {
  color: "#000",
  input: { color: "#000", textAlign: "center", verticalAlign: "center", height: "100%" },
  backgroundColor: "#fff",
  borderRadius: "16px",
  height: "80px",
  width: "100%",
  margin: 0,
  padding: "6px 10px",
};

export const inputStylesDescription = {
  color: "#000",
  input: { color: "#000", textAlign: "center", verticalAlign: "center", height: "100%" },
  backgroundColor: "#fff",
  borderRadius: "16px",
  height: "125px",
  width: "100%",
  margin: 0,
  padding: "6px 10px",
};

export const inputStylesDetails = {
  color: "#000",
  input: { color: "#000", textAlign: "center", verticalAlign: "center", height: "35px" },
  backgroundColor: "#fff",
  borderRadius: "16px",
  height: "40px",
  width: "100%",
  margin: 0,
  padding: 0,
};
