import React, { useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { Typography } from "@mui/material";
import { ReactSVG } from "react-svg";

import { OfferBoxProps } from "./offer-box.types";
import { OFFER_PAGE } from "constants/routes.constants";
import { useRoutesPath, useWindowSize } from "hooks";
import { pricePerSelectOptions } from "../../../pages/offers/create/create-offer.constants";
import { getWatersportIcon } from "../../../utils/watersports.utils";
import { substringText } from "../../../utils";

import { GuestIcon, PinIcon, CaptainIcon, InstructorIcon } from "assets/icons";

import styles from "./offer-box.module.scss";

const CAPTAIN = "captain";
const INSTRUCTOR = "instructor";

export const OfferBox: React.FC<OfferBoxProps> = ({ offer }) => {
  const cartRef = useRef<null | HTMLAnchorElement>(null);

  const { width } = useWindowSize();
  const { localePathParams } = useRoutesPath();
  const { t } = useTranslation();

  const [titleLength, setTitleLength] = useState<number>(10);
  const [locationLength, setLocationLength] = useState<number>(15);

  const pricePerValue = pricePerSelectOptions(t).find((rateType) => rateType.value === offer?.rate_type)?.label || "";
  const offerDetails = useMemo(
    () => ({
      images: "",
      // offer.offer_images.length > 1
      //   ? offer.offer_images[0].image_url
      //   : offer.organization.organization_images[0].image_url,
      icon: getWatersportIcon(offer.watersport.id, t),
      title: offer.title ? offer.title : offer.organization.name,
      location: offer.location.city,
      instructorAvailable: offer.services.find((service) => service.title === INSTRUCTOR),
      captainAvailable: offer.services.find((service) => service.title === CAPTAIN),
      currency: `${offer.currency.toUpperCase()} ${pricePerValue.toLowerCase()}`,
      isBoatStep: offer.watersport.name === "SAILING" || offer.watersport.name === "MOTORBOAT",
    }),
    [offer, pricePerValue, t],
  );
  const showGuests = offerDetails.isBoatStep || !offerDetails.instructorAvailable || !offerDetails.captainAvailable;
  const offerPath = localePathParams({
    route: OFFER_PAGE,
    params: {
      offerId: offer.id,
      watersport: offer.watersport.name.toLowerCase(),
      location: offer.location.city.toLowerCase(),
    },
  });

  const title = substringText({
    string: offerDetails.title,
    cutAtChart: titleLength,
    replace: "...",
    maxLength: titleLength,
  });

  const location = substringText({
    string: offerDetails.location,
    cutAtChart: locationLength,
    replace: "...",
    maxLength: locationLength,
  });

  useDidUpdate(
    () => {
      if (cartRef.current && cartRef) {
        const itemWidth = cartRef.current?.offsetWidth;
        setTitleLength(itemWidth / 20);
        setLocationLength(itemWidth / 40);
      }
    },
    [width],
    true,
  );

  return (
    <Link ref={cartRef} to={offerPath} className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={offerDetails.images} alt={offer.title} className={styles.image} />
      </div>

      <div className={styles.content}>
        <Typography className={styles.title}>{title}</Typography>
        <div className={styles.details}>
          <div className={styles.detailsItem}>
            <PinIcon className={styles.pinIcon} />
            <Typography>{location}</Typography>
          </div>
          {offerDetails.instructorAvailable && !offerDetails.isBoatStep && (
            <div className={styles.detailsItem}>
              <InstructorIcon />
              <Typography>{t("offersList.offerBox.instructor")}</Typography>
            </div>
          )}
          {offerDetails.captainAvailable && (
            <div className={styles.detailsItem}>
              <CaptainIcon />
              <Typography> {t("offersList.offerBox.captain")}</Typography>
            </div>
          )}

          {showGuests && (
            <div className={styles.detailsItem}>
              <GuestIcon />
              <Typography>{offer.num_of_opportunities}</Typography>
            </div>
          )}
        </div>
        <ReactSVG src={offerDetails.icon} className={styles.icon} />
        <Typography className={styles.price}>
          {offer.price} <span>{offerDetails.currency}</span>
        </Typography>
      </div>
    </Link>
  );
};
