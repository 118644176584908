export const components = {
  partners: "Partners",
  findUs: "Find us on social media",
  marketplace: "Marketplace",
  guides: "Guides",
  safety: "Safety",
  startRenting: "Start renting",
  experiences: "Experiences",
  destinations: "Destinations",
  social: "Social",
  companyNews: "Company news",
  blog: "Blog",
  about: "About",
  aboutSeaeasy: "About SeaEasy",
  termsOfUse: "Terms of use",
  help: "Help",
  contactUs: "Contact us",
  privacyPolicy: "Privacy policy",
  knowledgeCenter: "Knowledge center",
  toTop: "To top",
};
