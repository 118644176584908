import React from "react";
import { useSelector } from "react-redux";
import { ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useFormikContext } from "formik";

import { RootState } from "store";
import { getWatersportTranslation } from "utils/watersports.utils";
import { AssetsData } from "../../../assets.types";

import styles from "./watersport.module.scss";

export const Watersport = () => {
  const { t } = useTranslation();
  const { watersports } = useSelector((state: RootState) => state.watersports);
  const { setFieldValue, values } = useFormikContext<AssetsData>();

  const handleClick = (id: number) => {
    setFieldValue("watersport", id);
  };

  return (
    <ul className={styles.container}>
      {watersports.map((watersport) => {
        const isSelected = values.watersport === watersport.id;
        return (
          <ListItem
            onClick={() => handleClick(watersport.id)}
            key={watersport.id}
            className={classNames(styles.watersportButton, { [styles.watersportButtonSelected]: isSelected })}
          >
            <img src={watersport.thumbnail_url} alt={watersport.name} />
            <Typography>{getWatersportTranslation(t, watersport.name)}</Typography>
          </ListItem>
        );
      })}
    </ul>
  );
};
