import React, { useContext } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SubmitButtonsProps } from "./submit-buttons.types";
import { HOME_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";
import { CreateOfferContext } from "../create-offer.context";

import styles from "./submit-buttons.module.scss";

export const SubmitButtons: React.FC<SubmitButtonsProps> = ({
  isLastStep,
  showPrevButton,
  handlePrevPage,
  showLandingButton,
  submitting,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();
  const { isAddingLocation } = useContext(CreateOfferContext);

  const submitButtonText = isLastStep ? t("common.publish") : t("common.next");

  const openLanding = () => navigate(localePath({ route: HOME_PAGE }));

  return (
    <div className={styles.container}>
      {showLandingButton && (
        <Button className={styles.button} onClick={openLanding}>
          {t("common.homePage")}
        </Button>
      )}

      {showPrevButton && (
        <Button className={styles.button} onClick={handlePrevPage}>
          {t("common.back")}
        </Button>
      )}
      <Button type="submit" className={styles.nextButton} disabled={submitting || isAddingLocation}>
        {!submitting ? submitButtonText : t("common.submitting")}
      </Button>
    </div>
  );
};
