export const offersList = {
  map: "Mapa",
  weather: "Pogoda",
  offers: "Oferty",
  noOffers: "Nie znaleziono ofert!",
  showAllOffers: "Pokaż wszystkie oferty",
  somethingWentWrong: "Arrr! Coś poszło nie tak!",
  refresh: "Odśwież",

  offerBox: {
    captain: "Kapitan",
    instructor: "Instruktor",
  },
};
