import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RulesProps } from "./rules.types";

import styles from "./rules.module.scss";

export const Rules: React.FC<RulesProps> = ({ rules }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Typography fontFamily="Chillax-Semibold" className={styles.title}>
        {t("offerPage.rentalRules.rentalRules")}
      </Typography>

      <div className={styles.content}>
        <Typography>{rules}</Typography>
      </div>
    </div>
  );
};
