import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ReactComponent as WaveMobile } from "assets/icons/wave-mobile.svg";
import { ReactComponent as WaveDesktop } from "assets/icons/wave-desktop.svg";
import { ReactComponent as SunMobile } from "assets/icons/sun-icon-mobile.svg";
import { ReactComponent as SunDesktop } from "assets/icons/sun-icon-desktop.svg";

import styles from "./hero.module.scss";

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.hero}>
      <div className={styles.heroContent}>
        <SunMobile className={classNames(styles.sun, styles.mobile)} />
        <SunDesktop className={classNames(styles.sun, styles.desktop)} />
        <WaveMobile className={classNames(styles.wave, styles.mobile)} />
        <WaveDesktop className={classNames(styles.wave, styles.desktop)} />
        <div className={styles.heroText}>
          <Typography variant="h2" className={styles.title}>
            {t("about.hero.aboutUs")}
          </Typography>
          <Typography variant="h3" className={styles.content}>
            {t("about.hero.content")}
          </Typography>
        </div>
      </div>
    </div>
  );
};
