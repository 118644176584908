import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { AssetsBoxProps } from "./assets-box.types";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import styles from "./assets-box.module.scss";

export const AssetsBox: React.FC<AssetsBoxProps> = ({ asset }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.mobileContent}>
        <Typography className={styles.name}>{asset.type}</Typography>
        <IconButton onClick={handleOpen}>
          <ArrowIcon className={classNames(styles.mobileIcon, { [styles.mobileIconOpen]: isOpen })} />
        </IconButton>
      </div>
      {isOpen && (
        <div className={styles.mobileDetails}>
          <div className={styles.mobileRoleWrapper}>
            <Typography className={styles.mobileRole}>{t("assets.asset")}:</Typography>
            <Typography />
          </div>
        </div>
      )}
    </div>
  );
};
