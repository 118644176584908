import React, { Context, useContext, useRef } from "react";
import { Button } from "antd";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { Calendar } from "../components/calendar/calendar";
import { Details } from "../components/details/details";
import { CalendarViewContextType } from "../wrapper/calendar-view.context";
import { Loader } from "../../client";
import { Summary } from "../components/summary/summary";
import { ReservationsContext } from "../reservations-modal.context";
import { getViewContext } from "../wrapper/wrapper.constants";
import { postReservation } from "../../../server";
import { FormValues } from "../components/contact-person/contact-person.types";
import { ContactPerson } from "../components/contact-person/contact-person";

import styles from "./calendar-view-own.module.scss";

export const CalendarViewOwn = React.memo(() => {
  const contactPersonRef = useRef<FormikProps<FormValues>>(null);
  // const guestsDetailsRef = useRef<FormikProps<GuestData>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { isOwnReservation, isCalendarView, setOffer, offer, modalClose, refreshReservations } =
    useContext(ReservationsContext);

  const {
    step,
    handlePrevStep,
    handleNextStep,
    selectedRange,
    slots,
    serviceSelectedWorker,
    setFormData,
    formData,
    totalPrice,
    guests,
    selectedServices,
  } = useContext(getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>);

  const handleNextButtonClick = () => {
    if (step === 3) {
      return contactPersonRef.current?.submitForm();
    }

    // if (step === 4) {
    //   return guestsDetailsRef.current?.submitForm();
    // }
    handleNextStep();
  };

  const prevButtonDisabled = step === 1;
  const isNextButtonDisabled = step === 2 && selectedRange.length === 0;

  const handleFormSubmissionSuccessContactPerson = (values: FormValues) => {
    if (step === 3) {
      setFormData(values);
      handleNextStep();
    }
  };

  //
  // const handleFormSubmissionGuestsDetails = (values: GuestData, currentGuestIndex: number) => {
  //   if (step === 4) {
  //     const updatedValues: GuestData = {
  //       ...values,
  //     };
  //
  //     const updatedGuests = [...guestsData];
  //     updatedGuests[currentGuestIndex] = updatedValues;
  //     setGuestsData(updatedGuests);
  //     handleNextStep();
  //   }
  // };

  const { submit, submitting, onSubmitSuccess } = useSubmit(postReservation);

  const handleReservation = async () => {
    const services: {
      id: number;
      user_id?: number;
    }[] = [];

    serviceSelectedWorker.forEach((worker) => {
      return services.push({
        id: worker.service_id,
        user_id: worker.id,
      });
    });

    selectedServices.forEach((service) => {
      if (service.users.length === 0) {
        return services.push({
          id: service.id,
        });
      }
    });

    function findFirstAndLast(dates: string[]): { dateTimeFrom: string; dateTimeTo: string } {
      dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

      const firstDate = dates[0];
      const lastDate = dates[dates.length - 1];

      return { dateTimeFrom: firstDate, dateTimeTo: lastDate };
    }

    const data = {
      slots: [findFirstAndLast(selectedRange)],
      offer: offer?.id,
      num_of_guests: guests,
      services,
      price: totalPrice,
      user: {
        first_name: formData?.name,
        last_name: formData?.surname,
        email: formData?.email,
        phone_number: formData?.email,
      },
    };

    await submit({ data });
  };

  onSubmitSuccess(() => {
    modalClose();
    if (refreshReservations) refreshReservations();
    enqueueSnackbar(t("reservationsModal.reservationSent"), { variant: "success" });
  });

  return (
    <div className={styles.container}>
      {step === 1 && <Details />}

      {step === 2 && slots && <Calendar />}
      {step === 2 && !slots && offer && <Loader color="#fcf9f6" backgroundColor="#fcf9f6" />}

      {step === 3 && (
        <ContactPerson ref={contactPersonRef} onFormSubmissionSuccess={handleFormSubmissionSuccessContactPerson} />
      )}

      {/*{step === 4 && (*/}
      {/*  <GuestsDetails ref={guestsDetailsRef} onFormSubmissionSuccess={handleFormSubmissionGuestsDetails} />*/}
      {/*)}*/}

      {step === 4 && <Summary />}

      <div className={styles.buttons}>
        <Button className={styles.button} onClick={() => setOffer(null)}>
          {t("reservationsModal.selectOffer")}
        </Button>
        <Button className={styles.button} onClick={handlePrevStep} disabled={prevButtonDisabled}>
          {"<"}
        </Button>
        {step !== 4 && (
          <Button
            className={styles.button}
            htmlType="button"
            disabled={isNextButtonDisabled}
            onClick={handleNextButtonClick}
          >
            {">"}
          </Button>
        )}

        {step === 4 && (
          <Button className={styles.button} disabled={submitting} onClick={handleReservation}>
            {t("reservationsModal.book")}
          </Button>
        )}
      </div>
    </div>
  );
});
