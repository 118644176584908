import React, { useContext } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ItemBoxDesktopProps } from "./item-box-desktop.types";
import { ManagementContext } from "../../../management.context";

import styles from "./item-box-desktop.module.scss";

export const ItemBoxDesktop: React.FC<ItemBoxDesktopProps> = ({ name, nextBooking }) => {
  const { t } = useTranslation();
  const { openGuestsDetails } = useContext(ManagementContext);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Typography className={styles.name}>{name}</Typography>
        <Typography className={styles.nextBooking}>
          <span>{t("insights.clientBookings.nextBooking")}</span>
          <br /> {nextBooking}
        </Typography>
      </div>

      <div className={styles.content}>
        <div className={styles.time}>
          <Typography>
            252h
            <br /> {t("insights.clientBookings.soFar")}
          </Typography>
          <Typography>
            28h <br /> {t("insights.clientBookings.remaining")}
          </Typography>
        </div>
        <div className={styles.progress}>
          <div />
        </div>
      </div>

      <IconButton onClick={() => openGuestsDetails(1)} className={styles.iconButton}>
        <KeyboardArrowDownIcon />
      </IconButton>
    </div>
  );
};
