import React from "react";
import { Rating, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { DescriptionProps } from "./description.types";

import styles from "./description.module.scss";

export const Description: React.FC<DescriptionProps> = ({
  className,
  city,
  country,
  region,
  title,
  comments = 0,
  rating = 5,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, className)}>
      <Typography fontFamily="Chillax-Semibold" className={styles.title}>
        {title}
      </Typography>
      <div className={styles.content}>
        <Typography fontFamily="Chillax-Light">
          {city}, {region}, {country}
        </Typography>
        <div className={styles.row}>
          <Rating readOnly value={rating} className={styles.rating} />
          <Typography fontFamily="Chillax-Light">({t("offerPage.offerDescription.comments", { comments })})</Typography>
        </div>
      </div>
    </div>
  );
};
