import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { OfferDescriptionProps } from "./offer-description.types";

import styles from "./offer-description.module.scss";

export const OfferDescription: React.FC<OfferDescriptionProps> = ({ offerDescription, rentalDescription }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Typography fontFamily="Chillax-Semibold" className={styles.title}>
        {t("offerPage.description.description")}
      </Typography>

      <div className={styles.content}>
        <Typography>{offerDescription || rentalDescription}</Typography>
      </div>
    </div>
  );
};
