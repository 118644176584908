import { builder } from "../builder";

export type SlotsData = {
  datetime_from: string;
  datetime_to: string;
  offer_id: number;
  services: {
    id: number;
    user_id: number;
  }[];
};

export type Slots = {
  dateTimeFrom: string;
  dateTimeTo: string;
  slotId: string;
  quantity: number;
};

export const getAvailability = builder.createCommand<Slots[], SlotsData>()({
  endpoint: "/app/slots",
  method: "POST",
});
