export const burgerMenu = {
  login: "Zaloguj się",
  register: "Zarejestruj się",
  offers: "Oferty",
  account: "Konto",
  createOffer: "Stwórz ofertę",
  createRental: "Stwórz organizację",
  logOut: "Wyloguj",
  checkReservations: "Sprawdź rezerwacje",
  management: "Zarządzanie",
  addPayment: "Dodaj płatność",
  loading: "Ładowanie...",
};
