import React, { useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { OfferItemProps } from "./offer-item.types";
import { Modal } from "components";
import { OfferLinksModal } from "../offer-links-modal/offer-links-modal";
import { ModalData } from "../offer-item-desktop/offer-item-desktop.types";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import styles from "./offer-item.module.scss";

export const OfferItem: React.FC<OfferItemProps> = ({ offer }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState<boolean>(false);
  const [isOfferLinksModalOpen, setIsOfferLinksModalOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleModal = (modal: ModalData, value: boolean) => {
    switch (modal) {
      case "offerLink":
        return setIsOfferLinksModalOpen(value);

      case "availability":
        return setIsAvailabilityModalOpen(value);

      default:
    }
  };

  const offerTitle = offer.title ? offer.title : offer.organization.name;
  const offerNoServices = offer.services.length === 0;
  const servicesCopy =
    offer.services.length === 1
      ? offer.services[0].title
      : `${offer.services.length} ${t("availability.offers.service")}`;

  return (
    <div className={styles.container}>
      <Typography className={styles.offerTitle}>{offerTitle}</Typography>
      <IconButton className={styles.iconButton} onClick={handleOpen} disabled={offerNoServices}>
        <ArrowIcon />
      </IconButton>

      {isOpen && (
        <div className={styles.openContent}>
          <div className={styles.actionWrapper}>
            <Typography className={styles.actionName}>{t("availability.offers.service")}:</Typography>
            <Typography className={styles.actionContent}>{servicesCopy}</Typography>
          </div>

          <Button
            onClick={() => handleModal("offerLink", true)}
            className={styles.availabilityButton}
            disabled={offerNoServices}
          >
            {t("availability.actionButtons.addAvailability")}
          </Button>
        </div>
      )}

      <Modal open={isAvailabilityModalOpen}>
        <Typography>availability modal open</Typography>
      </Modal>
      <Modal open={isOfferLinksModalOpen}>
        <OfferLinksModal offer={offer} close={() => handleModal("offerLink", false)} />
      </Modal>
    </div>
  );
};
