import React, { ReactNode, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { sectionData } from "./section.constants";
import { RootState } from "store";
import { useWindowSize } from "hooks";

import { ReactComponent as RoadMapMobilePl } from "assets/icons/raod-map-mobile-pl.svg";
import { ReactComponent as RoadMapMobileEn } from "assets/icons/raod-map-mobile-en.svg";
import { ReactComponent as RoadMapDesktopPl } from "assets/icons/raod-map-desktop.svg";
import { ReactComponent as RoadMapDesktopEn } from "assets/icons/raod-map-desktop-en.svg";

import styles from "./section.module.scss";

export const Section = () => {
  const { t } = useTranslation();
  const { locale } = useSelector((state: RootState) => state.app);

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const { width } = useWindowSize();

  const nextSlide = () => {
    if (sectionData(t).length - 1 === activeSlide) return setActiveSlide(0);
    setActiveSlide((prevState) => prevState + 1);
  };

  const prevSlide = () => {
    if (activeSlide === 0) return setActiveSlide(sectionData(t).length - 1);
    setActiveSlide((prevState) => prevState - 1);
  };

  const isMobile = width < 576;

  const showRoadMap = (): ReactNode => {
    if (isMobile) {
      return locale === "pl-PL" ? (
        <RoadMapMobilePl className={styles.roadMap} />
      ) : (
        <RoadMapMobileEn className={styles.roadMap} />
      );
    }
    return locale === "pl-PL" ? (
      <RoadMapDesktopPl className={styles.roadMap} />
    ) : (
      <RoadMapDesktopEn className={styles.roadMap} />
    );
  };

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <Typography variant="h2" className={styles.title}>
          {t("about.section.howItWorks")}
        </Typography>
        <Typography variant="h3" className={styles.content}>
          {t("about.hero.content")}
        </Typography>
      </div>
      {showRoadMap()}
      <div className={classNames(styles.slider, styles.mobile)}>
        <div className={styles.arrows}>
          <IconButton className={styles.iconButton} onClick={prevSlide}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton className={styles.iconButton} onClick={nextSlide}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        <div className={styles.slides}>
          <div className={styles.slideBox}>
            <Typography variant="h3">
              {activeSlide + 1}. {sectionData(t)[activeSlide].title}
            </Typography>
            <Typography variant="h4">{sectionData(t)[activeSlide].content}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
