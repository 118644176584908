import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import { ModalData, OfferItemDesktopProps } from "./offer-item-desktop.types";
import { Modal } from "components";
import { OfferLinksModal } from "../offer-links-modal/offer-links-modal";

import styles from "./offer-item-desktop.module.scss";

export const OfferItemDesktop: React.FC<OfferItemDesktopProps> = ({ offer }) => {
  const { t } = useTranslation();

  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState<boolean>(false);
  const [isOfferLinksModalOpen, setIsOfferLinksModalOpen] = useState<boolean>(false);

  const handleModal = (modal: ModalData, value: boolean) => {
    switch (modal) {
      case "offerLink":
        return setIsOfferLinksModalOpen(value);

      case "availability":
        return setIsAvailabilityModalOpen(value);

      default:
    }
  };

  const offerTitle = offer.title ? offer.title : offer.organization.name;
  const offerNoServices = offer.services.length === 0;
  const servicesCopy =
    offer.services.length === 1
      ? offer.services[0].title
      : `${offer.services.length} ${t("availability.offers.service")}`;

  return (
    <div className={styles.container}>
      <Typography>{offerTitle}</Typography>

      <Typography>{offerNoServices ? t("availability.offers.noServices") : servicesCopy}</Typography>

      <Button disabled={offerNoServices} onClick={() => handleModal("offerLink", true)} className={styles.button}>
        <Typography>{t("availability.offers.edit")}</Typography>
        <AddIcon className={styles.buttonIcon} />
      </Button>

      <Modal open={isAvailabilityModalOpen}>
        <Typography>availability modal open</Typography>
      </Modal>
      <Modal open={isOfferLinksModalOpen}>
        <OfferLinksModal offer={offer} close={() => handleModal("offerLink", false)} />
      </Modal>
    </div>
  );
};
