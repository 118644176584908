import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AccordionItems } from "./help-center.constants";
import { AccordionItem } from "./accordion-item/accordion-item";

import background from "assets/images/background_account.png";

import styles from "./help-center.module.scss";

export const HelpCenterPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography fontSize={16} fontWeight={300}>
            {t("account.helpCenter")}
          </Typography>
          <Typography fontSize={32} fontWeight={700}>
            {t("account.howHelp")}
          </Typography>
        </div>
        <div className={styles.mainContent}>
          {AccordionItems(t).map((item) => (
            <AccordionItem key={item.title} title={item.title} content={item.content} />
          ))}
        </div>
        <div className={styles.section}>
          <Typography>{t("account.needToGetInTouch")}</Typography>
          <Typography fontSize={14} fontWeight="300" color="#aaa">
            {t("account.needToGetInTouchContent")}
          </Typography>
          <Button className={styles.button}>{t("common.contactUs")}</Button>
          <Typography fontSize={14} fontWeight="300">
            {t("account.giveFeedback")}
          </Typography>
        </div>
      </div>
    </div>
  );
};
