import { routes } from "./routes.translations";
import { home } from "./home.translations";
import { components } from "./components.translations";
import { login } from "./login.translations";
import { register } from "./register.translations";
import { common } from "./common.translations";
import { createOffer } from "./create-offer.translations";
import { burgerMenu } from "./burger-menu.translations";
import { offerPage } from "./offer-page.translations";
import { account } from "./account.translations";
import { rental } from "./rental.translations";
import { seo } from "./seo.translations";
import { termsOfUse } from "./terms-of-use.translations";
import { empytPage } from "./empyt-page.translations";
import { booking } from "./booking.translations";
import { reservations } from "./reservations.translations";
import { offersList } from "./offers-list.translations";
import { ownerReservations } from "./owner-reservations.translations";
import { about } from "./about";
import { navbar } from "./navbar.translations";
import { cookiesConsent } from "./cookie-consent.translations";
import { contact } from "./contact.translations";
import { watersports } from "./watersports.translations";
import { author } from "./author.translations";
import { article } from "./article.translations";
import { management } from "./management.translations";
import { paymentsCheck } from "./payments-check.translations";
import { queryParams } from "./query-params.translations";
import { pathParams } from "./path-params.translations";
import { profile } from "./profile.translations";
import { team } from "./team.translations";
import { notFoundPage } from "./not-found-page.translations";
import { availability } from "./availability.translations";
import { assets } from "./assets.translations";
import { insights } from "./insights.translation";
import { dates } from "./dates.translations";
import { reservationsModal } from "./reservations-modal.translations";

export const plTranslations = {
  about,
  routes,
  home,
  components,
  login,
  register,
  common,
  createOffer,
  burgerMenu,
  offerPage,
  account,
  rental,
  seo,
  termsOfUse,
  empytPage,
  booking,
  offersList,
  reservations,
  ownerReservations,
  navbar,
  cookiesConsent,
  contact,
  watersports,
  author,
  article,
  management,
  paymentsCheck,
  queryParams,
  pathParams,
  profile,
  team,
  availability,
  assets,
  notFoundPage,
  insights,
  dates,
  reservationsModal,
};
