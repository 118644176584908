import { builder } from "server/builder";
import { GetOfferServiceData, ServiceAssetData, ServiceHostData } from "./services.types";

export const getServices = builder.createCommand<GetOfferServiceData>()({
  endpoint: "/app/offer/:offerId/service",
  method: "GET",
});

export const serviceAssetPost = builder.createCommand<void, ServiceAssetData>()({
  endpoint: "/app/service_asset",
  method: "POST",
  retry: 5,
});

export const serviceHostsPost = builder.createCommand<void, ServiceHostData>()({
  endpoint: "/app/service_user",
  method: "POST",
  retry: 5,
});

export const serviceHostsDelete = builder.createCommand<void, ServiceHostData>()({
  endpoint: "/app/service_user",
  method: "POST",
  retry: 5,
});

export const deleteServiceAsset = builder.createCommand<unknown, ServiceAssetData>()({
  endpoint: "/app/service_asset",
  method: "DELETE",
});

export const deleteServiceHost = builder.createCommand<unknown, ServiceAssetData>()({
  endpoint: "/app/service_user",
  method: "DELETE",
});
