import React, { useContext, useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";

import { ModalData, servicesData } from "./services.constatns";
import { Modal } from "components";
import {
  AdditionalServicesData,
  CaptainData,
  ExtraChargesData,
  InstructorData,
  OfferData,
} from "../create-offer.types";
import { AdditionalServicesModal } from "./additional-services-modal/additional-services-modal";
import { ExtraChargesModal } from "./extra-charges-modal/extra-charges-modal";
import { InstructorModal } from "./instructor-modal/instructor-modal";
import { ServiceOption } from "./service-option/service-option";
import { ServiceItem } from "./service-item/service-item";
import { CaptainModal } from "./captain-modal/captain-modal";
import { CreateOfferContext } from "../create-offer.context";

import { ReactComponent as AdditionalServicesIcon } from "assets/icons/additional-services-icon-white.svg";
import { ReactComponent as ExtraChargesIcon } from "assets/icons/extra-charges-icon-white.svg";
import { ReactComponent as CaptainIcon } from "assets/icons/captain-icon-white.svg";
import { ReactComponent as InstructorIcon } from "assets/icons/instructor-icon-white.svg";

import styles from "./services.module.scss";

export const Services = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<OfferData>();
  const { captains, instructors, additionalServices, extraCharges } = values.services;
  const { setIsAddingLocation } = useContext(CreateOfferContext);

  const [modalVariant, setModalVariant] = useState<ModalData | null>(null);

  const setModalClose = () => {
    setModalVariant(null);
  };

  const showServices = (services: AdditionalServicesData[] | CaptainData[] | InstructorData[] | ExtraChargesData[]) =>
    services.length > 0;

  useDidMount(() => {
    setIsAddingLocation(false);
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.servicesToAdd}>
          {servicesData(t).map((item) => (
            <ServiceOption key={item.name} item={item} setIsModalOpen={setModalVariant} />
          ))}
        </div>

        <div className={styles.services}>
          <div className={styles.servicesWrapper}>
            {showServices(captains) ? (
              captains?.map((captainItem, index) => (
                <ServiceItem
                  key={captainItem.id}
                  captain={captainItem}
                  number={index}
                  setIsModalOpen={setModalVariant}
                />
              ))
            ) : (
              <div className={styles.iconWrapper}>
                <CaptainIcon className={styles.logo} />
              </div>
            )}
          </div>
          <div className={styles.servicesWrapper}>
            {showServices(instructors) ? (
              instructors?.map((instructorItem, index) => (
                <ServiceItem
                  key={instructorItem.id}
                  number={index}
                  setIsModalOpen={setModalVariant}
                  instructor={instructorItem}
                />
              ))
            ) : (
              <div className={styles.iconWrapper}>
                <InstructorIcon className={styles.logo} />
              </div>
            )}
          </div>
          <div className={styles.servicesWrapper}>
            {showServices(additionalServices) ? (
              additionalServices?.map((additionalService, index) => (
                <ServiceItem setIsModalOpen={setModalVariant} additionalService={additionalService} number={index} />
              ))
            ) : (
              <div className={styles.iconWrapper}>
                <AdditionalServicesIcon className={styles.logo} />
              </div>
            )}
          </div>
          <div className={styles.servicesWrapper}>
            {showServices(extraCharges) ? (
              extraCharges?.map((extraCharge, index) => (
                <ServiceItem
                  key={extraCharge.id}
                  setIsModalOpen={setModalVariant}
                  extraCharge={extraCharge}
                  number={index}
                />
              ))
            ) : (
              <div className={styles.iconWrapper}>
                <ExtraChargesIcon className={styles.logo} />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal onClose={setModalClose} open={!!modalVariant}>
        <div className={styles.modal}>
          {modalVariant === "captain" && <CaptainModal close={setModalClose} />}
          {modalVariant === "additional-services" && <AdditionalServicesModal close={setModalClose} />}
          {modalVariant === "extra-charges" && <ExtraChargesModal close={setModalClose} />}
          {modalVariant === "instructor" && <InstructorModal close={setModalClose} />}
        </div>
      </Modal>
    </>
  );
};
