export const createOffer = {
  steps: {
    addEquipment: "Dodaj swój sprzęt",
    addDetails: "Dodaj kluczowe szczegóły",
    addServices: "Dodaj usługi",
    addLocation: "Dodaj lokalizację",
    preview: "Podgląd",
    step: "krok",
    baseInfo: "Informacje podstawowe",
  },

  categories: {
    rental: "Wypożyczenie",
    kitesafari: "Kitesafari",
    cruise: "Rejs",
    forKids: "Dla dzieci / Kolonia",
    sportCourse: "Kurs sportowy",
    surfTrip: "Wycieczka surfingowa",
    divingTrip: "Nurkowanie",
    online: "Online",
    trainingCamp: "Obóz treningowy",
  },

  categorySelection: {
    chooseCategory: "Wybierz kategorie",
    chooseWatersport: "Wybierz sport",
  },

  baseInfo: {
    mandatory: "(obowiązkowe)",
    optional: "(opcjonalne)",
    pricing: {
      addPricing: "Dodaj cennik",
      editPricing: "Edytuj cennik",
      currentPricing: "Aktualny cennik",
      basePrice: "Cena podstawowa: ",
      rateType: "Stawka: ",
      deposit: "Depozyt: ",
      currency: "Walut: ",
      maxGuests: "Maks. gości: ",
      discount: "Zniżki: ",
    },
    availability: {
      addAvailability: "Dodaj dostępność",
      editAvailability: "Edytuj dostępność",
      currentAvailability: "Aktualna dostępność",
      months: "Miesiące: ",
      startTime: "Czas startu: ",
      endTime: "Czas zakończenia: ",
      startDate: "Data startu: ",
      endDate: "Data zakończenia: ",
      days: "Dni: ",
      brakeAfter: "Przerwa po (min): ",
      duration: "Czas trwania (min): ",
    },
    included: {
      addWhatsIncluded: "Dodaj to, co jest wliczone w cenę",
      editIncluded: "Edytuj to, co jest wliczone w cenę",
      currentIncluded: "Aktualnie, co jest wliczone w cenę",
      add: "Dodaj",
      included: "Wliczone: ",
      notIncluded: "Nie wliczone: ",
    },

    modal: {
      pricing: {
        addPricing: "Dodaj cennik",
        basePrice: "Cena bazowa",
        maxGuests: "Maks. gości",
        deposit: "Depozyt",
        rateType: "Stawka",
        currency: "Waluta",
      },
      included: {
        addWhatsIncluded: "Dodaj to, co jest wliczone w cenę",
        whatsIncluded: "Co jest wliczone",
        addFirstItem: "Dodaj pierwszą pozycję",
        whatsNotIncluded: "Co nie jest wliczone",
      },
      availability: {
        addAvailability: "Dodaj dostępność",
        months: "Miesiące",
        days: "Dni",
        startTime: "Czas start",
        endTime: "Czas zakończenia",
        duration: "Czas trwania (min)",
        brakeAfter: "Przerwa po (min)",
        startDate: "Data startu",
        endDate: "Data zakończenia",
      },
    },
  },

  equipmentDetails: {
    categorySelection: "Wybór kategorii",
    price: "Cena",
    currency: "Waluta",
    rateType: "Typ stawki",
    deposit: "Depozyt",
    guests: "Maks. gości",

    tooltip: {
      category: "Wybór kategorii sportu wodnego pozwoli nam odpowiednio wyświetlić twoją ofertę potencjalnym klientom.",
      price:
        "Cena - bazowa cena wynajmu, którą klienci muszą zapłacić za twój sprzęt. Dodatkowe opłaty i usługi mogą zostać dodane w kolejnych krokach.",
      deposit:
        "Dodanie depozytu jest opcjonalne, a podana cena będzie jedynie wyświetlana. SeaEasy nie pośredniczy w pobieraniu kaucji.",
      rateType:
        "Akceptowane jest 8 typów stawek: za pół godziny, za godzinę, za dzień, za tydzień, a także stawki za osobę za określony czas. Ten typ stawki będzie wykorzystywany przy dodawaniu wszystkich elementów oferty i zostanie użyty do obliczania wartości rezerwacji.",
    },
  },

  details: {
    createYourTitle: "Stwórz tytuł",
    createYourDescription: "Stwórz opis",
    additionalDetails: "Dodatkowe szczegóły",
    boatLength: "Długość łodzi (m)",
    manufacturer: "Producent",
    productionYear: "Rok produkcji",
    addPictures: "Dodaj zdjęcia",
    organizationPhotos: "Użyj zdjęć organizacji",

    validation: {
      title: "Tytuł jest wymagany",
      description: "Opis jest wymagany",
      boatLength: "Długość łodzi jest wymagana",
      boatLengthPositive: "Długość łodzi musi być większa niż 0",
      manufacturer: "Producent jest wymagany",
      productionYear: "Rok produkcji jest wymagany",
      yearMin: "Rok musi być większy niż 1700",
      yearMax: "Rok musi być mniejszy niż 2024",
      pictures: "Zdjęcia są wymagane",
      picturesMin5: "Dodaj min. 5 zdjęć",
    },

    tooltip: {
      title: "Tytuł - Tytuł będzie wyświetlany jako nagłówek twojej oferty.",
      pictures: `Dodaj przynajmniej 5 zdjęć do swojej oferty. Format zdjęcia: .jpg, .jpeg, .png. 
      Max rozmiar: 10MB`,
    },
  },

  services: {
    captain: "Kapitan",
    option: "Opcjonalne",
    instructor: "Instruktor",
    additionalServices: "Dodatkowe usługi",
    extraCharges: "Dodatkowe opłaty",
    add: "Add {{type}}",
    maxGuests: "Maksymalnie gości",
    fixedPrice: "Ryczałt",
    captainRequired: "Wymagany kapitan",
    instructorRequired: "Wymagany instruktor",
    isRequired: "jest wymagane",

    modal: {
      name: "Nazwa",
      price: "Cena",
      isFixed: "Ryczałt",
      maxGuests: "Maksymalna liczba gości",
      instructorRequired: "Instruktor wymagany",
      captainRequired: "Kapitan wymagany",
      addAdditionalServices: "Dodaj dodatkowe usługi",
      addCaptain: "Dodaj kapitana",
      addInstructor: "Dodaj instruktora",
      addExtraCharges: "Dodaj dodatkowe opłaty",
    },

    tooltip: {
      captain:
        "Kapitan do wynajęcia może zostać dodany do twojej oferty. Cena będzie zaprezentowana osobno jako opcjonalny dodatek dla klientów.",
      instructor:
        "Instruktor do wynajęcia może zostać dodany do twojej oferty. Cena będzie zaprezentowana osobno jako opcjonalny dodatek dla klientów.",
      additionalServices:
        "Usługi dodatkowe mogą zostać dodane przez klienta do podstawowej oferty wynajmu. Dodaj tu usługi takie jak wynajem pianki czy pakiet powitalny itp.",
      extraCharges:
        "Dodatkowe opłaty zostaną dodane do twojej podstawowej oferty wynajmu. Dodatkowe opłaty to przykładowo sprzątanie czy podatki i inne.",
      modal: {
        price:
          "Cena - bazowa cena za wynajęcie twojego kapitana, która zostanie dodana do twojej podstawowej ceny wynajmu. Typ stawki wynajmu może zostać zmieniony z stawki za czas na ryczałt.",
        priceInstructor:
          "Cena - bazowa cena za wynajęcie twojego instruktora, która zostanie dodana do twojej podstawowej ceny wynajmu. Typ stawki wynajmu może zostać zmieniony z stawki za czas na ryczałt.",
        priceAdditional:
          "Cena - bazowa cena za skorzystanie z twojej dodatkowej usługi, która zostanie dodana do twojej podstawowej ceny wynajmu. Typ stawki wynajmu może zostać zmieniony z stawki za czas na ryczałt.",
        priceCharges:
          "Cena - bazowa cena twoich dodatkowych opłat, która zostanie dodana do twojej podstawowej ceny wynajmu. Typ stawki wynajmu może zostać zmieniony z stawki za czas na ryczałt.",
        fixedPrice:
          "Ryczałt - jednorazowa opłata za wynajem niezależna od czasu. Zaznacz aby typ stawki wynajmu twojego kapitana został zmieniony z stawki za czas na ryczałt.",
        fixedPriceInstructor:
          "Ryczałt - jednorazowa opłata za wynajem niezależna od czasu. Zaznacz aby typ stawki wynajmu twojego instruktora został zmieniony z stawki za czas na ryczałt.",
        fixedPriceAdditional:
          "Ryczałt - jednorazowa opłata za wynajem niezależna od czasu. Zaznacz aby typ stawki wynajmu twoich usług dodatkowych został zmieniony z stawki za czas na ryczałt.",
        fixedPriceCharges:
          "Ryczałt - jednorazowa opłata dodatkowa niezależna od czasu. Zaznacz aby typ stawki twoich opłat dodatkowych został zmieniony z stawki za czas na ryczałt.",
        captainRequired:
          "Kapitan wymagany - Zaznacz aby wynajęcie kapitana było wymagane przy wynajęciu twojej podstawowej oferty.",
        instructorRequired:
          "Instruktor wymagany - Zaznacz aby wynajęcie instruktora było wymagane przy wynajęciu twojej podstawowej oferty.",
      },
    },
  },

  location: {
    yourLocations: "Lokalizacje Twojej organizacji",
    createNew: "Utwórz nową lokalizację",
    useSaved: "Użyj zapisanych lokalizacji",
    validate: "Validate",
    addAddress: "Dodaj adres swojego sprzętu",
    name: "Nazwa",
    description: "Opis",
    address: "Adres",
    city: "Miasto",
    region: "Region",
    postalCode: "Kod pocztowy",
    country: "Kraj",
    confirmYourAddress: "Potwierdź swój adres",
    createdSuccessfully: "Twoja lokalizacja została utworzona pomyślnie",

    validation: {
      name: "Nazwa jest wymagana",
      address: "Adres jest wymagany",
      city: "Miasto jest wymagane",
      country: "Kraj jest wymagany",
      latitude: "Szerokość geograficzna jest wymagana",
      longitude: "Długość geograficzna jest wymagana",
      postalCode: "Kod pocztowy jest wymagany",
    },
  },

  rateType: {
    halfHour: "Pół godziny",
    hour: "Godzina",
    day: "Dzień",
    week: "Tydzień",
    halfHourPerson: "Pół godziny na osobę",
    hourPerson: "Godzina na osobę",
    dayPerson: "Dzień na osobę",
    weekPerPerson: "Tydzień na osobę",

    readable: {
      halfHour: "Za pół godziny",
      hour: "Za godzinę",
      day: "Za dzień",
      week: "Za tydzień",
      halfHourPerson: "Za pół godziny za osobę",
      hourPerson: "Za godzinę za osobę",
      dayPerson: "Za dzień za osobę",
      weekPerPerson: "Za tydzień za osobę",

      number: {
        min: "min",
        hour: "godz",
        day: "dzień",
        week: "tydz",
        minPerson: "min / osobę",
        hourPerson: "godz / osobę",
        dayPerson: "dzień / osobę",
        weekPerson: "tydz / osobę",
      },
    },
  },

  preview: {
    preview: "Podgląd",
    description: "Opis",
  },

  pricePerHalfHour: "Pół godziny",
  pricePerHour: "Godzina",
  pricePerDay: "Dzień",
  pricePerWeek: "Tydzień",

  createSuccess: "Oferta utworzona pomyślnie",
  createError: "Błąd podczas tworzenia",
  editSuccess: "Oferta edytowana pomyślnie",

  beforeYouAddOffer: "Zanim dodasz pierwszą ofertę, musisz dodać metodę płatności.",
  addNow: "Dodaj teraz",
  notNow: "Nie teraz",
};
