import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import Select, { OnChangeValue } from "react-select";
import { useTranslation } from "react-i18next";
import { TimePicker } from "antd";
import dayjs from "dayjs";

import { AvailabilityModalProps, FieldsData } from "./availability-modal.types";
import { months, days, fields } from "./availability-modal.constants";
import { SelectDataType } from "../offers/offer-item/offer-item.constants";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./availability-modal.module.scss";

const format = "HH:mm";
const selectData = [
  {
    value: "location",
    label: "Location",
  },
  {
    value: "assets",
    label: "Assets",
  },
  {
    value: "hosts",
    label: "Hosts",
  },
];

export const AvailabilityModal: React.FC<AvailabilityModalProps> = ({ close }) => {
  const { t } = useTranslation();

  const handleSelectValues = (field: FieldsData, value: OnChangeValue<SelectDataType, true>) => {
    return {
      field,
      value,
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography>{t("availability.availabilityModal.editAvailability")}</Typography>
        <IconButton className={styles.closeIcon} onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className={styles.selectWrapper}>
        <Typography>{t("availability.availabilityModal.editFor")}</Typography>
        <Select className={styles.select} options={selectData} />
      </div>

      <div className={styles.content}>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.availabilityModal.months")}</Typography>
          <Select
            name={fields.months}
            onChange={(value) => handleSelectValues(fields.months, value)}
            className={styles.select}
            isMulti
            options={months(t)}
            placeholder={t("availability.availabilityModal.select")}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.availabilityModal.days")}</Typography>
          <Select
            onChange={(value) => handleSelectValues("days", value)}
            name={fields.days}
            className={styles.select}
            isMulti
            options={days(t)}
            placeholder={t("availability.availabilityModal.select")}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.availabilityModal.startTime")}</Typography>
          <TimePicker
            className={styles.timePicker}
            minuteStep={15}
            defaultValue={dayjs("12:08", format)}
            format={format}
            popupClassName={styles.popup}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.availabilityModal.endTime")}</Typography>
          <TimePicker
            className={styles.timePicker}
            minuteStep={15}
            defaultValue={dayjs("12:08", format)}
            format={format}
            popupClassName={styles.popup}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.availabilityModal.duration")}</Typography>
          <Select
            isMulti
            name={fields.duration}
            onChange={(value) => handleSelectValues(fields.duration, value)}
            className={styles.select}
            options={selectData}
            placeholder={t("availability.availabilityModal.select")}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.availabilityModal.brakeAfter")}</Typography>
          <Select
            isMulti
            name={fields.brake}
            onChange={(value) => handleSelectValues(fields.brake, value)}
            className={styles.select}
            options={selectData}
            placeholder={t("availability.availabilityModal.select")}
          />
        </div>
      </div>

      <Button className={styles.saveButton}>{t("availability.availabilityModal.save")}</Button>
    </div>
  );
};
