import { TFunction } from "i18next";
import { object, SchemaOf, string } from "yup";

import { ContactData } from "../contact.types";

export const sx = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "none" },
  },
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "18px",
    background: "#fff",
    border: "2px solid #474CAE",
  },
};

export const textAreaProps = {
  style: {
    color: "#000",
    borderRadius: "18px",
    background: "#fff",
    border: "2px solid #474CAE",
    height: "200px",
  },
};

export const initialValues: ContactData = {
  name: "",
  email: "",
  message: "",
};

export const contactSchema = (t: TFunction): SchemaOf<ContactData> =>
  object().shape({
    name: string().required(t("contact.nameIsRequired")),
    email: string().email(t("contact.valueMustBeEmail")).required(t("contact.emailIsRequired")),
    message: string().required(t("contact.messageIsRequired")),
  });
