import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import classNames from "classnames";

import { MobileMenuProps } from "./mobile-menu.types";
import { resetOrganizationStore, resetUserStore, RootState } from "store";
import { useRoutesPath } from "hooks";
import {
  CREATE_OFFER_PAGE,
  CREATE_RENTAL_PAGE,
  LOGIN_PAGE,
  MANAGEMENT_PAGE,
  PROFILE_PAGE,
  REGISTER_PAGE,
} from "constants/routes.constants";
import { sections } from "pages/rental/management/management.constants";
import { STORAGE_FIELDS } from "constants/storage.constants";
import { generateStripeLink } from "server";
import { Section } from "./section/section";

import styles from "./mobile-menu.module.scss";

export const MobileMenu: React.FC<MobileMenuProps> = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  color,
  watersports,
  destinations,
  watersportsPath,
  destinationsPath,
  isGreen,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { token } = useSelector((state: RootState) => state.auth);
  const { organizationData, activePayment, dataFetching } = useSelector((state: RootState) => state.organization);
  const { localePath, localePathParams } = useRoutesPath();

  const strapiDataLink = useSubmit(generateStripeLink.setParams({ organization_id: organizationData?.id || 1 }));
  const { submit, submitting } = strapiDataLink;

  const addPayment = () => {
    if (!submitting) {
      submit().then((res) => {
        window.open(res[0]?.stripe_url, "_self");
      });
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const logOut = () => {
    dispatch(resetUserStore());
    dispatch(resetOrganizationStore());
    localStorage.removeItem(STORAGE_FIELDS.token);
    localStorage.removeItem(STORAGE_FIELDS.refreshToken);
    handleCloseMenu();
  };

  const isAuthenticated = Boolean(token) || false;
  const showManagement = Boolean(organizationData);
  const showCreateRental = !organizationData;
  const showCreateOffer = organizationData && activePayment;
  const showAddPayments = organizationData && !activePayment;

  return (
    <div className={classNames(styles.container, { [styles.containerGreen]: isGreen })}>
      <IconButton onClick={toggleMobileMenu} className={isGreen ? styles.menuIconGreen : styles.menuIconPurple}>
        <MenuIcon />
      </IconButton>

      {isMobileMenuOpen && (
        <div className={styles.mobileMenu}>
          <div className={styles.userActions}>
            {!isAuthenticated && (
              <div className={styles.linksWrapper}>
                <Link
                  className={styles.link}
                  style={{ color }}
                  to={localePath({ route: LOGIN_PAGE })}
                  onClick={handleCloseMenu}
                >
                  {t("burgerMenu.login")}
                </Link>
                <Link
                  className={styles.link}
                  style={{ color }}
                  to={localePath({ route: REGISTER_PAGE })}
                  onClick={handleCloseMenu}
                >
                  {t("burgerMenu.register")}
                </Link>
              </div>
            )}

            {isAuthenticated && !dataFetching && (
              <div className={styles.linksWrapper}>
                <Link
                  className={styles.link}
                  style={{ color }}
                  to={localePath({ route: PROFILE_PAGE })}
                  onClick={handleCloseMenu}
                >
                  {t("burgerMenu.account")}
                </Link>

                {showCreateOffer && (
                  <Link
                    className={styles.link}
                    style={{ color }}
                    to={localePath({ route: CREATE_OFFER_PAGE })}
                    onClick={handleCloseMenu}
                  >
                    {t("burgerMenu.createOffer")}
                  </Link>
                )}

                {showAddPayments && (
                  <Typography className={styles.link} style={{ color }} onClick={addPayment}>
                    {submitting ? t("burgerMenu.loading") : t("burgerMenu.addPayment")}
                  </Typography>
                )}

                {showCreateRental && (
                  <Link
                    className={styles.link}
                    style={{ color }}
                    to={localePath({ route: CREATE_RENTAL_PAGE })}
                    onClick={handleCloseMenu}
                  >
                    {t("burgerMenu.createRental")}
                  </Link>
                )}

                {showManagement && (
                  <Link
                    className={styles.link}
                    style={{ color }}
                    to={localePathParams({ route: MANAGEMENT_PAGE, params: { section: sections(t)[0].name } })}
                    onClick={handleCloseMenu}
                  >
                    {t("burgerMenu.management")}
                  </Link>
                )}

                <Link className={styles.link} to="/" style={{ color }} onClick={logOut}>
                  {t("burgerMenu.logOut")}
                </Link>
              </div>
            )}
          </div>

          <Section
            handleCloseMenu={handleCloseMenu}
            color={color}
            destinations={destinations}
            watersports={watersports}
            destinationsPath={destinationsPath}
            watersportsPath={watersportsPath}
            isGreen={isGreen}
          />
        </div>
      )}
    </div>
  );
};
