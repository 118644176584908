import React from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { ProfileItemProps } from "../profile.types";
import { FormTextField } from "components";
import { inputProps, inputStyles } from "./profie-description.constants";
import { RentalData } from "server/organization/organization.types";

import styles from "./profile-description.module.scss";
import mainStyles from "../profile.module.scss";

export const ProfileDescription: React.FC<ProfileItemProps> = ({ className }) => {
  const { t } = useTranslation();
  const {
    values: { description },
  } = useFormikContext<RentalData>();

  return (
    <div className={classNames(className, styles.container)}>
      <Typography className={mainStyles.text}>{t("rental.profile.createYourDescription")}</Typography>
      <FormTextField
        name="description"
        className={styles.textField}
        variant="standard"
        multiline
        rows={6}
        sx={inputStyles}
        InputProps={inputProps}
        placeholder={t("rental.profile.placeholders.description")}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength: 5000 }}
      />
      <Typography className={mainStyles.counter}>{description.length}/5000</Typography>
    </div>
  );
};
