export const dates = {
  months: {
    january: "styczeń",
    february: "luty",
    march: "marzec",
    april: "kwiecień",
    may: "maj",
    june: "czerwiec",
    july: "lipiec",
    august: "sierpień",
    september: "wrzesień",
    october: "październik",
    november: "listopad",
    december: "grudzień",
  },
  days: {
    monday: "poniedziałek",
    tuesday: "wtorek",
    wednesday: "środa",
    thursday: "czwartek",
    friday: "piątek",
    saturday: "sobota",
    sunday: "niedziela",
  },
};
