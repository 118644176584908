import React from "react";
import classNames from "classnames";
import { IconButton, Typography } from "@mui/material";

import { FormBoxProps } from "./form-box.types";
import { useQueryParams } from "hooks";

import styles from "./form-box.module.scss";

export const FormBox: React.FC<FormBoxProps> = ({ className, name, queryName, icon, handleForm }) => {
  const { updateQueryParams } = useQueryParams();

  const setQueryParams = () => {
    updateQueryParams({ form: queryName });
    handleForm(true);
  };

  return (
    <div className={classNames(className, styles.container)}>
      <Typography className={styles.name}>{name}</Typography>
      <IconButton className={styles.box} onClick={setQueryParams}>
        <img src={icon} alt="section icon" className={styles.icon} />
      </IconButton>
    </div>
  );
};
