import React, { useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Watersport } from "./watersport/watersport";
import { Details } from "./details/details";

import styles from "./add-asset-form.module.scss";

export const AddAssetForm = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handlePrevStep = () => {
    setStep((prevState) => prevState - 1);
  };

  return (
    <div className={styles.container}>
      {step === 1 && <Watersport />}
      {step === 2 && <Details />}

      <div className={styles.buttons}>
        {step === 1 && (
          <Button onClick={handleNextStep} className={styles.button}>
            {t("assets.addAssetModal.addDetails")}
          </Button>
        )}
        {step === 2 && (
          <Button onClick={handlePrevStep} className={styles.prevButton}>
            {t("assets.addAssetModal.back")}
          </Button>
        )}
        {step === 2 && (
          <Button type="submit" className={styles.button}>
            {t("assets.addAssetModal.add")}
          </Button>
        )}
      </div>
    </div>
  );
};
