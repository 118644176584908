import { TFunction } from "i18next";
import { object, string } from "yup";
import { LatLng } from "leaflet";

import { TextInputProps } from "components/form/form-text-field/form-text-field.types";

export const DEFAULT_POSITION = new LatLng(51.9189046, 19.1343786);

export const select = {
  styles: {
    width: "100%",
    color: "#000",
    padding: "3px",
    borderBottom: "2px solid #474CAE",
    pl: 1,
    div: {
      color: "#000",
      "&::placeholder": { fontSize: 15 },
      mr: 1,
      borderRadius: "3px",
    },
  },
  props: {
    MenuProps: {
      BackdropProps: { sx: { opacity: 0.2 } },
      PaperProps: {
        sx: {
          backgroundColor: "#fff",
          color: "#000",
          top: 0,
          left: 0,
        },
      },
    },
  },
};

export const inputStyles = {
  borderBottom: "2px solid #474CAE",
  color: "#000",
  input: {
    color: "#000",
    px: 1,
    "&::placeholder": { fontSize: 15 },
    borderRadius: "5px",
    mb: "6px",
    mt: "6px",
  },
  textarea: { color: "#000", px: 1, "&::placeholder": { fontSize: 15 } },
  width: "100%",
};

export const labelProps = {
  shrink: true,
  sx: { paddingLeft: 1, color: "#474CAE", ml: 1, "&.Mui-focused": { color: "#474CAE" } },
};

export const locationFields = (t: TFunction): TextInputProps[] => [
  { name: "location.name", label: t("rental.location.name"), type: "text" },
  { name: "location.description", label: t("rental.location.description"), type: "text" },
  { name: "location.address", label: t("rental.location.address"), type: "text" },
  { name: "location.city", label: t("rental.location.city"), type: "text" },
  { name: "location.region", label: t("rental.location.region"), type: "text" },
  { name: "location.postal_code", label: t("rental.location.postalCode"), type: "text" },
];

export const locationSchema = (t: TFunction) =>
  object().shape({
    location: object().shape({
      name: string().required(t("rental.location.validation.nameRequired")),
      description: string(),
      address: string().required(t("rental.location.validation.addressRequired")),
      city: string().required(t("rental.location.validation.cityRequired")),
      country: string().required(t("rental.location.validation.countryRequired")),
      latitude: string().required(t("rental.location.validation.latitudeRequired")),
      longitude: string().required(t("rental.location.validation.longitudeRequired")),
      region: string(),
      postal_code: string().required(t("rental.location.validation.postalCodeRequired")),
    }),
  });
