export const assets = {
  add: "Add",
  remove: "Remove",
  asset: "Asset",
  quantity: "Quantity",
  addFirstAsset: "Add first asset",

  addAssetModal: {
    addAsset: "Add asset",
    send: "Send",
    windsurfing: "Windsurfing",
    addDetails: "Add details",
    name: "Name",
    quantity: "Quantity",
    description: "Description",
    size: "Size",
    sizeUnit: "Size unit",
    back: "Back",
    add: "Add",
    addedSuccesfully: "Added succesfully",
    wrong: "Something went wrong",
  },

  removeAssetModal: {
    removeAsset: "Remove asset",
    remove: "Remove",
    asset: "Asset",
  },
};
