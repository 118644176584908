export const insights = {
  insights: "Analityka",
  filters: {
    days: "dni",
    months: "miesiące",
    filter: "Filtry",
    month: "miesiąc",
    range: "Zakres",
  },
  chart: {
    noData: "Brak danych",
  },
  buttons: {
    revenue: "przychód",
    profit: "zysk",
    bookings: "rezerwacje",
    bookingRate: "wydajność",
    reservationsTime: "czas rezerwacji",
  },
  teamHours: {
    teamHours: "Godziny zespołu",
    hours: "godziny",
    seeAll: "Zobacz wszystko",
    details: "szczegóły",
    profit: "zysk",
  },
  clientBookings: {
    newClient: "nowy klient",
    nextBooking: "Następna rezerwacja",
    clientBookings: "Rezerwacje klientów",
    hours: "godziny",
    seeAll: "zobacz wszystko",
    soFar: "dotychczas",
    remaining: "pozostało",
    aboutClient: "O kliencie:",
    telephone: "Telefon: ",
    email: "Email: ",
    gender: "Płeć: ",
    age: "Wiek: ",
    dateOfBirth: "Data urodzenia: ",
    weight: "Waga: ",
    size: "Rozmiar: ",
    level: "Umiejętności: ",
    notes: "Notatki: ",
    lastBooked: "Ostatnia rezerwacja: ",
    host: "Gospodarz: ",
    services: "Usługi: ",
    group: "Grupa: ",
    individual: "Indywidualne",
    paidWithCash: "Zapłacone gotówka",
    groups: "Grupowe",
    extras: "Dodatkowe",
    lastPayouts: "Ostanie płatności",
    addPayouts: "Dodaj płatności",
    instructors: "Instruktorzy:",
    clients: "Klienci:",
    instructor: "Instruktor",
    addNotes: "Dodaj notatki",
  },
  guestsDetails: {
    hours: "godzin",
    newClient: "Nowy klient",
    aboutClient: "O kliencie:",
    currentPackage: "Obecny pakiet:",
    lastBookings: "Ostatnie rezerwacje",
    editPackage: "Edytuj pakiet:",
    editData: "Edytuj dane",
    exportData: "Eksportuj  dane",
    search: "Szukaj",
    payment: "Płatność:",
    services: "Usługi:",
    group: "Grupa:",
    notes: "Notatki:",
    instructor: "Instruktor:",
    clientBookings: "Rezerwacje klienta",

    editPackageModal: {
      offer: "Oferta:",
      totalHours: "Pozostała liczba godzin:",
      totalValue: "Całkowita pozostała wartość:",
      newValue: "Nowa wartość",
      newHours: "Nowe godziny",
      newService: "Nowe usługi",
      save: "Zapisz",
    },
  },
};
