import React from "react";
import { Typography } from "@mui/material";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { Buttons } from "./buttons/buttons";
import { Filters } from "./filters/filters";
import { Chart } from "./chart/chart";
import { TeamHours } from "./team-hours/team-hours";
import { useQueryParams, useShowContent } from "hooks";
import { ClientBookings } from "./client-bookings/client-bookings";
import { RootState } from "store";
import { insightsDataGet } from "server";
import { Loader } from "components";
import { filtersData } from "./filters/filters.constants";
import { getDateRangeFromDays, getDateRangeFromMonths, insightsApiData } from "./insights.constants";

import styles from "./insights.module.scss";

export const Insights = () => {
  const { t } = useTranslation();
  const { setQueryParams, query } = useQueryParams();
  const { organizationData } = useSelector((state: RootState) => state.organization);

  const queryParamsToRequest = () => {
    switch (query.filterTime) {
      case filtersData["7days"]:
        return {
          ...getDateRangeFromDays(7),
        };

      case filtersData["1month"]:
        return {
          ...getDateRangeFromMonths(1),
        };

      case filtersData["3months"]:
        return {
          ...getDateRangeFromMonths(3),
        };

      default:
        return { month: insightsApiData.month };
    }
  };

  const objectToQueryString = (obj: Record<string, any>): string => {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&");
  };

  const rangeQuery = () => {
    return {
      dataFrom: query.dataFrom,
      dataTo: query.dataTo,
    };
  };

  const insightsData = useFetch(
    insightsDataGet
      .setParams({ organizationId: organizationData?.id || 0 })
      .setQueryParams(
        query?.filterTime === "range"
          ? (objectToQueryString(rangeQuery()) as string)
          : (objectToQueryString(queryParamsToRequest()) as string),
      ),
  );

  const { showLoader } = useShowContent(insightsData);
  const { data } = insightsData;

  const organizationName = organizationData?.name;

  useDidUpdate(
    () => {
      setQueryParams({ chart: "profit", filterTime: "30days" });
    },
    [],
    true,
  );

  return (
    <div className={styles.container}>
      <Typography className={styles.insights}>{t("insights.insights")}</Typography>
      <div className={styles.contentMobile}>
        <Typography className={styles.title}>{organizationName}</Typography>
        <Filters />
        <Buttons />
        {!showLoader && <Chart data={data} />}
        {showLoader && <Loader color="#474CAE" backgroundColor="#474CAE" height="100%" />}
        <TeamHours />
        <ClientBookings />
      </div>
      <div className={styles.contentDesktop}>
        <div className={styles.leftSection}>
          <Typography className={styles.title}>{organizationName}</Typography>
          <Buttons />
          {!showLoader && <Chart data={data} />}
          {showLoader && <Loader color="#474CAE" backgroundColor="#474CAE" height="100%" />}
        </div>
        <div className={styles.rightSection}>
          <Filters />
          <TeamHours />
          <ClientBookings />
        </div>
      </div>
    </div>
  );
};
