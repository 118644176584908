import React, { useState } from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { useQueryParams, useRoutesPath } from "hooks";
import { Loader } from "components";

import styles from "./bm-offers.module.scss";

export const BmOffers = () => {
  const queryParams = useQueryParams();

  const { locale } = useRoutesPath();

  const [srcParams, setSrcParams] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lang, setLang] = useState<"pl" | "en">("pl");
  const [refresher, setRefresher] = useState<number>(0);

  const handleLoading = (value: boolean) => () => setIsLoading(value);

  const baseSrc = `https://www.booking-manager.com/wbm2/page.html?companyid=5909&resultsPerPage=10&setlang=${lang}&`;

  useDidUpdate(
    () => {
      const srcParamsToSet = queryParams.query;
      delete srcParamsToSet.watersport;

      setSrcParams(queryParams.stringify(srcParamsToSet));
    },
    [locale, refresher],
    true,
  );

  useDidUpdate(
    () => {
      setIsLoading(true);
      setLang(locale === "pl-PL" ? "pl" : "en");
    },
    [locale],
    true,
  );

  useDidUpdate(
    () => {
      setRefresher((prevState) => prevState + 1);
      setIsLoading(true);
    },
    [queryParams.query.filter_country],
    true,
  );

  return (
    <div className={styles.container} key={refresher}>
      {isLoading && <Loader className={styles.loader} />}

      <iframe
        className={styles.iframe}
        onLoadStartCapture={handleLoading(true)}
        onLoadStart={handleLoading(true)}
        onLoad={handleLoading(false)}
        title="booking-manager"
        data-hj-allow-iframe=""
        width="100%"
        style={{ display: isLoading ? "none" : "block" }}
        src={`${baseSrc}${srcParams}`}
      />
    </div>
  );
};
