import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { pl, enUS } from "date-fns/locale";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Profile } from "./profile/profile";
import { getAuthor } from "server";
import { useRoutesPath, useShowContent } from "hooks";
import { Loader } from "components";
import { StrapiAuthorModel } from "models";
import { ARTICLE_PAGE } from "constants/routes.constants";

import styles from "./author.module.scss";

export const AuthorPage = () => {
  const { t } = useTranslation();
  const { authorId } = useParams();
  const { localePathParams, locale } = useRoutesPath();

  const [author, setAuthor] = useState<null | StrapiAuthorModel>(null);

  const authorData = useFetch(
    getAuthor.setParams({ authorId: Number(authorId) }).setQueryParams({ populate: "*", fields: "*" }),
  );
  const { showContent, showLoader, showError } = useShowContent(authorData);
  const { onSuccess } = authorData;
  onSuccess((response) => {
    setAuthor(response.response.data);
  });

  const dateFormat = "dd.MM.yyyy";
  const dateLocale = locale === "pl-PL" ? pl : enUS;

  return (
    <div className={styles.container}>
      {showLoader && <Loader className={styles.loader} />}
      {showError && <Typography>{t("author.somethingWentWrong")}</Typography>}
      {showContent && author && (
        <>
          <Profile author={author} />
          <div className={styles.articles}>
            {author.attributes.articles.data.map(({ attributes }) => (
              // <Link to={getLinkPath(ARTICLE_PAGE.path, { slug: attributes.slug })} className={styles.articleBox}>
              <Link
                to={localePathParams({ route: ARTICLE_PAGE, params: { slug: attributes.slug } })}
                className={styles.articleBox}
              >
                <img src={attributes.image?.data[0].attributes.url} alt="author profile" />
                <div>
                  <Typography variant="h5">
                    {format(new Date(attributes.publishedAt), dateFormat, { locale: dateLocale })}
                  </Typography>
                  <Typography variant="h4">{attributes.title}</Typography>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
