import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { changeLanguage } from "i18next";
import { ClickAwayListener } from "@mui/base";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import gsap from "gsap";

import { RootState, setLocale } from "store";
import { LanguageSelectProps } from "./language-select.types";
import { DEFAULT_LOCALE } from "constants/app.constants";

import { ReactComponent as PlGreen } from "assets/icons/pl-green.svg";
import { ReactComponent as PlPurple } from "assets/icons/pl-purple.svg";
import { ReactComponent as EnPurple } from "assets/icons/en-purple.svg";
import { ReactComponent as EnGreen } from "assets/icons/en-green.svg";

import styles from "./language-select.module.scss";

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ isGreen }) => {
  const dispatch = useDispatch();
  const languageSelectTl = useRef<null | GSAPTimeline>(null);
  const languageItemsRef = useRef<null | HTMLDivElement>(null);

  const { locale } = useSelector((state: RootState) => state.app);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [languageSelectAnimated, setLanguageSelectAnimated] = useState<boolean>(false);

  const mapLocale = (selectedLocale?: string) => {
    if (selectedLocale === "pl-PL") return "pl-PL";
    return selectedLocale || DEFAULT_LOCALE;
  };

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLanguageChange = async (event: React.MouseEvent<HTMLElement>) => {
    const selectedLocale = mapLocale(event.currentTarget.dataset.language);
    dispatch(setLocale(selectedLocale));
    await changeLanguage(selectedLocale);
    setIsOpen(false);
  };

  const languageIcon = (localeToSelect = locale) => {
    if (localeToSelect === "pl-PL") {
      if (isGreen) return <PlGreen className={styles.icon} />;

      return <PlPurple className={styles.icon} />;
    }

    if (localeToSelect === "en-US") {
      if (isGreen) return <EnGreen className={styles.icon} />;

      return <EnPurple className={styles.icon} />;
    }
  };

  useDidUpdate(
    () => {
      if (languageItemsRef.current && !languageSelectAnimated) {
        gsap.set(languageItemsRef.current?.children, { autoAlpha: 0, y: "+=150px" });

        languageSelectTl.current = gsap.timeline({
          delay: 0.2,
        });

        languageSelectTl.current
          .to(languageItemsRef.current?.children, {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
          })
          .to(languageItemsRef.current?.children, { autoAlpha: 1, y: 0 });

        setLanguageSelectAnimated(true);
      }
    },
    [languageItemsRef, isOpen],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.switcher}>
        <IconButton onClick={toggleMenu}>{languageIcon()}</IconButton>
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div ref={languageItemsRef} className={classNames(styles.menuOpen, { [styles.menuGreen]: isGreen })}>
            <Button
              className={classNames(styles.item, { [styles.itemGreen]: isGreen })}
              data-language="pl-PL"
              onClick={handleLanguageChange}
            >
              {languageIcon("pl-PL")}
              <Typography>Polski</Typography>
            </Button>
            <Button
              className={classNames(styles.item, { [styles.itemGreen]: isGreen })}
              onClick={handleLanguageChange}
              data-language="en-US"
            >
              {languageIcon("en-US")}
              <Typography>English</Typography>
            </Button>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
