export const paymentsMockedData = [
  {
    id: 1,
    offerTitle: "Kretowiny",
    date: "asd",
    status: 0,
    payment: {
      method: "VISA",
      number: "**** 4242",
      value: 306,
      currency: "PLN",
      status: "PAID",
    },
  },
  {
    id: 1,
    offerTitle: "Kretowiny",
    date: "asddd",
    status: 1,
    payment: {
      method: "VISA",
      number: "**** 4242",
      value: 306,
      currency: "PLN",
      status: "PAID",
    },
  },
  {
    id: 1,
    offerTitle: "Kretowiny",
    date: "asddd",
    status: 0,
    payment: {
      method: "VISA",
      number: "**** 4242",
      value: 306,
      currency: "PLN",
      status: "PAID",
    },
  },
];
