import React, { useContext, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { TeamBox } from "./team-box/team-box";
import { useWindowSize } from "hooks";
import { Loader, Modal } from "components";
import { AddPersonModal } from "./add-person-modal/add-person-modal";
import { ManagementContext } from "../management.context";
import { RemoveHostModal } from "./remove-host-modal/remove-host-modal";

import { ReactComponent as GoogleIcon } from "assets/icons/google-icon.svg";

import styles from "./team.module.scss";

// TODO check is user owner of the organization and disable button

export const Team = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { loadingTeamData, refreshTeamData, teamData } = useContext(ManagementContext);

  const [isAddPersonModalOpen, setIsAddPersonModalOpen] = useState<boolean>(false);
  const [isRemovePersonModalOpen, setIsRemovePersonModalOpen] = useState<boolean>(false);

  const handleOpenAddPersonModal = () => {
    setIsAddPersonModalOpen(true);
  };

  const handleCloseAddPersonModal = () => {
    setIsAddPersonModalOpen(false);
  };

  const handleOpenRemovePersonModal = () => {
    setIsRemovePersonModalOpen(true);
  };

  const handleCloseRemovePersonModal = () => {
    setIsRemovePersonModalOpen(false);
  };

  const showLoader = (teamData?.length === 0 || !teamData) && loadingTeamData;

  const isMobile = width < 992;

  return (
    <div className={styles.container}>
      <Typography className={styles.team}>{t("team.team")}</Typography>
      <div className={styles.wrapper}>
        <div className={styles.buttons}>
          <div className={styles.button}>
            <Typography>{t("team.add")}</Typography>
            <IconButton onClick={handleOpenAddPersonModal}>
              <AddIcon />
            </IconButton>
          </div>
          <div className={styles.button}>
            <Typography>{t("team.remove")}</Typography>
            <IconButton onClick={handleOpenRemovePersonModal}>
              <RemoveIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.content}>
          {isMobile && <Typography className={styles.person}>{t("team.person")}</Typography>}

          {isMobile && !showLoader && (
            <div className={styles.teamList}>
              {teamData?.map((item) => {
                return <TeamBox member={item} />;
              })}
            </div>
          )}

          {isMobile && showLoader && <Loader backgroundColor="#fff" color="#fff" height="100%" />}

          {!isMobile && showLoader && <Loader backgroundColor="#fff" color="#fff" height="100%" />}

          {!isMobile && !showLoader && (
            <div className={styles.teamList}>
              <div className={styles.itemWrapper}>
                <Typography className={styles.person}>{t("team.person")}</Typography>

                <div className={styles.dataWrapper}>
                  {teamData?.map((item) => {
                    const userName = item.first_name.length !== 0 ? `${item.first_name} ${item.last_name}` : item.email;

                    return (
                      <div className={styles.nameWrapper}>
                        <Typography className={styles.itemTitle}>{userName}</Typography>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={styles.itemWrapper}>
                <Typography className={styles.person}>{t("team.role")}</Typography>

                <div className={styles.dataWrapper}>
                  {teamData?.map(() => (
                    <Typography className={styles.itemTitle}>role</Typography>
                  ))}
                </div>
              </div>

              <div className={styles.itemWrapper}>
                <Typography className={styles.person}>{t("team.googleCalendar")}</Typography>

                <div className={styles.dataWrapper}>
                  {teamData?.map(() => (
                    <Button className={classNames(styles.itemTitle, styles.itemTitleButton)}>
                      <GoogleIcon />
                      {t("team.connectWithGoogle")}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal open={isAddPersonModalOpen}>
        <AddPersonModal handleClose={handleCloseAddPersonModal} handleRefreshTeamData={refreshTeamData} />
      </Modal>

      <Modal open={isRemovePersonModalOpen}>
        <RemoveHostModal handleClose={handleCloseRemovePersonModal} handleRefreshTeamData={refreshTeamData} />
      </Modal>
    </div>
  );
};
