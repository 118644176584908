import React from "react";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";

import { contactSchema, initialValues, inputProps, sx, textAreaProps } from "./contact-form.constants";
import { FormTextField } from "components";
import { COOKIES_POLICY_PAGE, PRIVACY_POLICY_PAGE, TERMS_OF_USE_PAGE } from "constants/routes.constants";
import { ContactData } from "../contact.types";
import { useRoutesPath } from "hooks";
import { contactForm } from "server";
import { ContactFormData } from "server/contact/contact.types";

import stylesMain from "../contact.module.scss";
import styles from "./contact-form.module.scss";

export const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const formData = useSubmit(contactForm);
  const { submit, onSubmitSuccess, onSubmitError, submitting } = formData;
  onSubmitSuccess(() => {
    enqueueSnackbar(t("contact.sentSuccessfully"), { variant: "success" });
  });
  onSubmitError(() => {
    enqueueSnackbar(t("contact.sendError"), { variant: "error" });
  });

  const handleSendMessage = async (values: ContactData, formikHelpers: FormikHelpers<ContactData>) => {
    const data: ContactFormData = {
      email: values.email,
      text_data: values.message,
      name: values.name,
    };

    await submit({ data }).then((res) => {
      if (res[2] === 200) formikHelpers.resetForm();
    });
  };

  return (
    <div className={styles.contactUsContainer}>
      <Typography variant="h2" className={classNames(stylesMain.title, styles.contactUsTitle)}>
        {t("contact.contactUs")}
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSendMessage}
        validationSchema={contactSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form className={styles.contactUsContent}>
          <div className={styles.inputWrapper}>
            <Typography mb="-5px" ml="20px" className={styles.label}>
              {t("contact.name")}
            </Typography>
            <FormTextField name="name" sx={sx} InputProps={inputProps} />
          </div>
          <div className={styles.inputWrapper}>
            <Typography mb="-5px" ml="20px" className={styles.label}>
              Email
            </Typography>
            <FormTextField name="email" sx={sx} InputProps={inputProps} />
          </div>
          <div className={styles.inputWrapper}>
            <Typography mb="-5px" ml="20px" className={styles.label}>
              {t("contact.howCanWeHelpYou")}
            </Typography>
            <FormTextField name="message" sx={sx} InputProps={textAreaProps} multiline rows={8} />
          </div>
          <div className={styles.submitWrapper}>
            <Typography className={classNames(styles.policy)}>
              {t("contact.bySendingMessage")}{" "}
              <Link className={styles.link} to={localePath({ route: PRIVACY_POLICY_PAGE })}>
                {t("contact.privacyPolicy")}
              </Link>
              ,{" "}
              <Link className={styles.link} to={localePath({ route: COOKIES_POLICY_PAGE })}>
                {" "}
                {t("contact.cookiesPolicy")}
              </Link>{" "}
              {t("contact.and")}{" "}
              <Link className={styles.link} to={localePath({ route: TERMS_OF_USE_PAGE })}>
                {t("contact.termsOfUse")}
              </Link>
            </Typography>
            <Button type="submit" className={styles.button} disabled={submitting}>
              {t("contact.send")}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
