import React from "react";
import { IconButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, Select, TimePicker } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Dayjs } from "dayjs";

import { ModalProps } from "../base-info.types";
import { OfferData } from "../../create-offer.types";
import { daysOptions, monthsOptions } from "./availability-modal.constants";

import styles from "./availability.module.scss";

const formatHours = "HH:mm";
const formatISO = "YYYY-MM-DD";

export const AvailabilityModal: React.FC<ModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<OfferData>();

  const handleTimePicker = (time: Dayjs | null, timeString: string, name: string) => {
    const fieldName = `baseInfo.availability[${name}]`;

    setFieldValue(fieldName, time);
  };

  const handleDatePicker = (date: Dayjs, dateString: string | string[], name: string) => {
    const fieldName = `baseInfo.availability[${name}]`;

    setFieldValue(fieldName, date);
  };

  const handleSelect = (value: string[], name: string) => {
    const fieldName = `baseInfo.availability[${name}]`;

    setFieldValue(fieldName, value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.modalName}>
          {t("createOffer.baseInfo.modal.availability.addAvailability")}
        </Typography>
        <div className={styles.headerButtons}>
          <IconButton onClick={close} className={styles.iconButton}>
            <CheckIcon />
          </IconButton>
          <IconButton className={styles.iconButton} onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.availability.months")}</Typography>
          <Select
            popupClassName={styles.popup}
            className={styles.select}
            options={monthsOptions(t)}
            onChange={(e) => handleSelect(e, "months")}
            value={values.baseInfo.availability.months}
            mode="multiple"
          />
        </div>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.availability.days")}</Typography>
          <Select
            popupClassName={styles.popup}
            className={styles.select}
            options={daysOptions(t)}
            onChange={(e) => handleSelect(e, "days")}
            value={values.baseInfo.availability.days}
            mode="multiple"
          />
        </div>
        <div className={styles.rowWrapper}>
          <div className={styles.inputWrapper}>
            <Typography>{t("createOffer.baseInfo.modal.availability.startTime")}</Typography>
            <TimePicker
              popupClassName={styles.popup}
              className={styles.timePicker}
              format={formatHours}
              value={values.baseInfo.availability.startTime}
              onChange={(dayjsString, timeString) => handleTimePicker(dayjsString, timeString as string, "startTime")}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Typography>{t("createOffer.baseInfo.modal.availability.endTime")}</Typography>
            <TimePicker
              popupClassName={styles.popup}
              className={styles.timePicker}
              format={formatHours}
              value={values.baseInfo.availability.endTime}
              onChange={(dayjsString, timeString) => handleTimePicker(dayjsString, timeString as string, "endTime")}
            />
          </div>
        </div>
        <div className={styles.rowWrapper}>
          <div className={styles.inputWrapper}>
            <Typography>{t("createOffer.baseInfo.modal.availability.startDate")}</Typography>
            <DatePicker
              popupClassName={styles.popup}
              className={styles.datePicker}
              format={formatISO}
              value={values.baseInfo.availability.start}
              onChange={(date, dateString) => handleDatePicker(date, dateString, "start")}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Typography>{t("createOffer.baseInfo.modal.availability.endDate")}</Typography>
            <DatePicker
              popupClassName={styles.popup}
              className={styles.datePicker}
              format={formatISO}
              value={values.baseInfo.availability.end}
              onChange={(date, dateString) => handleDatePicker(date, dateString, "end")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
