/* eslint-disable @typescript-eslint/no-unused-vars */
import { ParamType } from "@better-typed/hyper-fetch";
import { stringify } from "query-string";

import { OFFERS_PAGE } from "../constants/routes.constants";

export type Negatives = null | undefined;

export type ExtractRouteParams<T extends string> = string extends T
  ? null
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: ParamType }
  : T extends `${infer _Start}:${infer Param}`
  ? { [k in Param]: ParamType }
  : null;

// TODO typing

export const getLinkPath = <T extends string>(
  path: T,
  // ...paramsArray: ExtractRouteParams<T> extends Negatives ? [] : [ExtractRouteParams<T>]
  ...paramsArray: never
): string => {
  let routePath: string = path;

  const params = paramsArray[0];
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      routePath = routePath.replace(new RegExp(`:${key}`, "g"), String(value));
    });
  }

  return routePath;
};

type BmStrapiDestinationsPathData = {
  filterCountry: string;
  dateStart: string;
  dateEnd: string;
};

export const bmStrapiDestinationsPath = ({
  filterCountry,
  dateEnd,
  dateStart,
}: BmStrapiDestinationsPathData): string => {
  const data = stringify({
    watersport: "YACHT",
    bm: true,
    view: "SearchResult",
    filter_country: filterCountry,
    daterange: `${dateStart} - ${dateEnd}`,
    daterangepicker_start: dateStart,
    daterangepicker_end: dateEnd,
  });

  return `?${data}`;
};
