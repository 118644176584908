import React from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikHelpers } from "formik";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { AddAssetModalProps } from "./add-asset-modal.types";
import { AddAssetForm } from "./add-asset-form/add-asset-form";
import { initialValues } from "./add-asset-modal.constants";
import { AssetsData, AssetsDataPost } from "../assets.types";
import { postAsset } from "server";
import { RootState } from "store";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./add-asset-modal.module.scss";

export const AddAssetModal: React.FC<AddAssetModalProps> = ({ handleClose, refreshAssets }) => {
  const { t } = useTranslation();
  const { organizationData } = useSelector((state: RootState) => state.organization);
  const { enqueueSnackbar } = useSnackbar();

  const postAssetSubmit = useSubmit(postAsset.setParams({ organizationId: organizationData?.id || 0 }));
  const { submit, onSubmitSuccess, onSubmitError } = postAssetSubmit;
  onSubmitSuccess(() => {
    enqueueSnackbar(t("assets.addAssetModal.addedSuccesfully"), { variant: "success" });
  });
  onSubmitError(() => {
    enqueueSnackbar(t("assets.addAssetModal.wrong"), { variant: "error" });
  });

  const handlePost = async (values: AssetsData, formikHelpers: FormikHelpers<AssetsData>) => {
    const data: AssetsDataPost = {
      quantity: values.quantity || 0,
      size: values.size,
      size_unit: values.sizeUnit,
      type: values.type,
      description: values.description,
      watersport: values.watersport || 1,
    };

    await submit({ data }).then((res) => {
      if (res[2] === 201) {
        formikHelpers.resetForm();
        handleClose();
        refreshAssets();
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.modalTitle}>{t("assets.addAssetModal.addAsset")}</Typography>
        <IconButton onClick={handleClose} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>

      <Formik initialValues={initialValues} onSubmit={handlePost}>
        <Form>
          <AddAssetForm />
        </Form>
      </Formik>
    </div>
  );
};
