import React, { useState } from "react";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import { PopupProps } from "./popup.types";
import { getOffer } from "server";
import { useRoutesPath, useShowContent, useWindowSize } from "hooks";
import { OfferModel } from "models";
import { OFFER_PAGE } from "constants/routes.constants";
import { pricePerSelectOptions } from "pages/offers/create/create-offer.constants";
import { substringText } from "utils";
import { COUNTRIES_EN, COUNTRIES_PL } from "constants/countries.constants";

import styles from "./popup.module.scss";

export const PopupContent: React.FC<PopupProps> = ({ offerId }) => {
  const { localePathParams, locale } = useRoutesPath();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const offerData = useFetch(getOffer.setParams({ id: offerId }));
  const { showContent, showLoader } = useShowContent(offerData);
  const [offer, setOffer] = useState<null | OfferModel>(null);
  const { onSuccess } = offerData;
  onSuccess((res) => {
    setOffer(res.response);
  });

  const title = offer?.title ? offer.title : offer?.organization.name;
  const price = offer?.price;
  const country =
    locale === "pl-PL"
      ? COUNTRIES_PL.find((countryItem) => countryItem.value === offer?.location.country)?.label
      : COUNTRIES_EN.find((countryItem) => countryItem.value === offer?.location.country)?.label;
  const address = `${offer?.location.city}, ${country}`;
  const pricePerValue = pricePerSelectOptions(t).find((rateType) => rateType.value === offer?.rate_type)?.label || "";
  const currency = `${offer?.currency.toUpperCase()}/${pricePerValue.toLowerCase()}`;
  const isOfferImages = offer && offer?.offer_images.length > 0;
  const loaderHeight = width > 400 ? "300px" : "250px";

  const offerPath = localePathParams({
    route: OFFER_PAGE,
    params: { offerId: offer?.id || 1 },
    queryParams: `?watersport=${offer?.watersport.name}&location=${offer?.location.city}`,
  });

  return (
    <Link to={offerPath} className={styles.container}>
      {showLoader && <Loader color="#F8EDE5" height={loaderHeight} backgroundColor="#F8EDE5" />}
      {showContent && (
        <div className={styles.content}>
          {offer && (
            <img
              src={
                isOfferImages ? offer.offer_images[0].image_url : offer.organization.organization_images[0].image_url
              }
              className={styles.image}
              alt={offer.title}
            />
          )}
          <div className={styles.details}>
            <div className={styles.itemWrapper}>
              {title && (
                <Typography className={styles.title}>
                  {substringText({
                    string: title as string,
                    replace: "...",
                    maxLength: 15,
                    cutAtChart: 15,
                  })}
                </Typography>
              )}
              <Typography className={styles.price}>{price}</Typography>
            </div>

            <div className={styles.itemWrapper}>
              <Typography className={styles.address}>
                {substringText({
                  string: address as string,
                  replace: "...",
                  maxLength: 20,
                  cutAtChart: 20,
                })}
              </Typography>
              <Typography className={styles.currency}>{currency}</Typography>
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};
