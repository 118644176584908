export const availability = {
  buttons: {
    offers: "Offers",
    hosts: "Hosts",
    assets: "Assets",
    locations: "Locations",
    availability: "Availability",
    changeAvailability: "Change availability",
    timeOff: "Time off",
  },
  actionButtons: {
    addAvailability: "Add availability",
    exceptions: "Exceptions",
  },

  offers: {
    offer: "Offer",
    services: "Services",
    availability: "Availability",
    openAvailabilityModal: "Open availability modal",
    add: "Add",
    service: "services",
    noServices: "no services",
    edit: "Edit",
  },

  offerLinksModal: {
    completeServices: "Complete services",
    service: "Service:",
    asset: "Asset:",
    host: "Host:",
    selectAsset: "Select asset",
    selectHost: "Select host",
  },

  modals: {
    addHosts: "Add hosts",
    addAssets: "Add assets",
    addLocations: "Add locations",
    select: "Select...",
    save: "save",
    hosts: "Hosts",
    assets: "Assets",
    locations: "Locations",
  },

  availabilityModal: {
    editAvailability: "Edit availability",
    editFor: "Edit for",
    months: "Months",
    days: "Days",
    startTime: "Start time",
    endTime: "End time",
    duration: "Duration (min)",
    brakeAfter: "Brake after (min)",
    save: "Save",
    select: "Select...",
    service: "Service: ",
    completeServices: "Complete services",
    selectAsset: "Select asset",
    selectHost: "Select host",
    asset: "Asset: ",
    host: "Host: ",
  },

  timeOffModal: {
    editTimeOff: "Edit time off",
    editFor: "Edit for",
    timeOffStartDate: "Time off start date",
    timeOffStartTime: "Time off start time",
    timeOffEndDate: "Time off end date",
    timeOffEndTime: "Time off end time",
    save: "Save",
  },

  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },

  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
};
