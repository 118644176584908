export const offersList = {
  map: "Map",
  weather: "Weather",
  offers: "Offers",
  noOffers: "No offers found!",
  showAllOffers: "Show all offers",
  somethingWentWrong: "Arrr! Something went wrong!",
  refresh: "Refresh",

  offerBox: {
    captain: "Captain",
    instructor: "Instructor",
  },
};
