import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFetch } from "@better-typed/react-hyper-fetch";
import classNames from "classnames";

import { ArticleBox } from "./article-box/article-box";
import { KNOWLEDGE_CENTER_PAGE } from "constants/routes.constants";
import { getArticles } from "server";
import { StrapiArticleModel } from "models";
import { useRoutesPath, useShowContent, useWindowSize } from "hooks";
import { CarouselComponent, Loader } from "components";
import { ArticlesProps } from "./articles.types";

import { ReactComponent as AnchorIcon } from "assets/icons/anchor-icon.svg";

import styles from "./articles.module.scss";
import stylesMain from "../home.module.scss";

export const Articles: React.FC<ArticlesProps> = ({ refElement }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();
  const { width } = useWindowSize();

  const [articles, setArticles] = useState<null | StrapiArticleModel[]>(null);

  const articlesData = useFetch(getArticles.setQueryParams({ populate: "*" }));
  const { showLoader, showContent } = useShowContent(articlesData);
  const { onSuccess } = articlesData;
  onSuccess((response) => {
    const mappedArticles = response.response.data.filter(
      (article) => article.attributes.isArticle && article.attributes.position !== null,
    );

    const array: StrapiArticleModel[] = [];
    mappedArticles.forEach((item: StrapiArticleModel) => {
      if (item.attributes.position === "left") array[0] = item;
      if (item.attributes.position === "center") array[1] = item;
      if (item.attributes.position === "right") array[2] = item;
    });

    array.length = 3;

    setArticles(array);
  });

  const openKnowledgeCenter = () => navigate(localePath({ route: KNOWLEDGE_CENTER_PAGE }));
  const isDesktop = width > 992;

  return (
    <div className={stylesMain.itemContainer}>
      <div className={stylesMain.itemHeader}>
        <Typography variant="h2" className={stylesMain.itemTitle}>
          {t("home.seaNews.seaNews")}
        </Typography>
      </div>

      {showLoader && <Loader height="223px" />}
      {showContent && articles && (
        <div className={styles.content} ref={refElement}>
          {isDesktop &&
            articles.map((article) => (
              <ArticleBox
                key={article.id}
                title={article.attributes.title}
                image={article.attributes.image?.data[0].attributes.url || ""}
                slug={article.attributes.slug}
                mobileImage={article.attributes.mobileImage.data.attributes.url}
                coverMobile={article.attributes.coverMobile.data.attributes.url}
                coverDesktop={article.attributes.coverDesktop.data.attributes.url}
              />
            ))}

          {!isDesktop && (
            <CarouselComponent arrowsHidden>
              {articles.map((article) => (
                <ArticleBox
                  key={article.id}
                  image={article.attributes.image?.data[0].attributes.url || ""}
                  title={article.attributes.title}
                  slug={article.attributes.slug}
                  mobileImage={article.attributes.mobileImage.data.attributes.url}
                  coverMobile={article.attributes.coverMobile.data.attributes.url}
                  coverDesktop={article.attributes.coverDesktop.data.attributes.url}
                />
              ))}
            </CarouselComponent>
          )}
        </div>
      )}

      <Button className={classNames(stylesMain.itemButton, styles.button)} onClick={openKnowledgeCenter}>
        {t("home.seaNews.seeMore")}
      </Button>

      <AnchorIcon className={styles.icon} />
    </div>
  );
};
