export const booking = {
  confirmAndPay: "Potwierdź i zapłać",
  total: "Łącznie",
  bookAndPay: "Zarezerwuj i zapłać",
  bookingPolicy: "Kliknięcie w przycisk powyżej przekieruje Cię do operatora płatności.",
  booking: "Rezerwowanie...",
  needLogIn: "Musisz się zalogować",

  yourTrip: {
    yourTrip: "Twoja wycieczka",
    dates: "Daty",
    arrival: "Data przyjazdu",
    departure: "Data wyjazdu",
    hours: "Godziny",
    hour: "Godzina",
    days: "Dni",
    day: "Dzień",
  },

  guests: {
    guests: "Goście",
    guest: "Gość",
  },

  cancellationPolicy: {
    cancellationPolicy: "Zasady rezygnacji",
    cancellationContent:
      "Rezygnacja obsługiwana jest przez wypożyczalnię zgodnie z Regulaminem Najmu i własnymi politykami.",
    agree: "Klikając poniższy przycisk, zgadzam się z SeaEasy",
    termsOfUse: "Warunki korzystania, ",
    rentalRules: "Zasady wynajmu, ",
    paymentTerms: "Zasady płatności.",
  },

  cart: {
    yourCart: "Twój koszyk",
    total: "Łącznie",
    servicesPrice: "Koszt usług",
    additionalServices: "Usługi dodatkowe",
    requiredServices: "Usługi wymagane",
    rentalTime: "Czas wynajmu",
    totalCost: "Łącznie",
  },

  success: {
    bookingSent: "Rezerwacja wysłana!",
    waitingForAccept: "Oferta czeka na potwierdzenie przez właściciela.",
  },

  cancel: {
    bookingCancel: "Twoja rezerwacja została anulowana",
    cancelError: "Przepraszamy, twoja rezerwacja została odrzucona.",
  },
};
