import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useNavigate } from "react-router";

import { FormTextField, Loader } from "components";
import { initialValues, resetPasswordFields, resetPasswordSchema } from "./reset-password-form.constants";
import { LOGIN_PAGE } from "constants/routes.constants";
import { resetPassword } from "server";
import { ResetPasswordFormData, ResetPasswordFormProps } from "./reset-password-form.types";
import { ResetPasswordData } from "server/auth/auth.types";
import { useRoutesPath } from "hooks";

import styles from "./reset-password-form.module.scss";

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ token, tokenValid }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { localePath } = useRoutesPath();

  const { submit, submitting, onSubmitSuccess, onSubmitError } = useSubmit(resetPassword.setQueryParams({ token }));
  onSubmitSuccess(() => {
    enqueueSnackbar(t("register.resetPasswordSuccess"), { variant: "success" });
    navigate(localePath({ route: LOGIN_PAGE }));
  });
  onSubmitError(() => {
    enqueueSnackbar(t("register.resetPasswordError"), { variant: "error" });
  });

  const handleResetPassword = async (
    values: ResetPasswordFormData,
    { setSubmitting }: FormikHelpers<ResetPasswordFormData>,
  ) => {
    const data: ResetPasswordData = { password: values.password };
    setSubmitting(true);
    await submit({ data });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordSchema(t)}
      onSubmit={handleResetPassword}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form className={styles.container}>
        {submitting && <Loader className={styles.loader} />}
        <Typography className={styles.title}>{t("register.resetPasswordTitle")}</Typography>
        <div className={styles.content}>
          {resetPasswordFields(t).map((field) => (
            <div key={field.name} className={styles.inputBox}>
              <Typography ml="10px" mb="-10px" fontFamily="Chillax-Regular">
                {field.label}
              </Typography>
              <FormTextField {...field} label="" disabled={!tokenValid} />
            </div>
          ))}

          <div className={styles.submit}>
            <Button type="submit" className={styles.button} variant="contained" disabled={submitting || !tokenValid}>
              {t("register.resetPassword")}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
