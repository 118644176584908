import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ItemBoxProps } from "./item-box.types";

import styles from "./item-box.module.scss";

export const ItemBox: React.FC<ItemBoxProps> = ({ name, nextBooking, details }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDetails = () => setIsOpen((prevState) => !prevState);

  return (
    <div className={styles.container}>
      <div className={styles.client}>
        <Typography className={styles.name}>{name}</Typography>
        <Typography className={styles.nextBooking}>
          <span>{t("insights.clientBookings.nextBooking")}:</span>
          <br /> {nextBooking}
        </Typography>
      </div>
      <div className={styles.content}>
        <div className={styles.time}>
          <Typography>
            252h
            <br /> {t("insights.clientBookings.soFar")}
          </Typography>
          <Typography>
            28h <br /> {t("insights.clientBookings.remaining")}
          </Typography>
        </div>
        <div className={styles.progress}>
          <div />
        </div>
      </div>

      {isOpen && (
        <div className={styles.details}>
          <div className={styles.detailsAbout}>
            <Typography className={styles.detailsTitle}>{t("insights.clientBookings.aboutClient")}</Typography>

            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.telephone")}</Typography>
              <Typography>{details.phoneNumber}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.email")}</Typography>
              <Typography>{details.email}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.gender")}</Typography>
              <Typography>{details.gender}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.age")}</Typography>
              <Typography>{details.age}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.dateOfBirth")}</Typography>
              <Typography>{details.dateOfBirth}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.weight")}</Typography>
              <Typography>{details.weight}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>{t("insights.clientBookings.size")}</Typography>
              <Typography>{details.size}</Typography>
            </div>
            <div className={styles.detailsWrapper}>
              <Typography>Level: </Typography>
              <Typography>{details.level}</Typography>
            </div>
            <div className={styles.detailsWrapperNotes}>
              <Typography>{t("insights.clientBookings.notes")}</Typography>
              <Typography>{details.notes}</Typography>
            </div>
          </div>
          <div className={styles.lastBooking}>
            <Typography className={styles.lastBookingTitle}>{t("insights.clientBookings.lastBooked")}</Typography>
            <div className={styles.lastBookingOffer}>
              <Typography>{details.lastBooking.date}</Typography>
              <Typography>{details.lastBooking.offer}</Typography>
            </div>
            <div className={styles.lastBookingWrapper}>
              <Typography>{t("insights.clientBookings.host")}</Typography>
              <Typography>{details.lastBooking.host.map((item) => item)}</Typography>
            </div>
            <div className={styles.lastBookingWrapper}>
              <Typography>{t("insights.clientBookings.services")}</Typography>
              <Typography>{details.lastBooking.services.map((item) => item)}</Typography>
            </div>
            <div className={styles.lastBookingWrapper}>
              <Typography>{t("insights.clientBookings.group")}</Typography>
              <Typography>{details.lastBooking.group.map((item) => item)}</Typography>
            </div>
            <div className={styles.lastBookingWrapper}>
              <Typography>{t("insights.clientBookings.notes")}</Typography>
              <Typography>{details.lastBooking.notes}</Typography>
            </div>
          </div>
        </div>
      )}

      <IconButton
        onClick={toggleDetails}
        className={classNames(styles.iconButton, { [styles.iconButtonOpen]: isOpen })}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
    </div>
  );
};
