import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { Footer } from "components";

import { ReactComponent as LogoSVG } from "assets/images/logo.svg";
import background from "assets/images/background-dark.png";

import styles from "./email-verified.module.scss";

export const EmailVerifiedPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} style={{ backgroundImage: `url${background}` }}>
      <div className={styles.content}>
        <LogoSVG className={styles.logo} />
        <div className={styles.verified}>
          <Typography fontWeight={700} fontSize={26}>
            {t("emailVerified.emailVerified")}
          </Typography>
          <Typography fontWeight={300}>{t("emailVerified.accountRegistered")}</Typography>
          <Typography fontWeight={300} sx={{ textDecoration: "underline", cursor: "pointer" }}>
            {t("emailVerified.addDetails")}
          </Typography>
        </div>
      </div>

      <Footer />
    </div>
  );
};
