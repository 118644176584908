import React, { useRef, useState } from "react";
import classNames from "classnames";
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useFormikContext } from "formik";

import { ProfileItemProps } from "../profile.types";
import { FilesDropzone } from "components";
import { tooltipConfig } from "../../create-rental.constants";
import { useWindowSize } from "hooks";
import { RentalData } from "server/organization/organization.types";

import { ReactComponent as ImageBackground } from "assets/icons/image-background.svg";
import { ReactComponent as TooltipIcon } from "assets/icons/tooltip-icon.svg";

import styles from "./profile-pictures.module.scss";
import mainStyles from "../profile.module.scss";

export const ProfilePictures: React.FC<ProfileItemProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { errors } = useFormikContext<RentalData>();

  const [numberOfElements, setNumberOfElements] = useState(0);

  const itemWidth = 152;
  const wrapperWidth = wrapperRef.current?.clientWidth;

  const isDesktop = width > 992;

  useDidUpdate(
    () => {
      if (wrapperWidth) {
        const numbers = wrapperWidth / itemWidth;
        if (numberOfElements !== numbers) setNumberOfElements(Math.floor(numbers));
      }
    },
    [wrapperWidth, width],
    true,
  );

  const picturesError = errors.pictures;

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.header}>
        <Typography className={classNames(mainStyles.text, styles.text)}>
          {t("rental.profile.addYourPhotos")}
        </Typography>
        <Tooltip
          title={t("rental.profile.tooltip.photos")}
          enterTouchDelay={0}
          leaveDelay={3000}
          className={styles.tooltip}
          componentsProps={tooltipConfig}
        >
          <TooltipIcon />
        </Tooltip>
      </div>
      <div className={styles.imagesWrapper} ref={wrapperRef}>
        <FilesDropzone name="pictures">
          {({ files, deleteFile }) => {
            if (files.length) {
              return (
                <Box
                  className={styles.photosWrapper}
                  style={{ gridTemplateColumns: `repeat(${numberOfElements}, 1fr)` }}
                >
                  {files.map((file) => (
                    <Grid container className={styles.imageContainer}>
                      <img key={file.name} alt={file.name} src={URL.createObjectURL(file)} />
                      <IconButton
                        className={styles.iconButton}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          deleteFile(file.name)();
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Grid>
                  ))}
                </Box>
              );
            }

            return (
              <div className={styles.photosWrapperEmpty}>
                {!isDesktop && (
                  <>
                    <div className={styles.imageBackground}>
                      <ImageBackground />
                    </div>
                    <div className={styles.imageBackground}>
                      <ImageBackground />
                    </div>
                    <div className={styles.imageBackground}>
                      <ImageBackground />
                    </div>
                    <div className={styles.imageBackground}>
                      <ImageBackground />
                    </div>
                  </>
                )}

                {isDesktop && numberOfElements && (
                  <>
                    <div className={styles.imageBackground}>
                      <Button className={styles.button}>+</Button>
                    </div>
                    {Array(numberOfElements)
                      .fill(0)
                      .map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={styles.imageBackground} key={index}>
                          <ImageBackground />
                        </div>
                      ))}
                  </>
                )}
              </div>
            );
          }}
        </FilesDropzone>
      </div>
      {picturesError && <Typography className={styles.error}>{picturesError}</Typography>}
    </div>
  );
};
