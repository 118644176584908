import React, { useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { ACCEPTED_COLOR, ACCEPTED_STATUS, createData, REJECTED_COLOR, statusesData } from "./booking-list.constants";
import { BookingListProps, TableData } from "./booking-list.types";
import { getWatersportTranslation } from "../../../../../utils/watersports.utils";
import { SportType } from "../../../../offers/create";

import styles from "./booking-list.module.scss";

// TODO dates refactor
const convertDate = (dateString: string) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() - 2);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");

  return `${day}-${month}-${year} ${hour}:${minute}`;
};

export const BookingList: React.FC<BookingListProps> = ({
  rejectOffer,
  submittingReservation,
  acceptOffer,
  reservations,
  isDataFetching,
}) => {
  const { t } = useTranslation();

  const [mappedReservations, setMappedReservations] = useState<null | TableData[]>([]);

  const isLoading = submittingReservation || isDataFetching;

  useDidUpdate(
    () => {
      const reservationsArr: TableData[] = [];

      reservations.map((reservation) => {
        const services: string[] = reservation.items
          .filter((item) => item.item_type === "SERVICE")
          .map((service) => service.service?.title || "");

        const dates = [reservation.start_time, reservation.end_time] as string[];

        const offer = reservation.items.find((item) => item.item_type === "OFFER");

        const watersportName = getWatersportTranslation(t, offer?.offer?.watersport.name as SportType);

        const reservationData = createData({
          offer: offer?.offer?.title ? `${watersportName} - ${offer?.offer.title}` : watersportName,
          guests: reservation.num_of_guests,
          totalProfit: `${reservation.price} ${offer?.offer?.currency}`,
          services,
          bookedBy: `${reservation.user.first_name} ${reservation.user.last_name}`,
          status: reservation.reservation_status,
          id: reservation.id,
          dates,
        });

        return reservationsArr.push(reservationData);
      });

      reservationsArr.reverse();

      setMappedReservations(reservationsArr);
    },
    [reservations],
    true,
  );

  return (
    <div className={styles.wrapper}>
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableCellMapFirst}>{t("ownerReservations.legend.offer")}</TableCell>
              <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.guests")}</TableCell>
              <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.totalProfit")}</TableCell>
              <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.dates")}</TableCell>
              <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.services")}</TableCell>
              <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.bookedBy")}</TableCell>
              <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedReservations?.map((row) => {
              return (
                <TableRow
                  key={row.offer}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    borderBottom: "2px solid #474CAE",
                    borderTop: "2px solid #474CAE",
                  }}
                >
                  <TableCell className={styles.tableCellFirst} scope="row">
                    {row.offer}
                  </TableCell>
                  <TableCell className={styles.tableCell}>{row.guests}</TableCell>
                  <TableCell className={styles.tableCell}>{row.totalProfit}</TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.dates.map((date) => {
                      return (
                        <Typography fontFamily="Chillax-regular" key={date}>
                          {convertDate(date)}
                        </Typography>
                      );
                    })}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.services.length === 0
                      ? t("ownerReservations.error.noServices")
                      : row.services.map((service) => (
                          <Typography fontFamily="Chillax-regular" key={service}>
                            {service}
                          </Typography>
                        ))}
                  </TableCell>
                  <TableCell className={styles.tableCell}>{row.bookedBy}</TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.status === ACCEPTED_STATUS ? (
                      <div className={styles.buttons}>
                        <Button
                          sx={{ backgroundColor: ACCEPTED_COLOR }}
                          className={styles.button}
                          onClick={() => acceptOffer(row.id)}
                          disabled={isLoading}
                        >
                          {t("ownerReservations.buttons.accept")}
                        </Button>
                        <Button
                          sx={{ backgroundColor: REJECTED_COLOR }}
                          className={styles.button}
                          onClick={() => rejectOffer(row.id)}
                          disabled={isLoading}
                        >
                          {t("ownerReservations.buttons.reject")}
                        </Button>
                      </div>
                    ) : (
                      <Typography
                        fontFamily="Chillax-regular"
                        color={(row.status === "ACCEPTED" && ACCEPTED_COLOR) || "#474CAE"}
                      >
                        {statusesData(t).find((status) => status.status === row.status)?.label}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
