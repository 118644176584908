import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { COOKIES_POLICY_PAGE } from "constants/routes.constants";
import { CookieConsentProps } from "./cookie-consent.types";
import { useRoutesPath } from "hooks";

import styles from "./cookie-consent.module.scss";

export const CookieConsent: React.FC<CookieConsentProps> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const handleClose = () => setIsOpen(false);

  return (
    <div className={styles.container}>
      <Typography fontFamily="Chillax-Semibold">{t("cookiesConsent.cookiesPolicy")}</Typography>
      <Typography fontFamily="Chillax-regular">
        {t("cookiesConsent.policy")}
        <Link className={styles.link} to={localePath({ route: COOKIES_POLICY_PAGE })}>
          {t("cookiesConsent.cookiesPolicy")}
        </Link>
        .
      </Typography>
      <div className={styles.buttons}>
        <Button onClick={handleClose} className={styles.button}>
          {t("cookiesConsent.decline")}
        </Button>
        <Button onClick={handleClose} className={styles.button}>
          {t("cookiesConsent.accept")}
        </Button>
      </div>
    </div>
  );
};
