export const team = {
  team: "Zespół",
  add: "Dodaj",
  remove: "Usuń",
  person: "Osoba",
  role: "Rola",
  googleCalendar: "Kalendarz Google",
  connectWithGoogle: "Połącz z Google",

  addModal: {
    addTeamMember: "Dodaj członka zespołu",
    email: "Email",
    role: "Rola",
    send: "Wyślij",
  },

  removeModal: {
    removeTeamMember: "Usuń członka zespołu",
    remove: "Usuń",
    email: "Email",
    employee: "Employee",
  },
};
