import React from "react";
import { IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { PersonalInfoProps } from "./personal-info.types";
import { useWindowSize } from "hooks";

import styles from "./personal-info.module.scss";

export const PersonalInfo: React.FC<PersonalInfoProps> = ({ userData, className, openEditModal }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const handleOpenModal = () => openEditModal(true);

  const isDesktop = width > 992;
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.item}>
        <div className={styles.itemInfo}>
          <Typography>{t("profile.personalInfo.legalName")}</Typography>
          <Typography>
            {userData?.first_name} {userData?.last_name}
          </Typography>
        </div>
        {isDesktop && (
          <IconButton onClick={handleOpenModal} className={styles.itemButton}>
            {t("profile.personalInfo.edit")}
          </IconButton>
        )}
      </div>

      <div className={styles.item}>
        <div className={styles.itemInfo}>
          <Typography>{t("profile.personalInfo.email")}</Typography>
          <Typography>{userData?.email}</Typography>
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.itemInfo}>
          <Typography>{t("profile.personalInfo.phoneNumber")}</Typography>
          <Typography>{userData?.phone_number}</Typography>
        </div>
        {isDesktop && (
          <IconButton onClick={handleOpenModal} className={styles.itemButton}>
            {t("profile.personalInfo.edit")}
          </IconButton>
        )}
      </div>
    </div>
  );
};
