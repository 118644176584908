import { TFunction } from "react-i18next";

export const getParameters = (t: TFunction) => [
  {
    value: "length",
    label: t("offerPage.details.boatDetails.length"),
  },
  {
    value: "boatDetails",
    label: t("offerPage.details.boatDetails.boatDetails"),
  },
  {
    value: "year",
    label: t("offerPage.details.boatDetails.year"),
  },
  {
    value: "manufacturer",
    label: t("offerPage.details.boatDetails.manufacturer"),
  },
  { value: "productionYear", label: t("offerPage.details.boatDetails.productionYear") },
  {
    value: "deposit",
    label: t("offerPage.details.boatDetails.deposit"),
  },
  {
    value: "production_year",
    label: t("offerPage.details.boatDetails.productionYear"),
  },
];
