export const clientBookingsData = [
  {
    name: "Adam Łuczak",
    hours: 568,
    profit: 23312,
    nextBooking: "25/05/2023",
    id: 1,
    details: {
      phoneNumber: "545-542-342",
      email: "asd@gmail.com",
      gender: "male",
      age: "20-30",
      dateOfBirth: "01/01/1999",
      weight: "90kg",
      size: "M",
      level: "Pro",
      notes:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      lastBooking: {
        offer: "Windsurfing group lesson",
        host: ["Szmal S", "ronaldo"],
        services: ["wind", "lifevest"],
        group: ["Szczęsny W", "Konrad K"],
        notes: "add notes",
        date: "21/05/2023 9:00-14:00",
      },
    },
  },
  {
    name: "Oskar Kordacki",
    hours: 231,
    profit: 2312,
    id: 2,
    nextBooking: "25/05/2023",
    details: {
      phoneNumber: "545-542-342",
      email: "asd@gmail.com",
      gender: "male",
      age: "20-30",
      dateOfBirth: "01/01/1999",
      weight: "90kg",
      size: "M",
      level: "Pro",
      notes:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      lastBooking: {
        offer: "Windsurfing group lesson",
        host: ["Szmal S"],
        services: ["wind", "lifevest"],
        group: ["Szczęsny W", "Konrad K"],
        notes: "add notes",
        date: "21/05/2023 9:00-14:00",
      },
    },
  },
];
