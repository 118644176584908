import React, { useState } from "react";
import { IconButton, ListItem, MenuList, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactSVG } from "react-svg";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useDispatch, useSelector } from "react-redux";

import { OfferBoxProps } from "./offer-box.types";
import { useRoutesPath } from "hooks";
import { EDIT_OFFER_PAGE, OFFER_PAGE } from "constants/routes.constants";
import { getWatersportIcon } from "utils/watersports.utils";
import { deleteOffer } from "server";
import { RootState, setOffers } from "store";

import { ReactComponent as DotsIcon } from "assets/icons/dots.svg";

import styles from "./offer-box.module.scss";

export const OfferBox: React.FC<OfferBoxProps> = ({ offer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { localePathParams } = useRoutesPath();
  const {
    title,
    id,
    organization: { name: organizationName, organization_images: organizationImages },
    watersport: { id: watersportId },
    offer_images: offerImages,
  } = offer;
  const { offers } = useSelector((state: RootState) => state.organization);

  const [buttonsOpen, setButtonsOpen] = useState<boolean>(false);

  const handleOpenOffer = () =>
    navigate(
      localePathParams({
        route: OFFER_PAGE,
        params: {
          offerId: offer.id,
          watersport: offer.watersport.name.toLowerCase(),
          location: offer.location.city.toLowerCase(),
        },
      }),
    );

  const handleOpenButtons = () => {
    setButtonsOpen((prevState) => !prevState);
  };

  const handleEditOffer = () => {
    navigate(localePathParams({ route: EDIT_OFFER_PAGE, params: { offerId: offer.id } }));
  };

  const handleCloseMenu = () => setButtonsOpen(false);

  const { submit: submitDeleteOffer, onSubmitSuccess: onSubmitSuccessDeleteOffer } = useSubmit(
    deleteOffer.setParams({ id }),
  );
  onSubmitSuccessDeleteOffer((res) => {
    const filteredOffers = offers.filter(({ id: idToDelete }) => idToDelete !== res.response.id);
    dispatch(setOffers(filteredOffers));
    setButtonsOpen(false);
  });

  const handleDeleteOffer = () => {
    submitDeleteOffer().then();
  };

  const image = offerImages[0]?.image_url || organizationImages[0]?.image_url;

  const watersportIcon = getWatersportIcon(watersportId, t);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} style={{ backgroundImage: `url(${image})` }}>
        <div className={styles.watersport}>
          <ReactSVG src={watersportIcon} className={styles.icon} />
        </div>
        <div className={styles.buttons}>
          <IconButton className={styles.dotsIcon} onClick={handleOpenButtons}>
            <DotsIcon />
          </IconButton>
        </div>

        {buttonsOpen && (
          <MenuList className={styles.menu}>
            <ListItem className={styles.menuItem} onClick={handleEditOffer}>
              <EditIcon /> <Typography>{t("management.yourBusiness.offers.edit")}</Typography>
            </ListItem>
            <ListItem className={styles.menuItem}>
              <ContentCopyIcon /> <Typography>{t("management.yourBusiness.offers.duplicate")}</Typography>
            </ListItem>
            <ListItem onClick={handleDeleteOffer} className={styles.menuItem}>
              <ClearIcon /> <Typography>{t("management.yourBusiness.offers.delete")}</Typography>
            </ListItem>

            <IconButton onClick={handleCloseMenu} className={styles.closeMenuButton}>
              <ClearIcon />
            </IconButton>
          </MenuList>
        )}
      </div>
      <Typography onClick={handleOpenOffer} className={styles.title}>
        {title || organizationName}
      </Typography>
    </div>
  );
};
