import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { createRentalProfileSteps } from "../create-rental.constants";

import { ReactComponent as StripeIcon } from "assets/icons/stripe.svg";

import stylesMain from "../create-rental.module.scss";
import styles from "./payments.module.scss";

export const Payments = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(stylesMain.itemContainer, styles.container)}>
      <Typography className={classNames(stylesMain.header, stylesMain.desktop)}>
        {t("rental.steps.step")} 3: {createRentalProfileSteps(t)[3].name}
      </Typography>
      <div className={styles.paymentItem}>
        <div className={styles.texts}>
          <Typography variant="h3">{t("rental.rentalPayment.addPaymentDetails")}</Typography>
          <Typography>{t("rental.rentalPayment.paymentContent")}</Typography>
        </div>

        <div className={styles.stripeBox}>
          <StripeIcon />
        </div>
      </div>
    </div>
  );
};
