import React, { useState } from "react";
import { Button } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { Dayjs } from "dayjs";
import { DatePicker, Popover } from "antd";
import localePl from "antd/es/date-picker/locale/pl_PL";
import localeEn from "antd/es/date-picker/locale/en_GB";
import { useSelector } from "react-redux";

import { useQueryParams } from "hooks";
import { filtersData } from "./filters.constants";
import { RootState } from "../../../../../store";

import styles from "./filters.module.scss";

const { RangePicker } = DatePicker;

export const Filters = () => {
  const { t } = useTranslation();
  const {
    updateQueryParams,
    query: { filterTime },
  } = useQueryParams();

  const [showRangePicker, setShowRangePicker] = useState<boolean>(false);
  const { locale } = useSelector((state: RootState) => state.app);

  const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) =>
    updateQueryParams({ filterTime: e.currentTarget.value, dataFrom: "", dataTo: "" });

  const handleRange = () => {
    setShowRangePicker(!showRangePicker);
    updateQueryParams({ filterTime: filtersData.range });
  };

  const handleRangeDates = (dates: [Dayjs | null, Dayjs | null]) => {
    if (dates[0] && dates[1]) {
      const formattedFrom = dates[0].format("YYYY-MM-DD");
      const formattedTo = dates[1].format("YYYY-MM-DD");

      updateQueryParams({
        dataFrom: formattedFrom,
        dataTo: formattedTo,
        filterTime: filtersData.range,
      });
    } else {
      console.error("Data values are missing.");
    }
  };

  useDidUpdate(
    () => {
      if (filterTime === filtersData.range) {
        setShowRangePicker(true);
      } else {
        setShowRangePicker(false);
      }
    },
    [filterTime],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Button
          onClick={handleFilter}
          value={filtersData["7days"]}
          className={classNames(styles.button, { [styles.activeButton]: filterTime === filtersData["7days"] })}
        >
          7 {t("insights.filters.days")}
        </Button>

        <Button
          onClick={handleFilter}
          value={filtersData["1month"]}
          className={classNames(styles.button, { [styles.activeButton]: filterTime === filtersData["1month"] })}
        >
          1 {t("insights.filters.month")}
        </Button>

        <Button
          onClick={handleFilter}
          value={filtersData["3months"]}
          className={classNames(styles.button, { [styles.activeButton]: filterTime === filtersData["3months"] })}
        >
          3 {t("insights.filters.months")}
        </Button>

        <Popover
          content={
            <RangePicker
              className={styles.rangePicker}
              popupClassName={styles.popup}
              format="YYYY-MM-DD"
              locale={locale === "pl-PL" ? localePl : localeEn}
              onChange={handleRangeDates}
            />
          }
          trigger="click"
          visible={showRangePicker}
          onVisibleChange={(visible) => setShowRangePicker(visible)}
        >
          <Button
            value="range"
            onClick={handleRange}
            className={classNames(styles.button, { [styles.activeButton]: filterTime === filtersData.range })}
          >
            {t("insights.filters.range")}
          </Button>
        </Popover>

        <Button value="filter" className={styles.button}>
          {t("insights.filters.filter")}
        </Button>
      </div>
    </div>
  );
};
