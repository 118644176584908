import {
  ABOUT_PAGE,
  CONTACT_PAGE,
  CREATE_OFFER_PAGE,
  KNOWLEDGE_CENTER_PAGE,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_USE_PAGE,
} from "constants/routes.constants";
import { RouteConstant } from "types";

type Section = {
  name: string;
  options: {
    component: string;
    path: RouteConstant;
    section?: string;
  }[];
};

export const sections: Section[] = [
  {
    name: "components.marketplace",
    options: [
      { component: "components.safety", path: KNOWLEDGE_CENTER_PAGE, section: "?section=safety" },
      { component: "components.startRenting", path: CREATE_OFFER_PAGE },
    ],
  },
  {
    name: "components.experiences",
    options: [
      { component: "components.guides", path: KNOWLEDGE_CENTER_PAGE, section: "?section=guides" },
      { component: "components.destinations", path: KNOWLEDGE_CENTER_PAGE, section: "?section=destinations" },
    ],
  },
  {
    name: "components.about",
    options: [
      { component: "components.aboutSeaeasy", path: ABOUT_PAGE },
      { component: "components.termsOfUse", path: TERMS_OF_USE_PAGE },
    ],
  },
  {
    name: "components.social",
    options: [
      { component: "components.companyNews", path: KNOWLEDGE_CENTER_PAGE, section: "?section=companyNews" },
      { component: "components.knowledgeCenter", path: KNOWLEDGE_CENTER_PAGE },
    ],
  },
  {
    name: "components.help",
    options: [
      { component: "components.contactUs", path: CONTACT_PAGE },
      { component: "components.privacyPolicy", path: PRIVACY_POLICY_PAGE },
    ],
  },
];
