import { useSelector } from "react-redux";

import { RootState } from "store";
import { LocalPathParamsProps, LocalPathProps } from "./use-routes-path.types";

export const useRoutesPath = () => {
  const { locale } = useSelector((state: RootState) => state.app);

  const localeValue = locale === "pl-PL" ? "pl" : "en";

  function localePath({ route, queryParams }: LocalPathProps) {
    const pathPl = route.path.pl.replace(/:locale/g, "pl");
    const pathEn = route.path.en.replace(/:locale/g, "en");

    if (queryParams) return locale === "pl-PL" ? `${pathPl}${queryParams}` : `${pathEn}${queryParams}`;

    return locale === "pl-PL" ? pathPl : pathEn;
  }

  function localePathParams({ route, localeParams, params, queryParams }: LocalPathParamsProps) {
    const pathPl = route.path.pl.replace(/:locale/g, "pl");
    const pathEn = route.path.en.replace(/:locale/g, "en");

    let path: string = locale === "pl-PL" ? pathPl : pathEn;

    if (localeParams) {
      const key = Object.keys(localeParams)[0];
      const value = Object.values(localeParams)[0];
      path = path.replace(new RegExp(`:${key}`, "g"), String(value[localeValue]));
    }

    if (params) {
      Object.keys(params).forEach((key) => {
        const value = params[key];
        path = path.replace(new RegExp(`:${key}`, "g"), String(value));
      });
    }

    if (queryParams) {
      path = `${path}${queryParams}`;
    }

    return path;
  }

  return { localePath, locale, localePathParams };
};
