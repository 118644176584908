import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import styles from "./external-login-services.module.scss";

export const ExternalLoginServices: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Button variant="outlined" className={styles.button} sx={{ mt: 1 }}>
        {t("login.externalFacebook")}
      </Button>
      <Button variant="outlined" className={styles.button} sx={{ mt: 1 }}>
        {t("login.externalGoogle")}
      </Button>
      <Button variant="outlined" className={styles.button} sx={{ mt: 1 }}>
        {t("login.externalApple")}
      </Button>
    </div>
  );
};
