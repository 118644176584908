import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "store";

import styles from "./weather.module.scss";

export const Weather = () => {
  const { organizationData, locations } = useSelector((state: RootState) => state.organization);

  const defaultPosition = locations.find((item) => item.id === organizationData?.location);
  const defaultLat = defaultPosition?.latitude || 49.753;
  const defaultLon = defaultPosition?.longitude || 21.006;

  const frameUrl = `https://embed.windy.com/embed2.html?lat=${defaultLat}&lon=${defaultLon}&detailLat=${defaultLat}&detailLon=${defaultLon}&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=true&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1`;

  return (
    <div className={styles.container}>
      <iframe className={styles.iframe} width="100%" height="100%" src={frameUrl} frameBorder="0" title="weather" />
    </div>
  );
};
