import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HOME_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";

import rentalRulesEn from "assets/images/rental-rules-en.webp";
import rentalRulesPl from "assets/images/rental-rules-pl.webp";
import { ReactComponent as Logo } from "assets/icons/logo-blue.svg";

import styles from "./before-start.module.scss";

export const BeforeStart = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath, locale } = useRoutesPath();

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo className={styles.logo} onClick={openHomePage} />
          <Typography fontFamily="Chillax-Medium" color="#474CAE" fontSize="26px">
            SeaEasy
          </Typography>
        </div>
        <div className={styles.about}>
          <Typography fontFamily="Chillax-semibold" className={styles.title}>
            {t("rental.beforeStart.beforeWeStart")} <br />
            {t("rental.beforeStart.checkHowItWorks")}
          </Typography>
          <Typography fontFamily="Chillax-regular">{t("rental.beforeStart.content1")}</Typography>
          <Typography fontFamily="Chillax-regular">{t("rental.beforeStart.content2")}</Typography>
          <Button type="submit" className={styles.button}>
            {t("rental.beforeStart.letsStart")}
          </Button>
        </div>
      </div>
      <div className={styles.rules}>
        <img className={styles.image} src={locale === "pl-PL" ? rentalRulesPl : rentalRulesEn} alt="rental rules" />
      </div>
    </div>
  );
};
