export const contact = {
  aboutUs: "About us",
  contactUs: "Contact us",
  name: "Name",
  howCanWeHelpYou: "How can we help you?",
  send: "Send",
  bySendingMessage: "By sending this message, you agree with our ",
  privacyPolicy: "Privacy policy",
  cookiesPolicy: "Cookies",
  and: "and",
  termsOfUse: "Terms of Use",
  nameIsRequired: "Name is required",
  emailIsRequired: "Email is required",
  valueMustBeEmail: "Value must be a email",
  messageIsRequired: "Message is required",
  sentSuccessfully: "Sent successfully",
  sendError: "Error when sending",
};
