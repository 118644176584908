import { builder } from "../builder";
import { GenerateStripeLinkData } from "./stripe.types";

export const checkStripeData = builder.createCommand<boolean>()({
  endpoint: "/app/organization/:organization_id/check_stripe_account_data",
  method: "GET",
  options: { timeout: 0 },
  retry: 10,
});

export const generateStripeLink = builder.createCommand<GenerateStripeLinkData>()({
  endpoint: "/app/organization/:organization_id/generate_account_link",
  method: "GET",
  options: { timeout: 0 },
  retry: 5,
});
