import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

import { OFFERS_PAGE } from "constants/routes.constants";
import { Loader } from "components";
import { Sections } from "../mobile-menu.types";
import { useRoutesPath } from "hooks";
import { SectionProps } from "./section.types";
import { getWatersportIcon, getWatersportTranslation } from "utils/watersports.utils";

import WatersportsShadowIconPurple from "assets/icons/waterposrt-icon-purple.svg";
import WatersportsShadowIconGreen from "assets/icons/waterposrt-icon-green.svg";
import GlobePurple from "assets/icons/globe-purple.svg";
import GlobeGreen from "assets/icons/globe-green.svg";

import styles from "../mobile-menu.module.scss";

export const Section: React.FC<SectionProps> = ({
  color,
  watersportsPath,
  destinationsPath,
  isGreen,
  handleCloseMenu,
  watersports,
  destinations,
}) => {
  const { t } = useTranslation();
  const { localePath, locale } = useRoutesPath();

  const [activeSection, setActiveSection] = useState<Sections>("yachts");

  const changeActiveSection = (section: Sections) => () => {
    setActiveSection(section);
  };

  return (
    <div className={classNames(styles.section, { [styles.sectionGreen]: isGreen })}>
      <div className={styles.sectionButtons}>
        <Button
          className={classNames(styles.sectionButton, {
            [styles.sectionButtonActive]: activeSection === "yachts",
          })}
          style={{ color }}
          onClick={changeActiveSection("yachts")}
        >
          {t("navbar.yachts")}
        </Button>
        <Button
          className={classNames(styles.sectionButton, {
            [styles.sectionButtonActive]: activeSection === "watersports",
          })}
          style={{ color }}
          onClick={changeActiveSection("watersports")}
        >
          {t("navbar.watersports")}
        </Button>
      </div>
      <div className={styles.sectionContent}>
        {activeSection === "yachts" && (
          <Link to="/" onClick={handleCloseMenu} className={styles.mobileMenuItem}>
            <img src={isGreen ? GlobeGreen : GlobePurple} alt="globe" className={styles.globeIcon} />

            <Typography color={color} className={styles.contentLinkAll}>
              {t("navbar.topDestinations")}
            </Typography>
          </Link>
        )}
        {activeSection === "yachts" && !watersports && <Loader color="#FFF8F2" backgroundColor="#FFF8F2" />}
        {activeSection === "yachts" &&
          destinations?.map((destination) => {
            const map = destination.attributes.map?.data.attributes.url;
            const mapPurple = destination.attributes.mapPurple?.data.attributes.url;

            return (
              <Link to={destinationsPath(destination)} onClick={handleCloseMenu} className={styles.mobileMenuItem}>
                <img src={isGreen ? map : mapPurple} alt="country map" className={styles.itemImg} />
                <Typography color={color} className={styles.contentLink}>
                  {locale === "pl-PL" ? destination.attributes.locationPl : destination.attributes.locationEn}
                </Typography>
              </Link>
            );
          })}

        {activeSection === "watersports" && (
          <Link to={localePath({ route: OFFERS_PAGE })} onClick={handleCloseMenu} className={styles.mobileMenuItem}>
            <img
              alt="watersports"
              className={styles.linkWatersportIcon}
              src={isGreen ? WatersportsShadowIconGreen : WatersportsShadowIconPurple}
            />

            <Typography color={color} className={styles.contentLinkAll}>
              {t("navbar.allWatersports")}
            </Typography>
          </Link>
        )}
        {activeSection === "watersports" && !watersports && <Loader color="#FFF8F2" backgroundColor="#FFF8F2" />}
        {activeSection === "watersports" &&
          watersports?.map((watersport) => {
            const watersportName = getWatersportTranslation(t, watersport.name);
            const watersportIcon = getWatersportIcon(watersport.id, t);

            return (
              <Link
                to={watersportsPath({
                  slugEn: watersport.name,
                })}
                onClick={handleCloseMenu}
                className={styles.mobileMenuItem}
              >
                <ReactSVG
                  src={watersportIcon}
                  className={classNames(styles.watersportIcon, { [styles.watersportGreenIcon]: isGreen })}
                />

                <Typography color={color} className={styles.contentLink}>
                  {watersportName}
                </Typography>
              </Link>
            );
          })}
      </div>
    </div>
  );
};
