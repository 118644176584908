import React, { useRef, useState } from "react";
import { IconButton, ListItem, Typography } from "@mui/material";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { CategoryProps } from "./category.types";
import { ARROW_WIDTH, ITEM_WIDTH } from "../filters";
import { useQueryParams, useWindowSize } from "hooks";
import { WatersportsData } from "server";

import styles from "../filters.module.scss";

export const Category: React.FC<CategoryProps> = ({ wrapperRef, options, setData }) => {
  const categoriesInnerWrapperRef = useRef<null | HTMLDivElement>(null);

  const { width } = useWindowSize();
  const {
    query: { category: categoryQuery },
    updateQueryParams,
  } = useQueryParams();

  const [showCategoriesArrows, setShowCategoriesArrows] = useState<boolean>(false);
  const [numOfItemsToShow, setNumOfItemsToShow] = useState<number>(0);
  const [indexFrom, setIndexFrom] = useState<number>(0);
  const [indexTo, setIndexTo] = useState<number>(0);
  const [, setActiveItemIndex] = useState<null | number>(null);

  const handleCategory = (category: { label: string; value: string; icon: string; options: WatersportsData[] }) => {
    setData([]);

    if (category.value === categoryQuery) {
      updateQueryParams({ category: "" });
    } else {
      updateQueryParams({ category: category.value });
    }
  };

  const handleNextItem = () => {
    const indexDiff = indexTo - indexFrom;
    const newIndexTo = indexTo + 1;
    const newIndexFrom = newIndexTo - indexDiff;

    if (newIndexTo - 1 === options.length) return;

    setIndexTo(newIndexTo);
    setIndexFrom(newIndexFrom);
  };

  const handlePrevItem = () => {
    const indexDiff = indexTo - indexFrom;
    const newIndexTo = indexTo - 1;
    const newIndexFrom = newIndexTo - indexDiff;

    if (newIndexTo === 0) return;

    setIndexTo(newIndexTo);
    setIndexFrom(newIndexFrom);
  };

  const rightArrowDisabled = indexTo === options.length;
  const leftArrowDisabled = indexFrom === 0;

  useDidUpdate(
    () => {
      setIndexFrom(0);

      if (options.length >= numOfItemsToShow) return setIndexTo(numOfItemsToShow);
      if (options.length < numOfItemsToShow) return setIndexTo(options.length);
    },
    [options, numOfItemsToShow],
    true,
  );

  useDidUpdate(
    () => {
      const itemsWidth = options.length * ITEM_WIDTH;

      if (wrapperRef.current?.offsetWidth) {
        if (wrapperRef?.current?.offsetWidth < itemsWidth) {
          setShowCategoriesArrows(true);
        } else {
          setShowCategoriesArrows(false);
        }
      }
    },
    [width, options],
    true,
  );

  useDidUpdate(
    () => {
      if (wrapperRef.current?.offsetWidth && wrapperRef.current) {
        const wrapperWidth: number = wrapperRef.current?.offsetWidth;
        const num: number = (wrapperWidth - ARROW_WIDTH * 2) / ITEM_WIDTH;

        setNumOfItemsToShow(Math.floor(num));
      }
    },
    [wrapperRef.current?.offsetWidth],
    true,
  );

  useDidUpdate(
    () => {
      if (categoryQuery) {
        const activeIndex = options.findIndex((item) => item.value === categoryQuery);
        setActiveItemIndex(activeIndex);
      } else {
        setActiveItemIndex(null);
      }
    },
    [categoryQuery],
    true,
  );

  return (
    <ul
      className={styles.itemsContainer}
      style={{ gridTemplateColumns: showCategoriesArrows ? `${ARROW_WIDTH}px 1fr ${ARROW_WIDTH}px` : "1fr" }}
    >
      {showCategoriesArrows && (
        <IconButton className={styles.arrowIcon} onClick={handlePrevItem} disabled={leftArrowDisabled}>
          {"<"}
        </IconButton>
      )}
      <div
        ref={categoriesInnerWrapperRef}
        className={styles.innerWrapper}
        style={{
          gridTemplateColumns: `repeat(${
            numOfItemsToShow <= options.length ? numOfItemsToShow : options.length
          },  ${ITEM_WIDTH}px)`,
        }}
      >
        {options.map((category, index) => {
          const isActive = category.value === categoryQuery;

          if (index < indexFrom) return null;
          if (index + 1 > indexTo) return null;

          return (
            <ListItem
              className={classNames(styles.itemElement, { [styles.itemElementActive]: isActive })}
              onClick={() => handleCategory(category)}
            >
              <ReactSVG className={styles.itemIcon} src={category.icon} />
              <Typography className={styles.itemName}>{category.label}</Typography>
            </ListItem>
          );
        })}
      </div>
      {showCategoriesArrows && (
        <IconButton className={styles.arrowIcon} onClick={handleNextItem} disabled={rightArrowDisabled}>
          {">"}
        </IconButton>
      )}
    </ul>
  );
};
