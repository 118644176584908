import axios, { AxiosResponse } from "axios";

import { environment } from "../../config/environment.config";
import { STORAGE_FIELDS } from "../../constants/storage.constants";

export const uploadImage = (presignedUrl: string, image: File): Promise<AxiosResponse<unknown>> =>
  axios.put(presignedUrl, image, { headers: { "Content-Type": image.type } });

export const deleteImage = (imageId: number): Promise<AxiosResponse<unknown>> =>
  axios.delete(`${environment.apiUrl}/app/offer/image/${imageId}`, {
    headers: {
      "x-api-key": environment.apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(STORAGE_FIELDS.token)}`,
    },
  });
