import React, { useContext, useState, useMemo } from "react";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import moment, { Moment } from "moment";

import { ServicesModel } from "../../../models";
import { ReservationsContext } from "../reservations-modal.context";
import { getAvailability, Slots } from "../../../server/availability/availability.server";
import { FormValues } from "../components/contact-person/contact-person.types";
import { RateTypeNoPerson } from "../../../pages/offers/create";
import { GuestData } from "../components/quests-details/guests-details.types";
import { WorkerInterface } from "./slots-view-own.context";

interface Props {
  children: React.ReactNode;
}

export type CalendarViewContextType = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  addGuest: () => void;
  removeGuest: () => void;
  guests: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  selectedServices: ServicesModel[];
  setSelectedServices: React.Dispatch<React.SetStateAction<ServicesModel[]>>;
  slotsSubmitting: boolean;
  slots: Slots[] | null;
  formData: FormValues | null;
  setFormData: React.Dispatch<React.SetStateAction<FormValues | null>>;
  selectedDate: moment.Moment;
  setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setSelectedRange: React.Dispatch<React.SetStateAction<string[]>>;
  selectedRange: string[];
  totalPrice: number;
  guestsData: GuestData[];
  setGuestsData: React.Dispatch<React.SetStateAction<GuestData[]>>;
  serviceSelectedWorker: WorkerInterface[];
  setServiceSelectedWorker: React.Dispatch<React.SetStateAction<WorkerInterface[]>>;
};

export const CalendarViewContext = React.createContext<CalendarViewContextType>({
  step: 1,
  setStep: () => {},
  addGuest: () => {},
  removeGuest: () => {},
  guests: 1,
  handleNextStep: () => {},
  handlePrevStep: () => {},
  selectedServices: [],
  setSelectedServices: () => {},
  slotsSubmitting: false,
  slots: [],
  formData: null,
  setFormData: () => {},
  selectedDate: moment(),
  setSelectedDate: () => {},
  selectedRange: [],
  setSelectedRange: () => {},
  totalPrice: 0,
  guestsData: [],
  setGuestsData: () => {},
  serviceSelectedWorker: [],
  setServiceSelectedWorker: () => {},
});

type MinutesByRateTypeType = {
  [key: string]: number;
};

type RateTypeMap = {
  [rateType in RateTypeNoPerson]: number;
};

export const minutesByRateType: MinutesByRateTypeType = {
  HALF_HOUR: 30,
  HOUR: 60,
  DAY: 60 * 24,
  WEEK: 60 * 24 * 7,
};

export const rateTypeMillisecondDiff: RateTypeMap = {
  HALF_HOUR: 1800000,
  HOUR: 3600000,
  DAY: 86400000,
  WEEK: 604800000,
  EVENT: 1,
};

export const DIVISOR_TO_MINUTES = 60000;

export interface Offer {
  id: number;
  price: number;
  rate_per_person: boolean;
  rate_type: RateTypeNoPerson;
  num_of_opportunities: number;
  services: ServicesModel[];
}

export interface Service {
  id: number;
  price: number;
  fixed_price: boolean;
}

export const getRateMultiplier = (offer: Offer, startDate: string, endDate: string): number => {
  const { rate_type: rateType } = offer;
  const start = moment(startDate);
  const end = moment(endDate);

  if (rateType === "EVENT") {
    return 1;
  }

  const totalDurationDays = end.diff(start, "days") + 1;

  if (rateType === "WEEK") {
    return Math.ceil(totalDurationDays / 7);
  }

  if (rateType === "DAY") {
    return totalDurationDays;
  }

  const totalDurationMinutes = end.diff(start, "minutes") + 1;
  return Math.ceil(totalDurationMinutes / minutesByRateType[rateType]);
};

export const calculateTotalPrice = (
  offer: Offer,
  selectedServices: ServicesModel[],
  selectedRange: string[],
  guests: number,
): number => {
  let totalPriceCounter = 0;

  if (selectedRange.length === 0) return 0;

  const startDate = selectedRange[0];
  const endDate = selectedRange[selectedRange.length - 1];
  const rateMultiplier = getRateMultiplier(offer, startDate, endDate);

  totalPriceCounter += rateMultiplier * offer.price;
  if (offer.rate_per_person) {
    totalPriceCounter *= guests;
  }

  selectedServices.forEach((service) => {
    let servicePrice = 0;

    if (service.fixed_price) {
      servicePrice = service.price;
    } else {
      servicePrice = rateMultiplier * service.price;
      if (offer.rate_per_person) {
        servicePrice *= guests;
      }
    }
    totalPriceCounter += servicePrice;
  });

  return totalPriceCounter;
};

export const CalendarViewContextProvider: React.FC<Props> = ({ children }) => {
  const { offer } = useContext(ReservationsContext);

  const [step, setStep] = useState<number>(1);
  const [guests, setGuests] = useState<number>(1);
  const [selectedServices, setSelectedServices] = useState<ServicesModel[]>([]);
  const [formData, setFormData] = useState<FormValues | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedRange, setSelectedRange] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [guestsData, setGuestsData] = useState<GuestData[]>([]);
  const [serviceSelectedWorker, setServiceSelectedWorker] = useState<WorkerInterface[]>([]);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handlePrevStep = () => {
    if (step === 1) return;
    setStep((prevState) => prevState - 1);
  };

  const addGuest = () => {
    if (guests === offer?.num_of_opportunities) return;

    setGuests((prevState) => prevState + 1);
  };

  const removeGuest = () => {
    if (guests === 0) return;

    setGuests((prevState) => prevState - 1);
  };

  const getAvailableSlots = useSubmit(getAvailability);
  const { submit: submitSlots, data: slots, submitting: slotsSubmitting } = getAvailableSlots;

  useDidUpdate(
    () => {
      if (offer) {
        const totalPriceCounter = calculateTotalPrice(offer, selectedServices, selectedRange, guests);
        setTotalPrice(totalPriceCounter);
      }
    },
    [offer, selectedServices, selectedRange, guests],
    true,
  );

  useDidUpdate(
    () => {
      const startOfMonth = selectedDate
        .clone()
        .startOf("month")
        .set({ hour: 0, minute: 0, second: 0 })
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endOfRange = selectedDate
        .clone()
        .add(2, "months")
        .endOf("month")
        .set({ hour: 23, minute: 59, second: 59 })
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");

      const services = selectedServices
        .map((service) => {
          const currentServiceWorkers = serviceSelectedWorker.filter(
            (serviceWorker) => serviceWorker.service_id === service.id,
          );

          return currentServiceWorkers.map((serviceWorker) => ({
            id: service.id,
            user_id: serviceWorker.id,
          }));
        })
        .flat();

      const removeDuplicates = (data: { id: number; user_id: number }[]) => {
        const seen = new Set();
        return data.filter((item) => {
          const key = `${item.id}_${item.user_id}`;
          if (!seen.has(key)) {
            seen.add(key);
            return true;
          }
          return false;
        });
      };

      const uniqueServices = removeDuplicates(services);

      const data = {
        datetime_from: startOfMonth,
        datetime_to: endOfRange,
        services: uniqueServices,
        offer_id: offer?.id || 0,
      };

      submitSlots({ data }).then();
    },
    [selectedDate, selectedServices, guests, serviceSelectedWorker],
    true,
  );

  useDidUpdate(() => {
    setSelectedRange([]);
  }, [slots, guests, selectedServices]);

  useDidMount(() => {
    if (offer?.services.length === 0) return;

    const requiredServices: ServicesModel[] = [];

    offer?.services.forEach((service) => {
      if (!service.required) return;

      requiredServices.push(service);
    });

    if (requiredServices.length !== 0) {
      setSelectedServices(requiredServices);
    }
  });

  const contextValue = useMemo(
    () => ({
      step,
      setStep,
      removeGuest,
      addGuest,
      guests,
      handlePrevStep,
      handleNextStep,
      selectedServices,
      setSelectedServices,
      slotsSubmitting,
      slots,
      formData,
      setFormData,
      selectedDate,
      setSelectedDate,
      selectedRange,
      setSelectedRange,
      totalPrice,
      guestsData,
      setGuestsData,
      serviceSelectedWorker,
      setServiceSelectedWorker,
    }),
    [
      step,
      guests,
      selectedServices,
      slotsSubmitting,
      slots,
      formData,
      selectedDate,
      selectedRange,
      totalPrice,
      guestsData,
      serviceSelectedWorker,
    ],
  );

  return <CalendarViewContext.Provider value={contextValue}>{children}</CalendarViewContext.Provider>;
};
