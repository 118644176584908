import React, { useRef, useState } from "react";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { FiltersProps } from "./filters.types";
import { useQueryParams } from "hooks";
import { offerCategories } from "./filters.constants";
import { RootState } from "store";
import { WatersportsData } from "server";
import { Watersports } from "./watersports/watersports";
import { Category } from "./category/category";

import styles from "./filters.module.scss";

export const ITEM_WIDTH = 120;
export const ARROW_WIDTH = 30;

export const FiltersComponent: React.FC<FiltersProps> = ({ isOpen, setIsOpen, setData }) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const { t } = useTranslation();
  const {
    query: { category: categoryQuery },
    updateQueryParams,
  } = useQueryParams();
  const { watersports } = useSelector((state: RootState) => state.watersports);

  const [watersportsOptions, setWatersportsOptions] = useState<WatersportsData[]>([]);

  const categoriesData = offerCategories(watersports, t);
  const handleOpen = () => setIsOpen((prevState) => !prevState);

  useDidUpdate(
    () => {
      if (categoryQuery) {
        const optionsToSet = categoriesData
          .find((category) => category.value === categoryQuery)
          ?.options.filter((item) => item !== undefined);

        setWatersportsOptions(optionsToSet as WatersportsData[]);
      } else {
        setWatersportsOptions(watersports.filter((item) => item !== undefined));
      }
    },
    [categoryQuery, watersports],
    true,
  );

  useDidUpdate(() => {
    updateQueryParams({ watersport: "" });
  }, [categoryQuery]);

  return (
    <div className={classNames(styles.container, { [styles.containerOpen]: isOpen })}>
      {isOpen && (
        <div className={styles.wrapper} ref={wrapperRef}>
          <Category wrapperRef={wrapperRef} options={categoriesData} setData={setData} />

          {watersports.length !== 0 && (
            <Watersports setData={setData} options={watersportsOptions} wrapperRef={wrapperRef} />
          )}

          {watersports.length === 0 && <div>loading...</div>}
        </div>
      )}

      <IconButton className={classNames(styles.openButton, { [styles.openButtonOpen]: isOpen })} onClick={handleOpen}>
        <ArrowDownwardIcon className={classNames(styles.openButtonIcon, { [styles.openButtonIconOpen]: isOpen })} />
      </IconButton>
    </div>
  );
};
