import React from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { Navbar, Footer } from "components";
import { PageProps } from "./page.types";
import { useRoutesPath } from "hooks";
import { RootState } from "store";

import styles from "./page.module.scss";

export const Page: React.FC<PageProps> = ({ children, background, showNavbar, showFooter, seo, navbarOnContent }) => {
  const { pathname } = useLocation();
  const { locale } = useRoutesPath();
  const { isModalOpen } = useSelector((state: RootState) => state.app);

  const backgroundStyles = background ? { backgroundImage: `url(${background})` } : {};

  const checkRoutePl = pathname === "/pl/";
  const checkRouteEn = pathname === "/en/";

  const defaultTitle = {
    pl: "SeaEasy – wypożyczalnia sprzętu wodnego, rejsy jachtem",
    en: "SeaEasy - water equipment rental, yacht cruises",
  };

  const defaultDescription = {
    pl: "Interesujesz się sportami wodnymi? Zawsze chciałeś spróbować windsurfingu? Sprawdź stronę SeaEasy i dziel się sprzętem oraz doświadczeniami.",
    en: "Are you interested in water sports? Always wanted to try windsurfing? Check out the SeaEasy website and share your equipment and experiences.",
  };

  const language: "pl" | "en" = locale === "pl-PL" ? "pl" : "en";

  return (
    <div className={showNavbar ? styles.navbarContainer : styles.container} style={backgroundStyles}>
      <Helmet>
        <title>{seo?.title ? seo?.title[language] : defaultTitle[language]}</title>
        <meta
          name="description"
          content={seo?.description ? seo?.description[language] : defaultDescription[language]}
        />
        <meta name="og:title" content={seo?.title ? seo?.title[language] : defaultTitle[language]} />
        <meta
          name="og:description"
          content={seo?.description ? seo?.description[language] : defaultDescription[language]}
        />
        <meta name="keywords" content={seo?.keywords ? seo?.keywords[language] : ""} />
      </Helmet>

      {showNavbar && (
        <Navbar
          className={classNames(styles.navbar, { [styles.navbarOpen]: isModalOpen })}
          isGreen={checkRoutePl || checkRouteEn}
        />
      )}
      <div className={classNames(styles.content, navbarOnContent && styles.navbarOnContent)}>{children}</div>
      {showFooter && <Footer />}
    </div>
  );
};
