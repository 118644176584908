import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NeedHelpProps } from "./need-help.types";
import { useRoutesPath } from "hooks";
import { CONTACT_PAGE } from "constants/routes.constants";

import styles from "./need-help.module.scss";

export const NeedHelp: React.FC<NeedHelpProps> = () => {
  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>{t("management.yourBusiness.form.needHelp")}</Typography>
      <Link className={styles.link} to={localePath({ route: CONTACT_PAGE })}>
        {t("management.yourBusiness.form.contactUs")}
      </Link>
    </div>
  );
};
