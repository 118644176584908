import React from "react";
import { Button } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks";
import { buttonsData } from "./buttons.constants";

import styles from "./buttons.module.scss";

export const Buttons = () => {
  const { t } = useTranslation();
  const {
    updateQueryParams,
    query: { chart },
  } = useQueryParams();

  const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => updateQueryParams({ chart: e.currentTarget.value });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Button
          onClick={handleFilter}
          value={buttonsData.revenue}
          className={classNames(styles.button, { [styles.activeButton]: chart === buttonsData.revenue })}
        >
          {t("insights.buttons.revenue")}
        </Button>
        {/* <Button */}
        {/*  onClick={handleFilter} */}
        {/*  value={buttonsData.profit} */}
        {/*  className={classNames(styles.button, { [styles.activeButton]: chart === buttonsData.profit })} */}
        {/* > */}
        {/*  {t("insights.buttons.profit")} */}
        {/* </Button> */}
        <Button
          onClick={handleFilter}
          value={buttonsData.bookings}
          className={classNames(styles.button, { [styles.activeButton]: chart === buttonsData.bookings })}
        >
          {t("insights.buttons.bookings")}
        </Button>

        <Button
          onClick={handleFilter}
          value={buttonsData.reservationsTime}
          className={classNames(styles.button, { [styles.activeButton]: chart === buttonsData.reservationsTime })}
        >
          {t("insights.buttons.reservationsTime")}
        </Button>

        {/* <Button */}
        {/*  onClick={handleFilter} */}
        {/*  value={buttonsData.bookingRate} */}
        {/*  className={classNames(styles.button, { [styles.activeButton]: chart === buttonsData.bookingRate })} */}
        {/* > */}
        {/*  {t("insights.buttons.bookingRate")} */}
        {/* </Button> */}
      </div>
    </div>
  );
};
