export const inputStyles = {
  paddingLeft: 1,
  borderRadius: "10px",
  input: { color: "#000", "&::placeholder": { fontSize: 15 } },
  width: "100%",
  height: "150px",
  background: "#fff",
  margin: 0,
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "40px",
    padding: "5px",
    paddingTop: "8px",
  },
  disableUnderline: true,
};
