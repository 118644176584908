export const ownerReservations = {
  reservations: "Rezerwacje: {{organizationName}}",

  legend: {
    offer: "Oferta:",
    guests: "Goście:",
    totalProfit: "Wartość całkowita:",
    services: "Usługi:",
    bookedBy: "Zarezerwowana przez:",
    status: "Status:",
    dates: "Daty:",
  },

  statuses: {
    created: "Utworzono",
    pendingActivation: "Oczekuję na aktywację",
    pendingAcceptance: "Oczekuję na akceptację",
    accepted: "Zaakceptowano",
    rejected: "Odrzucono",
    reported: "Zgłoszono",
    failed: "Niepoprawna",
    closed: "Zamknięta",
  },

  buttons: {
    accept: "zaakceptuj",
    reject: "odrzuć",
  },

  error: {
    noReservations: "Brak oczekujących rezerwacji...",
    noServices: "Brak usług",
    noOrganization: "Nie udało się pobrać rezerwacji dla Twojej organizacji.",
    createOrganization: "Utwórz organizacje",
  },
};
