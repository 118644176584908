import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { useNavigate } from "react-router-dom";

import { CreateOfferForm } from "./create-offer-form";
import { CreateOfferContextProvider } from "./create-offer.context";
import { PaymentMethod } from "./payment-method/payment-method";
import { RootState } from "store";
import { generateStripeLink } from "server";
import { useRoutesPath } from "hooks";
import { CREATE_RENTAL_PAGE } from "constants/routes.constants";
import { Loader } from "components";

import styles from "./create-offer.module.scss";

export const CreateOfferPage = () => {
  const navigate = useNavigate();

  const { watersports } = useSelector((state: RootState) => state.watersports);
  const { localePath } = useRoutesPath();
  const { organizationData, locations, activePayment, dataFetching } = useSelector(
    (state: RootState) => state.organization,
  );

  const [stripeLink, setStripeLink] = useState<null | string>(null);

  const stripeLinkData = useSubmit(generateStripeLink.setParams({ organization_id: organizationData?.id || 1 }));
  const { submit: submitStripeLink, onSubmitSuccess: onSubmitSuccessStripeLink } = stripeLinkData;
  onSubmitSuccessStripeLink((res) => {
    setStripeLink(res.response.stripe_url);
  });

  const value = {
    organization: organizationData,
    locations,
  };

  const showPaymentError = !activePayment && !dataFetching;
  const showOfferProcess =
    locations.length > 0 && organizationData && activePayment && !dataFetching && watersports.length !== 0;
  const showLoader = dataFetching;

  useDidMount(() => {
    if (!activePayment) submitStripeLink().then();
  });

  useDidMount(() => {
    if (!organizationData) navigate(localePath({ route: CREATE_RENTAL_PAGE }));
  });

  return (
    <CreateOfferContextProvider value={value}>
      {showPaymentError && <PaymentMethod stripeLink={stripeLink} />}

      {showLoader && <Loader height="87vh" />}

      {showOfferProcess && (
        <section className={styles.container}>
          <CreateOfferForm />
        </section>
      )}
    </CreateOfferContextProvider>
  );
};
