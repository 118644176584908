import React from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { PaymentsProps } from "./payments.types";
import { paymentsMockedData } from "./payments.constants";
import { PaymentItem } from "./payment-item/payment-item";

import styles from "./payments.module.scss";

export const Payments: React.FC<PaymentsProps> = ({ className }) => {
  const { t } = useTranslation();

  const completed = paymentsMockedData.filter((item) => item.status);
  const inProgress = paymentsMockedData.filter((item) => !item.status);

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.yourPayments}>
        <Typography className={styles.bookings}>{t("profile.payments.inProgress")}</Typography>

        <div className={styles.paymentsSectionWrapper}>
          <Typography className={styles.sectionName}>In progress</Typography>
          <div className={styles.separator} />

          {inProgress.map((item) => (
            <PaymentItem paymentItem={item} />
          ))}
        </div>

        <div className={styles.paymentsSectionWrapper}>
          <Typography className={styles.sectionName}>{t("profile.payments.completed")}</Typography>

          <div className={styles.separator} />

          {completed.map((item) => (
            <PaymentItem paymentItem={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
