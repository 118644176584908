export const team = {
  team: "Team",
  add: "Add",
  remove: "Remove",
  person: "Person",
  role: "Role",
  googleCalendar: "Google calendar",
  connectWithGoogle: "Connect with Google",

  addModal: {
    addTeamMember: "Add team member",
    email: "Email",
    role: "Role",
    send: "Send",
  },

  removeModal: {
    removeTeamMember: "Remove team member",
    remove: "Remove",
    email: "Email",
    employee: "Employee",
  },
};
