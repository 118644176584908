import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HOME_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-icon.svg";

import styles from "./booking-succes.module.scss";

export const BookingSuccessPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Logo className={styles.logo} onClick={openHomePage} />
        <div className={styles.content}>
          <Typography fontSize="30px" fontFamily="Chillax-Regular">
            {t("booking.success.bookingSent")}
          </Typography>
          <Typography fontFamily="Chillax-Regular">{t("booking.success.waitingForAccept")}</Typography>
        </div>
      </div>
    </div>
  );
};
