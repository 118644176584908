import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { ExternalLoginServices } from "pages/auth/login/external-login-services/external-login-services";
import { HOME_PAGE } from "constants/routes.constants";
import { NewsletterForm } from "./newsletter-form/newsletter-form";
import { useRoutesPath } from "hooks";

import logo from "assets/images/logo.png";
import { ReactComponent as LogoSVG } from "assets/images/logo.svg";
import background from "assets/images/background-dark.png";

import styles from "./newsletter.module.scss";

export const NewsletterPage = () => {
  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <Link to={localePath({ route: HOME_PAGE })} className={styles.logoDesktop}>
        <LogoSVG />
      </Link>
      <section className={styles.header}>
        <Link to={localePath({ route: HOME_PAGE })} className={styles.row}>
          <img src={logo} className={styles.logo} alt="SeaEasy logo" />
          <Typography color="common.white" fontSize={28}>
            SeaEasy
          </Typography>
        </Link>
        <Typography variant="h6" color="white" sx={{ fontSize: 24 }} className={styles.title} fontWeight={700}>
          {t("login.loginTitle")}
        </Typography>
      </section>
      <div className={styles.content}>
        <Box className={styles.formWrapper}>
          <NewsletterForm />
          <ExternalLoginServices />
        </Box>
      </div>
    </div>
  );
};
