import { strapiBuilder } from "server/builder";
import {
  StrapiArticleModel,
  StrapiTopDestinationsModels,
  StrapiTopOffersModels,
  StrapiWatersportsModels,
} from "models";
import { StrapiData } from "types";
import { ServerErrorType } from "server/server.types";
import { ArticlesParams, ArticleParams, ArticleBySlugParams } from "./article.types";

export const getArticles = strapiBuilder.createCommand<
  StrapiData<StrapiArticleModel[]>,
  void,
  ServerErrorType,
  ArticlesParams
>()({
  method: "GET",
  endpoint: "/api/articles",
  retry: 3,
  retryTime: 0,
});

export const getArticle = strapiBuilder.createCommand<
  StrapiData<StrapiArticleModel>,
  void,
  ServerErrorType,
  ArticleParams
>()({
  method: "GET",
  endpoint: "/api/articles/:articleId",
});

export const getArticleBySlug = strapiBuilder.createCommand<
  StrapiData<StrapiArticleModel[]>,
  void,
  ServerErrorType,
  ArticleBySlugParams
>()({
  method: "GET",
  endpoint: "/api/articles",
});

export const getTopOffers = strapiBuilder.createCommand<
  StrapiData<StrapiTopOffersModels[]>,
  void,
  ServerErrorType,
  ArticleParams
>()({
  method: "GET",
  endpoint: "/api/top-offers",
  retry: 3,
  retryTime: 0,
});

export const getTopOfferBySlug = strapiBuilder.createCommand<
  StrapiData<StrapiTopOffersModels[]>,
  void,
  ServerErrorType,
  ArticleBySlugParams
>()({
  method: "GET",
  endpoint: "/api/top-offers",
});

export const getTopDestinations = strapiBuilder.createCommand<
  StrapiData<StrapiTopDestinationsModels[]>,
  void,
  ServerErrorType,
  ArticleParams
>()({
  method: "GET",
  endpoint: "/api/top-destinations",
  retry: 3,
  retryTime: 0,
});

export const getTopDestinationsBySlug = strapiBuilder.createCommand<
  StrapiData<StrapiTopDestinationsModels[]>,
  void,
  ServerErrorType,
  ArticleBySlugParams
>()({
  method: "GET",
  endpoint: "/api/top-destinations",
});

export const getWatersportsStrapi = strapiBuilder.createCommand<
  StrapiData<StrapiWatersportsModels[]>,
  void,
  ServerErrorType,
  ArticleParams
>()({
  method: "GET",
  endpoint: "/api/watersports",
  retry: 3,
  retryTime: 0,
});

export const getWatersportsBySlug = strapiBuilder.createCommand<
  StrapiData<StrapiWatersportsModels[]>,
  void,
  ServerErrorType,
  ArticleBySlugParams
>()({
  method: "GET",
  endpoint: "/api/watersports",
});
