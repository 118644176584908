export const sizeUnits = [
  {
    label: "XS",
    value: "xs",
  },
  {
    label: "S",
    value: "s",
  },
  {
    label: "M",
    value: "m",
  },
  {
    label: "L",
    value: "l",
  },
  {
    label: "XL",
    value: "xl",
  },
  {
    label: "XXL",
    value: "xxl",
  },
];
