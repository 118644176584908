export const offerPage = {
  gallery: {
    details: "Details",
    rentalRules: "Rental rules",
    aboutRentalPlace: "About Rental Place",
    map: "Map",
    reviews: "Reviews",
  },

  offerDescription: {
    comments: "{{comments}} Comments",
  },

  details: {
    title: "Details",
    rentalDetails: {
      rentalDetails: "Rental details",
      captainRequired: "Captain required",
      captainAvailable: "Captain available",
      captainNotAvailable: "Captain not available",
      instructorAvailable: "Instructor available",
      instructorNotAvailable: "Instructor not available",
      guests: "Max guests",
      whatDoINeedToKnow: "What do I need to know?",
      whatAreTheBoatDetails: "What are boat details?",
      areThereAnyExtraCharges: "Are there any extra charges required?",
      areThereAnyAdditionalServices: "Are there any additional services available?",
    },
    boatDetails: {
      boatDetails: "Boat details",
      length: "Length",
      year: "Year",
      manufacturer: "Manufacturer",
      productionYear: "Production Year",
      deposit: "Deposit",
    },
    extraCharges: {
      extraCharges: "Extra charges",
    },
    additionalServices: {
      additionalServicesAvailable: "Additional services available",
      captain: "captain",
      instructor: "instructor",
    },
  },

  rentalRules: {
    rentalRules: "Rental rules",
  },

  description: {
    description: "What can I expect?",
  },

  aboutRental: {
    aboutRentalPlace: "Who will be the host?",
    seeMore: "See more",
  },

  map: {
    map: "How do I get there?",
    rentalPlace: "Where is the reception?",
    boatPlace: "Where is place?",
  },

  checkOtherOffers: {
    checkOutOtherOffers: "Check out other offers in ",
  },

  cart: {
    book: "Book now",
    addDates: "Add dates",
    youWontBeCharged: "You won't be charged yet",
    from: "From",
    mandatory: "Mandatory",
    optional: "Optional",
    howMuchWillIPay: "How much will I pay?",
    for: "for",
    totalFrom: "Total from",

    guests: {
      guests: "Guests",
    },

    timePickers: {
      arrivalDate: "Arrival Date",
      departureDate: "Departure Date",
      confirm: "Confirm",
      cancel: "Cancel",
    },

    services: {
      selectedServices: "Selected services",
      optionalServices: "Any extras?",
      captain: "Captain",
      instructor: "Instructor",
      oneOfPayment: "Lump sum",
      perPerson: "Per person",
    },

    summary: {
      total: "Total",
      rentalPrice: "Rental price",
      additionalServicesPrice: "Optional services price",
    },
  },

  similarOffers: "How does it compare to other offers?",

  sports: {
    windsurfing: "Windsurfing",
    kitesurfing: "Kitesurfing",
    sailing: "Sailing",
    motorboats: "Motorboat",
    kayaks: "Kayak",
    sup: "SUP",
    diving: "Diving",
    pedalo: "Pedalo",
    surfing: "Surfing",
    wakeboard: "Wakeboard",
    other: "Other",
    rowingBoats: "Rowing boat",
    efoil: "Efoil",
    wingfoil: "Wing foil",
    jetSki: "Jet ski",
  },
};
