import React, { useReducer, useState } from "react";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useNavigate } from "react-router-dom";
import { LatLng } from "leaflet";
import { Button } from "@mui/material";
import { List as ListIcon, Map as MapIcon, WbSunny as WbSunnyIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import classNames from "classnames";

import {
  PanelActionKind,
  panelInitialState,
  POSITION,
  POSITION_MULTIPLIER_MAX,
  POSITION_MULTIPLIER_MIN,
  reducers,
  WINDY_URL,
} from "./offers-list.constants";
import { getOffers } from "server";
import { useQueryParams, useRoutesPath } from "hooks";
import { OFFERS_PAGE } from "constants/routes.constants";
import { MarkersData } from "./offers-list.types";
import { OfferBox, Map, Loader, FiltersComponent } from "components";
import { NoOffers } from "./no-offers/no-offers";
import { RootState } from "store";
import { BmOffers } from "./bm-offers/bm-offers";
import { FetchingError } from "./fetching-error/fetching-error";

import "leaflet/dist/leaflet.css";

import styles from "./offers-list.module.scss";

export const OffersListPage = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();
  const { watersports } = useSelector((state: RootState) => state.watersports);

  const [offersRefresher, setOffersRefresher] = useState<number>(0);
  const [markers, setMarkers] = useState<null | MarkersData[] | MarkersData>(null);
  const [openPanel, dispatch] = useReducer(reducers, panelInitialState);
  const [mapPosition, setMapPosition] = useState<null | LatLng>(null);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const handleParamsToSubmit = (): { location?: string; watersport?: string; event_type?: string } => {
    const activeParams: { location?: string; watersport?: string; event_type?: string } = {};

    activeParams.location = queryParams?.query?.location as string;
    activeParams.watersport = queryParams?.query?.watersport as string;
    activeParams.event_type = queryParams.query.category as string;

    return activeParams;
  };

  const offersData = useSubmit(getOffers.setQueryParams(handleParamsToSubmit()));
  const { onSubmitSuccess: onSubmitSuccessOffers, submit: submitOffers, data, submitting, setData, error } = offersData;
  onSubmitSuccessOffers(({ response }) => {
    const markersArray = response.map((offer) => {
      const position = new LatLng(Number(offer.location.latitude), Number(offer.location.longitude));
      return {
        lat:
          position.lat + Math.random() * (POSITION_MULTIPLIER_MIN + POSITION_MULTIPLIER_MAX) - POSITION_MULTIPLIER_MAX,
        lng:
          position.lng + Math.random() * (POSITION_MULTIPLIER_MIN + POSITION_MULTIPLIER_MAX) - POSITION_MULTIPLIER_MAX,
        id: offer.id,
        title: offer.title || offer.organization.name,
        watersportId: offer.watersport.id,
        localizationId: offer.location.id || 0,
      };
    });

    const handleMapPosition = new LatLng(Number(markersArray[0].lat), Number(markersArray[0].lng));
    setMapPosition(handleMapPosition);

    setMarkers(markersArray);
  });

  const showAllOffers = () => {
    navigate(localePath({ route: OFFERS_PAGE }));
    queryParams.deleteQueryParams();
  };

  const refreshOffers = () => setOffersRefresher((prevState) => prevState + 1);

  const showLoader = (data?.length === 0 && submitting) || !data;

  const showNoContent = data?.length === 0 && !submitting;
  const showBmOffers = queryParams.query.watersport === "YACHT";

  useDidUpdate(() => {
    if (queryParams.search.length === 0) {
      queryParams.deleteQueryParams();
    }
  }, [queryParams.search]);

  useDidUpdate(() => {
    setData([]);
  }, [queryParams.query]);

  useDidUpdate(() => {
    refreshOffers();
  }, [queryParams.query]);

  useDidUpdate(() => {
    if (!queryParams.query.location) {
      setMapPosition(null);
    }
  }, [queryParams.query.location]);

  useDidUpdate(
    () => {
      submitOffers().then();
    },
    [offersRefresher],
    true,
  );

  return (
    <div className={classNames(styles.container, { [styles.containerFiltersOpen]: filtersOpen })}>
      {watersports && <FiltersComponent setData={setData} isOpen={filtersOpen} setIsOpen={setFiltersOpen} />}

      {showLoader && !showBmOffers && <Loader height="50vh" />}

      {data && !showBmOffers && (
        <div className={styles.offers}>
          {data.map((offer) => (
            <OfferBox offer={offer} />
          ))}
        </div>
      )}

      {showBmOffers && <BmOffers />}

      {error && !showBmOffers && <FetchingError showAllOffers={showAllOffers} />}
      {showNoContent && !showBmOffers && <NoOffers showAllOffers={showAllOffers} />}

      {openPanel.map && (
        <div className={classNames(styles.map, { [styles.mapOpenFilters]: filtersOpen })}>
          <Map markers={markers || []} position={mapPosition || POSITION} showLoader={showLoader} />
        </div>
      )}

      {openPanel.weather && (
        <div className={classNames(styles.map, { [styles.mapOpenFilters]: filtersOpen })}>
          <iframe width="100%" title="windy" height="100%" src={WINDY_URL} />
        </div>
      )}

      {!showBmOffers && (
        <div className={styles.floatingButtons}>
          {!openPanel.offers && (
            <Button
              defaultValue="offers"
              onClick={() => dispatch({ type: PanelActionKind.OPEN_OFFERS })}
              value="offers"
              className={styles.sectionButton}
            >
              <ListIcon className={styles.buttonIcon} />
              {t("offersList.offers")}
            </Button>
          )}
          {!openPanel.map && (
            <Button
              onClick={() => dispatch({ type: PanelActionKind.OPEN_MAP })}
              value="map"
              className={styles.sectionButton}
            >
              <MapIcon className={styles.buttonIcon} />
              {t("offersList.map")}
            </Button>
          )}
          {!openPanel.weather && (
            <Button
              onClick={() => dispatch({ type: PanelActionKind.OPEN_WEATHER })}
              value="weather"
              className={styles.sectionButton}
            >
              <WbSunnyIcon className={styles.buttonIcon} />
              {t("offersList.weather")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
