export const about = {
  hero: {
    aboutUs: "O nas",
    content: `Jesteśmy platformą, która odpowiada na problemy miłośników sportów wodnych na całym świecie. Podróżuj,
    wynajmuj, wymieniaj się doświadczeniami i zawieraj nowe znajomości. SeaEasy to dom na plaży nad morzem
    możliwości! Dołącz do załogi już dziś i wypłyńmy razem w ten piękny rejs!`,
  },

  section: {
    howItWorks: "Jak to działa?",
    content: `SeaEasy pomaga Wam wynająć sprzęt wodny. Każdy sprzęt wodny. Od kajaków, SUPów czy skuterów wodnych do jachtów żaglowych, winda i kite’a. Portal daje właścicielom możliwość zarabiania na wynajmie poprzez darmowe udostępnianie ofert oraz wspiera ich działalność za pomocą inteligentnego systemu zarządzania sprzętem i rezerwacjami. Niska prowizja i transparentne przedstawianie kosztów to nasze flagowe założenia.`,
    slides: {
      find: "Znajdź",
      findContent: "Znajdź propozycje wynajmu każdego rodzaju sprzętów wodnych dostosowane do twoich potrzeb.",
      bookIt: "Zarezerwuj",
      bookItContent:
        "Gdy już znajdziesz dobraną do siebie ofertę wyślij zapytanie do wypożyczalni i czekaj na potwierdzenie.",
      enjoy: "Wypływaj",
      enjoyContent: "Wypłyń z nami w ten piękny rejs!",
    },
  },

  crew: {
    ourCrew: "Nasza załoga",
    content: `Pasjonaci świata sportów wodnych - z bogatym doświadczeniem pochodzącym od globalnych liderów takich jak
  Microsoft, Amazon, Intel i wielu innych.`,
    content1: `Olsztyn to nasz homespot. Stolica Krainy Tysiąca Jezior. Tu zaczęło się SeaEasy, stąd jest nasza załoga, tu spotkacie nas na plażach, przystaniach i ulicach.
    SeaEasy dumnie reprezentuje to wspaniałe miasto, a inicjatywa powstała we współpracy z tutejszym Olsztyńskim Parkiem Naukowo-Technologicznym.`,
    technicalTeam: "Zespół",
    team: "Techniczny",
    salesTeam: "Sprzedaż",
    marketing: "& Marketing",
  },
};
