import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { summaryData } from "./summary.constants";
import { useWindowSize } from "hooks";
import { CarouselComponent } from "components";
import { SummaryProps } from "./summary.types";

import stylesMain from "../home.module.scss";
import styles from "./summary.module.scss";

export const Summary: React.FC<SummaryProps> = ({ refElement }) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isDesktop = width > 992;

  return (
    <div className={classNames(stylesMain.itemContainer, styles.container)} ref={refElement}>
      <Typography variant="h4" className={classNames(stylesMain.itemSubtitle, styles.subTitle)}>
        {t("home.ourProposition")}
      </Typography>
      <div className={styles.content}>
        {isDesktop &&
          summaryData(t).map(({ title, subTitle, icon }) => (
            <div className={styles.wrapper}>
              <img src={icon} alt="icon" />
              <Typography variant="h3" className={stylesMain.itemTitle}>
                {title}
              </Typography>
              <Typography variant="h4" className={stylesMain.itemSubtitleMedium}>
                {subTitle}
              </Typography>
            </div>
          ))}

        {!isDesktop && (
          <CarouselComponent arrowsHidden noOverflow>
            {summaryData(t).map(({ title, subTitle, icon }) => {
              return (
                <div className={styles.wrapper}>
                  <img src={icon} alt="icon" />
                  <Typography variant="h3" className={stylesMain.itemTitle}>
                    {title}
                  </Typography>
                  <Typography variant="h4" className={stylesMain.itemSubtitleMedium}>
                    {subTitle}
                  </Typography>
                </div>
              );
            })}
          </CarouselComponent>
        )}
      </div>
    </div>
  );
};
