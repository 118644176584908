import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikHelpers } from "formik";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import { FormTextField } from "components";
import { inputStyles, labelProps } from "./team-member-form.constants";
import { initialValues } from "../add-person-modal.constants";
import { teamPost } from "server";
import { TeamPostData } from "server/team/team.types";
import { TeamMemberFormProps } from "./team-member-form.types";

import styles from "./team-member-form.module.scss";

export const TeamMemberForm: React.FC<TeamMemberFormProps> = ({ handleClose, handleRefreshTeamData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [emailError, setEmailError] = useState<boolean>(false);

  const postMemberSubmit = useSubmit(teamPost);
  const { submit, submitting, onSubmitSuccess, onSubmitError } = postMemberSubmit;
  onSubmitSuccess(() => {
    enqueueSnackbar("add successfully", { variant: "success" });
    handleClose();
  });
  onSubmitError(() => {
    enqueueSnackbar("error", { variant: "error" });
  });

  const handlePost = async (values: TeamPostData, formikHelpers: FormikHelpers<TeamPostData>) => {
    if (!values.email) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    const data: TeamPostData = {
      email: values.email,
    };

    await submit({ data }).then((res) => {
      if (res[2] === 200) {
        formikHelpers.resetForm();
        handleRefreshTeamData();
      }
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handlePost}>
      <Form className={styles.container}>
        <div className={styles.textFieldWrapper}>
          <Typography className={classNames(styles.label, { [styles.labelError]: emailError })}>
            {t("team.addModal.email")}
          </Typography>
          <FormTextField
            variant="standard"
            label=""
            name="email"
            placeholder="xyz@gmail.com"
            sx={inputStyles}
            InputLabelProps={labelProps}
            InputProps={{ disableUnderline: true }}
            helperText={false}
          />
        </div>

        <Button disabled={submitting} className={styles.sendButton} type="submit">
          {t("team.addModal.send")}
        </Button>
      </Form>
    </Formik>
  );
};
