import React, { useState } from "react";
import { useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { IconButton, Switch, TextField, Tooltip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { InstructorData, OfferData } from "../../create-offer.types";
import { InstructorInitialData, InstructorModalProps } from "./instructor-modal.types";
import { initialInstructor, inputStyles, switchSx } from "./instructor-modal.constants";
import { tooltipConfig } from "../../create-offer.constants";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip.svg";

import styles from "./instructor-modal.module.scss";

export const InstructorModal: React.FC<InstructorModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<OfferData>();

  const [instructor, setInstructor] = useState<InstructorInitialData>(initialInstructor);
  const [priceError, setPriceError] = useState<boolean>(false);
  const [validating, setValidating] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInstructor({
      ...instructor,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setInstructor({
      ...instructor,
      [e.target.name]: checked,
    });
  };

  const addInstructor = () => {
    if (!priceError) {
      const instructorData: InstructorData = {
        guests: +instructor.maxGuests,
        price: +instructor.price,
        isRequired: instructor.isRequired,
        isFixed: instructor.isFixed,
        id: uuid(),
      };

      values.services.instructors.push(instructorData);
    }
  };

  const validateData = () => {
    if (instructor) {
      setValidating(true);

      if (instructor.price === "" || instructor.price === 0 || instructor.price === "0" || +instructor.price < 1) {
        setPriceError(true);
      } else {
        setPriceError(false);
      }
    }
  };

  useDidUpdate(() => {
    if (!validating) return;

    if (!priceError) {
      addInstructor();
      close();
    }
  }, [priceError, validating]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.title}>{t("createOffer.services.modal.addInstructor")}</Typography>
        <div className={styles.control}>
          <IconButton onClick={validateData}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.fieldWrapper}>
          <Typography className={classNames(styles.itemTitle, priceError && styles.titleError)}>
            {t("createOffer.services.modal.price")}
          </Typography>
          <TextField
            value={instructor.price}
            sx={inputStyles}
            variant="standard"
            InputProps={{ disableUnderline: true, inputProps: { min: 0, step: 0.01 } }}
            label=""
            name="price"
            onChange={(e) => handleChange(e)}
            type="number"
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.priceInstructor")}
            enterTouchDelay={0}
            className={styles.tooltipRight}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>
        <div className={styles.fieldWrapper}>
          <Typography className={styles.itemTitleCenter}>{t("createOffer.services.modal.isFixed")}</Typography>
          <Switch
            sx={switchSx}
            name="isFixed"
            value={instructor.isFixed}
            onChange={(e, checked) => handleSwitch(e, checked)}
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.fixedPriceInstructor")}
            enterTouchDelay={0}
            className={styles.tooltip}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>

        <div className={styles.fieldWrapper}>
          <Typography className={styles.itemTitleCenter}>
            {t("createOffer.services.modal.instructorRequired")}
          </Typography>
          <Switch
            sx={switchSx}
            name="isRequired"
            value={instructor.isRequired}
            onChange={(e, checked) => handleSwitch(e, checked)}
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.instructorRequired")}
            enterTouchDelay={0}
            className={styles.tooltipRight}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
