import React, { useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { TeamData } from "../team.types";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import styles from "./team-box.module.scss";

export const TeamBox: React.FC<TeamData> = ({ member }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const userName = member.first_name.length !== 0 ? `${member.first_name} ${member.last_name}` : member.email;

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.mobileContent}>
        <Typography className={styles.name}>{userName}</Typography>
        <IconButton onClick={handleOpen}>
          <ArrowIcon className={classNames(styles.mobileIcon, { [styles.mobileIconOpen]: isOpen })} />
        </IconButton>
      </div>
      {isOpen && (
        <div className={styles.mobileDetails}>
          <div className={styles.mobileRoleWrapper}>
            <Typography className={styles.mobileRole}>{t("team.addModal.role")}:</Typography>
            <Typography>role</Typography>
          </div>

          <Button>{t("team.connectWithGoogle")}</Button>
        </div>
      )}
    </div>
  );
};
