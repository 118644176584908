import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { TimePicker } from "antd";

import { TimeOffModalProps } from "./time-off-modal.types";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./time-off-modal.module.scss";

const format = "HH:mm";

const selectData = [
  {
    value: "location",
    label: "Location",
  },
  {
    value: "assets",
    label: "Assets",
  },
  {
    value: "hosts",
    label: "Hosts",
  },
];

export const TimeOffModal: React.FC<TimeOffModalProps> = ({ close }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography>{t("availability.timeOffModal.editTimeOff")}</Typography>
        <IconButton className={styles.closeIcon} onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className={styles.selectWrapper}>
        <Typography>{t("availability.timeOffModal.editFor")}</Typography>
        <Select className={styles.select} options={selectData} />
      </div>

      <div className={styles.content}>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.timeOffModal.timeOffStartDate")}</Typography>
          {/* <Select className={styles.select} options={selectData} /> */}

          <input type="date" className={styles.datePicker} />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.timeOffModal.timeOffStartTime")}</Typography>
          <TimePicker
            className={styles.timePicker}
            minuteStep={15}
            defaultValue={dayjs("12:08", format)}
            format={format}
            popupClassName={styles.popup}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.timeOffModal.timeOffEndDate")}</Typography>
          <input type="date" className={styles.datePicker} />
        </div>
        <div className={styles.selectWrapper}>
          <Typography>{t("availability.timeOffModal.timeOffEndTime")}</Typography>
          <TimePicker
            className={styles.timePicker}
            minuteStep={15}
            defaultValue={dayjs("12:08", format)}
            format={format}
            popupClassName={styles.popup}
          />
        </div>
      </div>

      <Button className={styles.saveButton}>{t("availability.timeOffModal.save")}</Button>
    </div>
  );
};
