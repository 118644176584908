import React, { useContext, useState, useEffect, Context } from "react";
import moment from "moment";
import "moment/locale/pl";
import { Button } from "antd";

import { SlotsViewContextType } from "../../../wrapper/slots-view.context";
import { ReservationsContext } from "../../../reservations-modal.context";
import { getViewContext } from "../../../wrapper/wrapper.constants";

import styles from "./calendar.module.scss";

moment.locale("pl");

export const Calendar: React.FC = () => {
  const { isOwnReservation, isCalendarView } = useContext(ReservationsContext);
  const { selectedDate, setSelectedDate } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>,
  );

  const [currentMonthDays, setCurrentMonthDays] = useState<moment.Moment[]>([]);

  useEffect(() => {
    const startOfMonth = selectedDate.clone().startOf("month");
    const startOfCalendar = startOfMonth.clone().startOf("week");
    const endOfMonth = selectedDate.clone().endOf("month");
    const endOfCalendar = endOfMonth.clone().endOf("week");
    const day = startOfCalendar;
    const days = [];

    while (day.isBefore(endOfCalendar, "day")) {
      days.push(day.clone());
      day.add(1, "day");
    }

    setCurrentMonthDays(days);
  }, [selectedDate]);

  const renderWeekdayHeaders = () => {
    return moment.weekdaysShort(true).map((weekday) => (
      <div key={weekday} className={styles.weekdayHeader}>
        <p>{weekday}</p>
      </div>
    ));
  };

  const renderDays = () => {
    const today = moment();

    return currentMonthDays.map((day) => {
      const isPastDay = day.isBefore(today, "day");

      return (
        <Button
          key={day.format("YYYY-MM-DD")}
          className={`${styles.day} ${day.isSame(selectedDate, "day") ? styles.selected : ""} ${
            !day.isSame(selectedDate, "month") ? styles.nonCurrentMonth : ""
          } ${isPastDay ? styles.pastDay : ""}`}
          onClick={() => !isPastDay && setSelectedDate(day)}
          disabled={isPastDay}
        >
          {day.date()}
        </Button>
      );
    });
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <Button className={styles.button} onClick={() => setSelectedDate(selectedDate.clone().subtract(1, "month"))}>
          {"<"}
        </Button>
        <span>{selectedDate.format("MMMM YYYY")}</span>
        <Button className={styles.button} onClick={() => setSelectedDate(selectedDate.clone().add(1, "month"))}>
          {">"}
        </Button>
      </div>
      <div className={styles.weekdays}>{renderWeekdayHeaders()}</div>
      <div className={styles.calendarBody}>{renderDays()}</div>
    </div>
  );
};
