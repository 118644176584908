import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { ExtraChargesProps } from "./extra-charges.types";

import styles from "./extra-charges.module.scss";
import stylesMain from "../details.module.scss";

export const ExtraCharges: React.FC<ExtraChargesProps> = ({ services }) => {
  const { t } = useTranslation();

  const unique = new Set();

  const uniqueServices = services.filter((service) => {
    const isDuplicate = unique.has(service.title);

    unique.add(service.title);

    return !isDuplicate;
  });

  return (
    <div className={styles.container}>
      <Typography className={stylesMain.title}>
        {t("offerPage.details.rentalDetails.areThereAnyExtraCharges")}
      </Typography>
      <div className={stylesMain.detailBox}>
        {uniqueServices.map((service) => (
          <div key={service.id} className={stylesMain.detailContent}>
            <Typography>{/* {service.price} {offer?.currency || ""} */}</Typography>
            <Typography sx={{ textTransform: "capitalize" }}>{service.title}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
