import React from "react";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AutoComplete } from "antd";
import { IconButton } from "@mui/material";

import { DestinationFormProps } from "./destination-form.types";
import { FormInitialData } from "../navbar.types";
import { useWindowSize } from "hooks";

import searchIconPurple from "assets/images/search-shadow-purple.webp";
import searchIconGreen from "assets/images/search-shadow-green.webp";
import "./destination-form.css";

import styles from "./destination-form.module.scss";

export const DestinationForm: React.FC<DestinationFormProps> = ({ isGreen, locationsData }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { setFieldValue } = useFormikContext<FormInitialData>();

  const handleChange = (value: string) => {
    setFieldValue("destination", value);
  };

  const isMobile = width < 900;
  const placeholder = isMobile ? t("navbar.inputPlaceholderMobile") : t("navbar.inputPlaceholder");

  return (
    <div className={classNames(styles.container, { [styles.containerGreen]: isGreen })}>
      {locationsData && (
        <>
          <AutoComplete
            className={classNames(styles.input, { [styles.inputGreen]: isGreen })}
            id="locations"
            options={locationsData}
            filterOption
            popupClassName={classNames(styles.popup, { [styles.popupGreen]: isGreen })}
            placeholder={placeholder}
            onChange={handleChange}
            style={{ color: "#a22" }}
          />
          <IconButton type="submit" className={styles.iconButton}>
            <img src={isGreen ? searchIconGreen : searchIconPurple} className={styles.icon} alt="asd" />
          </IconButton>
        </>
      )}
    </div>
  );
};
