import { TFunction } from "i18next";

import { YourBusinnes } from "./your-business/your-businnes";
import { ManageBooking } from "./manage-bookings/manage-booking";
import { Weather } from "./weather/weather";
import { Insights } from "./insights/insights";
import { Availability } from "./availability/availability";
import { Team } from "./team/team";
import { Assets } from "./assets/assets";

import reservationIcon from "assets/icons/reservations-icon.svg";
import weatherIcon from "assets/icons/weather-icon.svg";
import insightsIcon from "assets/icons/insights-icon.svg";
import offersIcon from "assets/icons/offers-icon.svg";
import availabilityIcon from "assets/icons/availability-icon.svg";
import teamIcon from "assets/icons/team-icon.svg";
import assetsIcon from "assets/icons/assets-icon.svg";

export const sections = (t: TFunction) => [
  {
    name: "weather",
    value: t("management.navigation.weather"),
    component: <Weather />,
    icon: weatherIcon,
  },
  {
    name: "insights",
    value: t("management.navigation.insights"),
    component: <Insights />,
    icon: insightsIcon,
  },
  {
    name: "bookings",
    value: t("management.navigation.manageBookings"),
    component: <ManageBooking />,
    icon: reservationIcon,
  },
  {
    name: "management",
    value: t("management.navigation.yourBusiness"),
    component: <YourBusinnes />,
    icon: offersIcon,
  },
  {
    name: "availability",
    value: t("management.navigation.availability"),
    component: <Availability />,
    icon: availabilityIcon,
  },
  {
    name: "team",
    value: t("management.navigation.team"),
    component: <Team />,
    icon: teamIcon,
  },
  {
    name: "assets",
    value: t("management.navigation.assets"),
    component: <Assets />,
    icon: assetsIcon,
  },
];

export const fullScreenContent = {
  teamHours: "team-hours",
  guestsDetails: "guests-details",
};
