export const rental = {
  steps: {
    step: "Krok",
    steps: "Kroki",
    beforeWeStart: "Zanim zaczniemy",
    createYourProfile: "Stwórz swój profil",
    addLocation: "Dodaj lokalizację",
    addPayment: "Dodaj metodę płatności",
  },

  beforeStart: {
    beforeWeStart: "zanim zaczniemy, ",
    checkHowItWorks: "Sprawdź jak to działa!",
    content1:
      "SeaEasy umożliwi ci udostępnienie swojej oferty wypożyczalni sprzętu wodnego, lekcji i pozostałych lekcji poprzez naszą platformę w zupełności za darmo!",
    content2:
      "Gdy twoja oferta zostanie zarezerwowana przez klienta otrzymasz prośbę o potwierdzenie transakcji. Po zaakceptowaniu rezerwacji SeaEasy prześle na twoje konto środki od klienta pomniejszone o 10% prowizji od rezerwacji oraz prowizję operatora płatności Stripe, w wysokości 1zł oraz 1,5%.",
    letsStart: "Zaczynajmy",
  },

  profile: {
    setYourProfilePicture: "Ustaw zdjęcie profilowe",
    createYourName: "Stwórz nazwę",
    createYourDescription: "Dodaj opis",
    addYourRules: "Dodaj regulamin",
    addYourPhotos: "Dodaj zdjęcia",

    placeholders: {
      name: "Nazwa twojego biznesu np. Cool Szkoła Żeglarstwa",
      description:
        "Opis twojego biznesu np. Cool Szkoła Żeglarstwa to miejsce z najlepszymi instruktorami, świetnym sprzętem wodnym i przyjemną lokalizacją nad jeziorem. Jesteśmy szkołą z tradycjami i bogatą historią...",
      rules: "Dodaj regulamin swojej organizacji np. Godziny otwarcia czy zakaz zwierząt na łodzi...",
    },

    validation: {
      pictureRequired: "Zdjęcie jest wymagane",
      nameRequired: "Nazwa jest wymagana",
      descriptionRequired: "Opis jest wymagany",
      rulesRequired: "Zasady są wymagane",
      minNumOfPhotos: "Wymagane jest minimum 5 zdjęć",
      photosRequired: "Zdjęcia są wymagane",
    },

    tooltip: {
      photo: `Dodaj zdjęcie profilowe swojej organizacji.
        Format zdjęcia: .jpg, .jpeg, .png.
        Max rozmiar: 10MB `,
      photos: `Dodaj przynajmniej 5 zdjęć swojej organizacji.
        Format zdjęcia: .jpg, .jpeg, .png.
        Max rozmiar: 10MB `,
      rules:
        "Regulamin organizacji będzie prezentowany razem z twoimi ofertami. Dodaj tu zasady obowiązujące w twoim biznesie takie jak godziny otwarcia, informacje o wymaganych dokumentach od wypożyczających lub zakazy obowiązujące w trakcie wynajmu czy szkoleń.",
    },
  },

  location: {
    confirmYourAddress: "Potwierdź swój adres",
    addYourLocation: "Dodaj pinezkę i potwierdź adres swojego biznesu",
    name: "Nazwa",
    description: "Opis (opcjonalny)",
    address: "Adres",
    phoneNumber: "Numer telefonu",
    country: "Kraj",
    region: "Region (opcjonalny)",
    city: "Miasto",
    postalCode: "Kod pocztowy",

    validation: {
      nameRequired: "Nazwa jest wymagana",
      addressRequired: "Adres jest wymagany",
      cityRequired: "Miasto jest wymagane",
      countryRequired: "Kraj jest wymagany",
      latitudeRequired: "Szerokość geograficzna jest wymagana",
      longitudeRequired: "Długość gegoraficzna jest wymagana",
      postalCodeRequired: "Kod pocztowy jest wymagany",
    },
  },

  rentalPayment: {
    addPaymentDetails: "Dodaj szczegóły płatności i wypłat przez Stripe",
    paymentContent:
      "SeaEeasy współpracuje z Stripe aby zapewnić najlepsze rozwiązania w zakresie płatności i zarządzania biznesem dla naszych użytkowników. Poprzez współpracę ze Stripe SeaEasy zapewnia bezpieczeństwo oraz zgodność z regulacjami całego procesu płatności dla każdego z klientów. Stripe poprosi teraz o podanie dodatkowych szczegółów twojej organizacji w celu potwierdzenia danych do płatności.",
    paymentDetails: "Szczegóły płatności",
    completeDetails: "musisz uzupełnić szczegóły płatności",
    goToDataCompletion: "przejdź do uzupełniania danych",
    everythingIsOk: "Wszystko w porządku",
    createOffer: "Utwórz ofertę",
    checking: "Sprawdzanie...",
    addDetails: "Dodaj szczegóły",

    tooltip: {
      info: "Lorem lorem lorem",
    },

    success: {
      paymentAdded: "Konto Stripe zostało pomyślnie utworzone.",
      addNewOffers:
        "Teraz możesz dodawać oferty. Jeżeli wszystkie wymagane dane nie zostały wypełnione, zostaniesz przekierowany na stronę operatora płatności",
      addOffer: "Dodaj nową ofertę",
    },
  },

  submitButtons: {
    addPayments: "Dodaj płatność",
    close: "Zamierzasz wyjść ze strony i twoje zmiany przepadną. Czy chcesz opuścić stronę?",
    yes: "Wyjdź",
    no: "Zostań",
    leave: "Wyjdź",
    addPaymentsModalCopy:
      "Teraz przejdziesz do dodawania płatności a twoja organizacja zostanie stworzona. Na pewno chcesz to zrobić?",
  },

  messages: {
    createOrganizationSuccess: "Organizacja została utworzona!",
    createOrganizationError: "Nie można utworzyć organizacji",
    createLocationError: "Nie można utworzyć nowej lokalizacji",
    haveOrganization: "Masz już organizację",
  },

  navigation: {
    createYourRental: "stwórz swoją wypożyczalnię",
    step: "krok",
  },
};
