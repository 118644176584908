import { TFunction } from "i18next";

import { SportType } from "../pages/offers/create";

import windsurfingIcon from "assets/icons/windsurfing-icon.svg";
import kitesurfingIcon from "assets/icons/kitesurfing-icon.svg";
import sailboatsIcon from "assets/icons/sailboats-icon.svg";
import motorboatsIcon from "assets/icons/motorboats-icon.svg";
import kayaksIcon from "assets/icons/kayaks-icon.svg";
import supIcon from "assets/icons/sup-icon.svg";
import divingIcon from "assets/icons/diving-icon.svg";
import pedaloIcon from "assets/icons/pedalo-icon.svg";
import surfingIcon from "assets/icons/surfing-icon.svg";
import wakeboardIcon from "assets/icons/wakeboard-icon.svg";
import otherIcon from "assets/icons/other-icon.svg";
import rowingBoatsIcon from "assets/icons/rowing-boats-icon.svg";
import efoilIcon from "assets/icons/efoil-icon.svg";
import wingFoilIcon from "assets/icons/wingfoil-icon.svg";
import jetSkiIcon from "assets/icons/jet-ski-icon.svg";
import windsurfingWhiteIcon from "assets/icons/windsurfing-white-icon.svg";
import kitesurfingWhiteIcon from "assets/icons/kitesurfing-white-icon.svg";
import sailboatsWhiteIcon from "assets/icons/sailing-white-icon.svg";
import motorboatsWhiteIcon from "assets/icons/motorboats-white-icon.svg";
import kayaksWhiteIcon from "assets/icons/kayaks-white-icon.svg";
import supWhiteIcon from "assets/icons/SUP-white-icon.svg";
import divingWhiteIcon from "assets/icons/diving-white-icon.svg";
import pedaloWhiteIcon from "assets/icons/pedalo-white-icon.svg";
import surfingWhiteIcon from "assets/icons/surfing-white-icon.svg";
import wakeboardWhiteIcon from "assets/icons/wakeboarding-white-icon.svg";
import otherWhiteIcon from "assets/icons/other-white-icon.svg";
import rowingBoatsWhiteIcon from "assets/icons/rowing-boats-white-icon.svg";
import efoilWhiteIcon from "assets/icons/efoil-white-icon.svg";
import wingFoilWhiteIcon from "assets/icons/wingfoil-white-icon.svg";
import jetSkiWhiteIcon from "assets/icons/jet-ski-white-icon.svg";
import windsurfingIconWide from "assets/icons/windsurfing-wide.svg";
import kitesurfingIconWide from "assets/icons/kitesurfing-wide.svg";
import sailboatsIconWide from "assets/icons/sailing-wide.svg";
import motorboatsIconWide from "assets/icons/motorboat-wide.svg";
import kayaksIconWide from "assets/icons/kayak-wide.svg";
import supIconWide from "assets/icons/SUP-wide.svg";
import divingIconWide from "assets/icons/diving-wide.svg";
import pedaloIconWide from "assets/icons/pedalo-wide.svg";
import surfingIconWide from "assets/icons/surfing-wide.svg";
import wakeboardIconWide from "assets/icons/wakeboarding-wide.svg";
import otherIconWide from "assets/icons/other-wide.svg";
import rowingBoatsIconWide from "assets/icons/rowing-boat-wide.svg";
import efoilIconWide from "assets/icons/eFoil-wide.svg";
import wingFoilIconWide from "assets/icons/wing-foil-wide.svg";
import jetSkiIconWide from "assets/icons/jet-ski-wide.svg";
import { ReactComponent as Kitesurfing } from "assets/icons/kitesurfing-native.svg";
import { ReactComponent as Sailboats } from "assets/icons/sailing-native.svg";
import { ReactComponent as Diving } from "assets/icons/diving-native.svg";
import { ReactComponent as Efoil } from "assets/icons/efoil-native.svg";
import { ReactComponent as Motorboats } from "assets/icons/motorboats-native.svg";
import { ReactComponent as Pedalo } from "assets/icons/pedalo-native.svg";
import { ReactComponent as Other } from "assets/icons/other-native.svg";
import { ReactComponent as RowingBoats } from "assets/icons/rowing-boats-native.svg";
import { ReactComponent as WingFoil } from "assets/icons/wing-foil-native.svg";
import { ReactComponent as Surfing } from "assets/icons/surfing-native.svg";
import { ReactComponent as Wakeboard } from "assets/icons/wakeboarding-native.svg";
import { ReactComponent as Windsurfing } from "assets/icons/windsurfing-native.svg";
import { ReactComponent as JetSki } from "assets/icons/jet-ski-native.svg";
import { ReactComponent as Kayaks } from "assets/icons/kayaks-native.svg";
import { ReactComponent as Sup } from "assets/icons/SUP-native.svg";
import windsurfingPin from "assets/icons/windsurfing-pin.png";
import wakeboardPin from "assets/icons/wakeboard-pin.png";
import surfingPin from "assets/icons/surfing-pin.png";
import supPin from "assets/icons/sup-pin.png";
import sailingPin from "assets/icons/sailing-pin.png";
import rowingBoatPin from "assets/icons/rowing-boat-pin.png";
import pedaloPin from "assets/icons/pedalo-pin.png";
import motorboatPin from "assets/icons/motorboat-pin.png";
import kitesurfingPin from "assets/icons/kitesurfing-pin.png";
import kayakPin from "assets/icons/kayak-pin.png";
import divingPin from "assets/icons/diving-pin.png";
import efoilPin from "assets/icons/efoil-pin.png";
import wingFoilPin from "assets/icons/wing-foil-pin.png";
import jetSkiPin from "assets/icons/jet-ski-pin.png";
import otherPin from "assets/icons/other-pin.png";
import defaultWatersportIcon from "assets/icons/watersports-icon.svg";
import yachtIcon from "assets/icons/yacht-watersport-icon.svg";
// TODO refactor icons as react component

export const watersportsData = (t: TFunction) => [
  {
    value: "windsurfing",
    name: t("watersports.windsurfing"),
    icon: windsurfingIcon,
    iconWhite: windsurfingWhiteIcon,
    iconWide: windsurfingIconWide,
    id: 1,
    svgIcon: <Windsurfing />,
    pin: windsurfingPin,
  },
  {
    value: "wakeboard",
    name: t("watersports.wakeboard"),
    icon: wakeboardIcon,
    iconWhite: wakeboardWhiteIcon,
    iconWide: wakeboardIconWide,
    id: 2,
    svgIcon: <Wakeboard />,
    pin: wakeboardPin,
  },
  {
    value: "surfing",
    name: t("watersports.surfing"),
    icon: surfingIcon,
    iconWhite: surfingWhiteIcon,
    iconWide: surfingIconWide,
    id: 3,
    svgIcon: <Surfing />,
    pin: surfingPin,
  },
  {
    value: "sup",
    name: t("watersports.sup"),
    icon: supIcon,
    iconWhite: supWhiteIcon,
    iconWide: supIconWide,
    id: 4,
    svgIcon: <Sup />,
    pin: supPin,
  },
  {
    value: "sailing",
    name: t("watersports.sailing"),
    icon: sailboatsIcon,
    iconWhite: sailboatsWhiteIcon,
    iconWide: sailboatsIconWide,
    id: 5,
    svgIcon: <Sailboats />,
    pin: sailingPin,
  },
  {
    value: "rowingBoat",
    name: t("watersports.rowingBoat"),
    icon: rowingBoatsIcon,
    iconWhite: rowingBoatsWhiteIcon,
    iconWide: rowingBoatsIconWide,
    id: 6,
    svgIcon: <RowingBoats />,
    pin: rowingBoatPin,
  },
  {
    value: "rowing boat",
    name: t("watersports.rowingBoat"),
    icon: rowingBoatsIcon,
    iconWhite: rowingBoatsWhiteIcon,
    iconWide: rowingBoatsIconWide,
    id: 6,
    svgIcon: <RowingBoats />,
    pin: rowingBoatPin,
  },
  {
    value: "pedalo",
    name: t("watersports.pedalo"),
    icon: pedaloIcon,
    iconWhite: pedaloWhiteIcon,
    iconWide: pedaloIconWide,
    id: 7,
    svgIcon: <Pedalo />,
    pin: pedaloPin,
  },

  {
    value: "motorboat",
    name: t("watersports.motorboat"),
    icon: motorboatsIcon,
    iconWhite: motorboatsWhiteIcon,
    iconWide: motorboatsIconWide,
    id: 9,
    svgIcon: <Motorboats />,
    pin: motorboatPin,
  },
  {
    value: "kitesurfing",
    name: t("watersports.kitesurfing"),
    icon: kitesurfingIcon,
    iconWhite: kitesurfingWhiteIcon,
    iconWide: kitesurfingIconWide,
    id: 10,
    svgIcon: <Kitesurfing />,
    pin: kitesurfingPin,
  },
  {
    value: "kayak",
    name: t("watersports.kayak"),
    icon: kayaksIcon,
    iconWhite: kayaksWhiteIcon,
    iconWide: kayaksIconWide,
    id: 11,
    svgIcon: <Kayaks />,
    pin: kayakPin,
  },
  {
    value: "diving",
    name: t("watersports.diving"),
    icon: divingIcon,
    iconWhite: divingWhiteIcon,
    iconWide: divingIconWide,
    id: 12,
    svgIcon: <Diving />,
    pin: divingPin,
  },
  {
    value: "efoil",
    name: t("watersports.efoil"),
    icon: efoilIcon,
    iconWhite: efoilWhiteIcon,
    iconWide: efoilIconWide,
    id: 13,
    svgIcon: <Efoil />,
    pin: efoilPin,
  },
  {
    value: "wingfoil",
    name: t("watersports.wingfoil"),
    icon: wingFoilIcon,
    iconWhite: wingFoilWhiteIcon,
    iconWide: wingFoilIconWide,
    id: 14,
    svgIcon: <WingFoil />,
    pin: wingFoilPin,
  },
  {
    value: "jetski",
    name: t("watersports.jetski"),
    icon: jetSkiIcon,
    iconWhite: jetSkiWhiteIcon,
    iconWide: jetSkiIconWide,
    id: 15,
    svgIcon: <JetSki />,
    pin: jetSkiPin,
  },
  {
    value: "other",
    name: t("watersports.other"),
    icon: otherIcon,
    iconWhite: otherWhiteIcon,
    iconWide: otherIconWide,
    id: 8,
    svgIcon: <Other />,
    pin: otherPin,
  },
  {
    value: "yacht",
    name: t("watersports.yacht"),
    icon: yachtIcon,
    iconWhite: yachtIcon,
    iconWide: yachtIcon,
    id: 0,
    svgIcon: <Other />,
    pin: otherPin,
  },
];

export const getWatersportIcon = (id: number, t: TFunction) =>
  watersportsData(t).find((item) => item.id === id)?.icon || defaultWatersportIcon;

export const getWatersportTranslation = (t: TFunction, watersport: SportType | "YACHT"): string =>
  t(`watersports.${watersport.toLowerCase()}`);
