import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WatersportsData } from "server";

type InitialState = {
  watersports: WatersportsData[];
};

const initialState: InitialState = {
  watersports: [],
};

const watersports = createSlice({
  name: "watersports",
  initialState,
  reducers: {
    resetWatersportsStore: () => initialState,
    setWatersports: (state, action: PayloadAction<WatersportsData[]>) => {
      state.watersports = action.payload;
    },
  },
});

export const { resetWatersportsStore, setWatersports } = watersports.actions;

export default watersports.reducer;
