export const management = {
  navigation: {
    dashboard: "DASHBOARD",

    yourBusiness: "Zarządzanie",
    manageBookings: "Rezerwacje",
    insights: "Analityka",
    weather: "Pogoda",
    team: "Zespół",
    availability: "Dostępność",
    assets: "Sprzęt",
    employees: "Pracownicy",
    guests: "Goście",

    management: "zarzadzanie",
    bookings: "rezerwacje",
  },

  yourBusiness: {
    form: {
      recruitEmployee: "Rekrutuj",
      orderEquipment: "Zamów sprzęt",
      requestService: "Usługi",
      needHelp: "Potrzebujesz pomocy?",
      contactUs: "Skontaktuj się z nami",

      topic: "Temat",
      email: "Email",
      message: "Wiadomość",

      recruitEmployeeDescription:
        "SeaEasy oferuje darmowe wsparcie w rekrutacji do twojej szkoły sportów wodnych, wypożyczalni czy innych plażowych zajęć. Wyślij nam wiadomość ze szczegółami, a my połączymy cię z odpowiednim kandydatem!",
      orderEquipmentDescription:
        "SeaEasy oferuje darmowe wsparcie w zamawianiu sprzętu do twojej szkoły sportów wodnych lub wypożyczalni. Wyślij nam wiadomość ze szczegółami, a my zbierzemy twoje zamówienie razem z innymi biznesami aby zdobyć rabat!",
      requestServiceDescription:
        "SeaEasy oferuje darmowe wsparcie w poszukiwaniu dostawców usług jak złote rączki czy fotografowie. Wyślij nam wiadomość ze szczegółami, a my dostarczymy dane kontaktowe do odpowiedniej osoby do tego zadania!",

      bySendingThisMessage: "Wysyłając wiadomość, akceptujesz naszą",
      privacyPolicy: "Politykę prywatności,",
      and: "i",
      cookies: "Ciasteczka",
      termsOfUse: "Regulamin użytkowania",

      send: "Wyślij",

      validation: {
        topicIsRequired: "Temat jest wymagany",
        valueMustBeEmail: "Wartość musi byc adresem email",
        emailIsRequired: "Email jest wymagany",
        messageIsRequired: "Wiadomość jest wymagana",
      },
    },

    rentalDetails: {
      editProfile: "Edytuj profil",
      contactDetails: "Szczegóły kontaktu",
    },

    offers: {
      rentalOffers: "Twoje oferty wynajmu",
      noOffers: "Brak ofert",
      edit: "Edytuj",
      duplicate: "Duplikuj",
      delete: "Usuń",
    },
  },

  bookings: {
    bookedBy: "Klient:",
    dates: "Daty",
    value: "Wartość",
    services: "Usługi",
    accept: "Akceptuj",
    decline: "Odrzuć",
    from: "od",
    until: "do",
    status: "Status",
    guest: "Goście",
    guests: "gości",
    confirmed: "Potwierdzone",
    loading: "Ładowanie...",
    calendar: "Kalendarz",
    list: "Lista",
    contactPerson: "Osoba kontaktowa",
  },

  addReservationModal: {
    addBooking: "Dodaj rezerwacje",
    offer: "Oferta",
    services: "Usługi",
    host: "Pracownik",
    clients: "Klienci",
    date: "Data",
    newClient: "Nowy klient?",
    value: "Wartość",
    notes: "Notatka",
    save: "Zapisz",
    select: "Wybierz...",
  },
};
