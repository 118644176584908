import React from "react";
import { Form, Formik } from "formik";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";

import { RemoveHostModalProps, RemoveHostsData } from "./remove-host-modal.types";
import { initialValues } from "./remove-host-modal.constants";
import { DeleteHostForm } from "./delete-host-form/delete-host-form";
import { RootState } from "store";
import { deleteMembersFromOrganization } from "server";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./remove-host-modal.module.scss";

export const RemoveHostModal: React.FC<RemoveHostModalProps> = ({ handleClose, handleRefreshTeamData }) => {
  const { t } = useTranslation();

  const { organizationData } = useSelector((state: RootState) => state.organization);

  const deleteHostData = useSubmit(deleteMembersFromOrganization);

  const handleSubmit = async (values: RemoveHostsData) => {
    if (!organizationData) return;

    const data = {
      organization: organizationData?.id,
      users_to_remove: values.hosts,
    };

    await deleteHostData.submit({ data });
    handleClose();
    handleRefreshTeamData();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form className={styles.container}>
        <div className={styles.header}>
          <Typography className={styles.modalTitle}>{t("team.removeModal.removeTeamMember")}</Typography>
          <IconButton onClick={handleClose} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>

        <DeleteHostForm />

        <Button className={styles.sendButton} type="submit">
          {t("team.removeModal.remove")}
        </Button>
      </Form>
    </Formik>
  );
};
