import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { aboutUsData } from "./contact.constants";
import { ContactForm } from "./contact-form/contact-form";

import Logo from "assets/icons/logo-blue.svg";
import background from "assets/images/contact-background.png";

import styles from "./contact.module.scss";

export const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img className={styles.background} src={background} alt="background" />
      <div className={styles.aboutUsContainer}>
        <Typography variant="h2" className={classNames(styles.title, styles.aboutUsTitle)}>
          {t("contact.aboutUs")}
        </Typography>
        <div className={styles.aboutUsContent}>
          <div className={styles.contentWrapper}>
            <img src={Logo} alt="SeaEasy logo" className={styles.icon} />
            <div>
              <Typography className={classNames(styles.text, styles.companyName)}>
                <span>SeaEasy Sp. z o.o.</span>
              </Typography>
              <Typography className={styles.text}>
                <span>KRS:</span> 0000961405
              </Typography>
              <Typography className={styles.text}>
                <span>NIP:</span> 7393966334
              </Typography>
              <Typography className={styles.text}>
                <span>REGON:</span> 521619294
              </Typography>
            </div>
          </div>
          {aboutUsData.map((data) => (
            <div className={styles.contentWrapper}>
              <img src={data.icon} alt="SeaEasy logo" className={styles.icon} />
              <div>
                {data.content.map((item) => (
                  <Typography className={styles.text}>{item}</Typography>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <ContactForm />
    </div>
  );
};
