import { AssetsData } from "../assets.types";

export const initialValues: AssetsData = {
  type: "",
  quantity: 0,
  description: "",
  size: 0,
  sizeUnit: "m",
  watersport: 1,
};
