import React from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { BackTopButtonProps } from "./back-top-button.types";

import styles from "./back-to-top.module.scss";

export const BackTopButton: React.FC<BackTopButtonProps> = ({ isGreen }) => {
  const { t } = useTranslation();

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Button onClick={handleScrollTop} className={classNames(styles.container, { [styles.isGreen]: isGreen })}>
      <KeyboardDoubleArrowUpIcon />

      <Typography>{t("components.toTop")}</Typography>
    </Button>
  );
};
