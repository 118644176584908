import React, { useContext, useState, useMemo } from "react";
import moment, { Moment } from "moment/moment";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { ServicesModel } from "../../../models";
import { getAvailability, Slots } from "../../../server/availability/availability.server";
import { FormValues } from "../components/contact-person/contact-person.types";
import { GuestData } from "../components/quests-details/guests-details.types";
import { ReservationsContext } from "../reservations-modal.context";
import { WorkerInterface } from "./slots-view-own.context";

export type SlotsViewContextType = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  addGuest: () => void;
  removeGuest: () => void;
  guests: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  selectedServices: ServicesModel[];
  setSelectedServices: React.Dispatch<React.SetStateAction<ServicesModel[]>>;
  slotsSubmitting: boolean;
  slots: Slots[] | null;
  formData: FormValues | null;
  setFormData: React.Dispatch<React.SetStateAction<FormValues | null>>;
  selectedDate: moment.Moment;
  setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setSelectedSlots: React.Dispatch<React.SetStateAction<Slots[]>>;
  selectedSlots: Slots[];
  totalPrice: number;
  guestsData: GuestData[];
  setGuestsData: React.Dispatch<React.SetStateAction<GuestData[]>>;
  serviceSelectedWorker: WorkerInterface[];
  setServiceSelectedWorker: React.Dispatch<React.SetStateAction<WorkerInterface[]>>;
};

export const SlotsViewContext = React.createContext<SlotsViewContextType>({
  step: 1,
  setStep: () => {},
  addGuest: () => {},
  removeGuest: () => {},
  guests: 1,
  handleNextStep: () => {},
  handlePrevStep: () => {},
  selectedServices: [],
  setSelectedServices: () => {},
  slotsSubmitting: false,
  slots: [],
  formData: null,
  setFormData: () => {},
  selectedDate: moment(),
  setSelectedDate: () => {},
  selectedSlots: [],
  setSelectedSlots: () => {},
  totalPrice: 0,
  guestsData: [],
  setGuestsData: () => {},
  serviceSelectedWorker: [],
  setServiceSelectedWorker: () => {},
});

interface Props {
  children: React.ReactNode;
}

interface Offer {
  id: number;
  price: number;
  rate_per_person: boolean;
  rate_type: "HALF_HOUR" | "HOUR" | "DAY" | "WEEK" | "EVENT";
  num_of_opportunities: number;
  services: ServicesModel[];
}

const minutesByRateType: { [key: string]: number } = {
  HALF_HOUR: 30,
  HOUR: 60,
  DAY: 1440,
  WEEK: 10080,
};

export const getRateMultiplier = (offer: Offer, slots: Slots[]): number => {
  const { rate_type: rateType } = offer;

  if (rateType === "EVENT") {
    return 1;
  }

  const totalDurationDays = slots.reduce((total, slot) => {
    const duration =
      (new Date(slot.dateTimeTo).getTime() - new Date(slot.dateTimeFrom).getTime()) / (1000 * 60 * 60 * 24);
    return total + duration;
  }, 0);

  if (rateType === "WEEK") {
    return Math.ceil(totalDurationDays / 7);
  }

  if (rateType === "DAY") {
    return totalDurationDays;
  }

  const totalDurationMinutes = slots.reduce((total, slot) => {
    const duration = (new Date(slot.dateTimeTo).getTime() - new Date(slot.dateTimeFrom).getTime()) / (1000 * 60);
    return total + duration;
  }, 0);

  return Math.ceil(totalDurationMinutes / minutesByRateType[rateType]);
};

export const calculateTotalPrice = (
  offer: Offer,
  selectedServices: ServicesModel[],
  selectedSlots: Slots[],
  guests: number,
): number => {
  let totalPriceCounter = 0;

  const rateMultiplier = getRateMultiplier(offer, selectedSlots);

  totalPriceCounter += rateMultiplier * offer.price;
  if (offer.rate_per_person) {
    totalPriceCounter *= guests;
  }

  selectedServices.forEach((service) => {
    let servicePrice = 0;

    if (service.fixed_price) {
      servicePrice = service.price;
    } else {
      servicePrice = rateMultiplier * service.price;
      if (offer.rate_per_person) {
        servicePrice *= guests;
      }
    }
    totalPriceCounter += servicePrice;
  });

  return totalPriceCounter;
};

export const SlotsViewContextProvider: React.FC<Props> = ({ children }) => {
  const { offer } = useContext(ReservationsContext);

  const [step, setStep] = useState<number>(1);
  const [guests, setGuests] = useState<number>(1);
  const [selectedServices, setSelectedServices] = useState<ServicesModel[]>([]);
  const [formData, setFormData] = useState<FormValues | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedSlots, setSelectedSlots] = useState<Slots[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [guestsData, setGuestsData] = useState<GuestData[]>([]);
  const [serviceSelectedWorker, setServiceSelectedWorker] = useState<WorkerInterface[]>([]);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handlePrevStep = () => {
    if (step === 1) return;
    setStep((prevState) => prevState - 1);
  };

  const addGuest = () => {
    if (guests === offer?.num_of_opportunities) return;

    setGuests((prevState) => prevState + 1);
  };

  const removeGuest = () => {
    if (guests === 1) return;

    setGuests((prevState) => prevState - 1);
  };

  const getAvailableSlots = useSubmit(getAvailability);
  const { submit: submitSlots, data: slots, submitting: slotsSubmitting } = getAvailableSlots;

  useDidUpdate(
    () => {
      if (offer) {
        const totalPriceCounter = calculateTotalPrice(offer, selectedServices, selectedSlots, guests);
        setTotalPrice(totalPriceCounter);
      }
    },
    [offer, selectedServices, selectedSlots, guests],
    true,
  );

  useDidUpdate(
    () => {
      if (offer) {
        const startOfDayUTC = selectedDate
          .clone()
          .startOf("day")
          .add(1, "hours")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss[Z]");

        const endOfDayUTC = selectedDate.clone().add(1, "hours").endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

        const services = selectedServices
          .map((service) => {
            const currentServiceWorkers = serviceSelectedWorker.filter(
              (serviceWorker) => serviceWorker.service_id === service.id,
            );

            return currentServiceWorkers.map((serviceWorker) => ({
              id: service.id,
              user_id: serviceWorker.id,
            }));
          })
          .flat();

        const removeDuplicates = (data: { id: number; user_id: number }[]) => {
          const seen = new Set();
          return data.filter((item) => {
            const key = `${item.id}_${item.user_id}`;
            if (!seen.has(key)) {
              seen.add(key);
              return true;
            }
            return false;
          });
        };

        const uniqueServices = removeDuplicates(services);

        const data = {
          datetime_from: startOfDayUTC,
          datetime_to: endOfDayUTC,
          services: uniqueServices,
          offer_id: offer?.id || 0,
        };

        submitSlots({ data }).then();
      }
    },

    [selectedDate, selectedServices, guests, offer, serviceSelectedWorker],
    true,
  );

  useDidUpdate(() => {
    setSelectedSlots([]);
  }, [slots, guests, selectedServices]);

  useDidMount(() => {
    if (offer?.services.length === 0) return;

    const requiredServices: ServicesModel[] = [];

    offer?.services.forEach((service) => {
      if (!service.required) return;

      requiredServices.push(service);
    });

    if (requiredServices.length !== 0) {
      setSelectedServices(requiredServices);
    }
  });

  const contextValue = useMemo(
    () => ({
      step,
      setStep,
      removeGuest,
      addGuest,
      guests,
      handlePrevStep,
      handleNextStep,
      selectedServices,
      setSelectedServices,
      slotsSubmitting,
      slots,
      formData,
      setFormData,
      selectedDate,
      setSelectedDate,
      selectedSlots,
      setSelectedSlots,
      totalPrice,
      guestsData,
      setGuestsData,
      serviceSelectedWorker,
      setServiceSelectedWorker,
    }),
    [
      step,
      guests,
      selectedServices,
      slotsSubmitting,
      slots,
      formData,
      selectedDate,
      selectedSlots,
      totalPrice,
      guestsData,
      serviceSelectedWorker,
    ],
  );

  return <SlotsViewContext.Provider value={contextValue}>{children}</SlotsViewContext.Provider>;
};
