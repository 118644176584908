import { TFunction } from "i18next";

import { WatersportsData } from "server";
import { watersports } from "pages/offers/create/category-select/category-select.constants";

import divingIcon from "assets/icons/diving-trip-icon.svg";
import rentalIcon from "assets/icons/rental-icon.svg";
import surftripIcon from "assets/icons/surf-trip-icon.svg";
import forKidsIcon from "assets/icons/for-kids-icon.svg";
import onlineIcon from "assets/icons/online-icon.svg";
import cruiseIcon from "assets/icons/cruise-icon.svg";
import kitesafariIcon from "assets/icons/kitesafari-icon.svg";
import trainingCampIcon from "assets/icons/training-camp-icon.svg";
import sportCourseIcon from "assets/icons/sport-course-icon.svg";

export const offerCategories = (watersportData: WatersportsData[], t: TFunction) => {
  const findWatersport = (name: string) => watersportData.filter((item) => item.name === name);

  return [
    {
      label: t("createOffer.categories.rental"),
      value: "RENTAL",
      icon: rentalIcon,
      options: [...watersportData],
    },
    {
      label: t("createOffer.categories.kitesafari"),
      value: "KITESAFARI",
      icon: kitesafariIcon,
      options: [...findWatersport(watersports.kitesurfing)],
    },
    {
      label: t("createOffer.categories.cruise"),
      value: "CRUISE",
      icon: cruiseIcon,
      options: [...findWatersport(watersports.motorboat), ...findWatersport(watersports.rowingBoat)],
    },
    {
      label: t("createOffer.categories.forKids"),
      value: "FOR_KIDS",
      icon: forKidsIcon,
      options: [...watersportData],
    },
    {
      label: t("createOffer.categories.sportCourse"),
      value: "SPORT_CRUISE",
      icon: sportCourseIcon,
      options: [...watersportData],
    },
    {
      label: t("createOffer.categories.surfTrip"),
      value: "SURF_TRIP",
      icon: surftripIcon,
      options: [
        ...findWatersport(watersports.surfing),
        ...findWatersport(watersports.kitesurfing),
        ...findWatersport(watersports.windsurfing),
      ],
    },
    {
      label: t("createOffer.categories.divingTrip"),
      value: "DIVING_TRIP",
      icon: divingIcon,
      options: [...findWatersport(watersports.diving)],
    },
    {
      label: t("createOffer.categories.online"),
      value: "ONLINE",
      icon: onlineIcon,
      options: [...watersportData],
    },
    {
      label: t("createOffer.categories.trainingCamp"),
      value: "TRAINING_CAMP",
      icon: trainingCampIcon,
      options: [...watersportData],
    },
  ];
};
