import React, { useState } from "react";
import { useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { IconButton, Switch, TextField, Tooltip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { AdditionalServicesData, OfferData } from "../../create-offer.types";
import { ExtraChargesInitialData, ExtraChargesModalProps } from "./extra-charges-modal.types";
import { initialExtraCharges, inputStyles, switchSx } from "./extra-charges-modal.constants";
import { tooltipConfig } from "../../create-offer.constants";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip.svg";

import styles from "./extra-charges-modal.module.scss";

export const ExtraChargesModal: React.FC<ExtraChargesModalProps> = ({ close }) => {
  const { values } = useFormikContext<OfferData>();
  const { t } = useTranslation();

  const [extraCharge, setExtraCharge] = useState<ExtraChargesInitialData>(initialExtraCharges);
  const [priceError, setPriceError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setExtraCharge({
      ...extraCharge,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (checked: boolean) => {
    setExtraCharge({
      ...extraCharge,
      isFixed: checked,
    });
  };

  const addExtraCharge = () => {
    if (!priceError && !nameError) {
      const extraChargeData: AdditionalServicesData = {
        id: uuid(),
        isRequired: true,
        isFixed: extraCharge.isFixed,
        name: extraCharge.name,
        price: +extraCharge.price,
      };

      values.services.extraCharges.push(extraChargeData);
    }
  };

  const validateData = () => {
    if (extraCharge) {
      setValidating(true);

      if (extraCharge.price === "" || extraCharge.price === 0 || extraCharge.price === "0" || +extraCharge.price < 1) {
        setPriceError(true);
      } else {
        setPriceError(false);
      }

      if (extraCharge.name.length > 0) {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
  };

  useDidUpdate(() => {
    if (!validating) return;

    if (!priceError && !nameError) {
      addExtraCharge();
      close();
    }
  }, [priceError, nameError, validating]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.title}>{t("createOffer.services.modal.addExtraCharges")}</Typography>
        <div className={styles.control}>
          <IconButton onClick={validateData}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.fieldWrapper}>
          <Typography className={classNames(styles.itemTitle, nameError && styles.titleError)}>
            {t("createOffer.services.modal.name")}
          </Typography>
          <TextField
            value={extraCharge.name}
            sx={inputStyles}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label=""
            name="name"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <Typography className={classNames(styles.itemTitle, priceError && styles.titleError)}>
            {t("createOffer.services.modal.price")}
          </Typography>
          <TextField
            value={extraCharge.price}
            sx={inputStyles}
            variant="standard"
            InputProps={{ disableUnderline: true, inputProps: { step: 0.01, min: 0 } }}
            label=""
            name="price"
            onChange={(e) => handleChange(e)}
            type="number"
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.priceCharges")}
            enterTouchDelay={0}
            className={styles.tooltip}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>

        <div className={styles.fieldWrapper}>
          <Typography className={styles.itemTitleCenter}>{t("createOffer.services.modal.isFixed")}</Typography>
          <Switch
            sx={switchSx}
            name="isFixed"
            value={extraCharge.isFixed}
            onChange={(e, checked) => handleSwitch(checked)}
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.fixedPriceCharges")}
            enterTouchDelay={0}
            className={styles.tooltip}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
