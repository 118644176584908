import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { ClickAwayListener } from "@mui/base";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import gsap from "gsap";
import classNames from "classnames";

import { resetOrganizationStore, resetUserStore, RootState } from "store";
import { STORAGE_FIELDS } from "constants/storage.constants";
import {
  CREATE_OFFER_PAGE,
  CREATE_RENTAL_PAGE,
  MANAGEMENT_PAGE,
  OFFERS_PAGE,
  PROFILE_PAGE,
} from "constants/routes.constants";
import { BurgerMenuProps } from "./burger-munu.types";
import { colors, menuItems } from "./burger-menu.constants";
import { useRoutesPath } from "hooks";
import { generateStripeLink, logout } from "server";
import { sections } from "../../../pages/rental/management/management.constants";
import { Loader } from "../../client";

import styles from "./burger-menu.module.scss";

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ isGreen, className }) => {
  const dispatch = useDispatch();
  const burgerMenuTl = useRef<null | GSAPTimeline>(null);
  const burgerMenuItemsRef = useRef<null | HTMLDivElement>(null);

  const { t } = useTranslation();
  const { token } = useSelector((state: RootState) => state.auth);
  const { organizationData, activePayment, dataFetching } = useSelector((state: RootState) => state.organization);
  const { localePath, localePathParams } = useRoutesPath();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [burgerMenuAnimated, setBurgerMenuAnimated] = useState<boolean>(false);

  const handleMenu = (isOpen: boolean) => () => {
    setIsMenuOpen(isOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const strapiDataLink = useSubmit(generateStripeLink.setParams({ organization_id: organizationData?.id || 1 }));
  const { submit, submitting } = strapiDataLink;

  const logoutData = useSubmit(logout);
  const { submit: logoutPost, onSubmitSuccess: onLogoutSuccess } = logoutData;
  onLogoutSuccess(() => {
    localStorage.removeItem(STORAGE_FIELDS.token);
    localStorage.removeItem(STORAGE_FIELDS.refreshToken);
    dispatch(resetOrganizationStore());
    dispatch(resetUserStore());
  });

  const addPayment = () => {
    if (!submitting) {
      submit().then((res) => {
        window.open(res[0]?.stripe_url, "_self");
      });
    }
  };

  const logOut = async () => {
    const data = {
      refresh: localStorage.getItem(STORAGE_FIELDS.refreshToken),
    };

    await logoutPost({ data });
  };

  const color = isGreen ? colors.green : colors.purple;
  const isAuthenticated = Boolean(token);

  useDidUpdate(
    () => {
      if (burgerMenuItemsRef.current && !burgerMenuAnimated) {
        gsap.set(burgerMenuItemsRef.current?.children, { autoAlpha: 0, y: "+=150px" });

        burgerMenuTl.current = gsap.timeline({
          delay: 0.2,
        });

        burgerMenuTl.current
          .to(burgerMenuItemsRef.current?.children, {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
          })
          .to(burgerMenuItemsRef.current?.children, { autoAlpha: 1, y: 0 });

        setBurgerMenuAnimated(true);
      }
    },
    [burgerMenuItemsRef, isMenuOpen],
    true,
  );

  return (
    <div className={classNames(styles.container, className)}>
      <IconButton className={styles.burgerIcon} onClick={toggleMenu} size="small" sx={{ "*": { fill: color } }}>
        <MenuIcon />
      </IconButton>

      {isMenuOpen && (
        <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
          <>
            <div className={styles.menuContainer} ref={burgerMenuItemsRef}>
              {!isAuthenticated &&
                menuItems(t).notAuthenticated.map((item) => (
                  <Link className={styles.link} style={{ color }} to={localePath({ route: item })}>
                    {item.value}
                  </Link>
                ))}

              <Link
                className={styles.link}
                style={{ color }}
                onClick={handleMenu(false)}
                to={localePath({ route: OFFERS_PAGE })}
              >
                {t("burgerMenu.offers")}
              </Link>

              {isAuthenticated && !dataFetching && (
                <>
                  <Link
                    className={styles.link}
                    onClick={handleMenu(false)}
                    style={{ color }}
                    to={localePath({ route: PROFILE_PAGE })}
                  >
                    {t("burgerMenu.account")}
                  </Link>

                  {organizationData && !activePayment && (
                    <Typography onClick={addPayment} className={styles.link} style={{ color }}>
                      {submitting ? t("burgerMenu.loading") : t("burgerMenu.addPayment")}
                    </Typography>
                  )}

                  {!organizationData && activePayment && (
                    <Link
                      className={styles.link}
                      onClick={handleMenu(false)}
                      style={{ color }}
                      to={localePath({ route: CREATE_OFFER_PAGE })}
                    >
                      {t("burgerMenu.createOffer")}
                    </Link>
                  )}

                  {!organizationData && (
                    <Link
                      className={styles.link}
                      onClick={handleMenu(false)}
                      style={{ color }}
                      to={localePath({ route: CREATE_RENTAL_PAGE })}
                    >
                      {t("burgerMenu.createRental")}
                    </Link>
                  )}

                  {organizationData && (
                    <Link
                      className={styles.link}
                      onClick={handleMenu(false)}
                      style={{ color }}
                      to={localePathParams({ route: MANAGEMENT_PAGE, params: { section: sections(t)[3].name } })}
                    >
                      {t("burgerMenu.management")}
                    </Link>
                  )}

                  <Button className={styles.logOutButton} onClick={logOut} style={{ color }}>
                    {t("burgerMenu.logOut")}
                  </Button>
                </>
              )}
            </div>
            {dataFetching && isAuthenticated && (
              <div className={styles.menuContainer}>
                <Loader color="#FFF8F3" backgroundColor="#FFF8F3" />
              </div>
            )}
          </>
        </ClickAwayListener>
      )}
    </div>
  );
};
