import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AdditionalServiceProps } from "./additional-service.types";
import { substringText } from "utils";

import styles from "./additional-service.module.scss";

export const AdditionalService: React.FC<AdditionalServiceProps> = ({ additionalService, currency, priceValue }) => {
  const { t } = useTranslation();
  const { price, name, isFixed } = additionalService;

  return (
    <div className={styles.container}>
      <div>
        <Typography fontSize="20px" fontFamily="Chillax-semibold">
          {substringText({ string: name, maxLength: 12, cutAtChart: 12 })}
        </Typography>
      </div>
      <div>
        <Typography fontSize="20px" fontFamily="Chillax-semibold">
          {price} {currency}
        </Typography>
        {isFixed && (
          <Typography fontSize="14px" fontFamily="Chillax-medium">
            {t("createOffer.services.fixedPrice")}
          </Typography>
        )}
        {!isFixed && (
          <Typography fontSize="14px" fontFamily="Chillax-medium">
            {priceValue}
          </Typography>
        )}
      </div>
    </div>
  );
};
