import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import classNames from "classnames";

import { FormCheckboxTypes } from "./form-checkbox.types";

import styles from "./form-checkbox.module.scss";

export const FormCheckbox = ({ name, label, onCustomChange, checked, className }: FormCheckboxTypes) => {
  const [field] = useField(name);
  const { onChange, onBlur } = field;

  return (
    <FormControlLabel
      className={classNames(styles.textInput, className)}
      control={
        <Checkbox
          onChange={onCustomChange || onChange}
          onBlur={onBlur}
          name={name}
          checked={checked}
          style={{ color: "rgba(213, 146, 103, 1)" }}
        />
      }
      label={label}
    />
  );
};
