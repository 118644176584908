import { TFunction } from "i18next";
import { array, mixed, object, SchemaOf, string } from "yup";

import { ProfileData } from "./profile.types";

export const rentalProfileSchema = (t: TFunction): SchemaOf<ProfileData> =>
  object().shape({
    name: string().required(t("rental.profile.validation.nameRequired")),
    avatar: mixed().required(t("rental.profile.validation.pictureRequired")),
    description: string().required(t("rental.profile.validation.descriptionRequired")),
    rules: string().required(t("rental.profile.validation.rulesRequired")),
    pictures: array()
      .of(mixed())
      .required(t("rental.profile.validation.photosRequired"))
      .min(5, t("rental.profile.validation.minNumOfPhotos")),
  });
