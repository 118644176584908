import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import CopyrightIcon from "@mui/icons-material/Copyright";

import { Sections } from "./sections/sections";
import { Socials } from "./socials/socials";
import { Partners } from "./partners/partners";
import { FooterProps } from "./footer.types";
import { Services } from "./services/services";

import styles from "./footer.module.scss";

export const Footer: React.FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const currentYear = new Date().getFullYear();

  const checkRoutePl = pathname === "/pl/";
  const checkRouteEn = pathname === "/en/";
  const isGreen = checkRouteEn || checkRoutePl;
  const color = isGreen ? "#4BBBB7" : "#474cae";

  return (
    <div className={classNames(styles.wrapper, className)}>
      <section className={styles.container}>
        <div className={styles.content}>
          <Typography fontSize={20} color={color}>
            {t("components.partners")}
          </Typography>
          <Partners />
        </div>
        <Socials color={color} />
      </section>
      <Sections color={color} isGreen={isGreen} />
      <Services color={color} />
      <div className={styles.copyrightWrapper}>
        <CopyrightIcon className={styles.icon} />
        <Typography color={color} className={styles.copyright}>
          SeaEasy {currentYear}
        </Typography>
      </div>
    </div>
  );
};
