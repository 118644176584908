import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HOME_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";

import { ReactComponent as Pineapple } from "assets/icons/pineapple-icon.svg";

import styles from "./booking-cancel.module.scss";

export const BookingCancelPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const openHomePage = () => {
    navigate(
      localePath({
        route: HOME_PAGE,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <Pineapple className={styles.icon} />
        <div className={styles.content}>
          <Typography className={styles.text}> {t("booking.cancel.bookingCancel")}</Typography>
          <Typography className={styles.textSmall}>{t("booking.cancel.cancelError")}</Typography>
          <Button className={styles.button} onClick={openHomePage}>
            {t("notFoundPage.homePage")}
          </Button>
        </div>
      </div>
    </div>
  );
};
