export const article = {
  section: {
    contents: "Zawartość:",
    otherArticles: "Inne Artykuły",
  },

  moreArticles: {
    sailSmart: "Czytaj więcej",
    seeMore: "Zobacz więcej",
  },
};
