import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { ReservationsContext } from "../../reservations-modal.context";
import { RootState } from "store";

import styles from "./offer-select.module.scss";

export const OfferSelect: React.FC = () => {
  const { t } = useTranslation();
  const { setOffer } = useContext(ReservationsContext);
  const { offers } = useSelector((state: RootState) => state.organization);

  return (
    <div className={styles.container}>
      <p>{t("reservationsModal.selectOffer")}</p>

      <div className={styles.offersList}>
        {offers.map((mappedOffer) => {
          const title = mappedOffer.title || mappedOffer.organization.name || "title";

          return (
            <Button
              className={styles.offerButton}
              onClick={() => {
                setOffer(mappedOffer);
              }}
              key={mappedOffer.id}
            >
              {title}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
