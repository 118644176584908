import React, { useState } from "react";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";
import { useTranslation } from "react-i18next";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { enUS, pl } from "date-fns/locale";

import { getOwnerReservations, getUserOrganization, putReservationStatus } from "server";
import { useRoutesPath, useShowContent } from "hooks";
import { Loader, Navbar } from "components";
import { TableData } from "./owner-reservations.types";
import {
  ACCEPTED_COLOR,
  ACCEPTED_STATUS,
  createData,
  REJECTED_COLOR,
  statusesData,
} from "./owner-reservations.constants";
import { CREATE_RENTAL_PAGE } from "constants/routes.constants";

import { ReactComponent as Logo } from "assets/icons/logo-icon.svg";

import styles from "./owner-reservation.module.scss";

export const OwnerReservationsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { submit: submitStatus } = useSubmit(putReservationStatus);
  const { localePath, locale } = useRoutesPath();

  const [reservations, setReservations] = useState<null | TableData[]>(null);
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [userOrganizationRefresher, setUserOrganizationRefresher] = useState<number>(0);

  const { submit, onSubmitSuccess } = useSubmit(getOwnerReservations);
  onSubmitSuccess(({ response }) => {
    const reservationsArr: TableData[] = [];

    response.map((reservation) => {
      const services: string[] = reservation.items
        .filter((item) => item.item_type === "SERVICE")
        .map((service) => service.service?.title || "");

      const dates = [reservation.start_time, reservation.end_time].map(() => "");

      const offer = reservation.items.find((item) => item.item_type === "OFFER");

      const reservationData = createData({
        offer: offer?.offer?.title || offer?.offer?.watersport.name || "",
        guests: reservation.num_of_guests,
        totalProfit: `${reservation.price} ${offer?.offer?.currency}`,
        services,
        bookedBy: `${reservation.user.first_name} ${reservation.user.last_name}`,
        status: reservation.reservation_status,
        id: reservation.id,
        dates,
      });

      return reservationsArr.push(reservationData);
    });

    setReservations(reservationsArr);
  });

  const userOrganizationData = useFetch(getUserOrganization, {
    dependencies: [userOrganizationRefresher],
  });
  const { showContent, showLoader, showError, showNoContent } = useShowContent(userOrganizationData);
  const { data, onSuccess } = userOrganizationData;
  onSuccess(async ({ response }) => {
    const id = response[0].id || 0;
    setOrganizationName(response[0].name);
    await submit({ params: { organizationID: id } });
  });

  const refreshUserOrganization = () => setUserOrganizationRefresher((prevState) => prevState + 1);

  const acceptOffer = async (id: number) => {
    await submitStatus({ params: { id }, data: { organization_action: "ACCEPT" } });
    refreshUserOrganization();
  };

  const rejectOffer = async (id: number) => {
    await submitStatus({ params: { id }, data: { organization_action: "REJECT" } });
    refreshUserOrganization();
  };

  const openCreateRental = () => {
    navigate(localePath({ route: CREATE_RENTAL_PAGE }));
  };

  const dateFormat = "dd-MM-yyyy hh:mm";
  const dateLocale = locale === "pl-PL" ? pl : enUS;

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.content}>
        {showLoader && <Loader height="90vh" />}
        {/* TODO component */}
        {showError && (
          <div className={styles.error}>
            <Logo className={styles.logo} />
            <div>
              <Typography>{t("ownerReservations.error.noOrganization")}</Typography>
              <Button onClick={openCreateRental}>{t("ownerReservations.error.createOrganization")}</Button>
            </div>
          </div>
        )}
        {showNoContent && <Typography>{t("ownerReservations.error.noReservations")}</Typography>}
        {showContent && data && reservations && (
          <div className={styles.wrapper}>
            <Typography className={styles.title}>
              {t("ownerReservations.reservations", { organizationName })}
            </Typography>

            <TableContainer component={Paper} sx={{ backgroundColor: "#F8F2ED", boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tableCellMapFirst}>{t("ownerReservations.legend.offer")}</TableCell>
                    <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.guests")}</TableCell>
                    <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.totalProfit")}</TableCell>
                    <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.dates")}</TableCell>
                    <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.services")}</TableCell>
                    <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.bookedBy")}</TableCell>
                    <TableCell className={styles.tableCellMap}>{t("ownerReservations.legend.status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations?.map((row) => {
                    return (
                      <TableRow
                        key={row.offer}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          borderBottom: "2px solid #FF8552",
                          borderTop: "2px solid #FF8552",
                        }}
                      >
                        <TableCell className={styles.tableCellFirst} scope="row">
                          {row.offer}
                        </TableCell>
                        <TableCell className={styles.tableCell}>{row.guests}</TableCell>
                        <TableCell className={styles.tableCell}>{row.totalProfit}</TableCell>
                        <TableCell className={styles.tableCell}>
                          {row.dates.map((date) => (
                            <Typography fontFamily="Chillax-regular" key={date}>
                              {format(new Date(date), dateFormat, { locale: dateLocale })}
                            </Typography>
                          ))}
                        </TableCell>
                        <TableCell className={styles.tableCell}>
                          {row.services.length === 0
                            ? t("ownerReservations.error.noServices")
                            : row.services.map((service) => (
                                <Typography fontFamily="Chillax-regular" key={service}>
                                  {service}
                                </Typography>
                              ))}
                        </TableCell>
                        <TableCell className={styles.tableCell}>{row.bookedBy}</TableCell>
                        <TableCell className={styles.tableCell}>
                          {row.status === ACCEPTED_STATUS ? (
                            <div className={styles.buttons}>
                              <Button
                                sx={{ backgroundColor: ACCEPTED_COLOR }}
                                className={styles.button}
                                onClick={() => acceptOffer(row.id)}
                              >
                                {t("ownerReservations.buttons.accept")}
                              </Button>
                              <Button
                                sx={{ backgroundColor: REJECTED_COLOR }}
                                className={styles.button}
                                onClick={() => rejectOffer(row.id)}
                              >
                                {t("ownerReservations.buttons.reject")}
                              </Button>
                            </div>
                          ) : (
                            <Typography
                              fontFamily="Chillax-regular"
                              color={(row.status === "ACCEPTED" && ACCEPTED_COLOR) || "#474CAE"}
                            >
                              {statusesData(t).find((status) => status.status === row.status)?.label}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};
