import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useRoutesPath } from "hooks";
import { CREATE_OFFER_PAGE } from "constants/routes.constants";
import { RootState } from "store";
import { AddPayments, Modal } from "components";

import { ReactComponent as AddIcon } from "assets/icons/add-icon.svg";

import styles from "./no-offers.module.scss";

export const NoOffers = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();
  const { activePayment } = useSelector((state: RootState) => state.organization);

  const [modalOpen, setModalOpen] = useState(false);
  const openAddOffer = () => {
    if (activePayment) return navigate(localePath({ route: CREATE_OFFER_PAGE }));

    setModalOpen(true);
  };

  return (
    <>
      <div className={styles.container}>
        <IconButton className={styles.button} onClick={openAddOffer}>
          <AddIcon />
        </IconButton>
        <Typography className={styles.noOffers}>{t("management.yourBusiness.offers.noOffers")}</Typography>
      </div>

      <Modal open={modalOpen} className={styles.modal}>
        <AddPayments setIsModalOpen={setModalOpen} />
      </Modal>
    </>
  );
};
