import PhoneIcon from "assets/icons/phone-icon.svg";
import MailIcon from "assets/icons/mail-icon.svg";
import PinIcon from "assets/icons/pin-icon.svg";

export const aboutUsData = [
  {
    icon: PhoneIcon,
    content: ["+48 664824380"],
  },
  {
    icon: MailIcon,
    content: ["contact@seaeasy.surf"],
  },
  {
    icon: PinIcon,
    content: ["ul.Lubelska 43A/400", "10-410 Olsztyn", "Polska"],
  },
];
