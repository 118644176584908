export const management = {
  navigation: {
    dashboard: "DASHBOARD",

    yourBusiness: "Management",
    manageBookings: "Bookings",
    insights: "Insights",
    weather: "Weather",
    team: "Team",
    availability: "Availability",
    assets: "Assets",
    employees: "Employees",
    guests: "Guests",

    management: "management",
    bookings: "bookings",
  },

  yourBusiness: {
    form: {
      recruitEmployee: "Recruit",
      orderEquipment: "Order equipment",
      requestService: "Service",
      needHelp: "Need help?",
      contactUs: "Contact us",

      topic: "Topic",
      email: "Email",
      message: "Message",

      recruitEmployeeDescription:
        "SeaEasy is offering free support in recruitment for your water sports school, rental and beach jobs. Send us a message with details and we will connect you with the right candidate for the job!",
      orderEquipmentDescription:
        "SeaEasy is offering free support in ordering equipment for your water sports school or rental. Send us a message with details and we will collect your request together  with other organizations to get a discount!",
      requestServiceDescription:
        "SeaEasy is offering free support in connecting your business with service providers like mechanics or photographers. Send us a message with details and we will provide you with contact details of right person for the job!",

      bySendingThisMessage: "By sending this message, you agree with our",
      privacyPolicy: "Privacy policy,",
      comma: ",",
      and: "and",
      cookies: "Cookies",
      termsOfUse: "Terms of Use",

      send: "Send",

      validation: {
        topicIsRequired: "Topic is required",
        valueMustBeEmail: "Value must be an email",
        emailIsRequired: "Email is required",
        messageIsRequired: "Message is required",
      },
    },

    rentalDetails: {
      editProfile: "Edit profile",
      contactDetails: "Contact details",
    },

    offers: {
      rentalOffers: "Your rental offers",
      noOffers: "No offers",

      edit: "Edit",
      duplicate: "Duplicate",
      delete: "Delete",
    },
  },

  bookings: {
    bookedBy: "Booked by:",
    dates: "Dates",
    value: "Value",
    services: "Services",
    accept: "Accept",
    decline: "Decline",
    from: "from",
    until: "until",
    status: "Status",
    guest: "Guests",
    guests: "guests",
    confirmed: "Confirmed",
    loading: "Loading...",
    calendar: "Calendar",
    list: "List",
    contactPerson: "Contact person",
  },

  addReservationModal: {
    addBooking: "Add booking",
    offer: "Offer",
    services: "Services",
    host: "Host",
    clients: "Clients",
    date: "Date",
    newClient: "New client?",
    value: "Value",
    notes: "Notes",
    save: "Save",
    select: "Select...",
  },
};
