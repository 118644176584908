export const about = {
  hero: {
    aboutUs: "About us",
    content: `We are a platform that supports water sports enthusiasts around the world. Travel,
    rent equipment, exchange experiences and make new friends. Join the crew today and let's share the waves!`,
  },

  section: {
    howItWorks: "How it works?",
    content: `SeaEasy allows you to rent water sports equipment. Any kind of equipment. From kayaks, SUPs, jet skis or yachts to kitesurfing, windsurfing or surfing equipment. Application allows water sports equipment owners, water sports schools and yacht charters to share their equipment and earn money through intelligent booking system. Low commission and transparent costs are the key principles.`,
    slides: {
      find: "Search",
      findContent: "Find rental offers for any type of water equipment tailored to your needs.",
      bookIt: "Book",
      bookItContent: "Once you find the offer that suits you, send a booking request and wait for confirmation.",
      enjoy: "Enjoy",
      enjoyContent: "Enjoy your trip & share the waves!",
    },
  },

  crew: {
    ourCrew: "Our Crew",
    content: `Water sports enthusiasts - with previous experience from global IT leaders such as
      Microsoft, Amazon, Intel and many more.`,
    content1: `Olsztyn is our homespot. The capital of the Land of a Thousand Lakes. SeaEasy started here, this is where our crew was born, here you will meet us on the lakes, beaches, marinas and streets.
    SeaEasy proudly represents this wonderful city as the initiative was created in cooperation with the local Olsztyn Science and Technology Park.`,
    technicalTeam: "Technical",
    team: "Team",
    salesTeam: "Sales",
    marketing: "& Marketing",
  },
};
