export const booking = {
  confirmAndPay: "Confirm and pay",
  total: "Total",
  bookAndPay: "Book and pay",
  bookingPolicy: "Clicking the button above will redirect you to payment operator.",
  booking: "Booking...",
  needLogIn: "You need to log in first",

  yourTrip: {
    yourTrip: "Your trip",
    dates: "Dates",
    arrival: "Arrival",
    departure: "Departure",
    hours: "Hours",
    hour: "Hour",
    days: "Days",
    day: "Day",
  },

  guests: {
    guests: "Guests",
    guest: "Guest",
  },

  cancellationPolicy: {
    cancellationPolicy: "Cancellation policy",
    cancellationContent: "Cancellation is handled by the rental according to Rental Rules and own policies.",
    agree: "By clicking the button below I agree with SeaEasy ",
    termsOfUse: "Terms of Use, ",
    rentalRules: "Rental rules, ",
    paymentTerms: "Payment terms.",
  },

  cart: {
    yourCart: "Your cart",
    total: "Total",
    servicesPrice: "Services price",
    additionalServices: "Additional services",
    rentalTime: "Rental time",
    totalCost: "Total cost",
    requiredServices: "Required services",
  },

  success: {
    bookingSent: "Booking sent!",
    waitingForAccept: "Offer waiting for confirm by owner.",
  },

  cancel: {
    bookingCancel: "Your booking has been canceled",
    cancelError: "Sorry, your reservation was drugged.",
  },
};
