import React, { useContext } from "react";
import { IconButton, ListItem, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

import { sections } from "../management.constants";
import { useRoutesPath, useWindowSize } from "hooks";
import { MANAGEMENT_PAGE } from "constants/routes.constants";
import { ManagementContext } from "../management.context";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import clientsIcon from "assets/icons/clients-icon.svg";
import employeesIcon from "assets/icons/employees-icon.svg";

import styles from "./navigation.module.scss";

// TODO nav refactor

export const Navigation: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { section } = useParams();
  const { localePathParams } = useRoutesPath();
  const { isNavigationOpen, setIsNavigationOpen, openTeamHours, openGuestsDetails } = useContext(ManagementContext);

  const toggleNav = () => {
    setIsNavigationOpen((prevState) => !prevState);
  };

  const isMobile = width <= 992;
  const currentSection = sections(t).findIndex(({ name }) => name === section);
  const sectionsData = sections(t);
  const currentSectionName = sectionsData[currentSection].value;

  const changeSection = (sectionPath: string) => {
    navigate(localePathParams({ route: MANAGEMENT_PAGE, params: { section: sectionPath } }));
    if (isMobile) setIsNavigationOpen(false);
  };

  if (isMobile)
    return (
      <div className={classNames(styles.containerMobile, { [styles.containerMobileOpen]: isNavigationOpen })}>
        <div className={styles.mobileHeader}>
          <Typography className={styles.activeSection}>
            DASHBOARD: <br /> {currentSectionName}
          </Typography>
          <IconButton onClick={toggleNav} className={classNames(styles.icon, isNavigationOpen && styles.iconOpen)}>
            <ArrowIcon />
          </IconButton>
        </div>
        {isNavigationOpen && (
          <ul className={styles.mobileMenu}>
            <ListItem
              className={classNames(styles.sectionItem, currentSection === 2 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[2].name)}
            >
              <ReactSVG
                src={sectionsData[2].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 2 })}
              />

              <Typography>{sectionsData[2].value}</Typography>
            </ListItem>

            <ListItem
              className={classNames(styles.sectionItem, currentSection === 0 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[0].name)}
            >
              <ReactSVG
                src={sectionsData[0].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 0 })}
              />

              <Typography>{sectionsData[0].value}</Typography>
            </ListItem>

            <ListItem
              className={classNames(styles.sectionItem, currentSection === 1 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[1].name)}
            >
              <ReactSVG
                src={sectionsData[1].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 1 })}
              />

              <Typography>{sectionsData[1].value}</Typography>
            </ListItem>

            <ListItem className={styles.sectionItem} onClick={() => openTeamHours(1)}>
              <ReactSVG src={employeesIcon} className={styles.icon} />

              <Typography>{t("management.navigation.employees")}</Typography>
            </ListItem>

            <ListItem className={styles.sectionItem} onClick={() => openGuestsDetails(1)}>
              <ReactSVG src={clientsIcon} className={styles.icon} />
              <Typography>{t("management.navigation.guests")}</Typography>
            </ListItem>

            <ListItem
              className={classNames(styles.sectionItem, currentSection === 3 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[3].name)}
            >
              <ReactSVG
                src={sectionsData[3].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 3 })}
              />

              <Typography>{sectionsData[3].value}</Typography>
            </ListItem>

            <ListItem
              className={classNames(styles.sectionItem, currentSection === 4 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[4].name)}
            >
              <ReactSVG
                src={sectionsData[4].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 4 })}
              />

              <Typography>{sectionsData[4].value}</Typography>
            </ListItem>

            <ListItem
              className={classNames(styles.sectionItem, currentSection === 5 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[5].name)}
            >
              <ReactSVG
                src={sectionsData[5].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 5 })}
              />

              <Typography>{sectionsData[5].value}</Typography>
            </ListItem>

            <ListItem
              className={classNames(styles.sectionItem, currentSection === 6 && styles.sectionItemActive)}
              onClick={() => changeSection(sectionsData[6].name)}
            >
              <ReactSVG
                src={sectionsData[6].icon}
                className={classNames(styles.icon, { [styles.iconActive]: currentSection === 6 })}
              />

              <Typography>{sectionsData[6].value}</Typography>
            </ListItem>
          </ul>
        )}
      </div>
    );

  return (
    <div className={styles.container}>
      <ul className={styles.menu}>
        <ListItem
          className={classNames(styles.sectionItem, currentSection === 2 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[2].name)}
        >
          <ReactSVG
            src={sectionsData[2].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 2 })}
          />

          <Typography>{sectionsData[2].value}</Typography>
        </ListItem>

        <ListItem
          className={classNames(styles.sectionItem, currentSection === 0 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[0].name)}
        >
          <ReactSVG
            src={sectionsData[0].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 0 })}
          />

          <Typography>{sectionsData[0].value}</Typography>
        </ListItem>

        <ListItem
          className={classNames(styles.sectionItem, currentSection === 1 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[1].name)}
        >
          <ReactSVG
            src={sectionsData[1].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 1 })}
          />

          <Typography>{sectionsData[1].value}</Typography>
        </ListItem>

        <ListItem className={styles.sectionItem} onClick={() => openTeamHours(1)}>
          <ReactSVG src={employeesIcon} className={styles.icon} />

          <Typography>{t("management.navigation.employees")}</Typography>
        </ListItem>

        <ListItem className={styles.sectionItem} onClick={() => openGuestsDetails(1)}>
          <ReactSVG src={clientsIcon} className={styles.icon} />
          <Typography>{t("management.navigation.guests")}</Typography>
        </ListItem>

        <ListItem
          className={classNames(styles.sectionItem, currentSection === 3 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[3].name)}
        >
          <ReactSVG
            src={sectionsData[3].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 3 })}
          />

          <Typography>{sectionsData[3].value}</Typography>
        </ListItem>

        <ListItem
          className={classNames(styles.sectionItem, currentSection === 4 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[4].name)}
        >
          <ReactSVG
            src={sectionsData[4].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 4 })}
          />

          <Typography>{sectionsData[4].value}</Typography>
        </ListItem>

        <ListItem
          className={classNames(styles.sectionItem, currentSection === 5 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[5].name)}
        >
          <ReactSVG
            src={sectionsData[5].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 5 })}
          />

          <Typography>{sectionsData[5].value}</Typography>
        </ListItem>

        <ListItem
          className={classNames(styles.sectionItem, currentSection === 6 && styles.sectionItemActive)}
          onClick={() => changeSection(sectionsData[6].name)}
        >
          <ReactSVG
            src={sectionsData[6].icon}
            className={classNames(styles.icon, { [styles.iconActive]: currentSection === 6 })}
          />

          <Typography>{sectionsData[6].value}</Typography>
        </ListItem>
      </ul>
    </div>
  );
};
