import moment, { Moment } from "moment";

import { Slots } from "../../../../server/availability/availability.server";

export const transformAvailabilityData = (data: Slots[]): { [key: string]: number } => {
  const availabilityObject: { [key: string]: number } = {};
  data.forEach(({ dateTimeFrom, quantity }) => {
    const date = moment(dateTimeFrom).format("YYYY-MM-DD");
    availabilityObject[date] = quantity;
  });
  return availabilityObject;
};

export const calculateDateRange = (start: Moment, end: Moment): string[] => {
  const range = [];
  const currentDate = start.clone();
  while (currentDate.isSameOrBefore(end)) {
    range.push(currentDate.toISOString());
    currentDate.add(1, "days");
  }
  return range;
};

export const isDayBlocked = (day: Moment, availability: { [key: string]: number }, guests: number): boolean => {
  if (day.isBefore(moment(), "day")) {
    return true;
  }
  const formattedDay = day.format("YYYY-MM-DD");
  const availablePlaces = availability[formattedDay] || 0;
  return availablePlaces < guests;
};

export const isBlockedInRange = (
  start: Moment,
  end: Moment,
  availability: { [key: string]: number },
  guests: number,
): boolean => {
  const currentDate = start.clone();
  while (currentDate.isBefore(end)) {
    if (isDayBlocked(currentDate, availability, guests)) {
      return true;
    }
    currentDate.add(1, "days");
  }
  return false;
};
