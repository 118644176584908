import { TFunction } from "i18next";
import { SchemaOf, string, object } from "yup";

import { LoginData } from "server/auth/auth.types";

export const initialValues: LoginData = {
  email: "",
  password: "",
};

export const loginSchema = (t: TFunction): SchemaOf<LoginData> =>
  object().shape({
    email: string().email(t("login.invalidEmail")).required(t("login.emailRequired")),
    password: string().required(t("login.passwordRequired")),
  });

export const sx = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "none" },
  },
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "15px",
    background: "#fff",
    border: "2px solid #474CAE",
  },
};
