import React, { useContext, useRef, useState } from "react";
import { LineChart, Tooltip, XAxis, YAxis, Line } from "recharts";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useQueryParams, useWindowSize } from "hooks";
import { ManagementContext } from "../../management.context";
import { toolTipContentStyle } from "./chart.constants";
import { ChartProps } from "./chart.types";

import styles from "./chart.module.scss";

export const Chart: React.FC<ChartProps> = ({ data }) => {
  const { t } = useTranslation();
  const containerRef = useRef<null | HTMLDivElement>(null);

  const { width } = useWindowSize();
  const {
    query: { chart },
  } = useQueryParams();
  const { isNavigationOpen } = useContext(ManagementContext);

  const [chartWidth, setChartWidth] = useState<number>(containerRef.current?.offsetWidth as number);
  const [chartHeight, setChartHeight] = useState<number>(400); // Default to 400 if no height from container

  const isMobile = width < 992;

  useDidUpdate(
    () => {
      if (containerRef.current) {
        setChartWidth(containerRef.current.offsetWidth);
        setChartHeight(containerRef.current.offsetHeight || 400);
      }
    },
    [containerRef, width, isNavigationOpen],
    true,
  );

  return (
    <div className={styles.container} ref={containerRef}>
      {data && data.length > 0 ? (
        <LineChart width={chartWidth} height={isMobile ? chartHeight - 50 : chartHeight} data={data}>
          <XAxis
            dataKey="date_to"
            tickLine={false}
            axisLine={{ stroke: "#fff" }}
            tick={{ fill: "#fff", fontFamily: "Chillax-semibold" }}
          />
          <YAxis
            width={isMobile ? 10 : 60}
            tickLine={false}
            axisLine={{ stroke: "#fff" }}
            tick={isMobile ? false : { fill: "#fff", fontFamily: "Chillax-semibold" }}
          />
          <Tooltip contentStyle={toolTipContentStyle} />
          <Line
            strokeWidth={4}
            dot={{ stroke: "#fff", strokeWidth: 2 }}
            type="monotone"
            className={styles.line}
            dataKey={chart as string}
            stroke="#FF8552"
          />
        </LineChart>
      ) : (
        <div
          style={{
            width: chartWidth,
            height: chartHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <Typography className={styles.noData}>{t("insights.chart.noData")}</Typography>
        </div>
      )}
    </div>
  );
};
