import React from "react";
import Carousel from "react-multi-carousel";

import { CarouselProps } from "./carousel.types";

import styles from "./carousel.module.scss";

export const CarouselComponent: React.FC<CarouselProps> = ({
  children,
  arrowsHidden,
  customResponsive,
  noOverflow,
}) => {
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={customResponsive || responsive}
      swipeable
      rewind
      focusOnSelect
      infinite
      partialVisible
      renderDotsOutside
      autoPlay
      draggable
      containerClass={noOverflow ? styles.carouselContainerNoOverflow : styles.carouselContainer}
      sliderClass={styles.carousel}
      itemClass={styles.item}
      arrows={!arrowsHidden}
    >
      {children}
    </Carousel>
  );
};
