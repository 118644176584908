import React, { Context, useContext } from "react";
import { Button } from "antd";
import { useSnackbar } from "notistack";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useTranslation } from "react-i18next";

import { Details } from "../components/details/details";
import { Slots } from "../components/slots/slots";
import { SlotsViewContextType } from "../wrapper/slots-view.context";
// import { ContactPerson } from "../components/contact-person/contact-person";
// import { GuestsDetails } from "../components/quests-details/guests-details";
import { Summary } from "../components/summary/summary";
// import { FormValues } from "../components/contact-person/contact-person.types";
// import { GuestData } from "../components/quests-details/guests-details.types";
import { ReservationsContext } from "../reservations-modal.context";
import { getViewContext } from "../wrapper/wrapper.constants";
import { postReservation } from "../../../server";

import styles from "../calendar-view /calendar-view.module.scss";

export const SlotsView = () => {
  // const contactPersonRef = useRef<FormikProps<FormValues>>(null);
  // const guestsDetailsRef = useRef<FormikProps<GuestData>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { isOwnReservation, isCalendarView, offer, modalClose } = useContext(ReservationsContext);

  const {
    // setFormData,
    handleNextStep,
    step,
    selectedSlots,
    // setGuestsData,
    guests,
    handlePrevStep,
    // guestsData,
    serviceSelectedWorker,
    selectedServices,
  } = useContext(getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>);

  const prevButtonDisabled = step === 1;
  const isNextButtonDisabled = step === 2 && selectedSlots.length === 0;

  const handleNextButtonClick = () => {
    handleNextStep();
  };

  // const handleFormSubmissionSuccessContactPerson = (values: FormValues) => {
  //   if (step === 3) {
  //     setFormData(values);
  //     handleNextStep();
  //   }
  // };

  // const handleFormSubmissionGuestsDetails = (values: GuestData, currentGuestIndex: number) => {
  //   if (step === 4) {
  //     const updatedValues: GuestData = {
  //       ...values,
  //     };
  //
  //     const updatedGuests = [...guestsData];
  //     updatedGuests[currentGuestIndex] = updatedValues;
  //     setGuestsData(updatedGuests);
  //     handleNextStep();
  //   }
  // };

  const { submit, submitting, onSubmitSuccess } = useSubmit(postReservation);

  const handleReservation = async () => {
    const services: {
      id: number;
      user_id?: number;
    }[] = [];

    serviceSelectedWorker.forEach((worker) => {
      return services.push({
        id: worker.service_id,
        user_id: worker.id,
      });
    });

    selectedServices.forEach((service) => {
      if (service.users.length === 0) {
        return services.push({
          id: service.id,
        });
      }
    });

    const data = {
      slots: selectedSlots.map((slot) => {
        return {
          dateTimeFrom: slot.dateTimeFrom,
          dateTimeTo: slot.dateTimeTo,
        };
      }),
      offer: offer?.id,
      num_of_guests: guests,
      services,
    };

    await submit({ data });
  };

  onSubmitSuccess(() => {
    modalClose();
    enqueueSnackbar(t("reservationsModal.reservationSent"), { variant: "success" });
  });

  return (
    <div className={styles.container}>
      {step === 1 && <Details />}

      {step === 2 && <Slots />}

      {/*{step === 3 && (*/}
      {/*  <ContactPerson ref={contactPersonRef} onFormSubmissionSuccess={handleFormSubmissionSuccessContactPerson} />*/}
      {/*)}*/}

      {/*{step === 4 && (*/}
      {/*  <GuestsDetails ref={guestsDetailsRef} onFormSubmissionSuccess={handleFormSubmissionGuestsDetails} />*/}
      {/*)}*/}

      {step === 3 && <Summary />}

      <div className={styles.buttons}>
        <Button onClick={handlePrevStep} className={styles.button} disabled={prevButtonDisabled}>
          {"<"}
        </Button>
        {step !== 3 && (
          <Button
            htmlType="button"
            className={styles.button}
            disabled={isNextButtonDisabled}
            onClick={handleNextButtonClick}
          >
            {">"}
          </Button>
        )}

        {step === 3 && (
          <Button className={styles.button} disabled={submitting} onClick={handleReservation}>
            {t("reservationsModal.book")}
          </Button>
        )}
      </div>

      {/*{step !== 5 && (*/}
      {/*  <div className={styles.price}>*/}
      {/*    <p>*/}
      {/*      Total price: <span>{totalPrice}</span>*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*)}*/}

      {/*{step !== 6 && (*/}
      {/*  <div className={styles.price}>*/}
      {/*    <p>*/}
      {/*      Total price: <span>{totalPrice}</span>*/}
      {/*    </p>*/}
      {/*    <Button onClick={handleReservation}>SEND</Button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
