import { Context, useContext } from "react";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

import { getViewContext } from "../../../wrapper/wrapper.constants";
import { SlotsViewContextType } from "../../../wrapper/slots-view.context";
import { ReservationsContext } from "../../../reservations-modal.context";

import styles from "./selected-slots.module.scss";

export const SelectedSlots = () => {
  const { t } = useTranslation();
  const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);
  const { selectedSlots } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>,
  );

  const sortedSlots = selectedSlots.sort((a, b) => moment(a.dateTimeFrom).diff(moment(b.dateTimeFrom)));
  return (
    <div className={styles.container}>
      <p className={styles.selectedSlots}>{t("reservationsModal.summary.selectedSlots")}</p>
      <div className={styles.content}>
        {sortedSlots.map((slot) => {
          const dateTimeFrom = moment.utc(slot.dateTimeFrom).format("HH:mm");
          const dateTimeTo = moment.utc(slot.dateTimeTo).format("HH:mm");

          return (
            <div className={styles.slot} key={slot.dateTimeFrom}>
              <span>{moment(slot.dateTimeFrom).format("DD.MM YYYY")}</span>
              <p>{`${dateTimeFrom} - ${dateTimeTo}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
