import React from "react";
import { IconButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Field, FieldArray, Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { ModalProps } from "../base-info.types";
import { OfferData } from "../../create-offer.types";

import styles from "./included-modal.module.scss";

export const IncludedModal: React.FC<ModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<OfferData>();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.modalName}>
          {t("createOffer.baseInfo.modal.included.addWhatsIncluded")}
        </Typography>
        <div className={styles.headerButtons}>
          <IconButton onClick={close} className={styles.iconButton}>
            <CheckIcon />
          </IconButton>
          <IconButton className={styles.iconButton} onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <Form className={styles.contentWrapper}>
        <FieldArray
          name="baseInfo.included.included"
          render={(arrayHelpers) => {
            return (
              <>
                <div className={styles.contentHeader}>
                  <Typography className={styles.contentName}>
                    {t("createOffer.baseInfo.modal.included.whatsIncluded")}
                  </Typography>
                  <IconButton className={styles.addButton} type="button" onClick={() => arrayHelpers.push("")}>
                    +
                  </IconButton>
                </div>
                <div className={styles.wrapper}>
                  {values.baseInfo.included.included.length === 0 && (
                    <Typography className={styles.addFirstItem}>
                      {t("createOffer.baseInfo.modal.included.addFirstItem")}
                    </Typography>
                  )}

                  {values.baseInfo.included.included.length !== 0 &&
                    values.baseInfo.included.included.map((includedItem, index) => {
                      return (
                        <div className={styles.content}>
                          <Field className={styles.input} name={`baseInfo.included.included[${index}]`} />
                          <IconButton
                            className={styles.iconButton}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            -
                          </IconButton>
                        </div>
                      );
                    })}
                </div>
              </>
            );
          }}
        />

        <FieldArray
          name="baseInfo.included.notIncluded"
          render={(arrayHelpers) => {
            return (
              <>
                <div className={styles.contentHeader}>
                  <Typography className={styles.contentName}>
                    {t("createOffer.baseInfo.modal.included.whatsNotIncluded")}
                  </Typography>
                  <IconButton className={styles.addButton} type="button" onClick={() => arrayHelpers.push("")}>
                    +
                  </IconButton>
                </div>
                <div className={styles.wrapper}>
                  {values.baseInfo.included.notIncluded.length === 0 && (
                    <Typography className={styles.addFirstItem}>
                      {t("createOffer.baseInfo.modal.included.addFirstItem")}
                    </Typography>
                  )}
                  {values.baseInfo.included.notIncluded.length !== 0 &&
                    values.baseInfo.included.notIncluded.map((notIncludedItem, index) => {
                      return (
                        <div className={styles.content}>
                          <Field className={styles.input} name={`baseInfo.included.notIncluded[${index}]`} />
                          <IconButton
                            className={styles.iconButton}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            -
                          </IconButton>
                        </div>
                      );
                    })}
                </div>
              </>
            );
          }}
        />
      </Form>
    </div>
  );
};
