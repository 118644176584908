import React from "react";
import { Rating, Typography } from "@mui/material";
import classNames from "classnames";

import { RevieBoxProps } from "./review-box.types";

import styles from "./review-box.module.scss";

export const ReviewBox: React.FC<RevieBoxProps> = ({ className, content, guest, name }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Typography fontSize="16px" fontFamily="Chillax-Regular">
            <span>{name}</span> {guest}
          </Typography>
          <Typography fontWeight={300} fontSize="16px" fontFamily="Chillax-regular">
            {content}
          </Typography>
          <Rating name="size-small" disabled defaultValue={5} size="large" className={styles.rating} />
        </div>
      </div>
    </div>
  );
};
