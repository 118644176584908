import React, { useRef } from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import gsap from "gsap";

import { useWindowSize } from "hooks";

import gallery1 from "assets/images/gallery1.webp";
import gallery2 from "assets/images/gallery2.webp";
import gallery3 from "assets/images/gallery3.webp";
import gallery4 from "assets/images/gallery4.webp";
import gallery5 from "assets/images/gallery5.webp";
import gallery6 from "assets/images/gallery6.webp";
import gallery7 from "assets/images/gallery7.webp";
import gallery8 from "assets/images/gallery8.webp";
import gallery9 from "assets/images/gallery9.webp";
import gallery10 from "assets/images/gallery10.webp";
import gallery11 from "assets/images/gallery11.webp";
import gallery12 from "assets/images/gallery12.webp";
import gallery13 from "assets/images/gallery13.webp";
import gallery14 from "assets/images/gallery14.webp";
import gallery15 from "assets/images/gallery15.webp";
import gallery16 from "assets/images/gallery16.webp";

import styles from "./gallery.module.scss";

export const Gallery = () => {
  const galleryRef = useRef<null | HTMLDivElement>(null);

  const { width, height } = useWindowSize();

  const isMobile = width < 950;
  const isLandscape = height < width;
  const showMobileLandscape = isLandscape && isMobile;

  const gridColumns = showMobileLandscape ? 8 : 4;

  useDidUpdate(
    () => {
      const galleryHeight = galleryRef.current?.offsetHeight || 700;

      if (galleryRef.current && galleryRef) {
        gsap.fromTo(
          galleryRef.current,
          { y: 0 },
          {
            y: -galleryHeight,
            scrollTrigger: {
              trigger: galleryRef.current,
              start: "top bottom",
              scrub: 2,
            },
          },
        );
      }
    },
    [],
    true,
  );

  return (
    <div className={styles.container}>
      <div style={{ height: "150px", overflow: "hidden" }} className={styles.waveTop}>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%" }}>
          <path
            d="M0.00,49.98 C225.73,218.59 282.16,-90.27 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "#F8EDE5" }}
          />
        </svg>
      </div>

      <div className={styles.gallery} ref={galleryRef}>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-26%)" : "translateX(40px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery1} alt="gallery" />
          <img src={gallery2} alt="gallery" />
          <img src={gallery3} alt="gallery" />
          <img src={gallery4} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery4} alt="gallery" />
              <img src={gallery3} alt="gallery" />
              <img src={gallery2} alt="gallery" />
              <img src={gallery1} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-25%)" : "translateX(20px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery5} alt="gallery" />
          <img src={gallery6} alt="gallery" />
          <img src={gallery7} alt="gallery" />
          <img src={gallery8} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery8} alt="gallery" />
              <img src={gallery7} alt="gallery" />
              <img src={gallery6} alt="gallery" />
              <img src={gallery5} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-24%)" : "translateX(-20px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery9} alt="gallery" />
          <img src={gallery10} alt="gallery" />
          <img src={gallery11} alt="gallery" />
          <img src={gallery12} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery12} alt="gallery" />
              <img src={gallery11} alt="gallery" />
              <img src={gallery10} alt="gallery" />
              <img src={gallery9} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-23%)" : "translateX(-40px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery13} alt="gallery" />
          <img src={gallery14} alt="gallery" />
          <img src={gallery15} alt="gallery" />
          <img src={gallery16} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery16} alt="gallery" />
              <img src={gallery15} alt="gallery" />
              <img src={gallery14} alt="gallery" />
              <img src={gallery13} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-22%)" : "translateX(40px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery1} alt="gallery" />
          <img src={gallery2} alt="gallery" />
          <img src={gallery3} alt="gallery" />
          <img src={gallery4} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery4} alt="gallery" />
              <img src={gallery3} alt="gallery" />
              <img src={gallery2} alt="gallery" />
              <img src={gallery1} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-21%)" : "translateX(20px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery5} alt="gallery" />
          <img src={gallery6} alt="gallery" />
          <img src={gallery7} alt="gallery" />
          <img src={gallery8} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery8} alt="gallery" />
              <img src={gallery7} alt="gallery" />
              <img src={gallery6} alt="gallery" />
              <img src={gallery5} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-20%)" : "translateX(-20px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery9} alt="gallery" />
          <img src={gallery10} alt="gallery" />
          <img src={gallery11} alt="gallery" />
          <img src={gallery12} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery12} alt="gallery" />
              <img src={gallery11} alt="gallery" />
              <img src={gallery10} alt="gallery" />
              <img src={gallery9} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-19%)" : "translateX(-40px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery13} alt="gallery" />
          <img src={gallery14} alt="gallery" />
          <img src={gallery15} alt="gallery" />
          <img src={gallery16} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery16} alt="gallery" />
              <img src={gallery15} alt="gallery" />
              <img src={gallery14} alt="gallery" />
              <img src={gallery13} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-18%)" : "translateX(-40px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery13} alt="gallery" />
          <img src={gallery14} alt="gallery" />
          <img src={gallery15} alt="gallery" />
          <img src={gallery16} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery16} alt="gallery" />
              <img src={gallery15} alt="gallery" />
              <img src={gallery14} alt="gallery" />
              <img src={gallery13} alt="gallery" />
            </>
          )}
        </div>
        <div
          className={styles.imagesWrapper}
          style={{
            transform: isMobile ? "translateX(-17%)" : "translateX(-40px)",
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          <img src={gallery13} alt="gallery" />
          <img src={gallery14} alt="gallery" />
          <img src={gallery15} alt="gallery" />
          <img src={gallery16} alt="gallery" />
          {showMobileLandscape && (
            <>
              <img src={gallery16} alt="gallery" />
              <img src={gallery15} alt="gallery" />
              <img src={gallery14} alt="gallery" />
              <img src={gallery13} alt="gallery" />
            </>
          )}
        </div>
      </div>

      <div style={{ height: "150px", overflow: "hidden" }} className={styles.waveBottom}>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%" }}>
          <path
            d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: "none", fill: "#F8EDE5" }}
          />
        </svg>
      </div>
    </div>
  );
};
