import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";
import ReactMarkdown from "react-markdown";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { ArticleParams } from "./article.types";
import { getArticleBySlug, getArticles, getAuthor } from "server";
import { StrapiArticleModel, StrapiAuthorModel } from "models";
import { useShowContent } from "hooks";
import { Header } from "./header/header";
import { Section } from "./section/section";
import { MoreArticles } from "./more-articles/more-articles";
import { Loader } from "components";

import styles from "./article.module.scss";

export const ArticlePage = () => {
  const { slug } = useParams<ArticleParams>();

  const [article, setArticle] = useState<null | StrapiArticleModel>(null);
  const [articles, setArticles] = useState<StrapiArticleModel[]>([]);
  const [author, setAuthor] = useState<null | StrapiAuthorModel>(null);

  const articleData = useFetch(
    getArticleBySlug.setQueryParams({
      populate: "*",
      "filters[slug]": slug || "",
    }),
  );
  const { showContent, showLoader } = useShowContent(articleData);
  const { onSuccess } = articleData;
  onSuccess((response) => {
    setArticle(response.response.data[0]);
  });

  const authorData = useSubmit(
    getAuthor.setParams({ authorId: article?.attributes.author.data.id || 0 }).setQueryParams({
      populate: "*",
      fields: "*",
    }),
  );
  const { onSubmitSuccess, submit, submitting } = authorData;
  onSubmitSuccess((response) => {
    setAuthor(response.response.data);
  });

  const articlesData = useFetch(getArticles.setQueryParams({ populate: "*" }));
  const { onSuccess: onSuccessArticles } = articlesData;
  onSuccessArticles((response) => {
    setArticles(response.response.data.filter((articleItem) => articleItem.id >= 2 && articleItem.id <= 4));
  });

  const transformImageUrl = (src: string) => src;

  useDidUpdate(
    () => {
      if (showContent || showLoader) window.scrollTo({ top: 0, left: 0 });
    },
    [showContent, slug, article, showLoader],
    true,
  );

  useDidUpdate(() => {
    if (article) {
      submit().then();
    }
  }, [article]);

  return (
    <div className={styles.container}>
      {showLoader && submitting && <Loader className={styles.loader} />}
      {showContent && !submitting && article && author && (
        <>
          <Header article={article} author={author} />

          <div className={styles.content}>
            <div className={styles.wrapper}>
              <div className={styles.article}>
                <ReactMarkdown transformImageUri={transformImageUrl} className={styles.text}>
                  {article.attributes.text}
                </ReactMarkdown>
              </div>

              <Section articles={articles} article={article} />
            </div>

            <MoreArticles articles={articles} />
          </div>
        </>
      )}
    </div>
  );
};
