import React, { useContext, useState } from "react";
import { IconButton, ListItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { RootState } from "store";
import { offerCategories } from "./category-select.constants";
import { WatersportsData } from "server";
import { getWatersportTranslation } from "utils/watersports.utils";
import { OfferData, SportType } from "../create-offer.types";
import { CreateOfferContext } from "../create-offer.context";

import styles from "./category-select.module.scss";

export const CategorySelect = () => {
  const { t } = useTranslation();
  const { watersports } = useSelector((state: RootState) => state.watersports);
  const { setFieldValue, values } = useFormikContext<OfferData>();
  const { setSportType, setSportTypeId } = useContext(CreateOfferContext);

  const categoriesData = offerCategories(
    watersports.filter((item) => item.name !== "YACHT"),
    t,
  );

  const initialCategory = categoriesData.find((item) => item.value === values.categorySelect.category);
  const [category, setCategory] = useState<{ label: string; value: string; icon: string; options: WatersportsData[] }>(
    initialCategory || categoriesData[0],
  );

  const handleCategory = (categoryItem: { label: string; value: string; icon: string; options: WatersportsData[] }) => {
    setCategory(categoryItem);
    setFieldValue("categorySelect.category", categoryItem.value);
    setFieldValue("categorySelect.sportType", null);
  };

  const handleSportType = (sport: SportType, id: number) => {
    setFieldValue("categorySelect.sportType", sport);
    setSportTypeId(id);
    setSportType(sport);
  };

  useDidUpdate(
    () => {
      handleSportType(category.options[0].name, category.options[0].id);
    },
    [category.options],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <Typography className={styles.sectionName}>{t("createOffer.categorySelection.chooseCategory")}</Typography>
        <ul className={styles.itemsWrapper}>
          {categoriesData.map((categoryItem) => {
            const isActive = category.value === categoryItem.value;

            return (
              <ListItem className={styles.categoryItem} onClick={() => handleCategory(categoryItem)}>
                <IconButton className={classNames(styles.categoryButton, { [styles.categoryButtonActive]: isActive })}>
                  <img src={categoryItem.icon} alt={categoryItem.label} className={styles.icon} />
                </IconButton>
                <Typography className={styles.categoryName}>{categoryItem.label}</Typography>
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className={styles.contentWrapper}>
        <Typography className={styles.sectionName}>{t("createOffer.categorySelection.chooseWatersport")}</Typography>
        <ul className={styles.optionsWrapper}>
          {category.options.map((optionItem) => {
            const isActive = values.categorySelect.sportType === optionItem.name;

            return (
              <ListItem className={styles.optionItem} onClick={() => handleSportType(optionItem.name, optionItem.id)}>
                <IconButton className={classNames(styles.optionButton, { [styles.optionButtonActive]: isActive })}>
                  <img src={optionItem.thumbnail_url} alt={optionItem.name} className={styles.icon} />
                </IconButton>

                <Typography className={styles.optionName}>{getWatersportTranslation(t, optionItem.name)}</Typography>
              </ListItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
