import React, { Context, useContext, useState, useRef } from "react";
import classNames from "classnames";
import { Button } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { SlotsViewContextType } from "../../../wrapper/slots-view.context";
import { ServiceProps } from "./service.types";
import { getViewContext } from "../../../wrapper/wrapper.constants";
import { ReservationsContext } from "../../../reservations-modal.context";

import styles from "./service.module.scss";

export const Service: React.FC<ServiceProps> = ({ service }) => {
  const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);

  const { setSelectedServices, selectedServices, setServiceSelectedWorker, serviceSelectedWorker } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>,
  );

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const isServiceSelected = selectedServices.some((selectedService) => selectedService.id === service.id);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleService = () => {
    if (isServiceSelected) {
      const filteredServices = selectedServices.filter((selectedService) => selectedService.id !== service.id);
      setSelectedServices(filteredServices);
    }

    if (!isServiceSelected) {
      if (service.users.length > 0) {
        const workerToAdd = { ...service?.users[0], service_id: service.id };
        setServiceSelectedWorker([...serviceSelectedWorker, workerToAdd]);
      }

      const serviceToAdd = { ...service };

      const newServices = [...selectedServices, serviceToAdd];

      setSelectedServices(newServices);
    }
  };

  const isServiceRequired = service.required;

  const handleWorkerSelect = () => {
    setIsSelectOpen((prevState) => !prevState);
  };

  const handleChangeWorker = (user: { id: number; full_name: string }) => {
    const newWorkerToAdd = {
      full_name: user.full_name,
      id: user.id,
      service_id: service.id,
    };

    const filteredServiceWorkers = serviceSelectedWorker.filter(
      (serviceWorker) => serviceWorker.service_id !== service.id,
    );

    setServiceSelectedWorker([...filteredServiceWorkers, newWorkerToAdd]);
  };

  useDidUpdate(
    () => {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsSelectOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [containerRef],
    true,
  );

  useDidUpdate(
    () => {
      if (isServiceSelected) {
        if (service.users.length === 1) {
          const serviceWorker = {
            full_name: service.users[0].full_name,
            id: service.users[0].id,
            service_id: service.id,
          };

          setServiceSelectedWorker([...serviceSelectedWorker, serviceWorker]);
        }
      }
    },

    [selectedServices],
    true,
  );

  if (isServiceSelected)
    return (
      <div className={styles.container} ref={containerRef}>
        <p>{service.title}</p>

        {!isServiceRequired && (
          <Button className={styles.button} onClick={handleService}>
            -
          </Button>
        )}

        {service.users.length > 1 && (
          <Button className={styles.detailsButton} onClick={handleWorkerSelect}>
            {isSelectOpen ? <SaveIcon className={styles.icon} /> : <EditIcon className={styles.icon} />}
          </Button>
        )}

        {isSelectOpen && (
          <>
            {/*<p>{t("reservationsModal.details.changeWorker")}</p>*/}
            <div className={styles.select}>
              {service.users.map((user) => {
                const isSelected = serviceSelectedWorker.some((selectedWorker) => selectedWorker.id === user.id);

                return (
                  <Button
                    key={user.id}
                    className={classNames(styles.selectButton, isSelected && styles.selectButtonSelected)}
                    onClick={() => handleChangeWorker(user)}
                  >
                    <p>{user.full_name || "name"}</p>
                  </Button>
                );
              })}
            </div>
          </>
        )}
      </div>
    );

  return (
    <div className={styles.container} ref={containerRef}>
      <p>{service.title}</p>

      {!service.required && (
        <Button className={styles.button} onClick={handleService}>
          +
        </Button>
      )}
    </div>
  );
};
