import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks";
import { clientBookingsData } from "../../../insights/client-bookings/client-bookings.constants";
import { Modal } from "../../../../../../components";
import { EditPackageModal } from "./edit-package-modal/edit-package-modal";

import styles from "./guest-details.module.scss";

export const GuestDetails = () => {
  const { t } = useTranslation();
  const {
    query: { id },
  } = useQueryParams();

  const [isEditPackageModalOpen, setIsEditPackageModalOpen] = useState<boolean>(false);

  const handleEditPackageModal = () => {
    setIsEditPackageModalOpen((prevState) => !prevState);
  };

  const activeGuestIndex = clientBookingsData.findIndex((item) => item.id === parseInt(String(id), 10));
  const activeGuest = clientBookingsData[activeGuestIndex];

  return (
    <div className={styles.container}>
      <div className={styles.overview}>
        <Typography className={styles.newClient}>{t("insights.clientBookings.newClient")}</Typography>
        <div>
          <Typography className={styles.name}>{activeGuest.name}</Typography>
          <div className={styles.time}>
            <Typography>
              252h
              <br /> {t("insights.clientBookings.soFar")}
            </Typography>
            <Typography>
              28h <br /> {t("insights.clientBookings.remaining")}
            </Typography>
          </div>
          <div className={styles.progress}>
            <div />
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <Typography className={styles.detailsName}>{t("insights.clientBookings.aboutClient")}</Typography>
        <div className={styles.detailsAbout}>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.telephone")}</Typography>
            <Typography>{activeGuest.details.phoneNumber}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.email")}</Typography>
            <Typography>{activeGuest.details.email}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.gender")}</Typography>
            <Typography>{activeGuest.details.gender}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.age")}</Typography>
            <Typography>{activeGuest.details.age}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.dateOfBirth")}</Typography>
            <Typography>{activeGuest.details.dateOfBirth}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.weight")}</Typography>
            <Typography>{activeGuest.details.weight}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.size")}</Typography>
            <Typography>{activeGuest.details.size}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>Level: </Typography>
            <Typography>{activeGuest.details.level}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.notes")}</Typography>
            <Typography>{activeGuest.details.notes}</Typography>
          </div>
        </div>
      </div>
      <div className={styles.currentPackage}>
        <div className={styles.currentPackageLeft}>
          <Typography className={styles.detailsName}>{t("insights.guestsDetails.currentPackage")}</Typography>
          <Button onClick={handleEditPackageModal} className={styles.editButton}>
            {t("insights.guestsDetails.editPackage")}
          </Button>
        </div>
        <div>
          <div className={styles.time}>
            <Typography>
              252h
              <br /> {t("insights.clientBookings.soFar")}
            </Typography>
            <Typography>
              28h <br /> {t("insights.clientBookings.remaining")}
            </Typography>
          </div>
          <div className={styles.progress}>
            <div />
          </div>
        </div>
      </div>

      <div className={styles.lastBookings}>
        <Typography className={styles.detailsName}>{t("insights.guestsDetails.lastBookings")}</Typography>
        <div className={styles.detailsAbout}>
          <div className={styles.bookingWrapper}>
            <Typography>21/05/2023 - 25/05/2023</Typography>
            <Typography className={styles.bold}>Windsurfing Group Lessons</Typography>
          </div>
          <div className={styles.bookingWrapper}>
            <Typography>{t("insights.guestsDetails.payment")}</Typography>
            <Typography>Bank transfer</Typography>
          </div>
          <div className={styles.bookingWrapper}>
            <Typography>{t("insights.guestsDetails.services")}</Typography>
            <Typography>Wetsuit, lifevest, instructor</Typography>
          </div>
          <div className={styles.bookingWrapper}>
            <Typography>{t("insights.guestsDetails.group")}</Typography>
            <Typography>Adam N., Konrad K.</Typography>
          </div>
          <div className={styles.bookingWrapper}>
            <Typography>{t("insights.guestsDetails.notes")}</Typography>
            <Typography>Add notes</Typography>
          </div>
          <div className={styles.bookingWrapper}>
            <Typography>{t("insights.guestsDetails.instructor")}</Typography>
            <Typography>Krzysztof K.</Typography>
          </div>
        </div>
      </div>

      <Modal open={isEditPackageModalOpen}>
        <EditPackageModal close={handleEditPackageModal} activeGuest={activeGuest} />
      </Modal>
    </div>
  );
};
