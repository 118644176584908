import { LatLng } from "leaflet";

import { PanelAction, PanelState } from "./offers-list.types";

export const POSITION = new LatLng(53.770226, 20.490189);
export const POSITION_MULTIPLIER_MIN = 0.001;
export const POSITION_MULTIPLIER_MAX = 0.0005;

export enum PanelActionKind {
  OPEN_MAP = "openMap",
  OPEN_OFFERS = "openOffers",
  OPEN_WEATHER = "openWeather",
}

export const panelInitialState: PanelState = {
  offers: true,
  map: false,
  weather: false,
};

export const WINDY_URL =
  "https://embed.windy.com/embed2.html?lat=52.248&lon=21.003&detailLat=52.248&detailLon=21.003&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1";

export const reducers = (state: PanelState, action: PanelAction) => {
  const { type } = action;
  switch (type) {
    case PanelActionKind.OPEN_OFFERS:
      return {
        map: false,
        weather: false,
        offers: true,
      };

    case PanelActionKind.OPEN_MAP:
      return {
        map: true,
        weather: false,
        offers: false,
      };

    case PanelActionKind.OPEN_WEATHER: {
      return {
        map: false,
        weather: true,
        offers: false,
      };
    }

    default:
      return state;
  }
};
