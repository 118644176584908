export const assets = {
  add: "Dodaj",
  remove: "Usuń",
  asset: "Sprzęt",
  quantity: "Ilość",
  addFirstAsset: "Dodaj pierwszy sprzęt",

  addAssetModal: {
    addAsset: "Dodaj sprzęt",
    send: "Wyślij",
    windsurfing: "Windsurfing",
    addDetails: "Dodaj szczegóły",
    name: "Nazwa",
    quantity: "Ilość",
    description: "Opis",
    size: "Rozmiar",
    sizeUnit: "Jednostka wielkości",
    back: "Powrót",
    add: "Dodaj",
    addedSuccesfully: "Dodano poprawnie",
    wrong: "Coś poszło nie tak",
  },

  removeAssetModal: {
    removeAsset: "Usuń sprzęt",
    remove: "Usuń",
    asset: "Sprzęt",
  },
};
