import React from "react";
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";

import { SocialIcons, SocialObject } from "./socials.types";

import { ReactComponent as TikTok } from "assets/icons/tiktok-icon.svg";

const iconStyles = {
  width: 40,
  height: 40,
};

export const socialIcons: SocialIcons = {
  facebook: <Facebook sx={iconStyles} />,
  instagram: <Instagram sx={iconStyles} />,
  linkedin: <LinkedIn sx={iconStyles} />,
  tiktok: <TikTok width={iconStyles.width} height={iconStyles.height} />,
};

export const socialMedia: SocialObject = {
  facebook: { name: "Facebook", label: "facebook", url: "https://www.facebook.com/SeaEasy-107312585347120" },
  instagram: { name: "Instagram", label: "instagram", url: "https://www.instagram.com/sea_easy/?hl=pl" },
  linkedin: { name: "LinkedIn", label: "linkedIn ", url: "https://www.linkedin.com/company/seaeasy/" },
  tiktok: { name: "TikTok", label: "TikTok", url: "https://www.tiktok.com/@sea_easy" },
};
