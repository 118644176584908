import { strapiBuilder } from "server/builder";
import { StrapiCategoryModel } from "models";
import { StrapiData } from "types";
import { CategoryQueryParams } from "./category.types";
import { ServerErrorType } from "server/server.types";

export const getCategories = strapiBuilder.createCommand<
  StrapiData<StrapiCategoryModel[]>,
  void,
  ServerErrorType,
  CategoryQueryParams
>()({
  method: "GET",
  endpoint: "/api/categories",
});

export const getCategory = strapiBuilder.createCommand<StrapiData<StrapiCategoryModel>>()({
  method: "GET",
  endpoint: "/api/categories/:categoryId",
});
