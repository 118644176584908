import { Builder } from "@better-typed/hyper-fetch";

import { ServerErrorType } from "./server.types";
import { resetUserStore, setToken, store } from "store";
import { environment } from "config/environment.config";
import { STORAGE_FIELDS } from "constants/storage.constants";
import { renewToken } from "server/auth/auth.server";
import { LOGIN_PAGE } from "../constants/routes.constants";

export const builder = new Builder<ServerErrorType>({ baseUrl: environment.apiUrl })
  .setDebug(true)
  .onRequest((command) => {
    // eslint-disable-next-line no-param-reassign
    command.headers = { ...command.headers, "x-api-key": environment.apiKey };
    return command;
  })
  .onError(async (response, command) => {
    const status = response[2];
    const refreshToken = localStorage.getItem(STORAGE_FIELDS.refreshToken);
    const isLoginEndpoint = command.endpoint.includes("login");

    if (!isLoginEndpoint && !command.used && refreshToken && status === 403) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [data, , responseStatus] = await renewToken.setData({ refresh: refreshToken }).exec();
      if (data?.access) {
        store.dispatch(setToken(data.access));
        localStorage.setItem(STORAGE_FIELDS.token, data.access);
        return command.setUsed(true).send();
      }

      if (typeof responseStatus === "number" && responseStatus === 401 && !data?.access) {
        store.dispatch(resetUserStore());
        localStorage.removeItem(STORAGE_FIELDS.token);
        localStorage.removeItem(STORAGE_FIELDS.refreshToken);
        window.open(LOGIN_PAGE.path.en, "_self");
        return command.setUsed(true).send();
      }
    }

    return response;
  })
  .setQueryParamsConfig({
    arrayFormat: "comma",
    skipEmptyString: true,
    skipNull: true,
  })
  .onAuth((command) => {
    const state = store.getState();
    const authToken = state.auth.token;

    if (authToken) {
      return command.setHeaders({
        ...command.headers,
        Authorization: `Bearer ${authToken}`,
      });
    }

    return command;
  });

export const strapiBuilder = new Builder<ServerErrorType>({ baseUrl: environment.strapiUrl });
