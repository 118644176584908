export const home = {
  searchBar: {
    destination: "Destination",
    whereGo: "Where do you want to go?",
    watersport: "Watersport",
    type: "Watersport type",

    // booking manager
    bookEquipment: "Book water sports equipment",
  },

  baner: {
    rentals: "top water sports",
    waterSport: "trips, schools & rentals",
    copy2: "book your wave time!",
    button: "Book!",
    bookWave: "book your wave time!",
    customers: "customers",
    served: "served",
    surf: "surf",
    vibes: "vibes",
    positive: "positive",
    feedback: "feedback",
  },

  partners: "Our partners",
  ourProposition: "Our proposition",
  seaTalk: "Sea talk",

  destination: {
    topDestination: "TOP DESTINATIONS",
    content: "Check out the most loved marinas, lakes, surfing spots and many more",

    thailand: "Thailand",
    maldives: "Maldives",
    spain: "Spain",
    croatia: "Croatia",
    greece: "Greece",
  },

  offers: {
    topOffers: "TOP OFFERS",
    content: "See the offers from our best rentals & schools",
    hour: "hour",
    week: "week",
    seeMore: "See more",

    // locations

    astrea42: "Castellamare di Stabia, Italy",
    gulet: "Bodrum, Turkey",
    nautitech: "Corfu, Greece",
    bavaria: "Stockholm, Sweden",
    lagoon40: "Limassol, Cyprus",
    sessa: "Podstrana, Croatia",
    lagoon: "Martinique, Martinique",
    oceanis: "Roda de Barà, Spain",
  },

  chooseWatersport: {
    chooseWatersport: "CHOOSE YOUR WATERSPORT",
    content: "Rent all watersports equipment from sailboats to surfboards",
  },

  section: {
    areYouAnOwner: "Are you an owner of a rental place?",
    createYourOffer: "Create you offer and grow your business with us!",
    start: "Start now",
    ourHappyCustomers: "Our happy customers",

    review: {
      happyCustomer: "Our happy customers",
      review1: {
        name: "Piotr,",
        guest: " Bavaria 42, Ibiza",
        content:
          "Profesjonalne podejście, doskonała atmosfera, pełna elastyczność, troska o powierzony jacht sprawiają , że polecam Pana Tomka i jego załogę z pełnym przekonaniem.",
      },
      review2: {
        name: "Magda,",
        guest: " Bavaria 36, Malta",
        content:
          "Wszystko mega fajnie, właściciel jachtu wytłumaczył co i jak, dał mapkę gdzie są najfajniejsze miejsca oraz przekazał wszystkie potrzebne informacje. Na pewno skorzystam następnym razem kiedy będziemy na Malcie.",
      },
      review3: {
        name: "Łukasz, ",
        guest: " Oceanis 411, Majorka",
        content:
          "Miłe chwile spędzone na wodzie. Dużo nabranego doświadczenia na pewno pomoże na kolejnych rejsach. Dziękujemy za wsparcie w organizacji. Pozdrawiamy :)",
      },
    },
  },

  seaNews: {
    seaNews: "SEA NEWS",
    seeMore: "See more",
  },

  summary: {
    seaMore: "SURF MORE, WORRY LESS",
    seaMoreContent: "Best water sports opportunities you can find in the web!",
    weveGot: "WE’VE GOT YOUR BACK",
    weveGotContent: "We take care of every topic you might have without questions asked",
    price: "ALWAYS THE BEST PRICE",
    priceContent: "No excuses. No hidden fees. Always the best price!",
    reviews: "REAL REVIEWS",
    reviewsContent: "Reviews you can trust. Real reviews from real water sports lovers!",
  },

  sports: {
    windsurfing: "Windsurfing",
    kitesurfing: "Kitesurfing",
    sailing: "Sailing",
    motorboats: "Motorboats",
    kayaks: "Kayaks",
    sup: "SUP",
    diving: "Diving",
    pedalo: "Pedalo",
    surfing: "Surfing",
    wakeboard: "Wakeboard",
    other: "Other",
    rowingBoats: "Rowing boats",
  },
};
