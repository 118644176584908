export const profile = {
  navigation: {
    personalInfo: "Informacje osobiste",
    payments: "Płatności i wypłaty",
  },

  header: {
    changeProfilePicture: "Zmień zdjęcie profilowe",
  },

  personalInfo: {
    legalName: "Imię i nazwisko",
    edit: "Edytuj",
    email: "Adres email",
    phoneNumber: "Numer telefonu",
  },

  payments: {
    inProgress: "W trakcie",
    completed: "Zakończone",
  },

  info: {
    personalInfo: "Jakie informacje są udostępniane innym?",
    personalInfoContent: "SeaEasy udostępnia dane kontaktowe Właścicieli i Gości dopiero po potwierdzeniu rezerwacji",

    paymentsInfo1: "Dokonuj wszystkich płatności za pośrednictwem SeaEasy",
    paymentsInfoCopy1:
      "Zawsze płać i komunikuj się za pośrednictwem Sea Easy, aby zapewnić sobie ochronę zgodnie z naszymi Warunkami użytkowania, anulowaniem i innymi zabezpieczeniami.",

    paymentsInfo2: "Potrzebujesz pomocy?",
    paymentsInfoCopy2: "Sprawdź nasze Centrum pomocy, gdzie znajdziesz listę najczęściej zadawanych pytań.",
  },

  modal: {
    firstName: "Imię",
    lastName: "Nazwisko",
    email: "Adres email",
    phoneNumber: "Numer telefonu",
    save: "Zapisz",
  },

  schema: {
    numberRequired: "Numer jest wymagany",
    emailRequired: "Email jest wymagany",
    firstNameRequired: "Imię jest wymagane",
    lastNameRequired: "Nazwisko jest wymagane",
  },
};
