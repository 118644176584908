import React from "react";

export const BookingPage = () => {
  // const navigate = useNavigate();
  //
  // const { t } = useTranslation();
  // const { localePath } = useRoutesPath();
  // const { token } = useSelector((state: RootState) => state.auth);
  //
  // const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  //
  // const { submit, submitting, onSubmitSuccess } = useSubmit(postReservation);
  // onSubmitSuccess(({ response }) => {
  //   window.open(response.stripe_checkout_url, "_self");
  //   setIsRedirecting(true);
  // });
  //
  // const isAuthenticated = Boolean(token);
  //
  // const handleSubmitData = async () => {
  //   if (!isAuthenticated) return navigate(localePath({ route: LOGIN_PAGE }));
  //
  //   if (endDate && startDate && offer) {
  //     await submit({ data: {} });
  //   }
  // };
  //
  // useDidMount(() => {
  //   if (!offer) navigate(localePath({ route: OFFERS_PAGE }));
  // });

  return (
    // <div className={styles.container}>
    //   {offer && (
    //     <div className={styles.wrapper}>
    //       <div className={styles.header}>
    //         <IconButton sx={{ "*": { fill: "#FF8552" } }} onClick={() => navigate(-1)}>
    //           <ArrowBackIcon />
    //         </IconButton>
    //         <Typography fontFamily="Chillax-Semibold" fontSize="26px">
    //           {t("booking.confirmAndPay")}
    //         </Typography>
    //       </div>
    //
    //       <Cart />
    //
    //       <div className={styles.content}>
    //         <Typography fontFamily="Chillax-semibold" fontSize={22} sx={{ mb: -3 }}>
    //           {t("booking.yourTrip.yourTrip")}
    //         </Typography>
    //
    //         <div className={styles.separator} />
    //
    //         <Dates />
    //
    //         <div className={styles.guestsWrapper}>
    //           <Typography fontFamily="Chillax-Medium" fontSize="20px">
    //             {t("booking.guests.guests")}
    //           </Typography>
    //           <Typography fontFamily="Chillax-Regular">{guests}</Typography>
    //         </div>
    //
    //         <div className={styles.separator} />
    //
    //         <Policy />
    //
    //         <div className={styles.accept}>
    //           <Button
    //             className={styles.button}
    //             onClick={handleSubmitData}
    //             disabled={submitting || isRedirecting || !isAuthenticated}
    //           >
    //             {!isAuthenticated && t("booking.needLogIn")}
    //             {isAuthenticated && submitting && t("booking.booking")}
    //             {!submitting && isAuthenticated && t("booking.bookAndPay")}
    //           </Button>
    //           <Typography fontFamily="Chillax-Regular">{t("booking.bookingPolicy")}</Typography>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div>booking</div>
  );
};
