import React from "react";
import classNames from "classnames";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ProfileItemProps } from "../profile.types";
import { FilesDropzone } from "components";
import { tooltipConfig } from "../../create-rental.constants";

import avatarIcon from "assets/icons/avatar-rental.svg";
import { ReactComponent as TooltipIcon } from "assets/icons/tooltip-icon.svg";

import styles from "./profile-picture.module.scss";
import mainStyles from "../profile.module.scss";

export const ProfilePicture: React.FC<ProfileItemProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(className, styles.container)}>
      <FilesDropzone name="avatar" single>
        {({ files, error }) => {
          const file = files[0] ? URL.createObjectURL(files[0]) : null;
          return (
            <div className={styles.profile}>
              <Typography className={classNames(mainStyles.text, styles.text)}>
                {t("rental.profile.setYourProfilePicture")}
              </Typography>
              <div className={styles.profilePicture}>
                <img src={file || avatarIcon} alt="SeaEasy logo" className={file ? styles.avatar : styles.empty} />
              </div>
              {error && (
                <Typography fontSize={12} color="error.main">
                  {error}
                </Typography>
              )}
            </div>
          );
        }}
      </FilesDropzone>
      <Tooltip
        title={t("rental.profile.tooltip.photo")}
        enterTouchDelay={0}
        leaveDelay={3000}
        className={styles.tooltip}
        componentsProps={tooltipConfig}
      >
        <TooltipIcon />
      </Tooltip>
    </div>
  );
};
