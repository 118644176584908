import React, { useState } from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";

import { fullScreenContent, sections } from "./management.constants";
import { useQueryParams } from "hooks";
import { buttonsData } from "./insights/buttons/buttons.constants";
import { filtersData } from "./insights/filters/filters.constants";
import { getAssets, getUser, teamGet } from "server";
import { TeamGetData } from "../../../server/team/team.types";
import { RootState } from "store";
import { AssetsDataGetResponse } from "./assets/assets.types";
import { UserModel } from "../../../models";

interface Props {
  children: React.ReactNode;
}

type ManagementContextType = {
  isFullScreenSectionOpen: boolean;
  setIsFullScreenSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isNavigationOpen: boolean;
  setIsNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeSection: { name: string; value: string; component: JSX.Element };
  openTeamHours: (id: number) => void;
  openGuestsDetails: (id: number) => void;
  teamData: TeamGetData | null;
  loadingTeamData: boolean;
  refreshTeamData: () => void;
  assetsData: AssetsDataGetResponse[] | null;
  userData: UserModel | null;
  loadingAssetsData: boolean;
  refreshAssetsData: () => void;
  loadingUser: boolean;
};

export const ManagementContext = React.createContext<ManagementContextType>({
  isFullScreenSectionOpen: false,
  setIsFullScreenSectionOpen: () => {},
  isNavigationOpen: false,
  setIsNavigationOpen: () => {},
  activeSection: { value: "", name: "", component: <div /> },
  openTeamHours: () => {},
  openGuestsDetails: () => {},
  teamData: [],
  loadingTeamData: false,
  refreshTeamData: () => {},
  assetsData: [],
  loadingAssetsData: false,
  refreshAssetsData: () => {},
  userData: {} as UserModel,
  loadingUser: true,
});

export const ManagementContextProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { section } = useParams();
  const { updateQueryParams } = useQueryParams();
  const { organizationData } = useSelector((state: RootState) => state.organization);

  const [isFullScreenSectionOpen, setIsFullScreenSectionOpen] = useState<boolean>(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState(sections(t)[0]);
  const [teamDataRefresher, setTeamDataRefresher] = useState<number>(0);
  const [assetsRefresher, setAssetsRefresher] = useState<number>(0);

  const changeActiveSection = () => {
    const newSection = sections(t).find((sectionItem) => sectionItem.name === section);
    setActiveSection(newSection || sections(t)[4]);
  };

  const openTeamHours = (id: number) => {
    setIsFullScreenSectionOpen(true);
    updateQueryParams({
      id,
      content: fullScreenContent.teamHours,
      chart: buttonsData.revenue,
      filterTime: filtersData["1month"],
    });
  };

  const teamDataGet = useFetch(teamGet, { dependencies: [teamDataRefresher] });
  const { data: teamData, loading: loadingTeamData } = teamDataGet;

  const getAssetsData = useFetch(getAssets.setParams({ organizationId: organizationData?.id || 0 }), {
    dependencies: [assetsRefresher],
  });
  const { data: assetsData, loading: loadingAssetsData } = getAssetsData;

  const getUserData = useFetch(getUser);
  const { data: userData, loading: loadingUser } = getUserData;

  const refreshTeamData = () => setTeamDataRefresher((prevState) => prevState + 1);
  const refreshAssetsData = () => setAssetsRefresher((prevState) => prevState + 1);

  const openGuestsDetails = (id: number) => {
    setIsFullScreenSectionOpen(true);
    updateQueryParams({
      id,
      content: fullScreenContent.guestsDetails,
    });
  };

  // TODO section redirect refactor

  useDidUpdate(
    () => {
      changeActiveSection();
    },
    [section],
    true,
  );

  return (
    <ManagementContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setIsFullScreenSectionOpen,
        isFullScreenSectionOpen,
        setIsNavigationOpen,
        isNavigationOpen,
        activeSection,
        openTeamHours,
        openGuestsDetails,
        refreshTeamData,
        loadingTeamData,
        teamData,
        assetsData,
        refreshAssetsData,
        loadingAssetsData,
        loadingUser,
        userData,
      }}
    >
      {children}
    </ManagementContext.Provider>
  );
};
