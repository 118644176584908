import React, { useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { BackTopButton, CookieConsent, Page } from "components";
import { RouteConfig } from "types";
import { RootState } from "store";
import { LOGIN_PAGE } from "constants/routes.constants";
import { useQueryParams, useRoutesPath } from "hooks";

export const PageRoute: React.FC<RouteConfig> = ({
  component: Component,
  showNavbar,
  background,
  showFooter,
  auth,
  navbarOnContent,
  name,
  preventReload,
  seo,
  path,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const params = useParams();

  const { token } = useSelector((state: RootState) => state.auth);
  const { localePath, locale } = useRoutesPath();
  const { pathname } = useLocation();

  const [cookiesPolicyOpen, setCookiesPolicyOpen] = useState<boolean>(true);
  const [showBackToTopButton, setShowBackToTopButton] = useState<boolean>(false);

  const isAuthenticated = Boolean(token && token);

  useDidUpdate(
    () => {
      const activeParams: Record<string, unknown> = params;
      delete activeParams?.locale;

      if (locale === "pl-PL") {
        let pathPl: string = path.pl;
        pathPl = pathPl.replace(/:locale/g, "pl");

        if (Object.keys(activeParams).length > 0) {
          Object.keys(activeParams).forEach((key) => {
            const value = activeParams[key];
            pathPl = pathPl.replace(new RegExp(`:${key}`, "g"), String(value));
          });
        }

        if (queryParams.search.length > 1) return navigate(`${pathPl}${queryParams.search}`);

        return navigate(pathPl);
      }

      if (locale === "en-US") {
        let pathEn: string = path.en;
        pathEn = pathEn.replace(/:locale/g, "en");

        if (Object.keys(activeParams).length > 0) {
          Object.keys(activeParams).forEach((key) => {
            const value = activeParams[key];
            pathEn = pathEn.replace(new RegExp(`:${key}`, "g"), String(value));
          });
        }

        if (queryParams.search.length > 1) return navigate(`${pathEn}${queryParams.search}`);

        return navigate(pathEn);
      }
    },
    [locale],
    true,
  );

  useDidUpdate(
    () => {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 1000) {
          setShowBackToTopButton(true);
        } else {
          setShowBackToTopButton(false);
        }
      });
    },
    [],
    true,
  );

  const checkRoutePl = pathname === "/pl/";
  const checkRouteEn = pathname === "/en/";
  const isGreen = checkRouteEn || checkRoutePl;

  if (auth && !isAuthenticated) return <Navigate to={localePath({ route: LOGIN_PAGE })} />;

  return (
    <>
      <Page
        showNavbar={showNavbar}
        background={background}
        showFooter={showFooter}
        navbarOnContent={navbarOnContent}
        name={name}
        preventReload={preventReload || false}
        seo={seo || null}
      >
        <Component />
      </Page>
      {cookiesPolicyOpen && !isAuthenticated && <CookieConsent setIsOpen={setCookiesPolicyOpen} />}
      {showBackToTopButton && <BackTopButton isGreen={isGreen} />}
    </>
  );
};
