import React from "react";
import classNames from "classnames";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { YourBusinessItemsProps } from "../your-business.types";
import { OfferBox } from "./offer-box/offer-box";
import { NoOffers } from "./no-offers/no-offers";
import { useRoutesPath } from "hooks";
import { CREATE_OFFER_PAGE } from "constants/routes.constants";

import { ReactComponent as AddIcon } from "assets/icons/add-icon.svg";

import styles from "./offers.module.scss";

export const Offers: React.FC<YourBusinessItemsProps> = ({ className, userOffers }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const handleOpenAddOffer = () => {
    navigate(localePath({ route: CREATE_OFFER_PAGE }));
  };

  const noOffers = userOffers?.length === 0;
  const showUserOffers = !noOffers;

  return (
    <div className={classNames(className, styles.container)}>
      <Typography className={styles.title}>{t("management.yourBusiness.offers.rentalOffers")}</Typography>
      <div className={styles.wrapper}>
        {noOffers && <NoOffers />}
        {showUserOffers && (
          <div className={styles.offersWrapper}>
            <IconButton onClick={handleOpenAddOffer} className={styles.button}>
              <AddIcon />
            </IconButton>
            {userOffers?.map((offer) => (
              <OfferBox offer={offer} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
