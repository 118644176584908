import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { DestinationBox } from "./destination-box/destination-box";
import { StrapiTopDestinationsModels } from "models";
import { getTopDestinations } from "server";
import { useShowContent, useWindowSize } from "hooks";
import { CarouselComponent, Loader } from "components";
import { DestinationsProps } from "./destinations.types";

import "react-multi-carousel/lib/styles.css";

import styles from "./destinations.module.scss";
import stylesMain from "../home.module.scss";

export const Destinations: React.FC<DestinationsProps> = ({ refElement }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [topDestinations, setTopDestinations] = useState<null | StrapiTopDestinationsModels[]>(null);

  const topOffersData = useFetch(getTopDestinations.setQueryParams({ populate: "*" }));
  const { showContent, showLoader } = useShowContent(topOffersData);
  const { onSuccess } = topOffersData;
  onSuccess((response) => {
    setTopDestinations(response.response.data);
  });

  const isDesktop = width > 992;

  return (
    <div className={stylesMain.itemContainer} ref={refElement}>
      <div className={stylesMain.itemHeader}>
        <Typography variant="h2" className={stylesMain.itemTitle}>
          {t("home.destination.topDestination")}
        </Typography>
        <Typography variant="h3" className={stylesMain.itemSubtitle}>
          {t("home.destination.content")}
        </Typography>
      </div>

      <div className={styles.container}>
        {showLoader && <Loader />}
        {showContent && !isDesktop && topDestinations && (
          <CarouselComponent arrowsHidden>
            {topDestinations.map((destination) => (
              <DestinationBox destination={destination} />
            ))}
          </CarouselComponent>
        )}
        {showContent &&
          topDestinations &&
          isDesktop &&
          topDestinations.map((destination) => <DestinationBox destination={destination} />)}
      </div>
    </div>
  );
};
