export const home = {
  searchBar: {
    destination: "Miejsce",
    whereGo: "Skąd chcesz wypłynąć?",
    watersport: "Typ sprzętu",
    type: "Dodaj typ sprzętu",

    // booking manager
    bookEquipment: "Zarezerwuj sprzęt wodny",
  },

  baner: {
    rentals: "wyjazdy, szkoły i wypożyczalnie",
    waterSport: "sprzętu wodnego",
    copy2: "zarezerwuj czas na fali!",
    button: "Zarezerwuj!",
    bookWave: "złap czas na fali!",
    customers: "obsłużonych",
    served: "klientów",
    surf: "surferskich",
    vibes: "wibracji",
    positive: "pozytywnych",
    feedback: "opinii",
  },

  partners: "Nasi partnerzy",
  ourProposition: "Nasza propozycja",
  seaTalk: "Morska gadka",

  destination: {
    topDestination: "POLECANE KIERUNKI",
    content: "Sprawdź najciekawsze porty, jeziora, spoty do surfingu i wiele innych",

    thailand: "Tajlandia",
    maldives: "Malediwy",
    spain: "Hiszpania",
    croatia: "Chorwacja",
    greece: "Grecja",
  },

  offers: {
    topOffers: "NAJLEPSZE OFERTY",
    content: "Zobacz oferty z naszych najlepszych wypożyczalni i szkół",
    hour: "godz",
    week: "tydz",
    seeMore: "Zobacz więcej",

    // locations

    astrea42: "Castellamare di Stabia, Włochy",
    gulet: "Bodrum, Turcja",
    nautitech: "Corfu, Grecja",
    bavaria: "Sztokholm, Szwecja",
    lagoon40: "Limassol, Cypr",
    sessa: "Podstrana, Chorwacja",
    lagoon: "Martynika, Martynika",
    oceanis: "Roda de Barà, Hiszpania",
  },

  chooseWatersport: {
    chooseWatersport: "WYBIERZ SWÓJ SPORT WODNY",
    content: "Wynajmuj każdy rodzaj sprzętu wodnego od łodzi po deski do surfingu",
  },

  section: {
    areYouAnOwner: "Jesteś właścicielem wypożyczalni lub szkoły?",
    createYourOffer: "Dodaj swoją ofertę i rozwijaj swój biznes z nami!",
    start: "Dodaj ofertę",
    ourHappyCustomers: "Nasi zadowoleni klienci",

    review: {
      happyCustomer: "OPINIE KLIENTÓW",
      review1: {
        name: "Piotr,",
        guest: "Bavaria 42, Ibiza",
        content:
          "Profesjonalne podejście, doskonała atmosfera, pełna elastyczność, troska o powierzony jacht sprawiają , że polecam Pana Tomka i jego załogę z pełnym przekonaniem.",
      },
      review2: {
        name: "Magda,",
        guest: " Bavaria 36, Malta",
        content:
          "Wszystko mega fajnie, właściciel jachtu wytłumaczył co i jak, dał mapkę gdzie są najfajniejsze miejsca oraz przekazał wszystkie potrzebne informacje. Na pewno skorzystam następnym razem kiedy będziemy na Malcie.",
      },
      review3: {
        name: "Łukasz, ",
        guest: " Oceanis 411, Majorka",
        content:
          "Miłe chwile spędzone na wodzie. Dużo nabranego doświadczenia na pewno pomoże na kolejnych rejsach. Dziękujemy za wsparcie w organizacji. Pozdrawiamy :)",
      },
    },
  },

  seaNews: {
    seaNews: "MORSKIE OPOWIEŚCI",
    seeMore: "Zobacz więcej",
  },

  summary: {
    seaMore: `WIĘCEJ FAL, MNIEJ ZMARTWIEŃ`,
    seaMoreContent: "Darmowa rezerwacja najlepszych ofert sportów wodnych!",
    weveGot: `DOSKONAŁA OBSŁUGA`,
    weveGotContent: "Zajmiemy się każdym tematem jaki może się pojawić 7 dni w tygodniu!",
    price: "CENY NIE DO POBICIA",
    priceContent: "Na każdą kieszeń. Bez ukrytych opłat. Bez wymówek!",
    reviews: "ZWERYFIKOWANE OCENY",
    reviewsContent: "Oceny od prawdziwych klientów, którym możesz zaufać!",
  },

  sports: {
    windsurfing: "Windsurfing",
    kitesurfing: "Kitesurfing",
    sailing: "Żaglówka",
    motorboats: "Motorówka",
    kayaks: "Kajaki",
    sup: "SUP",
    diving: "Nurkowanie",
    pedalo: "Rower wodny",
    surfing: "Surfing",
    wakeboard: "Wakeboarding", // updated
    other: "Pozostałe",
    rowingBoats: "Łódź wiosłowa",
  },
};
