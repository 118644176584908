import React from "react";
import classNames from "classnames";
import { Box, Modal as ModalMUI, ModalProps } from "@mui/material";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useDispatch } from "react-redux";

import { backdropProps } from "./modal.constants";
import { setIsModalOpen } from "store";

import styles from "./modal.module.scss";

export const Modal: React.FC<ModalProps> = ({ open, children, className, onClose }) => {
  const dispatch = useDispatch();

  useDidUpdate(
    () => {
      if (open) dispatch(setIsModalOpen(true));

      return () => dispatch(setIsModalOpen(false));
    },
    [open],
    true,
  );

  return (
    <ModalMUI open={open} BackdropProps={backdropProps} className={styles.wrapper} onClose={onClose}>
      <Box className={classNames(styles.container, className)}>{children}</Box>
    </ModalMUI>
  );
};
