import { array, mixed, number, object, string } from "yup";

import { pricePerOptions } from "../create-offer.constants";

export const baseInfoSchema = () =>
  object().shape({
    baseInfo: object().shape({
      pricing: object().shape({
        basePrice: number().required().min(1),
        rateType: mixed().oneOf(pricePerOptions),
        maxGuests: number().required().min(1),
        deposit: number().required(),
        currency: string().required(),
      }),
      availability: object().shape({
        months: array().of(string()),
        days: array().of(string()),
        startTime: string(),
        endTime: string(),
        start: string(),
        end: string(),
      }),
      // included: object().shape({
      //   included: string(),
      //   notIncluded: string(),
      // }),
    }),
  });
