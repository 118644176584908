import React, { useContext, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { LatLng, LeafletMouseEvent } from "leaflet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useSnackbar } from "notistack";

import { OfferData } from "../create-offer.types";
import { FormSelect, FormTextField, Map } from "components";
import { inputStyles, labelProps, locationFields, select } from "./location.constants";
import { RootState } from "store";
import { COUNTRIES_EN, COUNTRIES_PL } from "constants/countries.constants";
import { CreateOfferContext } from "../create-offer.context";
import { LocationModel } from "models";

import { ReactComponent as LocationIcon } from "assets/icons/location-icon.svg";
import { ReactComponent as PinIcon } from "assets/icons/pin-purple-icon.svg";

import styles from "./location.module.scss";
import mainStyles from "../create-offer.module.scss";

const position = new LatLng(51.9189046, 19.1343786);
export const Location = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { values, setFieldValue, validateField, validateForm } = useFormikContext<OfferData>();
  const { locale } = useSelector((state: RootState) => state.app);
  const { locations, addUserLocation, setIsAddingLocation } = useContext(CreateOfferContext);

  const [openMap, setOpenMap] = useState(true);
  const [hideLocationsList, setHideLocationsList] = useState<boolean>(false);
  const [locationsOpen, setLocationsOpen] = useState<boolean>(false);

  const marker =
    values.location.locationData.latitude && values.location.locationData.longitude
      ? [new LatLng(+values.location.locationData.latitude, +values.location.locationData.longitude)]
      : [];

  const onMapClick = (event: LeafletMouseEvent) => {
    const x = event.latlng.lat.toFixed(6);
    const y = event.latlng.lng.toFixed(6);
    setFieldValue("location.locationData.latitude", x);
    setFieldValue("location.locationData.longitude", y);
    setOpenMap(true);
  };

  const toggleMap = (value: boolean) => () => {
    setOpenMap(value);
    setHideLocationsList(false);
  };

  const handleOrganization = (item: LocationModel) => {
    if (item.temporaryId) {
      setFieldValue("location.temporary_id", item.temporaryId);
      setFieldValue("location.location_id", null);
    } else {
      setFieldValue("location.location_id", item.id);
      setFieldValue("location.temporary_id", null);
    }
  };

  const handleCreateNewLocation = () => {
    validateForm(values).then((value) => {
      if (value.location?.locationData) return;

      const data = values.location?.locationData;

      if (data) {
        if (data?.description?.length === 0) {
          delete data.description;
        }

        if (data?.region?.length === 0) {
          delete data.region;
        }

        const locationData: LocationModel = {
          ...data,
          id: null,
          temporaryId: uuid(),
        } as LocationModel;

        addUserLocation(locationData);

        enqueueSnackbar(t("createOffer.location.createdSuccessfully"), { variant: "success" });
        setFieldValue("location.location_id", null);
        setFieldValue("location.temporary_id", locationData.temporaryId);
        setFieldValue("location.locationData", {});
        setHideLocationsList(false);
        setLocationsOpen(false);
      }
    });
  };

  const handleNewLocation = () => {
    setHideLocationsList(true);
    setFieldValue("location.location_id", null);
    setFieldValue("location.temporary_id", null);
    setIsAddingLocation(true);
  };

  const handleLocationsList = () => {
    setLocationsOpen((prevState) => !prevState);
  };

  const showUserLocations = locations.length !== 0 && !hideLocationsList;
  const showContent = locations.length || 1 > 0;
  const showForm = values.location.locationData.latitude && values.location.locationData.longitude && hideLocationsList;
  const showMap = showForm && openMap;

  useDidMount(() => {
    setIsAddingLocation(true);
  });

  useDidUpdate(() => {
    if (values.location.location_id || values.location.temporary_id) setIsAddingLocation(false);
  }, [values.location.location_id, values.location.temporary_id]);

  useDidMount(() => {
    if (values.location.location_id || values.location.temporary_id) return setIsAddingLocation(false);

    setIsAddingLocation(true);
  });

  useDidUpdate(() => {
    if (values.location.location_id || values.location.temporary_id) setIsAddingLocation(false);
  }, [values.location.location_id, values.location.temporary_id]);

  useDidUpdate(() => {
    if (hideLocationsList) validateField("location.locationData");
  }, [hideLocationsList]);

  return (
    <div className={styles.container}>
      {showContent && (
        <>
          <div className={styles.content}>
            <Typography className={mainStyles.itemTitle}>{t("createOffer.location.addAddress")}</Typography>
            {!showMap && (
              <div className={styles.mapContainer}>
                <Map markers={marker} handleClick={onMapClick} center={position} className={styles.map} />
              </div>
            )}
            {showMap && (
              <div className={styles.form}>
                <div className={styles.formHeader}>
                  <IconButton className={styles.icon} onClick={toggleMap(false)}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <Typography>{t("createOffer.location.confirmYourAddress")}</Typography>
                </div>
                <div className={styles.formContent}>
                  {locationFields(t).map((field) => (
                    <FormTextField
                      key={field.name}
                      {...field}
                      sx={inputStyles}
                      InputLabelProps={labelProps}
                      InputProps={{ disableUnderline: true }}
                      variant="standard"
                    />
                  ))}
                  <FormSelect
                    name="location.locationData.country"
                    label={t("createOffer.location.country")}
                    options={locale === "pl-PL" ? COUNTRIES_PL : COUNTRIES_EN}
                    variant="standard"
                    sx={select.styles}
                    InputLabelProps={labelProps}
                    InputProps={{ disableUnderline: true }}
                    SelectProps={select.props}
                  />
                  <Button className={styles.addNewLocationButton} onClick={handleCreateNewLocation}>
                    {t("createOffer.location.createNew")}
                  </Button>
                </div>
              </div>
            )}
          </div>

          {showUserLocations && (
            <div className={styles.locationsList}>
              <div className={styles.locationsListContent}>
                <Button onClick={handleLocationsList} className={styles.locationButton}>
                  <PinIcon />
                  <Typography>{t("createOffer.location.useSaved")}</Typography>
                </Button>

                {locationsOpen && (
                  <div className={styles.locationsListWrapper}>
                    {locations?.map((item) => {
                      const selectedLocation = item.temporaryId
                        ? item.temporaryId === values.location.temporary_id
                        : item.id === values.location.location_id;

                      return (
                        <Button
                          key={item.id || item.temporaryId}
                          className={classNames(
                            styles.locationButton,
                            selectedLocation && styles.locationButtonSelected,
                          )}
                          onClick={() => handleOrganization(item)}
                        >
                          {item.name}
                        </Button>
                      );
                    })}
                  </div>
                )}

                <Button onClick={handleNewLocation} className={styles.locationButton}>
                  <LocationIcon />
                  <Typography>{t("createOffer.location.createNew")}</Typography>
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
