import React from "react";
import { Helmet } from "react-helmet";

export const HomeHelmet = () => {
  // TODO refactor

  return (
    <Helmet>
      <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "seaeasy.surf",
      "url": "https://seaeasy.surf/",
      "sameAs": ["https://www.facebook.com/"],
      "logo": "https://seaeasy.surf/"
    }
  `}</script>
    </Helmet>
  );
};
