import React, { useContext, useState } from "react";
import { Agenda, Day, Inject, Month, ScheduleComponent, Week, WorkWeek } from "@syncfusion/ej2-react-schedule";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";

import { getOwnerReservations, putReservationStatus } from "server";
import { RootState } from "store";
import { EventTypes } from "./manage-booking.types";
import { BookingList } from "./booking-list/booking-list";
import { Loader, Modal, ReservationsModal } from "components";
import { TimeOffModal } from "../availability/time-off-modal/time-off-modal";
import { ManagementContext } from "../management.context";

import "./schedule.css";

import styles from "./manage-booking.module.scss";

export const ManageBooking = () => {
  const { t } = useTranslation();
  const { organizationData, offers } = useSelector((state: RootState) => state.organization);
  const { locale } = useSelector((state: RootState) => state.app);
  const { teamData } = useContext(ManagementContext);

  const [reservationsItems, setReservationsItems] = useState<null | EventTypes[]>(null);
  const [reservationsRefresher, setReservationsRefresher] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeView, setActiveView] = useState<"calendar" | "list">("calendar");
  const [isAddingReservationModalOpen, setIsAddingReservationModalOpen] = useState<boolean>(false);
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState<boolean>(false);

  const { submit: submitStatus, onSubmitSuccess, submitting: submittingReservation } = useSubmit(putReservationStatus);
  onSubmitSuccess(() => {
    setReservationsRefresher((prevState) => prevState + 1);
  });

  const reservationsData = useFetch(getOwnerReservations.setParams({ organizationID: organizationData?.id || 0 }), {
    dependencies: [reservationsRefresher],
  });
  const { onSuccess, loading: dataFetching } = reservationsData;
  onSuccess((res) => {
    const reservations: EventTypes[] = res.response.map((item) => {
      const isService = item.items.find((service) => service.item_type === "SERVICE");

      const endTime = new Date(item.end_time);
      endTime.setHours(endTime.getHours() - 2);

      const startTime = new Date(item.start_time);
      startTime.setHours(startTime.getHours() - 2);

      return {
        Id: item.id,
        FirstName: item.user.first_name,
        LastName: item.user.last_name,
        StartTime: startTime,
        EndTime: endTime,
        // StartTime: changeDateFormat(startTime),
        // EndTime: changeDateFormat(endTime),
        Guests: item.num_of_guests,
        Status: item.reservation_status,
        User: item.user,
        Price: item.price,
        Services: isService ? item.items.filter((service) => service.item_type === "SERVICE") : null,
        Offer: item.items.find((offer) => offer.item_type === "OFFER"),
      } as EventTypes;
    });
    setReservationsItems(reservations.filter((item) => item.Status !== "REJECTED"));
    setLoading(false);
  });

  const refreshReservations = () => {
    setReservationsRefresher((prevState) => prevState + 1);
  };

  const acceptOffer = async (id: number) => {
    setLoading(true);
    await submitStatus({ params: { id }, data: { organization_action: "ACCEPT" } });
  };

  const rejectOffer = async (id: number) => {
    setLoading(true);
    await submitStatus({ params: { id }, data: { organization_action: "REJECT" } });
  };

  const handleAddingReservationModal = (isOpen: boolean) => () => {
    setIsAddingReservationModalOpen(isOpen);
  };

  const eventBox = (props: EventTypes): JSX.Element => {
    const waitingToAccept = props.Status === "PENDING_ACTIVATION" || props.Status === "PENDING_ACCEPTANCE";

    return (
      <div
        key={props.Status}
        className={classNames(styles.eventItem, {
          [styles.eventItemAccept]: waitingToAccept,
        })}
      >
        {props.Offer.offer.title ? (
          <Typography>{props.Offer.offer.title}</Typography>
        ) : (
          <Typography>{props.FirstName}</Typography>
        )}
      </div>
    );
  };

  const popupHeader = (props: EventTypes): JSX.Element => {
    return (
      <div className={styles.headerContainer}>
        {props.Offer.offer.title && <Typography className={styles.headerTitle}>{props.Offer.offer.title}</Typography>}
        <Typography className={styles.headerBookedBy}>
          {t("management.bookings.bookedBy")} {props.FirstName} {props.LastName}
        </Typography>
      </div>
    );
  };

  const getServiceUserDetails = ({ userId }: { userId: number }): string => {
    const user = teamData?.find((teamPerson) => teamPerson.id === userId);
    return `${user?.first_name} ${user?.last_name}` as string;
  };

  const handleContent = (props: EventTypes): JSX.Element => {
    const isAccepted = props.Status === "ACCEPTED";
    const toAccept = props.Status === "PENDING_ACCEPTANCE";

    return (
      <div className={styles.contentPopupContainer}>
        <div className={styles.contentPopupContent}>
          <div className={styles.contentSeparator} />

          <div className={styles.contentItemWrapper}>
            <Typography className={styles.contentItemName}>{t("management.bookings.dates")}</Typography>
            <div>
              <Typography>
                {t("management.bookings.from")} <span>{format(props.StartTime, "dd.MM.yyyy hh:mm")}</span>
              </Typography>
              <Typography>
                {t("management.bookings.until")} <span>{format(props.EndTime, "dd.MM.yyyy hh:mm")}</span>
              </Typography>
            </div>
          </div>

          {props.Services && <div className={styles.contentSeparator} />}

          {props.Services && (
            <div className={styles.contentItemWrapper}>
              <Typography className={styles.contentItemName}>{t("management.bookings.services")}</Typography>
              <div>
                {props.Services.map((service) => (
                  <Typography>
                    <span>{service.service.title}</span> <br />
                    <span>{getServiceUserDetails({ userId: service?.user })}</span>
                  </Typography>
                ))}
              </div>
            </div>
          )}

          <div className={styles.contentSeparator} />

          <div className={styles.contentItemWrapper}>
            <Typography className={styles.contentItemName}>{t("management.bookings.guest")}</Typography>
            <Typography>
              <span>{props.Guests}</span>
            </Typography>
          </div>

          <div className={styles.contentSeparator} />

          <div className={styles.contentItemWrapper}>
            <Typography className={styles.contentItemName}>{t("management.bookings.contactPerson")}</Typography>
            <div>
              <Typography>
                <span>
                  {props.User?.first_name} {props.User?.last_name}
                </span>
              </Typography>

              <Typography>
                <span>{props.User?.email}</span>
              </Typography>

              <Typography>
                <span>{props.User?.phone_number}</span>
              </Typography>
            </div>
          </div>

          <div className={styles.contentSeparator} />

          <div className={styles.contentItemWrapper}>
            <Typography className={styles.contentItemName}>{t("management.bookings.value")}</Typography>
            <Typography>
              <span>
                {props.Price} {props.Offer.offer.currency}
              </span>
            </Typography>
          </div>

          {toAccept && (
            <div className={styles.contentButtons}>
              <Button className={styles.contentAcceptButton} disabled={loading} onClick={() => acceptOffer(props.Id)}>
                {loading ? t("management.bookings.loading") : t("management.bookings.accept")}
              </Button>
              <Button className={styles.contentDeclineButton} disabled={loading} onClick={() => rejectOffer(props.Id)}>
                {loading ? t("management.bookings.loading") : t("management.bookings.decline")}
              </Button>
            </div>
          )}

          {isAccepted && (
            <div className={styles.contentItemWrapper}>
              <Typography className={styles.contentItemName}>{t("management.bookings.status")}</Typography>
              <Typography>
                <span>{t("management.bookings.confirmed")}</span>
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleViewChange = (value: "calendar" | "list") => () => {
    setActiveView(value);
  };

  const handleTimeOffModal = (isOpen: boolean) => () => setIsTimeOffModalOpen(isOpen);

  const language = locale === "pl-PL" ? "pl" : "en";

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <Button
          onClick={handleViewChange("calendar")}
          className={classNames(styles.viewButton, { [styles.activeViewButton]: activeView === "calendar" })}
        >
          {t("management.bookings.calendar")}
        </Button>
        <Button
          onClick={handleViewChange("list")}
          className={classNames(styles.viewButton, { [styles.activeViewButton]: activeView === "list" })}
        >
          {t("management.bookings.list")}
        </Button>

        <Button onClick={handleTimeOffModal(true)} className={styles.timeOffModal}>
          {t("availability.buttons.timeOff")}
        </Button>
      </div>

      {reservationsData && activeView === "calendar" && dataFetching && <Loader className={styles.loader} />}

      {reservationsData && activeView === "calendar" && !dataFetching && (
        <ScheduleComponent
          width="100%"
          timezone="Poland"
          height="100%"
          eventSettings={{ dataSource: reservationsItems || [], template: eventBox as never }}
          quickInfoTemplates={{
            content: handleContent as never,
            header: popupHeader as never,
          }}
          readonly
          className={styles.schedule}
          locale={language}
          currentView="Week"
          dateFormat="dd/MM/yyyy"
        >
          <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
        </ScheduleComponent>
      )}

      {reservationsData.data && activeView === "list" && (
        <BookingList
          isDataFetching={dataFetching}
          rejectOffer={rejectOffer}
          acceptOffer={acceptOffer}
          reservations={reservationsData.data}
          submittingReservation={submittingReservation}
        />
      )}

      {offers && offers.length !== 0 && (
        <IconButton onClick={handleAddingReservationModal(true)} className={styles.addReservationButton}>
          <AddIcon />
        </IconButton>
      )}

      <Modal open={isAddingReservationModalOpen}>
        <ReservationsModal
          close={() => setIsAddingReservationModalOpen(false)}
          isOwnReservation
          open={isAddingReservationModalOpen}
          refreshReservations={refreshReservations}
        />
      </Modal>

      <Modal open={isTimeOffModalOpen}>
        <TimeOffModal close={handleTimeOffModal(false)} />
      </Modal>
    </div>
  );
};
