export const common = {
  submit: "Submit",
  save: "Save",
  delete: "Delete",
  add: "Add",
  remove: "Remove",
  back: "Back",
  next: "Next",
  publish: "Publish",
  preview: "Preview",
  yes: "Yes",
  no: "No",
  seeMore: "see more",
  edit: "Edit",
  contactUs: "Contact us",
  cancel: "Cancel",
  looksGood: "Looks good",
  completed: "Completed",
  fillAllFields: "Fill all required fields",
  learnMore: "Learn more",
  send: "Send",
  checkDetails: "Check details",
  thereIsNothingHere: "Sorry, there is nothing here",
  pl: "Polski",
  en: "English",
  homePage: "home page",
  submitting: "Submitting",
  loading: "Loading",

  refresh: "Refresh",
  fetchingError: "Fetching Error",
  fetchingErrorDescription: "data could not be retrieved",

  lorem:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
};
