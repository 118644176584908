import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LocationModel, OfferModel } from "models";
import { UserOrganizationData } from "server/organization/organization.types";

type InitialState = {
  organizationData: UserOrganizationData | null;
  activePayment: boolean;
  offers: OfferModel[];
  locations: LocationModel[];
  organizationDataRefresher: number;
  offersDataRefresher: number;
  dataFetching: boolean;
};

const initialState: InitialState = {
  organizationData: null,
  activePayment: false,
  offers: [],
  locations: [],
  organizationDataRefresher: 1,
  offersDataRefresher: 1,
  dataFetching: true,
};

const organization = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetOrganizationStore: () => initialState,
    setData: (state, action: PayloadAction<InitialState>) => {
      state.organizationData = action.payload.organizationData;
      state.offers = action.payload.offers;
      state.activePayment = action.payload.activePayment;
      state.locations = action.payload.locations;
    },
    setOrganizationData: (state, action: PayloadAction<UserOrganizationData | null>) => {
      state.organizationData = action.payload;
    },
    setActivePayment: (state, action: PayloadAction<boolean>) => {
      state.activePayment = action.payload;
    },
    setOffers: (state, action: PayloadAction<OfferModel[]>) => {
      state.offers = action.payload;
    },
    setLocations: (state, action: PayloadAction<LocationModel[]>) => {
      state.locations = action.payload;
    },
    addOffer: (state, action: PayloadAction<OfferModel>) => {
      state.offers.push(action.payload);
    },
    refreshOrganizationData: (state) => {
      state.organizationDataRefresher += 1;
    },
    refreshOffersData: (state) => {
      state.offersDataRefresher += 1;
    },
    setDataFetching: (state, action: PayloadAction<boolean>) => {
      state.dataFetching = action.payload;
    },
  },
});

export const {
  setData,
  resetOrganizationStore,
  setOffers,
  setOrganizationData,
  setActivePayment,
  setLocations,
  addOffer,
  refreshOrganizationData,
  refreshOffersData,
  setDataFetching,
} = organization.actions;

export default organization.reducer;
