import { strapiBuilder } from "../builder";
import { StrapiData } from "../../types";
import { ServerErrorType } from "../server.types";
import { SeaTalkTypes, StrapiParams } from "./sea-talk.types";

export const getSeaTalk = strapiBuilder.createCommand<
  StrapiData<SeaTalkTypes[]>,
  void,
  ServerErrorType,
  StrapiParams
>()({
  method: "GET",
  endpoint: "/api/morska-gadkas",
  retry: 3,
  retryTime: 0,
});
