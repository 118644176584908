export const register = {
  register: "Sign up",
  registerTitle: "Sign up",
  resetPassword: "Reset password",
  resetPasswordTitle: "Reset password",
  or: "or",
  firstNameLabel: "First name",
  lastNameLabel: "Last name",
  emailLabel: "Email",
  passwordLabel: "Password",
  phoneLabel: "Phone number",
  passwordRequirements: "* Password must contain at least 6 characters",
  loginLabel: "Already have an account?",
  loginToYourAccount: "Log in",
  repeatPasswordLabel: "Repeat password",
  externalGoogle: "Register with Google",
  externalFacebook: "Register with Facebook",
  externalApple: "Register with Apple",
  looksGood: "Looks Good",
  agreement: "I want to receive news and content from SeaEasy.",
  third_party_agreement:
    "Share my registration data with the content providers in SeaEasy. This information may be used for commercial purposes.",

  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  invalidEmail: "This e-mail address is invalid",
  emailRequired: "E-mail is required",
  passwordRequired: "Password is required",
  passwordsMatch: "Passwords must match",
  retypePassword: "Repeat your password",
  invalidPhoneNumber: "Phone number is invalid",
  phoneNumberRequired: "Phone number is required",
  minimumLength: "Password must be at least 6 characters long",

  info: "By signing in or creating an account you agree with our ",
  privacyPolicy: "Privacy policy",
  cookiesPolicy: "cookies",
  and: "and",
  termsOfUse: "Terms of use",

  successText: "Confirm the verification link on your inbox",
  registerError: "Error while registering the user",
  resetPasswordError: "Error while resetting the password",
  resetPasswordSuccess: "Password changed successfully",
  tokenValidationError: "Given reset password link is invalid",
};
