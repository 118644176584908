import { CaptainInitialData } from "./captain-modal.types";

export const initialCaptain: CaptainInitialData = {
  price: 0,
  isFixed: false,
  maxGuests: 0,
  isRequired: false,
};

export const inputStyles = {
  color: "#000",
  input: { color: "#000", height: "60px", textAlign: "center", verticalAlign: "center", margin: 0, padding: 0 },
  select: { color: "#000", height: "60px", textAlign: "center", verticalAlign: "center", margin: 0, padding: "50px" },
  backgroundColor: "#fff",
  borderRadius: "8px",
  height: "60px",
  width: "100%",
  border: "1px solid #868ad9",
};

export const switchSx = {
  alignSelf: "center",
  justifySelf: "center",
};
