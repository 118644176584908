export const ownerReservations = {
  reservations: "Reservations: {{organizationName}}",

  legend: {
    offer: "Offer:",
    guests: "Guests:",
    totalProfit: "Total Profit:",
    services: "Services:",
    bookedBy: "Booked By:",
    status: "Status:",
    dates: "Dates:",
  },

  statuses: {
    created: "Created",
    pendingActivation: "Pending activation",
    pendingAcceptance: "Pending acceptance",
    accepted: "Accepted",
    rejected: "Rejected",
    reported: "Reported",
    failed: "Failed",
    closed: "Closed",
  },

  buttons: {
    accept: "accept",
    reject: "reject",
  },

  error: {
    noReservations: "No pending reservations...",
    noServices: "No services",
    noOrganization: "The reservation for your organization could not be retrieved.",
    createOrganization: "Create organization",
  },
};
