import { TFunction } from "i18next";
import { number, object, string } from "yup";

import { TextInputProps } from "components/form/form-text-field/form-text-field.types";

export const select = {
  styles: {
    width: "100%",
    color: "#000",
    padding: "3px",
    borderBottom: "2px solid #474CAE",
    div: {
      color: "#000",
      px: "3px",
      "&::placeholder": { fontSize: 15 },
      mr: 1,
      borderRadius: "3px",
    },
  },
  props: {
    MenuProps: {
      BackdropProps: { sx: { opacity: 0.2 } },
      PaperProps: {
        sx: {
          backgroundColor: "#fff",
          color: "#000",
          top: 0,
          left: 0,
        },
      },
    },
  },
};

export const inputStyles = {
  borderBottom: "2px solid #474CAE",
  color: "#000",
  input: {
    color: "#000",
    px: 1,
    "&::placeholder": { fontSize: 15 },
    borderRadius: "5px",
    mb: "6px",
    mt: "6px",
  },
  textarea: { color: "#000", px: 1, "&::placeholder": { fontSize: 15 } },
  width: "100%",
};

export const labelProps = {
  shrink: true,
  sx: { paddingLeft: 1, color: "#474CAE", ml: 1, "&.Mui-focused": { color: "#474CAE" } },
};
export const locationFields = (t: TFunction): TextInputProps[] => [
  { name: "location.locationData.name", label: t("createOffer.location.name"), type: "text" },
  { name: "location.locationData.description", label: t("createOffer.location.description"), type: "text" },
  { name: "location.locationData.address", label: t("createOffer.location.address"), type: "text" },
  { name: "location.locationData.city", label: t("createOffer.location.city"), type: "text" },
  { name: "location.locationData.region", label: t("createOffer.location.region"), type: "text" },
  { name: "location.locationData.postal_code", label: t("createOffer.location.postalCode"), type: "text" },
];

export const locationSchema = (t: TFunction) =>
  object().shape({
    location: object().shape({
      location_id: number().nullable(),
      temporary_id: string().nullable(),
      locationData: object().when(["location_id", "temporary_id"], {
        is: (location_id: number, temporary_id: string) => {
          return location_id === null && temporary_id === null;
        },
        then: (schema) =>
          schema.shape({
            name: string().required(t("createOffer.location.validation.name")),
            description: string(),
            address: string().required(t("createOffer.location.validation.address")),
            city: string().required(t("createOffer.location.validation.city")),
            country: string().required(t("createOffer.location.validation.country")),
            latitude: string().required(t("createOffer.location.validation.latitude")),
            longitude: string().required(t("createOffer.location.validation.longitude")),
            region: string(),
            postal_code: string().required(t("createOffer.location.validation.postalCode")),
          }),
      }),
    }),
  });
