export const watersports = {
  windsurfing: "Windsurfing",
  kitesurfing: "Kitesurfing",
  sailing: "Żeglarstwo",
  motorboat: "Łódź motorowa",
  kayak: "Kajak",
  sup: "SUP",
  diving: "Nurkowanie",
  pedalo: "Rowerek wodny",
  surfing: "Surfing",
  wakeboard: "Wakeboard",
  other: "Inne",
  rowingBoat: "Łódź wiosłowa",
  efoil: "Efoil",
  wingfoil: "Wing foil",
  jetski: "Skuter wodny",
  rowing_boat: "Łódź wiosłowa",
  yacht: "Jachty",
  "rowing boat": "Rowing boat",
};
