import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RootState } from "store";
import { OfferItem } from "./offer-item/offer-item";
import { useWindowSize } from "hooks";
import { OfferItemDesktop } from "./offer-item-desktop/offer-item-desktop";
import { Loader } from "components";

import styles from "./offers.module.scss";

export const Offers = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { offers, dataFetching } = useSelector((state: RootState) => state.organization);

  const isMobile = width < 992;

  return (
    <div className={styles.container}>
      {isMobile && <Typography className={styles.sectionName}>{t("availability.offers.offer")}</Typography>}

      {!isMobile && (
        <div className={styles.map}>
          <Typography>{t("availability.offers.offer")}</Typography>
          <Typography>{t("availability.offers.services")}</Typography>
          <Typography>{t("availability.offers.availability")}</Typography>
        </div>
      )}

      <div className={styles.content}>
        {dataFetching && <Loader color="#fff" backgroundColor="#fff" height="100%" />}
        {!dataFetching &&
          offers.map((offer) => {
            if (isMobile) return <OfferItem offer={offer} />;
            return <OfferItemDesktop offer={offer} />;
          })}
      </div>
    </div>
  );
};
