export const availability = {
  buttons: {
    offers: "Oferty",
    hosts: "Pracownicy",
    assets: "Sprzęt",
    locations: "Lokalizacje",
    availability: "Dostępność",
    changeAvailability: "Zmień dostępność",
    timeOff: "Wyłącz rezerwacje",
  },
  actionButtons: {
    addAvailability: "Dodaj dostępność",
    exceptions: "Wyjątki",
  },

  offers: {
    offer: "Oferta",
    services: "Usługi",
    availability: "Dostępność",
    openAvailabilityModal: "Otwórz panel dostępności",
    add: "Dodaj",
    service: "usługi",
    noServices: "brak usług",
    edit: "Edytuj",
  },

  offerLinksModal: {
    completeServices: "Dokończy usługę",
    service: "Usługa:",
    asset: "Sprzęt:",
    host: "Pracownik:",
    selectAsset: "Wybierz sprzęt",
    selectHost: "Wybierz pracownika",
  },

  modals: {
    addHosts: "Dodaj pracowników",
    addAssets: "Dodaj sprzęt",
    addLocations: "Dodaj lokalizację",
    select: "Wybierz...",
    save: "zapisz",
    hosts: "Pracownicy",
    assets: "Sprzęt",
    locations: "Lokalizacje",
  },

  availabilityModal: {
    editAvailability: "Edytuj dostępność",
    editFor: "Edytuj dla",
    months: "Miesiące",
    days: "Dni",
    startTime: "Czas rozpoczęcia",
    endTime: "Czas zakończenia",
    duration: "Czas trwania (min)",
    brakeAfter: "Zakończenie po (min)",
    save: "Zapisz",
    select: "Wybierz...",
    service: "Usługa: ",
    completeServices: "Dokończ usługi",
    selectAsset: "Wybierz sprzęt",
    selectHost: "Wybierz pracownika",
    asset: "Sprzęt: ",
    host: "Pracownik: ",
  },

  timeOffModal: {
    editTimeOff: "Edytuj czas wolny",
    editFor: "Edytuj dla",
    timeOffStartDate: "Czas startu",
    timeOffStartTime: "Data startu",
    timeOffEndDate: "Czas zakończenia",
    timeOffEndTime: "Data zakończenia",
    save: "Save",
  },

  months: {
    january: "Styczeń",
    february: "Luty",
    march: "Marzec",
    april: "Kwiecień",
    may: "Maj",
    june: "Czerwiec",
    july: "Lipiec",
    august: "Sierpień",
    september: "Wrzesień",
    october: "Październik",
    november: "Listopad",
    december: "Grudzień",
  },

  days: {
    monday: "Poniedziałek",
    tuesday: "Wtorek",
    wednesday: "Środa",
    thursday: "Czwartek",
    friday: "Piątek",
    saturday: "Sobota",
    sunday: "Niedziela",
  },
};
