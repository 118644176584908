import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { DestinationBoxTypes } from "./destination-box.types";
import { OFFERS_PAGE } from "constants/routes.constants";
import { MetaData } from "components";
import { useRoutesPath } from "hooks";
import { bmStrapiDestinationsPath } from "utils/route-params";

import styles from "./destination-box.module.scss";

export const DestinationBox: React.FC<DestinationBoxTypes> = ({ destination }) => {
  const { locale, localePathParams, localePath } = useRoutesPath();

  const {
    attributes: {
      meta,
      filterCountry,
      locationEn,
      locationPl,
      image: strapiImage,
      dateStart,
      dateEnd,
      isBmOffer,
      location,
    },
  } = destination;

  const destinationPath = (): string => {
    if (isBmOffer) {
      const queryParams = bmStrapiDestinationsPath({ filterCountry, dateEnd, dateStart });
      return localePathParams({ route: OFFERS_PAGE, queryParams });
    }
    return localePath({
      route: OFFERS_PAGE,
      queryParams: `?location=${location}`,
    });
  };

  const image = strapiImage?.data.attributes.url;

  return (
    <Link to={destinationPath()} className={styles.container}>
      <img src={image} className={styles.image} alt="destination" />
      <Typography className={styles.title}>{locale === "pl-PL" ? locationPl : locationEn}</Typography>
      {meta && <MetaData meta={meta[0]} />}
    </Link>
  );
};
