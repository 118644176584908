import React, { Context, useContext, useState } from "react";
import moment, { Moment } from "moment";
import "moment/locale/pl";
import { Button } from "antd";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { transformAvailabilityData, calculateDateRange, isDayBlocked, isBlockedInRange } from "./calendar.constants";
import { CalendarViewContextType } from "../../wrapper/calendar-view.context";
import { ReservationsContext } from "components/reservations/reservations-modal.context";
import { Slots } from "../../../../server/availability/availability.server";
import { getViewContext } from "components/reservations/wrapper/wrapper.constants";

import styles from "./calendar.module.scss";

moment.locale("pl");

export const Calendar: React.FC = () => {
  const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);

  const { slots, selectedRange, guests, setSelectedDate, selectedDate, slotsSubmitting, setSelectedRange } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>,
  );

  const [availability, setAvailability] = useState<{ [key: string]: number }>(
    transformAvailabilityData(slots as Slots[]),
  );
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [hoveredDate, setHoveredDate] = useState<Moment | null>(null);

  const checkAvailability = () => {
    const selectedDates = selectedRange.map((date) => moment(date));
    return selectedDates.every((date) => !isDayBlocked(date, availability, guests));
  };

  const handleDayClick = (day: Moment) => {
    if (isDayBlocked(day, availability, guests)) {
      return;
    }

    if (
      isDayBlocked(day, availability, guests) ||
      (isSelecting && isBlockedInRange(startDate as Moment, day, availability, guests))
    ) {
      return;
    }

    if (!startDate || !isSelecting) {
      setStartDate(day);
      setEndDate(null);
      setIsSelecting(true);
      setSelectedRange([day.toISOString()]);
    } else if (isSelecting) {
      if (day.isBefore(startDate, "day")) {
        setEndDate(startDate);
        setStartDate(day);
        setSelectedRange(calculateDateRange(day, startDate));
      } else {
        setEndDate(day);
        setSelectedRange(calculateDateRange(startDate, day));
      }
      setIsSelecting(false);
    }
  };

  const handleDayMouseEnter = (day: Moment): void => {
    if (isSelecting) {
      setHoveredDate(day);
    }
  };

  const isDaySelected = (day: Moment): boolean => {
    if (isDayBlocked(day, availability, guests)) return false;
    return selectedRange.some((date) => day.isSame(moment(date), "day"));
  };

  const isDayInRange = (day: Moment): boolean => {
    if (isDayBlocked(day, availability, guests)) return false;
    if (!startDate || !endDate) return false;
    return day.isAfter(startDate, "day") && day.isBefore(endDate, "day");
  };

  const isDayHighlighted = (day: Moment) => {
    if (!isSelecting || !startDate || !hoveredDate) return false;
    const start = startDate.isBefore(hoveredDate) ? startDate : hoveredDate;
    const end = startDate.isBefore(hoveredDate) ? hoveredDate : startDate;

    if (
      isDayBlocked(day, availability, guests) ||
      (isSelecting && isBlockedInRange(startDate as Moment, day, availability, guests))
    ) {
      return false;
    }

    return day.isSameOrAfter(start, "day") && day.isSameOrBefore(end, "day");
  };

  const goToPreviousMonth = (): void => {
    setSelectedDate(selectedDate.clone().subtract(1, "month"));
  };

  const goToNextMonth = (): void => {
    setSelectedDate(selectedDate.clone().add(1, "month"));
  };

  const renderCalendarHeader = () => {
    const days = moment.weekdaysShort(true);
    return days.map((day) => (
      <div key={day} className={styles.dayName}>
        {day}
      </div>
    ));
  };

  const renderCalendar = (date: Moment): JSX.Element => {
    const daysInMonth = date.daysInMonth();
    const firstDayOfMonth = date.clone().startOf("month").isoWeekday();
    const emptyDaysArray = Array.from({ length: firstDayOfMonth - 1 });
    const daysArray = Array.from({ length: daysInMonth });

    const emptyDays = emptyDaysArray.map(() => <div className={styles.emptyDay} />);

    const days = daysArray.map((_, index) => {
      const day = moment(date).date(index + 1);
      const isBlocked = isDayBlocked(day, availability, guests);
      const isSelected = isDaySelected(day);
      const isInRange = isDayInRange(day);
      const isHighlighted = isDayHighlighted(day);

      if (slotsSubmitting) {
        return <div key={day.toISOString()} className={`${styles.day} ${styles.skeleton}`} />;
      }

      return (
        <Button
          disabled={isBlocked}
          key={day.toISOString()}
          className={`${styles.day} ${isBlocked ? styles.blocked : ""} ${isSelected ? styles.selected : ""} ${
            isInRange ? styles.inRange : ""
          } ${isHighlighted ? styles.highlighted : ""}`}
          onClick={() => handleDayClick(day)}
          onMouseEnter={() => handleDayMouseEnter(day)}
        >
          {index + 1}
        </Button>
      );
    });

    return (
      <div className={styles.month}>
        {renderCalendarHeader()}
        {emptyDays}
        {days}
      </div>
    );
  };

  useDidUpdate(
    () => {
      setAvailability(transformAvailabilityData(slots as Slots[]));
    },
    [selectedDate, slots],
    true,
  );

  useDidUpdate(
    () => {
      if (selectedRange.length > 0 && !checkAvailability()) {
        setStartDate(null);
        setEndDate(null);
        setSelectedRange([]);
      }
    },
    [selectedRange],
    true,
  );

  const nextMonth = selectedDate.clone().add(1, "month");

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <button type="button" className={styles.button} onClick={goToPreviousMonth}>
          {"<"}
        </button>
        <span className={styles.monthName}>{selectedDate.format("MMMM YYYY")}</span>
        <button type="button" className={styles.button} onClick={goToNextMonth}>
          {">"}
        </button>
      </div>
      <div className={styles.calendarBody}>
        {renderCalendar(selectedDate)}
        <span className={styles.monthName}>{nextMonth.format("MMMM YYYY")}</span>
        {renderCalendar(nextMonth)}
      </div>
    </div>
  );
};
