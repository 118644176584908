import React from "react";

import { InfoDisplayProps } from "./info-display.types";

import styles from "./info-display.module.scss";

export const InfoDisplay: React.FC<InfoDisplayProps> = ({ label, value, fontSize = 16 }) => {
  return (
    <div className={styles.container}>
      <p style={{ fontSize }}>
        {label}: <span style={{ fontSize }}>{value}</span>
      </p>
    </div>
  );
};
