import React, { useContext } from "react";
import { Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { FormTextField } from "components";
import { inputStyles, inputStylesDescription, inputStylesDetails } from "./details.constants";
import { Photos } from "./photos/photos";
import { CreateOfferContext } from "../create-offer.context";
import { OfferData } from "../create-offer.types";
import { checkIsBoatStep, tooltipConfig } from "../create-offer.constants";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip.svg";

import styles from "./details.module.scss";

export const Details = () => {
  const { t } = useTranslation();
  const { isDesktop, icon, sportType } = useContext(CreateOfferContext);
  const { errors, values } = useFormikContext<OfferData>();

  const isBoatStep = checkIsBoatStep(sportType);

  const titleError = errors.keyDetails?.title;
  const descriptionError = errors.keyDetails?.description;

  return (
    <div className={styles.container}>
      <div className={classNames(styles.textFieldBox, styles.title)}>
        <div className={styles.wrapper}>
          <Typography variant="h3">{t("createOffer.details.createYourTitle")}</Typography>
          <Tooltip
            title={t("createOffer.details.tooltip.title")}
            enterTouchDelay={0}
            className={styles.tooltip}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>
        <FormTextField
          name="keyDetails.title"
          variant="standard"
          type="number"
          sx={inputStyles}
          multiline
          rows={3}
          InputProps={{ disableUnderline: true }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ step: 0.01, maxLength: 50 }}
          helperText={false}
        />
        <div className={styles.helpers}>
          {titleError && (
            <Typography className={styles.error} color="#ec3636">
              {titleError}
            </Typography>
          )}
          <Typography variant="h5" className={styles.counter}>
            {values.keyDetails.title?.length}/50
          </Typography>
        </div>
      </div>
      <div className={classNames(styles.textFieldBox, styles.description)}>
        <Typography variant="h3">{t("createOffer.details.createYourDescription")}</Typography>
        <FormTextField
          name="keyDetails.description"
          variant="standard"
          type="number"
          sx={inputStylesDescription}
          multiline
          rows={5}
          InputProps={{ disableUnderline: true }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ step: 0.01, maxLength: 5000 }}
          helperText={false}
        />
        <div className={styles.helpers}>
          {descriptionError && (
            <Typography color="#ec3636" className={styles.error}>
              {descriptionError}
            </Typography>
          )}
          <Typography variant="h5" className={styles.counter}>
            {values.keyDetails.description?.length}/5000
          </Typography>
        </div>
      </div>

      {isBoatStep && (
        <div className={classNames(styles.additionalDetails, styles.floatSection)}>
          <div className={styles.detailsContent}>
            <div className={styles.detailsHeader}>
              <Typography>{t("createOffer.details.additionalDetails")}</Typography>
              {isDesktop && <img src={icon} alt="watersport" />}
            </div>

            <div className={styles.additionalDetailsTextField}>
              <Typography variant="h3">{t("createOffer.details.manufacturer")}</Typography>
              <FormTextField
                name="keyDetails.additionalDetails.manufacturer"
                variant="standard"
                sx={inputStylesDetails}
                InputProps={{ disableUnderline: true }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ step: 0.01 }}
              />
            </div>
            <div className={styles.additionalDetailsTextField}>
              <Typography variant="h3">{t("createOffer.details.boatLength")}</Typography>
              <FormTextField
                name="keyDetails.additionalDetails.boatLength"
                variant="standard"
                type="number"
                sx={inputStylesDetails}
                InputProps={{ disableUnderline: true, inputProps: { min: 0, step: 0.01 } }}
              />
            </div>
            <div className={styles.additionalDetailsTextField}>
              <Typography variant="h3">{t("createOffer.details.productionYear")}</Typography>
              <FormTextField
                name="keyDetails.additionalDetails.productionYear"
                variant="standard"
                type="number"
                sx={inputStylesDetails}
                InputProps={{ disableUnderline: true, inputProps: { step: 1, min: 0 } }}
              />
            </div>
          </div>
          {!isDesktop && <img src={icon} alt="water sport icon" className={styles.mobileIcon} />}
        </div>
      )}

      {!isBoatStep && (
        <div className={styles.noDetailsIcon}>
          <img src={icon} alt="water sport icon" />
        </div>
      )}

      <Photos className={styles.photos} />
    </div>
  );
};
