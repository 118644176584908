import easySurf from "assets/images/easy-surf.png";
import deskorelax from "assets/images/deskorelax.png";
import yildizYachting from "assets/images/yildiz-yachting.png";
import globalYachting from "assets/images/global-yachting.png";
import byc from "assets/images/byc.png";
import beCharter from "assets/images/be-charter.png";
import palmayachts from "assets/images/palmayachts.png";
import italiamare from "assets/images/italiamre.png";
import sailty from "assets/images/sailty.png";
import southSea from "assets/images/south-sea.png";
import waves from "assets/images/7waves.png";
import mikntis from "assets/images/mikntis.png";

export const partnersData = [
  deskorelax,
  easySurf,
  yildizYachting,
  globalYachting,
  byc,
  beCharter,
  palmayachts,
  italiamare,
  sailty,
  southSea,
  waves,
  mikntis,
];

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 8,
    slidesToSlide: 8,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1600 },
    items: 8,
    slidesToSlide: 8,
  },
  semiTablet: {
    breakpoint: { max: 1600, min: 1200 },
    items: 6,
    slidesToSlide: 6,
  },
  tablet: {
    breakpoint: { max: 1200, min: 464 },
    items: 5,
    slidesToSlide: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
};
