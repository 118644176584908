export const createOffer = {
  steps: {
    addEquipment: "Add your equipment",
    addDetails: "Add key details",
    addServices: "Add services",
    addLocation: "Add location",
    preview: "Preview",
    step: "step",
    baseInfo: "Base info",
  },

  categories: {
    rental: "Rental",
    kitesafari: "Kitesafari",
    cruise: "Cruise",
    forKids: "For kids / Kolonia",
    sportCourse: "Sport course",
    surfTrip: "Surf trip",
    divingTrip: "Diving trip",
    online: "Online",
    trainingCamp: "Training camp",
  },

  categorySelection: {
    chooseCategory: "Choose category",
    chooseWatersport: "Choose watersport",
  },

  baseInfo: {
    mandatory: "(mandatory)",
    optional: "(optional)",
    pricing: {
      addPricing: "Add pricing",
      editPricing: "Edit pricing",
      currentPricing: "Current pricing",
      basePrice: "Base price: ",
      rateType: "Rate type: ",
      deposit: "Deposit: ",
      currency: "Currency: ",
      maxGuests: "Max guests: ",
      discount: "Discounts: ",
    },
    availability: {
      addAvailability: "Add availability",
      editAvailability: "Edit availability",
      currentAvailability: "Current availability",
      months: "Months: ",
      startTime: "Start time: ",
      endTime: "End time: ",
      startDate: "Start date: ",
      endDate: "End date: ",
      days: "Days: ",
      brakeAfter: "Brake after (min): ",
      duration: "Duration (min): ",
    },
    included: {
      addWhatsIncluded: "Add what's included",
      editIncluded: "Edit what's included",
      currentIncluded: "Current what's included",
      add: "Add",
      included: "Included: ",
      notIncluded: "Not included: ",
    },

    modal: {
      pricing: {
        addPricing: "Add pricing",
        basePrice: "Base price",
        maxGuests: "Max guests",
        deposit: "Deposit",
        rateType: "Rate type",
        currency: "Currency",
      },
      included: {
        addWhatsIncluded: "Add what's included",
        whatsIncluded: "What's included",
        addFirstItem: "Add first item",
        whatsNotIncluded: "What's not included",
      },
      availability: {
        addAvailability: "Add availability",
        months: "Months",
        days: "Days",
        startTime: "Start time",
        endTime: "End time",
        duration: "Duration (min)",
        brakeAfter: "Brake after (min)",
        startDate: "Start date",
        endDate: "End date",
      },
    },
  },

  equipmentDetails: {
    categorySelection: "Category selection",
    price: "Price",
    currency: "Currency",
    rateType: "Rate type",
    deposit: "Deposit",
    guests: "Max guests",

    tooltip: {
      category: "Choosing water sport category will allow us to properly display your offer for potential customers.",
      price:
        "Price -  base rental price amount that the customer will pay for your equipment.  Extra charges and additional services can be added separately in following steps.",
      deposit:
        "Adding deposit is optional and the price will only be displayed. SeaEasy is not participating in deposit transfer.",
      rateType:
        "There are 8 rate types accepted: per half hour, per hour, per day, per week, as well as rates per person per time. This rate type will be used when adding all pieces of your offer and will be used to calculate booking value.",
    },
  },

  details: {
    createYourTitle: "Create your title",
    createYourDescription: "Create your description",
    additionalDetails: "additional details",
    boatLength: "Boat length (m)",
    manufacturer: "Manufacturer",
    productionYear: "Production year",
    addPictures: "Add pictures",
    organizationPhotos: "Use organization photos",

    validation: {
      title: "Title is required",
      description: "Description is required",
      boatLength: "Boat length is required",
      boatLengthPositive: "Boat length has to be greater than 0",
      manufacturer: "Manufacturer is required",
      productionYear: "Production year is required",
      yearMin: "Year has to be greater than 1700",
      yearMax: "Year has to be less than 2023",
      pictures: "Pictures are required",
      picturesMin5: "Add min 5 photos",
    },

    tooltip: {
      title: "Title - Title will be displayed as header of your rental offer.",
      pictures: `Add at least 5 pictures for your rental offer. Accepted formats: .jpg, .jpeg, .png. 
          Max size: 10MB`,
    },
  },

  services: {
    captain: "Captain",
    option: "Optional",
    instructor: "Instructor",
    additionalServices: "Additional services",
    extraCharges: "Extra charges",
    add: "Add {{type}}",
    maxGuests: "Max guests",
    fixedPrice: "Fixed price",
    captainRequired: "Captain required",
    instructorRequired: "Instructor required",
    isRequired: "is required",

    modal: {
      name: "Name",
      price: "Price",
      isFixed: "Fixed price",
      maxGuests: "Max guests",
      instructorRequired: "Instructor required",
      captainRequired: "Captain required",
      addAdditionalServices: "Add additional services",
      addCaptain: "Add captain",
      addInstructor: "Add instructor",
      addExtraCharges: "Add extra charges",
    },

    tooltip: {
      captain:
        "Captain for hire can be added to your base rental offer. This will be presented separately as optional cost for clients.",
      instructor:
        "Lessons with instructors can be added to your base rental offer. This will be presented separately as optional cost for clients.",
      additionalServices:
        "Additional services can be added by customer to base rental offer. This can be used for adding services like wetsuit rental, welcome pack and other.",
      extraCharges:
        "Extra charges will always be added to your base rental offer. This can be used for adding charges like cleaning or taxes and other.",
      modal: {
        price:
          "Base price for hiring your captain, that the customer will pay extra to your base rental price. Rate type can be changed to fixed from the time rate used for your base rental offer.",
        priceInstructor:
          "Base price for hiring your instructor, that the customer will pay extra to your base rental price. Rate type can be changed to fixed from the time rate used for your base rental offer.",
        priceAdditional:
          "Base price for using your additional service, that the customer will pay extra to your base rental price. Rate type can be changed to fixed from the time rate used for your base rental offer.",
        priceCharges:
          "Base price for using your extra charges, that the customer will pay extra to your base rental price. Rate type can be changed to fixed from the time rate used for your base rental offer.",
        fixedPrice:
          "Lump sum - one time payment for rental not related to time. Use this field to change the rate type for your captain from time rate type to lump sum.",
        fixedPriceInstructor:
          "Lump sum - one time payment for rental not related to time. Use this field to change the rate type for your instructor from time rate type to lump sum.",
        fixedPriceAdditional:
          "Lump sum - one time payment for rental not related to time. Use this field to change the rate type for your additional services from time rate type to lump sum.",
        fixedPriceCharges:
          "Lump sum - one time payment for rental not related to time. Use this field to change the rate type for your extra charges from time rate type to lump sum.",
        captainRequired:
          "Captain required - Use this to make hiring captain required when renting your base rental offer.",
        instructorRequired:
          "Instructor required - Use this to make lessons with instructor required when renting your base rental offer.",
      },
    },
  },

  location: {
    yourLocations: "Your organization locations",
    createNew: "Create new location",
    useSaved: "Use my saved location",
    validate: "Validate",
    addAddress: "Add the address of your equipment",
    name: "Name",
    description: "Description",
    address: "Address",
    city: "City",
    region: "Region",
    postalCode: "Postal code",
    country: "Country",
    confirmYourAddress: "Confirm your address",
    createdSuccessfully: "Your location has been created successfully",

    validation: {
      name: "Name is required",
      address: "Address is required",
      city: "City is required",
      country: "Country is required",
      latitude: "Latitude is required",
      longitude: "Longitude is required",
      postalCode: "Postal code is required",
    },
  },

  rateType: {
    halfHour: "Half hour",
    hour: "Hour",
    day: "Day",
    week: "Week",
    halfHourPerson: "Half hour per person",
    hourPerson: "Hour per person",
    dayPerson: "Day per person",
    weekPerPerson: "Week per person",

    readable: {
      halfHour: "Per half hour",
      hour: "Per hour",
      day: "Per day",
      week: "Per week",
      halfHourPerson: "Per half hour per person",
      hourPerson: "Per hour per person",
      dayPerson: "Per day per person",
      weekPerPerson: "Per week per person",

      number: {
        min: "min",
        hour: "hour",
        day: "day",
        week: "week",
        minPerson: "min / person",
        hourPerson: "hour / person",
        dayPerson: "day / person",
        weekPerson: "week / person",
      },
    },
  },

  preview: {
    preview: "Preview",
    description: "Description",
  },

  pricePerHalfHour: "Half hour",
  pricePerHour: "Hour",
  pricePerDay: "Day",
  pricePerWeek: "Week",

  createSuccess: "Offer created successfully",
  createError: "Error while creating",
  editSuccess: "Offer edited successfully",

  beforeYouAddOffer: "Before you add first offer u need to add payments method.",
  addNow: "Add now",
  notNow: "Not now",
};
