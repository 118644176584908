import React from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ProfilePicture } from "./profile-picture/profile-picture";
import { ProfileName } from "./profile-name/profile-name";
import { ProfileDescription } from "./profile-description/profile-description";
import { ProfilePictures } from "./profile-pictures/profile-pictures";
import { ProfileRules } from "./profile-rules/profile-rules";
import { createRentalProfileSteps } from "../create-rental.constants";

import stylesMain from "../create-rental.module.scss";
import styles from "./profile.module.scss";

export const Profile = () => {
  const { t } = useTranslation();

  const injectClassNames = (className: string) => classNames(styles.profileItem, className);

  return (
    <div className={classNames(stylesMain.itemContainer, styles.container)}>
      <Typography className={classNames(stylesMain.header, stylesMain.desktop)}>
        {t("rental.steps.step")} 1: {createRentalProfileSteps(t)[1].name}
      </Typography>
      <div className={styles.content}>
        <ProfilePicture className={injectClassNames(styles.avatar)} />
        <ProfileName className={injectClassNames(styles.name)} />
        <ProfileDescription className={injectClassNames(styles.description)} />
        <ProfileRules className={injectClassNames(styles.rules)} />
        <ProfilePictures className={injectClassNames(styles.pictures)} />
      </div>
    </div>
  );
};
