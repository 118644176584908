import React from "react";
import { Form, Formik } from "formik";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { initialValues, inputProps, inputStyles, newsletterSchema } from "./newsletter-form.contants";
import { FormTextField } from "components";

import styles from "./newsletter-form.module.scss";

export const NewsletterForm = () => {
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={newsletterSchema(t)}>
      <Form>
        <div className={styles.container}>
          <Typography
            variant="h6"
            sx={{ borderBottom: "1px solid #D59267", mb: 2, pb: 2, fontSize: 24 }}
            fontWeight={400}
            className={styles.title}
            color="rgba(213, 146, 103, 1)"
          >
            {t("seo.subscribeToSeaNewsletter")}
          </Typography>
          <FormTextField
            name="email"
            type="email"
            placeholder="Email address"
            sx={inputStyles}
            size="small"
            className={styles.input}
            InputProps={inputProps}
          />
          <Typography className={styles.policy} sx={{ fontSize: 14, mt: 3, mb: 2, color: "#fff" }}>
            {t("register.info")} <span>{t("register.privacyPolicy")}</span> {t("register.and")}{" "}
            <span>{t("register.termsOfUse")}</span>
          </Typography>
          <Button type="submit" className={styles.button} variant="contained">
            {t("common.looksGood")}
          </Button>
          <Typography variant="subtitle2" className={styles.separator}>
            <span>{t("login.or")}</span>
          </Typography>
        </div>
      </Form>
    </Formik>
  );
};
