import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormTextField } from "components";
import { sx } from "./edit-modal.constants";

import "./edit-modal.css";

import styles from "./edit-modal.module.scss";

export const EditModal = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <FormTextField
        sx={sx}
        name="firstName"
        label={t("profile.modal.firstName")}
        variant="standard"
        className={styles.input}
      />
      <FormTextField
        sx={sx}
        name="lastName"
        label={t("profile.modal.lastName")}
        variant="standard"
        className={styles.input}
      />
      <FormTextField
        sx={sx}
        name="number"
        label={t("profile.modal.phoneNumber")}
        variant="standard"
        className={styles.input}
        type="number"
      />

      <Button type="submit" className={styles.button}>
        {t("profile.modal.save")}
      </Button>
    </div>
  );
};
