export const burgerMenu = {
  login: "Login",
  register: "Register",
  offers: "Offers",
  account: "Account",
  createOffer: "Create offer",
  createRental: "Create rental",
  logOut: "Log out",
  checkReservations: "Check reservations",
  management: "Management",
  addPayment: "Add payment",
  loading: "Loading...",
};
