export const paymentsCheck = {
  addedSuccessfully:
    "Połączenie z płatnościami przez Stripe nawiązane poprawnie! Czy chcesz przejść do zarządzania biznesem?",

  addedIncorrectly:
    "Połączenie z płatnościami przez Stripe nie zostało nawiązane. Czy chcesz przejść do dodawania płatności przez Stripe?",

  manage: "Przejdź",
  homePage: "Strona główna",
  addStripe: "Dodaj Stripe",
  loading: "Ładowanie...",
};
