export const pathParams = {
  slug: {
    windsurfing: "windsurfing",
    kitesurfing: "kitesurfing",
    zeglarstwo: "zeglarstwo",
    "lodz-motorowa": "lodz-motorowa",
    kajaki: "kajak",
    sup: "sup",
    nurkowanie: "nurkowanie",
    "rowerek-wodny": "rowerek-wodny",
    surfing: "surfing",
    wakeboard: "wakeboard",
    inne: "inne",
    "lodz-wioslowa": "lodz-wioslowa",
    efoil: "efoil",
    wingfoil: "wing-foil",
    jetski: "skuter-wodny",
  },
};
