export const reservations = {
  guests: "gości",
  accept: "zaakceptuj",
  reject: "odrzuć",

  // timersie components

  // reservations

  booking: {
    price: "Cena",
    booking: "Rezerwacja",
    book: "Rezerwuj",
    next: "Dalej",
    availableDates: "Terminy dostępne ",
    notAvailable: "Brak wolnych terminów",
    reservationSent: "Rezerwacja wysłana",
    selectOfferFirst: "Wybierz ofertę",
    discardButton: "Usuń",
    timeZone: "Strefa czasowa dla Polska, Warszawa (GMT +2)",

    details: {
      guests: "Goście:",
      services: "Usługi:",
      datesChosen: "Wybrane daty:",
      offer: "Oferta",
      requiredServices: "Wymagane usługi:",
    },
  },

  contactGuest: {
    contactPerson: "Contact person",
    name: "Imię",
    surname: "Nazwisko",
    email: "E-mail",
    phone: "Telefon",
    additionalRequests: "Dodatkowe prośby",
    required: "*wymagane",
    emailFormat: "Wymagany format: xyz@abc.com",
  },

  clientInfo: {
    clientInfo: "Informacje o gościach",
    guest: "Gość",
    labels: {
      name: "Imię",
      surname: "Nazwisko",
      email: "E-mail",
      phone: "Telefon",
      dateOfBirth: "Data urodzenia",
      additionalRequests: "Dodatkowe prośby",
      guest: "Gość #",
      wetsuitSize: "Rozmiar",
      parentName: "Imię rodzica",
      parentSurname: "Nazwisko rodzica",
      parentNumber: "Numer rodzica",
      age: "Wiek",
      weight: "Waga (kg)",
      height: "Wzrost (cm)",
      skill: "Umiejętności",
      skills: {
        firstTime: "Pierwszy raz",
        beginner: "Początkujący",
        intermediate: "Średniozaawansowany",
        advanced: "Zaawansowany",
        pro: "Pro",
      },
    },
  },

  summary: {
    confirmation: "Potwierdzenie",
    offer: "Oferta:",
    guests: "Goście:",
    dates: "Daty:",
    price: "Cena:",
    requests: "Prośby:",
    contactPerson: "Osoba do kontaktu",
    guestsDetails: "Szczegóły gości:",
    guest: "Gość",
    terms: "Zasady i warunki",
    conditionsOfParticipation: "Warunki uczestnictwa",
    swimmingCertificate: "Oświadczenie o umiejętności pływania",
    riskStatement: "Oświadczenie o zagrożeniach",
    useTheImage: "Zgoda na wykorzystanie wizerunku",
    cancellationPolicy: "Zasady anulowania",
    learnMore: "Zobacz więcej",
    optional: "*opcjonalne",
  },
};
