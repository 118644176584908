// const API_URL = "http://127.0.0.1:8000";

const API_URL = "https://2rxn7z71n9.execute-api.eu-central-1.amazonaws.com/dev";

/**
 Config of all environment variables - instead of using process.env... inside the code,
 we just can import the environment object.
 */

export const environment = {
  apiUrl: process.env.REACT_APP_API_URL || API_URL,
  apiKey: process.env.REACT_APP_API_KEY || "",
  strapiUrl: process.env.REACT_APP_STRAPI_URL || "",
  syncfusionKey: process.env.REACT_APP_SYNCFUSION_KEY || "",
};
