import React, { useMemo, useState } from "react";
import moment, { Moment } from "moment/moment";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { OfferModel, ServicesModel } from "../../../models";
import { getAvailability, Slots } from "../../../server/availability/availability.server";
import { FormValues } from "../components/contact-person/contact-person.types";
import { GuestData } from "../components/quests-details/guests-details.types";
import { calculateTotalPrice } from "./slots-view.context";

export interface WorkerInterface {
  id: number;
  full_name: string;
  service_id: number;
}

type SlotsViewOwnContextType = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  addGuest: () => void;
  removeGuest: () => void;
  guests: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  selectedServices: ServicesModel[];
  setSelectedServices: React.Dispatch<React.SetStateAction<ServicesModel[]>>;
  slotsSubmitting: boolean;
  slots: Slots[] | null;
  formData: FormValues | null;
  setFormData: React.Dispatch<React.SetStateAction<FormValues | null>>;
  selectedDate: moment.Moment;
  setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setSelectedSlots: React.Dispatch<React.SetStateAction<Slots[]>>;
  selectedSlots: Slots[];
  totalPrice: number;
  guestsData: GuestData[];
  setGuestsData: React.Dispatch<React.SetStateAction<GuestData[]>>;
  serviceSelectedWorker: WorkerInterface[];
  setServiceSelectedWorker: React.Dispatch<React.SetStateAction<WorkerInterface[]>>;
};

export const SlotsViewOwnContext = React.createContext<SlotsViewOwnContextType>({
  step: 1,
  setStep: () => {},
  addGuest: () => {},
  removeGuest: () => {},
  guests: 1,
  handleNextStep: () => {},
  handlePrevStep: () => {},
  selectedServices: [],
  setSelectedServices: () => {},
  slotsSubmitting: false,
  slots: [],
  formData: null,
  setFormData: () => {},
  selectedDate: moment(),
  setSelectedDate: () => {},
  selectedSlots: [],
  setSelectedSlots: () => {},
  totalPrice: 0,
  guestsData: [],
  setGuestsData: () => {},
  serviceSelectedWorker: [],
  setServiceSelectedWorker: () => {},
});

interface Props {
  children: React.ReactNode;
  offer: OfferModel;
}

export const SlotsViewOwnContextProvider: React.FC<Props> = ({ children, offer }) => {
  const [step, setStep] = useState<number>(1);
  const [guests, setGuests] = useState<number>(1);
  const [selectedServices, setSelectedServices] = useState<ServicesModel[]>([]);
  const [formData, setFormData] = useState<FormValues | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedSlots, setSelectedSlots] = useState<Slots[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [guestsData, setGuestsData] = useState<GuestData[]>([]);
  const [serviceSelectedWorker, setServiceSelectedWorker] = useState<WorkerInterface[]>([]);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handlePrevStep = () => {
    if (step === 1) return;
    setStep((prevState) => prevState - 1);
  };

  const addGuest = () => {
    if (guests === offer?.num_of_opportunities) return;

    setGuests((prevState) => prevState + 1);
  };

  const removeGuest = () => {
    if (guests === 0) return;

    setGuests((prevState) => prevState - 1);
  };

  const getAvailableSlots = useSubmit(getAvailability);
  const { submit: submitSlots, data: slots, submitting: slotsSubmitting } = getAvailableSlots;

  useDidUpdate(
    () => {
      if (offer) {
        const totalPriceCounter = calculateTotalPrice(offer, selectedServices, selectedSlots, guests);
        setTotalPrice(totalPriceCounter);
      }
    },
    [offer, selectedServices, selectedSlots, guests],
    true,
  );

  useDidUpdate(
    () => {
      if (offer) {
        const startOfDayUTC = selectedDate
          .clone()
          .startOf("day")
          .add(1, "hours")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss[Z]");

        const endOfDayUTC = selectedDate.clone().add(1, "hours").endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

        const services = selectedServices
          .map((service) => {
            const currentServiceWorkers = serviceSelectedWorker.filter(
              (serviceWorker) => serviceWorker.service_id === service.id,
            );

            return currentServiceWorkers.map((serviceWorker) => ({
              id: service.id,
              user_id: serviceWorker.id,
            }));
          })
          .flat();

        const removeDuplicates = (data: { id: number; user_id: number }[]) => {
          const seen = new Set();
          return data.filter((item) => {
            const key = `${item.id}_${item.user_id}`;
            if (!seen.has(key)) {
              seen.add(key);
              return true;
            }
            return false;
          });
        };

        const uniqueServices = removeDuplicates(services);

        const data = {
          datetime_from: startOfDayUTC,
          datetime_to: endOfDayUTC,
          services: uniqueServices,
          offer_id: offer?.id || 0,
        };

        submitSlots({ data }).then();
      }
    },

    [selectedDate, selectedServices, guests, offer, serviceSelectedWorker],
    true,
  );

  useDidUpdate(() => {
    setSelectedSlots([]);
  }, [slots, guests, selectedServices]);

  useDidUpdate(
    () => {
      if (offer?.services.length === 0) return;

      const requiredServices: ServicesModel[] = [];

      offer?.services.forEach((service) => {
        if (!service.required) return;

        requiredServices.push(service);
      });

      if (requiredServices.length !== 0) {
        setSelectedServices(requiredServices);
      }
    },
    [offer],
    true,
  );

  const contextValue = useMemo(
    () => ({
      step,
      setStep,
      removeGuest,
      addGuest,
      guests,
      handlePrevStep,
      handleNextStep,
      selectedServices,
      setSelectedServices,
      slotsSubmitting,
      slots,
      formData,
      setFormData,
      selectedDate,
      setSelectedDate,
      selectedSlots,
      setSelectedSlots,
      totalPrice,
      guestsData,
      setGuestsData,
      serviceSelectedWorker,
      setServiceSelectedWorker,
    }),
    [
      step,
      guests,
      selectedServices,
      slotsSubmitting,
      slots,
      formData,
      selectedDate,
      selectedSlots,
      totalPrice,
      guestsData,
      serviceSelectedWorker,
    ],
  );

  return <SlotsViewOwnContext.Provider value={contextValue}>{children}</SlotsViewOwnContext.Provider>;
};
