import React from "react";
import classNames from "classnames";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { ProfileItemProps } from "../profile.types";
import { FormTextField } from "components";
import { inputProps, inputStyles } from "../profile-description/profie-description.constants";
import { RentalData } from "server/organization/organization.types";
import { tooltipConfig } from "../../create-rental.constants";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip-icon.svg";

import styles from "./profile-rules.module.scss";
import mainStyles from "../profile.module.scss";

export const ProfileRules: React.FC<ProfileItemProps> = ({ className }) => {
  const { t } = useTranslation();
  const {
    values: { rules },
  } = useFormikContext<RentalData>();

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.header}>
        <Typography className={classNames(mainStyles.text, styles.text)}>{t("rental.profile.addYourRules")}</Typography>
        <Tooltip
          title={t("rental.profile.tooltip.rules")}
          enterTouchDelay={0}
          leaveDelay={3000}
          className={styles.tooltip}
          componentsProps={tooltipConfig}
        >
          <TooltipIcon />
        </Tooltip>
      </div>
      <FormTextField
        name="rules"
        className={styles.textField}
        variant="standard"
        multiline
        rows={6}
        sx={inputStyles}
        InputProps={inputProps}
        placeholder={t("rental.profile.placeholders.rules")}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength: 5000 }}
      />
      <Typography className={mainStyles.counter}>{rules.length}/5000</Typography>
    </div>
  );
};
