import React from "react";

import { ManagementContextProvider } from "./management.context";
import { Management } from "./management";

export const ManagementPage = () => {
  return (
    <ManagementContextProvider>
      <Management />
    </ManagementContextProvider>
  );
};
