import React from "react";

import { ExtraCharges } from "./extra-charges/extra-charges";
import { RentalDetails } from "./rental-details/rental-details";
import { BoatDetails } from "./boat-details/boat-details";
import { AdditionalServices } from "./additional-services/additional-services";
import { DetailsProps, ParamsData } from "./details.types";

import styles from "./details.module.scss";

export const Details: React.FC<DetailsProps> = ({ offer }) => {
  const params: ParamsData = { ...offer.parameters };
  delete params.num_of_captains;
  delete params.num_of_instructor;

  const paramsValues = Object.values(params).every((item) => item === null);

  const requiredServices = offer.services.filter((service) => service.required);
  const additionalServices = offer.services.filter((service) => !service.required);

  return (
    <div className={styles.container}>
      <RentalDetails guests={offer.num_of_opportunities} watersport={offer.watersport} services={offer.services} />
      {!paramsValues && <BoatDetails parameters={params} currency={offer.currency} />}
      {requiredServices.length !== 0 && <ExtraCharges services={requiredServices} />}
      {additionalServices.length !== 0 && <AdditionalServices services={additionalServices} />}
    </div>
  );
};
