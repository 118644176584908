export const register = {
  register: "Zarejestruj się",
  registerTitle: "Załóż konto",
  resetPassword: "Zmień hasło",
  resetPasswordTitle: "Zmień hasło",
  or: "lub",
  firstNameLabel: "Imię",
  lastNameLabel: "Nazwisko",
  emailLabel: "Email",
  passwordLabel: "Hasło",
  phoneLabel: "Telefon",
  passwordRequirements: "* Hasło musi zawierać minimum 6 znaków",
  loginLabel: "Masz już konto?",
  loginToYourAccount: "Zaloguj się",
  repeatPasswordLabel: "Powtórz hasło",
  externalGoogle: "Zarejestruj się przez Google",
  externalFacebook: "Zarejestruj się przez Facebook",
  externalApple: "Zarejestruj się przez Apple",
  looksGood: "Wygląda spoko",
  agreement: "Chcę otrzymywać wiadomości i treści od SeaEasy.",
  third_party_agreement:
    "Udostępnij dostawcom treści w SeaEasy dane z mojej rejestracji. Informacje te mogą byc wykorzystywane do celów marketingowych.",

  firstNameRequired: "Imię jest wymagane",
  lastNameRequired: "Nazwisko jest wymagane",
  invalidEmail: "Adres email jest niepoprawny",
  emailRequired: "Email jest wymagany",
  passwordRequired: "Hasło jest wymagane",
  passwordsMatch: "Hasła muszą się zgadzać",
  retypePassword: "Powtórz hasło",
  invalidPhoneNumber: "Numer telefonu jest niepoprawny",
  phoneNumberRequired: "Numer telefonu jest wymagany",
  minimumLength: "Hasło musi mieć minimum 6 znaków",

  info: "Logując się lub tworząc konto zgadzasz się z naszą ",
  privacyPolicy: "Polityką prywatności",
  cookiesPolicy: "cookies",
  and: "oraz",
  termsOfUse: "Regulaminem",

  successText: "Zweryfikuj swoje konto poprzez link otrzymany na swoją skrzynkę pocztową",
  registerError: "Błąd podczas tworzenia konta",
  resetPasswordError: "Błąd podczas zmiany hasła",
  resetPasswordSuccess: "Pomyślnie zmieniono hasło",
  tokenValidationError: "Link do zmiany hasła jest niepoprawny",
};
