import profilePicture from "assets/images/profilePicture.jpeg";

export const teamHoursData = [
  {
    picture: profilePicture,
    name: "Adam Łuczak",
    city: "Kretowiny",
    hours: 568,
    profit: 23312,
    id: 1,
  },
  {
    picture: profilePicture,
    name: "Oskar Kordacki",
    city: "Gdansk",
    hours: 231,
    profit: 2312,
    id: 2,
  },

  // {
  //   picture: profilePicture,
  //   name: "Adam Łuczak",
  //   city: "Kretowiny",
  //   hours: 568,
  //   profit: 23312,
  // },
  // {
  //   picture: profilePicture,
  //   name: "Oskar Kordacki",
  //   city: "Gdansk",
  //   hours: 231,
  //   profit: 2312,
  // },
];
