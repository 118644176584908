import { strapiBuilder } from "server/builder";
import { StrapiData } from "types";
import { ServerErrorType } from "server/server.types";
import { StrapiAuthorModel } from "models";
import { AuthorParams } from "./author.types";

export const getAuthor = strapiBuilder.createCommand<
  StrapiData<StrapiAuthorModel>,
  void,
  ServerErrorType,
  AuthorParams
>()({
  method: "GET",
  endpoint: "/api/authors/:authorId",
});

export const getAuthors = strapiBuilder.createCommand<
  StrapiData<StrapiAuthorModel[]>,
  void,
  ServerErrorType,
  AuthorParams
>()({
  method: "GET",
  endpoint: "/api/authors",
});
