import { builder } from "../builder";
import { ReservationData, ReservationSelfData } from "./booking.types";
import { ReservationModel } from "models";

export const postReservation = builder.createCommand<ReservationModel, ReservationData>()({
  endpoint: "/app/reservation",
  method: "POST",
  options: { timeout: 0 },
});

export const postReservationSelf = builder.createCommand<ReservationModel, ReservationSelfData>()({
  endpoint: "/app/reservation/organization",
  method: "POST",
  options: { timeout: 0 },
});
