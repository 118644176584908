export const components = {
  partners: "Partnerzy",
  findUs: "Znajdź nas w mediach społecznościowych",
  marketplace: "Wynajem",
  guides: "Poradniki",
  safety: "Bezpieczeństwo",
  startRenting: "Zacznij wypożyczać",
  experiences: "Doświadczenia",
  destinations: "Podróże",
  social: "Społeczność",
  news: "Wiadomości",
  blog: "Centrum Wiedzy", // updated
  about: "O nas",
  aboutSeaeasy: "O SeaEasy",
  termsOfUse: "Regulamin",
  help: "Pomoc",
  contactUs: "Kontakt",
  privacyPolicy: "Polityka prywatności",
  companyNews: "Wiadomości Firmowe",
  knowledgeCenter: "Centrum Wiedzy",
  toTop: "Do góry",
};
