import React from "react";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";

import { generateStripeLink } from "server";
import { AddPaymentsProps } from "./add-payments.types";
import { RootState } from "store";

import { ReactComponent as Logo } from "assets/icons/logo-blue.svg";

import styles from "./add-payments.module.scss";

export const AddPayments: React.FC<AddPaymentsProps> = ({ setIsModalOpen }) => {
  const { t } = useTranslation();
  const { organizationData } = useSelector((state: RootState) => state.organization);

  const stripeLinkData = useSubmit(generateStripeLink.setParams({ organization_id: organizationData?.id || 0 }));
  const { submit, onSubmitSuccess, submitting } = stripeLinkData;
  onSubmitSuccess((res) => {
    window.open(res.response.stripe_url, "_self");
  });

  const openStripe = async () => {
    await submit();
    setIsModalOpen(false);
  };

  const openLanding = () => setIsModalOpen(false);

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />

      <Typography className={styles.addPayment}>{t("createOffer.beforeYouAddOffer")}</Typography>

      <div className={styles.content}>
        <Button
          onClick={openStripe}
          className={classNames(styles.button, styles.createOfferButton)}
          disabled={submitting}
        >
          {submitting ? t("common.loading") : t("createOffer.addNow")}
        </Button>
        <Button onClick={openLanding} className={styles.button}>
          {t("createOffer.notNow")}
        </Button>
      </div>
    </div>
  );
};
