import React, { useContext, useState, Context } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CalendarViewContextType } from "../../wrapper/calendar-view.context";
import { ReservationsContext } from "../../reservations-modal.context";
import { Guests } from "./guests/guests";
import { Services } from "./services/services";
import { InfoDisplay } from "./info-display/info-display";
import { getViewContext } from "components/reservations/wrapper/wrapper.constants";
import { TERMS_OF_USE_PAGE } from "../../../../constants/routes.constants";
import { useRoutesPath } from "../../../../hooks";
import { SlotsViewContextType } from "../../wrapper/slots-view.context";
import { SelectedSlots } from "./selected-slots/selected-slots";
import { SelectedRange } from "./selected-range/selected-range";

import styles from "./summar.module.scss";

export const Summary = () => {
  const { t } = useTranslation();
  const { isCalendarView, isOwnReservation, offer } = useContext(ReservationsContext);
  const { localePath } = useRoutesPath();

  const { selectedServices, selectedRange, totalPrice } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>,
  );
  const { selectedSlots } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>,
  );

  const [isGuestsDetailsOpen, setIsGuestsDetailsOpen] = useState<boolean>(false);
  const [isServicesDetailsOpen, setIsServicesDetailsOpen] = useState<boolean>(false);

  const handleGuestsDetails = () => {
    setIsGuestsDetailsOpen((prevState) => !prevState);
  };

  const handleServicesDetails = () => {
    setIsServicesDetailsOpen((prevState) => !prevState);
  };

  const noServices = selectedServices.length === 0;
  const offerTitle = offer?.title || offer?.organization.name || "";

  // const totalPriceValueWithCurrency = `${totalPrice} ${offer?.currency}`;

  return (
    <div className={styles.container}>
      <InfoDisplay label={t("reservationsModal.summary.offer")} value={offerTitle} />

      {/*<InfoDisplay label="Total price" value={totalPriceValueWithCurrency} />*/}

      <Guests isOpen={isGuestsDetailsOpen} handleIsOpen={handleGuestsDetails} />

      {selectedSlots && <SelectedSlots />}
      {selectedRange && <SelectedRange />}

      {!noServices && <Services isOpen={isServicesDetailsOpen} handleIsOpen={handleServicesDetails} />}

      <InfoDisplay label={t("reservationsModal.summary.price")} value={`${totalPrice} ${offer?.currency}`} />

      <div className={styles.policy}>
        <p>
          {t("reservationsModal.summary.policy.byClickingTheButton")}{" "}
          {/*<Link className={styles.link} to={localePath({ route: TERMS_OF_USE_PAGE })}>*/}
          {/*  {t("reservationsModal.summary.policy.rentalRules")}{" "}*/}
          {/*</Link>*/}
          <Link className={styles.link} target="_blank" to={localePath({ route: TERMS_OF_USE_PAGE })}>
            {t("reservationsModal.summary.policy.seaEasyTerms")}.
          </Link>
          {/*<Link className={styles.link} to={localePath({ route: TERMS_OF_USE_PAGE })}>*/}
          {/*  {t("reservationsModal.summary.policy.cancellationPolicy")}{" "}*/}
          {/*</Link>{" "}*/}
          {/*<Link className={styles.link} to={localePath({ route: PRIVACY_POLICY_PAGE })}>*/}
          {/*  {t("reservationsModal.summary.policy.paymentTerms")}*/}
          {/*</Link>*/}
        </p>
      </div>
    </div>
  );
};
