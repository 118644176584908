export const reservationsModal = {
  booking: "Booking",
  error: "Error",
  book: "Book",
  selectOffer: "Select offer",
  noAvailableSlots: "No slots available for the selected day",
  reservationSent: "Your reservation has been successfully sent",
  details: {
    offer: "Offer:",
    guests: "Guests:",
    selectedServices: "Selected services:",
    additionalServices: "Additional services:",
    changeWorker: "Changer worker",
  },
  contactPerson: {
    contactPerson: "Contact person",
    name: "Name",
    surname: "Surname",
    telephone: "Telephone",
    schema: {
      nameIsRequired: "Name is required",
      surnameIsRequired: "Surname is required",
      emailIsRequired: "Email is required",
      telephoneIsRequired: "Telephone is required",
    },
  },
  summary: {
    offer: "Offer",
    price: "Price",
    selectedSlots: "Selected dates:",
    guests: {
      guests: "Guests",
    },
    services: {
      services: "Services",
      moreDetails: "More details",
      title: "Title:",
      description: "Description:",
      price: "Price:",
      required: "Required service:",
      edit: "Edit",
      yes: "Yes",
      no: "No",
    },
    policy: {
      byClickingTheButton: "By clicking the button below I agree with",
      rentalRules: "Rental rules",
      seaEasyTerms: "SeaEasy Terms of Use",
      cancellationPolicy: "Cancellation policy,",
      paymentTerms: "Payment terms.",
    },
  },
};
