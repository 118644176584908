import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Rate } from "antd";

import { RentalPlaceProps } from "./rental-place.types";

import rentalProfile from "assets/images/destination4.jpeg";

import styles from "./rental-place.module.scss";

export const RentalPlace: React.FC<RentalPlaceProps> = ({ organization }) => {
  const { t } = useTranslation();

  const avatar = organization.organization_images.filter((image) => image.is_avatar === true);

  return (
    <div className={styles.container}>
      <Typography fontFamily="Chillax-Semibold" className={styles.title}>
        {t("offerPage.aboutRental.aboutRentalPlace")}
      </Typography>

      <div className={styles.content}>
        <img src={avatar[0] ? avatar[0].image_url : rentalProfile} alt="" className={styles.image} />
        <div className={styles.contentWrapper}>
          <Typography className={styles.name} fontFamily="Chillax-Medium">
            {organization.name}
          </Typography>
          <Rate value={5} disabled className={styles.rating} />
          <Typography className={styles.address} fontFamily="Chillax-Regular">
            {organization.location?.city}, {organization.location?.region}, {organization.location?.country}
          </Typography>
        </div>
      </div>

      <Typography>{organization.description}</Typography>

      {/* <Button className={styles.button}>{t("offerPage.aboutRental.seeMore")}</Button> */}
    </div>
  );
};
