import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWillUnmount } from "@better-typed/react-lifecycle-hooks";

import { ManagementContext } from "../management.context";
import { useQueryParams } from "hooks";
import { fullScreenContent } from "../management.constants";
import { GuestsDetails } from "./guests-details/guests-details";
import { EmployeesDetails } from "./employees-details/employees-details";

import styles from "./full-screen-section.module.scss";

export const FullScreenSection = () => {
  const { updateQueryParams, query } = useQueryParams();
  const { setIsFullScreenSectionOpen } = useContext(ManagementContext);

  const closeSection = () => setIsFullScreenSectionOpen(false);

  useWillUnmount(() => {
    updateQueryParams({ ...query, id: null, content: null });
  });

  return (
    <div className={styles.container}>
      <IconButton onClick={closeSection} className={styles.closeButton}>
        <CloseIcon />
      </IconButton>

      {query.content === fullScreenContent.teamHours && <EmployeesDetails />}
      {query.content === fullScreenContent.guestsDetails && <GuestsDetails />}
    </div>
  );
};
