import React, { useContext } from "react";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ItemBoxDesktopProps } from "./item-box-desktop.types";
import { ManagementContext } from "../../../management.context";

import styles from "./item-box-desktop.module.scss";

export const ItemBoxDesktop: React.FC<ItemBoxDesktopProps> = ({ name, city, profit, hours, picture, id }) => {
  const { t } = useTranslation();
  const { openTeamHours } = useContext(ManagementContext);

  return (
    <div className={styles.container}>
      <Container onClick={() => openTeamHours(id)} className={styles.content}>
        <img src={picture} className={styles.image} alt={name} style={{ gridColumn: "1/2" }} />
        <div className={styles.wrapper} style={{ gridColumn: "2/4" }}>
          <Typography className={styles.bold}>{name}</Typography>
          <Typography className={styles.light}>{city}</Typography>
        </div>
        <div className={styles.wrapper} style={{ gridColumn: "4/5" }}>
          <Typography className={styles.bold}>{hours}</Typography>
          <Typography className={styles.light}>{t("insights.teamHours.hours")}</Typography>
        </div>
        <div className={styles.wrapper} style={{ gridColumn: "6/7" }}>
          <Typography className={styles.boldOrange}>{profit}</Typography>
          <Typography className={styles.light}>{t("insights.teamHours.profit")}</Typography>
        </div>
      </Container>
    </div>
  );
};
