export const login = {
  loginTitle: "Sign in",
  or: "or",
  emailLabel: "Email",
  passwordLabel: "Password",
  registerLabel: "Don't have an account?",
  forgotPasswordTitle: "Change your password",
  forgotPasswordLabel: "Forgot your password?",
  forgotPasswordInfo: "Reset password link will be sent to you by email",
  forgotPassword: "Send",
  createAccount: "Create an account",
  externalGoogle: "Continue with Google",
  externalFacebook: "Continue with Facebook",
  externalApple: "Continue with Apple",
  login: "Login",

  invalidEmail: "This e-mail address is invalid",
  emailRequired: "E-mail is required",
  passwordRequired: "Password is required",

  loginError: "Error while logging in the user",
  forgotPasswordError: "Error while requesting for password change",
  forgotPasswordSuccess: "Password change email has been sent",
};
