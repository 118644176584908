import React from "react";
import { IconButton, ListItem, Typography } from "@mui/material";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { NavigationProps } from "./navigation.types";
import { RentalData } from "server/organization/organization.types";

import { ReactComponent as OpenMenuIcon } from "assets/icons/open-menu-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import styles from "./navigation.module.scss";

export const Navigation: React.FC<NavigationProps> = ({
  steps,
  isOpen,
  step,
  openNavigation,
  setStep,
  setIsOpen,
  isDesktop,
}) => {
  const { validateForm } = useFormikContext<RentalData>();
  const { t } = useTranslation();

  const toggleNavigation = () => {
    openNavigation((prevState) => !prevState);
  };

  const changeSection = (indexToSet: number) => {
    let stepToSet = indexToSet + 1;

    if (!isDesktop) {
      setIsOpen(false);
    }

    if (indexToSet < step) return setStep(stepToSet);

    if (indexToSet > step + 1) {
      stepToSet -= 1;
    }
    validateForm().then((res) => {
      if (Object.keys(res).length === 0) {
        setStep(stepToSet);
      }
    });

    if (!isDesktop) {
      setIsOpen(false);
    }
  };

  return (
    <>
      {!isDesktop && (
        <div className={classNames(styles.container, { [styles.containerMenuIsOpen]: isOpen })}>
          <div className={styles.navigation}>
            <div className={styles.stepsPreview}>
              <Typography>
                {t("rental.navigation.step")} {step}:
              </Typography>
              <Typography>{steps[step].name}</Typography>
            </div>

            <OpenMenuIcon
              onClick={toggleNavigation}
              className={classNames(styles.button, { [styles.buttonOpenMenu]: isOpen })}
            />
          </div>

          {isOpen && (
            <ul className={styles.mobileMenuOpen}>
              {steps.slice(1).map((stepItem, index) => {
                const isActiveStep = index + 1 === step;
                const disabled = index + 1 > step + 1;

                return (
                  <ListItem
                    key={stepItem.name}
                    className={classNames(
                      styles.stepButton,
                      isActiveStep && styles.stepButtonActive,
                      disabled && styles.disabled,
                    )}
                    onClick={disabled ? () => {} : () => changeSection(index)}
                  >
                    <Typography className={styles.stepItem}>{stepItem.name}</Typography>
                    <div className={classNames(styles.pointer, { [styles.activePointer]: isActiveStep })} />
                  </ListItem>
                );
              })}
            </ul>
          )}
        </div>
      )}
      {isDesktop && (
        <div className={classNames(styles.container, isOpen && styles.containerMenuIsOpen)}>
          {isOpen && (
            <>
              <Typography className={styles.title}>{t("rental.navigation.createYourRental")}</Typography>

              <ul className={styles.mobileMenuOpen}>
                {steps.slice(1).map((stepItem, index) => {
                  const isActiveStep = index + 1 === step;
                  const disabled = index + 1 > step + 1;

                  return (
                    <ListItem
                      key={stepItem.name}
                      className={classNames(
                        styles.stepButton,
                        isActiveStep && styles.stepButtonActive,
                        disabled && styles.disabled,
                      )}
                      onClick={disabled ? () => {} : () => changeSection(index)}
                    >
                      <Typography className={styles.stepItem}>{stepItem.name}</Typography>
                      <div className={classNames(styles.pointer, isActiveStep && styles.activePointer)} />
                    </ListItem>
                  );
                })}
              </ul>
            </>
          )}
          <div className={styles.arrowIcon}>
            <IconButton
              onClick={toggleNavigation}
              className={classNames(styles.iconButton, { [styles.buttonOpenMenu]: isOpen })}
            >
              <ArrowIcon />
            </IconButton>
            {!isOpen && (
              <Typography className={styles.stepText}>
                {t("rental.navigation.step")} {step} / {steps.length - 1}
              </Typography>
            )}
          </div>
        </div>
      )}
    </>
  );
};
