import React from "react";

import { partners } from "./partners.constants";

import styles from "./partners.module.scss";

export const Partners: React.FC = () => {
  return (
    <div className={styles.container}>
      {partners.map((partner) => (
        <img key={partner.name} className={styles.partner} src={partner.image} alt="Partner" />
      ))}
    </div>
  );
};
