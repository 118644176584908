import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { OffersBox } from "./offers-box/offers-box";
import { OFFERS_PAGE } from "constants/routes.constants";
import { getTopOffers } from "server";
import { useRoutesPath, useShowContent, useWindowSize } from "hooks";
import { StrapiTopOffersModels } from "models";
import { CarouselComponent, Loader } from "components";
import { OffersProps } from "./offers.types";
import { responsive } from "./offers.constants";

import styles from "./offers.module.scss";
import stylesMain from "../home.module.scss";

export const Offers: React.FC<OffersProps> = ({ refElement }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();
  const { width } = useWindowSize();

  const [topOffers, setTopOffers] = useState<null | StrapiTopOffersModels[]>(null);

  const topOffersData = useFetch(getTopOffers.setQueryParams({ populate: "*" }));
  const { showContent, showLoader } = useShowContent(topOffersData);
  const { onSuccess } = topOffersData;
  onSuccess((response) => {
    setTopOffers(response.response.data);
  });

  const openOffer = () => navigate(localePath({ route: OFFERS_PAGE }));
  const isDesktop = width > 992;

  return (
    <div className={stylesMain.itemContainer} ref={refElement}>
      <div className={stylesMain.itemHeader}>
        <Typography variant="h2" className={stylesMain.itemTitle}>
          {t("home.offers.topOffers")}
        </Typography>
        <Typography variant="h3" className={stylesMain.itemSubtitle}>
          {t("home.offers.content")}
        </Typography>
      </div>

      <div className={styles.content}>
        {showLoader && <Loader />}
        {showContent && topOffers && isDesktop && (
          <CarouselComponent customResponsive={responsive} arrowsHidden noOverflow>
            {topOffers.map((offer) => (
              <OffersBox offer={offer} />
            ))}
          </CarouselComponent>
        )}
        {showContent && topOffers && !isDesktop && (
          <CarouselComponent customResponsive={responsive} arrowsHidden>
            {topOffers?.map((offer) => (
              <OffersBox offer={offer} />
            ))}
          </CarouselComponent>
        )}
      </div>

      <Button className={stylesMain.itemButton} onClick={openOffer}>
        {t("home.offers.seeMore")}
      </Button>
    </div>
  );
};
