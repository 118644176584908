import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { ResetPasswordForm } from "./reset-password-form/reset-password-form";
import { HOME_PAGE } from "constants/routes.constants";
import { Navbar } from "components";
import { validateRestPasswordToken } from "server";
import { useRoutesPath } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-name.svg";

import styles from "./reset-password.module.scss";

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { localePath } = useRoutesPath();

  const [searchParams] = useSearchParams();
  const [tokenValid, setTokenValid] = useState<boolean>(true);

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  const token: string | null = searchParams.get("token");
  const { onError } = useFetch(validateRestPasswordToken.setQueryParams({ token }));
  onError(() => {
    setTokenValid(false);
    enqueueSnackbar(t("register.tokenValidationError"), { variant: "error" });
  });

  return (
    <div className={styles.container}>
      <Navbar className={styles.mobile} />
      <div className={classNames(styles.banner, styles.desktop)}>
        <Logo onClick={openHomePage} className={styles.logo} />
      </div>
      <div className={styles.content}>
        <ResetPasswordForm token={token || ""} tokenValid={tokenValid} />
      </div>
    </div>
  );
};
