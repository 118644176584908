import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { NoOffersProps } from "./no-offers.types";

import { ReactComponent as Pineapple } from "assets/icons/pineapple-icon.svg";

import styles from "./no-offers.module.scss";

export const NoOffers: React.FC<NoOffersProps> = ({ showAllOffers }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Pineapple className={styles.icon} />
      <div className={styles.content}>
        <Typography className={styles.text}>{t("offersList.noOffers")}</Typography>
        <Button className={styles.button} onClick={showAllOffers}>
          {t("offersList.showAllOffers")}
        </Button>
      </div>
    </div>
  );
};
