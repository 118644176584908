export const offerPage = {
  gallery: {
    details: "Szczegóły",
    rentalRules: "Rental rules",
    aboutRentalPlace: "O wypożyczalni",
    map: "Mapa",
    reviews: "Opinie",
  },

  offerDescription: {
    comments: "{{comments}} komentarzy",
  },

  details: {
    title: "Szczegóły",
    rentalDetails: {
      rentalDetails: "Szczegóły wynajmu",
      captainRequired: "Wymagany kapitan",
      captainAvailable: "Dostępny kapitan",
      captainNotAvailable: "Kapitan niedostępny",
      instructorAvailable: "Dostępny instruktor",
      instructorNotAvailable: "Instruktor niedostępny",
      guests: "Maks gości",
      whatDoINeedToKnow: "Co muszę wiedzieć?",
      whatAreTheBoatDetails: "Jakie są parametry łodzi?",
      areThereAnyExtraCharges: "Jakie są wymagane opłaty dodatkowe?",
      areThereAnyAdditionalServices: "Czy są dostępne dodatkowe udogodnienia?",
    },
    boatDetails: {
      boatDetails: "Szczegóły łodzi",
      length: "Długość",
      year: "Rok",
      manufacturer: "Producent",
      productionYear: "Rok produkcji",
      deposit: "Kaucja",
    },
    extraCharges: {
      extraCharges: "Dodatkowe opłaty",
    },
    additionalServices: {
      additionalServicesAvailable: "Dostępne dodatkowe usługi",
      captain: "kapitan",
      instructor: "instruktor",
    },
  },

  rentalRules: {
    rentalRules: "Zasady wynajmu",
  },

  description: {
    description: "Czego mogę się spodziewać?",
  },

  aboutRental: {
    aboutRentalPlace: "Kto będzie gospodarzem?",
    seeMore: "Zobacz więcej",
  },

  map: {
    map: "Jak do was dotrzeć?",
    rentalPlace: "Gdzie jest główna siedziba?",
    boatPlace: "Gdzie jest sprzęt?",
  },

  checkOtherOffers: {
    checkOutOtherOffers: "Sprawdź inne oferty w ",
  },

  cart: {
    book: "Zarezerwuj teraz",
    addDates: "Dodaj daty",
    youWontBeCharged: "Płatność nie zostanie jeszcze naliczona",
    from: "Od",
    mandatory: "Obowiązkowe",
    optional: "Opcjonalne",
    howMuchWillIPay: "Ile zapłacę?",
    for: "za",
    totalFrom: "Razem od",

    guests: {
      guests: "Goście",
    },

    timePickers: {
      arrivalDate: "Data przyjazdu",
      departureDate: "Data wyjazdu",
      confirm: "Zatwierdź",
      cancel: "Anuluj",
    },

    services: {
      selectedServices: "Wybrane usługi",
      optionalServices: "Może dodatkowe usługi?",
      captain: "Kapitan",
      instructor: "Instruktor",
      oneOfPayment: "Ryczałt",
      perPerson: "Za osobę",
    },

    summary: {
      total: "Łącznie",
      rentalPrice: "Cena wynajmu",
      additionalServicesPrice: "Cena dodatkowych usług",
    },
  },

  similarOffers: "Jakie inne oferty polecacie?",

  sports: {
    windsurfing: "Windsurfing",
    kitesurfing: "Kitesurfing",
    sailing: "Żeglarstwo",
    motorboats: "Łódź motorowa",
    kayaks: "Kajak",
    sup: "SUP",
    diving: "Nurkowanie",
    pedalo: "Rowerek wodny",
    surfing: "Surfing",
    wakeboard: "Wakeboard",
    other: "Inne",
    rowingBoats: "Łódź wiosłowa",
    efoil: "Efoil",
    wingfoil: "Wing foil",
    jetSki: "Skuter wodny",
  },
};
