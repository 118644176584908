import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ArticleBoxProps } from "./article-box.types";
import { ARTICLE_PAGE } from "constants/routes.constants";
import { getLinkPath } from "utils/route-params";
import { useRoutesPath, useWindowSize } from "hooks";

import styles from "./article-box.module.scss";

export const ArticleBox: React.FC<ArticleBoxProps> = ({ title, coverMobile, coverDesktop, slug }) => {
  const { localePathParams } = useRoutesPath();
  const { width } = useWindowSize();

  const isMobile = width < 992;

  return (
    <Link to={getLinkPath(localePathParams({ route: ARTICLE_PAGE, params: { slug } }))} className={styles.container}>
      <div className={styles.imageBox}>
        <img src={isMobile ? coverMobile : coverDesktop} className={styles.image} alt="article" />
      </div>

      <div className={styles.content}>
        <Typography fontFamily="Chillax-Regular" className={styles.text} />
        <Typography fontFamily="Chillax-Medium" fontSize="16px" className={styles.text} variant="h3">
          {title}
        </Typography>
      </div>
    </Link>
  );
};
