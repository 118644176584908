import React from "react";
import { TFunction } from "i18next";

import { OrganizationData, RentalData } from "server/organization/organization.types";
import { Step } from "./create-rental.types";
import { BeforeStart } from "./before-start/before-start";
import { Profile } from "./profile/profile";
import { Location } from "./location/location";
import { Payments } from "./payments/payments";
import { rentalProfileSchema } from "./profile/profile.constants";
import { locationSchema } from "./location/location.constants";

export const rentalInitialValues: RentalData = {
  avatar: null,
  name: "",
  description: "",
  rules: "",
  pictures: [],
  location_id: 0,
};

export const USER_NO_ORGANIZATION = "User is not associated with any organization";

export const createRentalProfileSteps = (t: TFunction): Step[] => {
  return [
    { component: <BeforeStart />, schema: null, name: t("rental.steps.beforeWeStart") },
    { component: <Profile />, schema: rentalProfileSchema(t), name: t("rental.steps.createYourProfile") },
    { component: <Location />, schema: locationSchema(t), name: t("rental.steps.addLocation") },
    { component: <Payments />, schema: null, name: t("rental.steps.addPayment") },
  ];
};

export const mapOrganizationData = (data: RentalData, id: number): OrganizationData => ({
  name: data.name,
  location: id,
  description: data.description,
  rules: data.rules,
  avatar_name: data.avatar?.name || "",
  image_names: data.pictures.map((image) => image.name),
});

export const tooltipConfig = {
  tooltip: {
    sx: {
      bgcolor: "#fff",
      color: "#474CAE",
      border: "2px solid #474CAE",
    },
  },
};
