import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import classNames from "classnames";

import { LoaderProps } from "./loader.types";

import Logo from "assets/images/logo-color.png";
import LogoWhite from "assets/images/logo-white.png";
import styles from "./loader.module.css";

export const Loader: React.FC<LoaderProps> = ({
  height,
  showText,
  className,
  color,
  backgroundColor,
  isWhiteLoader,
  showTransparency,
}) => {
  const { t } = useTranslation();

  return (
    <Box component="div" sx={{ height }} className={classNames(styles.wrapper, className)}>
      <Box
        component="div"
        className={classNames(styles.innerWrapper, { [styles.innerWrapperTransparency]: showTransparency })}
        style={{ backgroundColor }}
      >
        <div style={{ backgroundImage: `url(${isWhiteLoader ? LogoWhite : Logo})` }} className={styles.loader}>
          <div className={styles.eclipse} style={{ backgroundColor: color }} />
          {showText && (
            <Typography variant="body1" color="primary.light">
              {t("common.loading")}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
};
