import { useQueryParams } from "../hooks";

import styles from "./frame.module.scss";

export const Frame = () => {
  const { query } = useQueryParams();
  const isOwner = query.isOwner === "true";
  const isClient = query.isClient === "true";
  const { selectedOffer } = query;

  const allOffers = query.allOffers === "true";

  return (
    <div className={styles.container}>
      Frame content:
      <ul>
        {isOwner && <li>Owner Access</li>}
        {isClient && <li>Client Access</li>}

        {selectedOffer && <li>Selected Offer ID: {selectedOffer}</li>}
        {allOffers && <li>Listing All Offers</li>}
      </ul>
    </div>
  );
};
