import React, { useState } from "react";
import { useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { IconButton, Switch, TextField, Tooltip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { tooltipConfig } from "../../create-offer.constants";
import { inputStyles, initialAdditionalServices, switchSx } from "./additional-services-modal.constants";
import { AdditionalServicesData, OfferData } from "../../create-offer.types";
import { AdditionalServicesInitialData, AdditionalServicesModalProps } from "./additional-services-modal.types";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip.svg";

import styles from "./additional-services-modal.module.scss";

export const AdditionalServicesModal: React.FC<AdditionalServicesModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<OfferData>();

  const [additionalService, setAdditionalService] = useState<AdditionalServicesInitialData>(initialAdditionalServices);
  const [priceError, setPriceError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAdditionalService({
      ...additionalService,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (checked: boolean) => {
    setAdditionalService({
      ...additionalService,
      isFixed: checked,
    });
  };

  const addAdditionalService = () => {
    if (!priceError && !nameError) {
      const additionalServicesData: AdditionalServicesData = {
        id: uuid(),
        isRequired: false,
        isFixed: additionalService.isFixed,
        name: additionalService.name,
        price: +additionalService.price,
      };

      values.services.additionalServices.push(additionalServicesData);
    }
  };

  const validateData = () => {
    if (additionalService) {
      setValidating(true);

      if (
        additionalService.price === "" ||
        additionalService.price === 0 ||
        additionalService.price === "0" ||
        +additionalService.price < 1
      ) {
        setPriceError(true);
      } else {
        setPriceError(false);
      }

      if (additionalService.name.length > 0) {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
  };

  useDidUpdate(() => {
    if (!validating) return;

    if (!priceError && !nameError) {
      addAdditionalService();
      close();
    }
  }, [priceError, nameError, validating]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.title}>{t("createOffer.services.modal.addAdditionalServices")}</Typography>
        <div className={styles.control}>
          <IconButton onClick={validateData}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.fieldWrapper}>
          <Typography className={classNames(styles.itemTitle, nameError && styles.titleError)}>
            {t("createOffer.services.modal.name")}
          </Typography>
          <TextField
            value={additionalService.name}
            sx={inputStyles}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label=""
            name="name"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <Typography className={classNames(styles.itemTitle, priceError && styles.titleError)}>
            {t("createOffer.services.modal.price")}
          </Typography>
          <TextField
            value={additionalService.price}
            sx={inputStyles}
            variant="standard"
            InputProps={{ disableUnderline: true, inputProps: { min: 0, step: 0.01 } }}
            label=""
            name="price"
            onChange={(e) => handleChange(e)}
            type="number"
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.priceAdditional")}
            enterTouchDelay={0}
            className={styles.tooltip}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>

        <div className={styles.fieldWrapper}>
          <Typography className={styles.itemTitleCenter}>{t("createOffer.services.modal.isFixed")}</Typography>
          <Switch
            sx={switchSx}
            name="isFixed"
            value={additionalService.isFixed}
            onChange={(e, checked) => handleSwitch(checked)}
          />
          <Tooltip
            title={t("createOffer.services.tooltip.modal.fixedPriceAdditional")}
            enterTouchDelay={0}
            className={styles.tooltip}
            componentsProps={tooltipConfig}
          >
            <TooltipIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
