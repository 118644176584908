import React from "react";
import { ReactSVG } from "react-svg";

import { servicesData } from "./services.constants";

import styles from "./services.module.scss";

type ServiceProps = {
  color: string;
};

export const Services: React.FC<ServiceProps> = ({ color }) => {
  return (
    <div className={styles.container} style={{ borderColor: color }}>
      {servicesData.map((item) => (
        <ReactSVG src={item} className={styles.icon} />
      ))}
    </div>
  );
};
