export const reservations = {
  guests: "guests",
  accept: "accept",
  reject: "reject",

  // timersie components

  // reservations

  booking: {
    price: "Price",
    booking: "Booking",
    book: "Book",
    next: "Next",
    availableDates: "Slots available on ",
    notAvailable: "No vacancies",
    reservationSent: "Reservation sent",
    selectOfferFirst: "Select offer first",
    discardButton: "Discard",
    timeZone: "Time zone for Poland, Warsaw (GMT +2)",

    details: {
      guests: "Guests:",
      services: "Services:",
      datesChosen: "Dates chosen:",
      offer: "Offer",
      requiredServices: "Required services:",
    },
  },

  contactGuest: {
    contactPerson: "Contact person",
    name: "Name",
    surname: "Surname",
    email: "E-mail",
    phone: "Phone",
    additionalRequests: "Additional requests",
    required: "*required",
    emailFormat: "Required format: xyz@abc.com",
  },

  clientInfo: {
    clientInfo: "Client info",
    guest: "Guest",
    labels: {
      name: "Name",
      surname: "Surname",
      email: "E-mail",
      phone: "Phone",
      dateOfBirth: "Date of birth",
      additionalRequests: "Additional requests",
      guest: "Guest #",
      wetsuitSize: "Wetsuit size",
      parentName: "Parent name",
      parentSurname: "Parent surname",
      parentNumber: "Parent number",
      age: "Age",
      weight: "Weight (kg)",
      height: "Height (cm)",
      skill: "Skill",
      skills: {
        firstTime: "First time",
        beginner: "Beginner",
        intermediate: "Intermediate",
        advanced: "Advanced",
        pro: "Pro",
      },
    },
  },

  summary: {
    confirmation: "Confirmation",
    offer: "Offer:",
    guests: "Guests:",
    dates: "Dates:",
    price: "Price:",
    requests: "Requests:",
    contactPerson: "Contact person",
    guestsDetails: "Guests details:",
    guest: "Guest",
    terms: "Terms & Conditions",
    conditionsOfParticipation: "conditions of participation",
    swimmingCertificate: "Swimming certificate",
    riskStatement: "Risk Statement",
    useTheImage: "Consent to use the image",
    cancellationPolicy: "Cancellation policy",
    learnMore: "Learn more",
    optional: "*opcjonalne",
  },
};
