import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { accordionStyles } from "./accordion-item.constants";
import { AccodrionItemProps } from "./accordion-item.types";

import styles from "../help-center.module.scss";

export const AccordionItem: React.FC<AccodrionItemProps> = ({ title, content }) => {
  return (
    <Accordion className={styles.accordion} sx={accordionStyles}>
      <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography fontSize={14} fontWeight={300}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography fontSize={14} fontWeight={300}>
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
