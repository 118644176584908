import React from "react";
import { TFunction } from "i18next";

import { CompanyNews } from "./about/about";
import { Lifestyle } from "./lifestyle/lifestyle";
import { Destinations } from "./destinations/destinations";
import { Guides } from "./guides/guides";
import { Faq } from "./faq/faq";
import { KnowledgeCenterSection } from "./knowledge-center.types";

export const sections = (t: TFunction): KnowledgeCenterSection[] => [
  { value: "companyNews", label: t("seo.companyNews"), component: <CompanyNews /> },
  { value: "lifestyle", label: t("seo.lifestyle"), component: <Lifestyle /> },
  { value: "destinations", label: t("seo.destinations"), component: <Destinations /> },
  { value: "guides", label: t("seo.guides"), component: <Guides /> },
  { value: "safety", label: t("seo.safety"), component: <Faq /> },
];
