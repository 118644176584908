import { TFunction } from "i18next";

export const inputStyles = {
  color: "#000",
  input: {
    color: "#000",
    textAlign: "left",
    verticalAlign: "left",
    height: "56px",
    fontFamily: "Chillax-medium",
    margin: 0,
    padding: 0,
    paddingLeft: "8px",
  },
  backgroundColor: "#fff",
  borderRadius: "16px",
  border: "1px solid #474CAE",
  height: "56px",
  width: "100%",
};

export const labelProps = {
  shrink: true,
  sx: { paddingLeft: 1, color: "#fff", ml: 1, "&.Mui-focused": { color: "#aaa" } },
};

export const selectProps = {
  MenuProps: {
    BackdropProps: { sx: { opacity: 0.2 } },
    PaperProps: { sx: { backgroundColor: "#474CAE", color: "white", textTransform: "lowercase" } },
  },
};

export const selectStyles = {
  width: "200px",
  borderRadius: "10px",
  div: {
    color: "#595EB6",
    width: "100%",
    height: "56px",
    fontSize: "20px",
    "&::placeholder": { fontSize: 15 },
    borderRadius: "3px",
    div: {
      textAlign: "center",
      width: "100%",
      borderRadius: "16px",
      fontFamily: "Chillax-medium",
    },
  },
};

export const rolesOptions = (t: TFunction) => [
  {
    value: "admin",
    label: t("team.roles.admin"),
  },
  {
    value: "manager",
    label: t("team.roles.manager"),
  },
  {
    value: "instructor",
    label: t("team.roles.instructor"),
  },
  {
    value: "employee",
    label: t("team.roles.employee"),
  },
];
