export const navbar = {
  yachts: "Jachty",
  watersports: "Sporty wodne",
  allWatersports: "Wszystko",
  allYachts: "Wszystkie jachty",
  destinations: "Top kierunki",
  topDestinations: "Świat",
  inputPlaceholder: "Gdzie chcesz płynąć?",
  inputPlaceholderMobile: "Gdziekolwiek",
};
