import React from "react";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ItemBoxDesktopProps } from "./guest-box.types";
import { useQueryParams } from "hooks";

import styles from "./guest-box.module.scss";

export const GuestBox: React.FC<ItemBoxDesktopProps> = ({ name, nextBooking, id }) => {
  const { t } = useTranslation();
  const { query, updateQueryParams } = useQueryParams();

  const handleGuest = () => {
    updateQueryParams({ id });
  };

  const isActive = query.id === id.toString();

  return (
    <Container onClick={handleGuest} className={classNames(styles.container, { [styles.active]: isActive })}>
      <div className={styles.info}>
        <Typography className={styles.name}>{name}</Typography>
        <Typography className={styles.nextBooking}>
          <span>{t("insights.clientBookings.nextBooking")}</span>
          <br /> {nextBooking}
        </Typography>
      </div>

      <div className={styles.content}>
        <div className={styles.time}>
          <Typography>
            252h
            <br /> {t("insights.clientBookings.soFar")}
          </Typography>
          <Typography>
            28h <br /> {t("insights.clientBookings.remaining")}
          </Typography>
        </div>
        <div className={styles.progress}>
          <div />
        </div>
      </div>
    </Container>
  );
};
