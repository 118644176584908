import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ProfileProps } from "./profile.types";

import profile from "assets/images/profilePicture.jpeg";

import styles from "./profile.module.scss";

export const Profile: React.FC<ProfileProps> = ({ author }) => {
  const { t } = useTranslation();

  const numOfArticles = author.attributes.articles.data.length;

  return (
    <div className={styles.profile}>
      <div className={styles.author}>
        <img src={profile} alt="" />
        <Typography variant="h1">{author.attributes.Name}</Typography>
        <Typography variant="h3">
          {t("author.profile.articles")} <span>{numOfArticles}</span>
        </Typography>
      </div>

      <div className={styles.separator} />

      <div className={styles.description}>
        <Typography variant="h2">{author.attributes.Description}</Typography>
      </div>
    </div>
  );
};
