import React from "react";
import { Typography } from "@mui/material";

import { PaymentItemProps } from "./payment-item.types";

import offerImage from "assets/images/offer2.jpeg";

import styles from "./payment-item.module.scss";

export const PaymentItem: React.FC<PaymentItemProps> = ({ paymentItem }) => {
  const { offerTitle, date, payment } = paymentItem;

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <img src={offerImage} alt={offerTitle} className={styles.image} />
        <div className={styles.leftSectionWrapper}>
          <Typography className={styles.bold}>
            {offerTitle}, {date}
          </Typography>
          <Typography className={styles.light}>
            {payment.method} {payment.number}
          </Typography>
        </div>
      </div>
      <div className={styles.rightSection}>
        <Typography className={styles.bold}>
          {payment.value} {payment.currency}
        </Typography>
        <Typography className={styles.light}>
          {payment.status}, {date}
        </Typography>
      </div>
    </div>
  );
};
