import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t("reservationsModal.contactPerson.schema.nameIsRequired")),
    surname: Yup.string().required(t("reservationsModal.contactPerson.schema.surnameIsRequired")),
    email: Yup.string().email("Invalid email").required(t("reservationsModal.contactPerson.schema.emailIsRequired")),
    phoneNumber: Yup.string().required(t("reservationsModal.contactPerson.schema.telephoneIsRequired")),
    additionalRequests: Yup.string(),
  });
