import React, { useContext, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";

import { OfferData } from "../create-offer.types";
import { CreateOfferContext } from "../create-offer.context";
import { rateTypeTranslated } from "../create-offer.constants";
import { COUNTRIES_EN, COUNTRIES_PL } from "constants/countries.constants";
import { RootState } from "store";

import styles from "./preview.module.scss";

export const Preview = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<OfferData>();
  const { keyDetails, baseInfo } = values;
  const { organization, locations, useOrganizationImages } = useContext(CreateOfferContext);
  const { locale } = useSelector((state: RootState) => state.app);

  const [images, setImages] = useState<string[] | null>(null);

  const activeLocation = useMemo(() => {
    if (values.location.temporary_id)
      return locations?.find((locationItem) => locationItem.temporaryId === values.location.temporary_id);
    return locations?.find((locationItem) => locationItem.id === values.location.location_id);
  }, [locations, values.location.location_id, values.location.temporary_id]);

  const rateType = rateTypeTranslated(t, baseInfo.pricing.rateType);
  const country =
    locale === "pl-PL"
      ? COUNTRIES_PL.find(({ value }) => value === activeLocation?.country)?.label
      : COUNTRIES_EN.find(({ value }) => value === activeLocation?.country)?.label;

  useDidMount(() => {
    if (keyDetails.photos.length > 0 && !useOrganizationImages) {
      const addedImages = keyDetails.photos.map((imageItem) => URL.createObjectURL(imageItem));

      const currentImages: string[] = [];

      if (values.keyDetails.offerPhotos) {
        values.keyDetails.offerPhotos?.forEach((item) => {
          currentImages.push(item.image_url);
        });
      }

      const photos = [...addedImages, ...currentImages];

      setImages(photos);
    }
    if (organization?.organization_images && useOrganizationImages) {
      setImages(
        organization?.organization_images.filter(({ is_avatar }) => !is_avatar).map(({ image_url }) => image_url),
      );
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.carouselWrapper}>
        {images && (
          <Carousel infiniteLoop autoPlay useKeyboardArrows emulateTouch showStatus={false} showThumbs={false}>
            {images.map((image) => (
              <div key={image} style={{ backgroundImage: `url(${image})` }} className={styles.carouselItem} />
            ))}
          </Carousel>
        )}
      </div>
      <div className={styles.details}>
        <Typography className={styles.title}>{keyDetails.title}</Typography>
        <Typography className={styles.address}>
          {activeLocation?.city}, {activeLocation?.region && activeLocation.region}, {country}
        </Typography>

        <Typography className={styles.price}>
          {baseInfo.pricing.basePrice} {baseInfo.pricing.currency.toLowerCase()} {rateType.toLowerCase()}
        </Typography>
      </div>
      <div className={styles.description}>
        <Typography>{t("createOffer.preview.description")}</Typography>
        <Typography>{keyDetails.description}</Typography>
      </div>
    </div>
  );
};
