import { TFunction } from "i18next";

export const navigationData = (t: TFunction) => [
  {
    label: t("profile.navigation.personalInfo"),
    value: "personal info",
  },
  {
    label: t("profile.navigation.payments"),
    value: "payments",
  },
];
