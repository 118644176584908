export const rental = {
  steps: {
    step: "Step",
    steps: "Steps",
    beforeWeStart: "Before we start",
    createYourProfile: "Create your profile",
    addLocation: "Add location",
    addPayment: "Add payment method",
  },

  beforeStart: {
    beforeWeStart: "before we start, ",
    checkHowItWorks: "Check how it works!",
    content1:
      "SeaEasy will allow you to share your offer for water sports equipment, lessons and additional services via our platform completely for free!",
    content2:
      "When your offer is reserved by the client, you will receive a request to confirm the transaction. After accepting the reservation, SeaEasy will send to your account the funds from the client reduced by 10% commission on booking and the Stripe payment operator's commission, in the amount of PLN 1 and 1.5%.",
    letsStart: "Let's start",
  },

  profile: {
    setYourProfilePicture: "Set your profile picture",
    createYourName: "Create your name",
    createYourDescription: "Create your description",
    addYourRules: "Add rental rules",
    addYourPhotos: "Add pictures",

    placeholders: {
      name: "The name of your business eg. Cool Surf School...",
      description:
        "Description of your business eg. Cool Surf School is a place with the best instructors, great water sports equipment and location by the ocean. We have a history of providing perfect experience for our customers...",
      rules: "Add rules and terms for your business eg. Open hours or no pets allowed...",
    },

    validation: {
      pictureRequired: "Picture is required",
      nameRequired: "Name is required",
      descriptionRequired: "Description is required",
      rulesRequired: "Rules are required",
      minNumOfPhotos: "Required is at least 5 photos",
      photosRequired: "Photos are required",
    },

    tooltip: {
      photo: `Add a profile picture for your organization.
         Accepted formats: .jpg, .jpeg, .png
        Max size: 10MB`,
      photos: `Add at least 5 pictures of your organization.
         Accepted formats: .jpg, .jpeg, .png
        Max size: 10MB`,
      rules:
        "Rules of your organization will be presented with your offers. Please add here the terms and conditions that apply to your business such as open hours, information about documents required for renting or what is not allowed during rentals or lessons.",
    },
  },

  location: {
    confirmYourAddress: "Confirm your address",
    addYourLocation: "Add pin & confirm the address of your business",
    name: "Name",
    description: "Description (optional)",
    address: "Address",
    phoneNumber: "Phone number",
    country: "Country",
    region: "Region (optional)",
    city: "City",
    postalCode: "Postal code",

    validation: {
      nameRequired: "Name is required",
      addressRequired: "Address is required",
      cityRequired: "City is required",
      countryRequired: "Country is required",
      latitudeRequired: "Latitude is required",
      longitudeRequired: "Longitude is required",
      postalCodeRequired: "Postal code is required",
    },
  },

  rentalPayment: {
    addPaymentDetails: "Add payment & payout details via Stripe",
    paymentContent:
      "SeaEasy is working with Stripe to provide best payment and business management services for our clients. By using Stripe SeaEasy is ensuring safety and compliance of the entire payment process for all our clients. Stripe will now ask you for your organization details in order to provide financial services.",
    paymentDetails: "Payment details",
    completeDetails: "you need to complete the payment details",
    goToDataCompletion: "go to data completion",
    everythingIsOk: "Everything is ok",
    createOffer: "Create offer",
    checking: "Checking...",
    addDetails: "Add details",

    tooltip: {
      info: "Lorem lorem lorem",
    },

    success: {
      paymentAdded: "Stripe account created successfully.",
      addNewOffers:
        "Now you can add offers. If all the required data is not filled in, you will be redirected to the payment operator's website",
      addOffer: "Add new offer",
    },
  },

  submitButtons: {
    addPayments: "Add payments",
    close: "You are about to leave this site and your changes will be lost. Do you wish to leave?",
    yes: "Leave",
    no: "Stay",
    leave: "Exit",
    addPaymentsModalCopy: "asd",
  },

  messages: {
    createOrganizationSuccess: "Organization has been created!",
    createOrganizationError: "Could not create an organization",
    createLocationError: "Could not create a new location",
    haveOrganization: "You already have an organization",
  },

  navigation: {
    createYourRental: "create your rental",
    step: "step",
  },
};
