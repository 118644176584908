import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CaptainProps } from "./captain.types";

import styles from "./captain.module.scss";

export const Captain: React.FC<CaptainProps> = ({ captain, number, currency, priceValue }) => {
  const { t } = useTranslation();
  const { isRequired, price, isFixed } = captain;

  return (
    <div className={styles.container}>
      <div>
        <Typography fontSize="20px" fontFamily="Chillax-semibold">
          {t("createOffer.services.captain")} #{number + 1}
        </Typography>
      </div>
      <div>
        <Typography fontSize="20px" fontFamily="Chillax-semibold">
          {price} {currency}
        </Typography>
        {isFixed && (
          <Typography fontSize="14px" fontFamily="Chillax-medium">
            {t("createOffer.services.fixedPrice")}
          </Typography>
        )}
        {!isFixed && (
          <Typography fontSize="16px" fontFamily="Chillax-semibold">
            {priceValue}
          </Typography>
        )}
      </div>

      {isRequired && (
        <Typography fontSize="14px" fontFamily="Chillax-regular">
          *{t("createOffer.services.captainRequired")}
        </Typography>
      )}
    </div>
  );
};
