import React, { useRef, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import classNames from "classnames";
import Reaptcha from "reaptcha";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { useSelector } from "react-redux";

import { FormCheckbox, FormTextField, Loader } from "components";
import { initialValues, registerFields, registerSchema } from "./register-form.constants";
import { RegisterData } from "server/auth/auth.types";
import {
  COOKIES_POLICY_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_USE_PAGE,
} from "constants/routes.constants";
import { register } from "server";
import { useRoutesPath } from "hooks";
import { RootState } from "store";

import styles from "./register-form.module.scss";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const captchaRef = useRef<Reaptcha>(null);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { localePath, locale } = useRoutesPath();
  const { token } = useSelector((state: RootState) => state.auth);

  const [captchaToken, setCaptchaToken] = useState<null | string>(null);

  const { submit, submitting, onSubmitSuccess, onSubmitError } = useSubmit(register);
  onSubmitSuccess(() => {
    navigate(localePath({ route: LOGIN_PAGE }));
  });
  onSubmitError(() => {
    enqueueSnackbar(t("register.registerError"), { variant: "error" });
  });

  const handleRegister = async (values: RegisterData, { setSubmitting }: FormikHelpers<RegisterData>) => {
    if (captchaToken) {
      const data: RegisterData = {
        ...values,
        email: values.email.toLowerCase(),
        phone_number: values.phone_number.toString(),
      };
      setSubmitting(true);
      await submit({ data });
    }
  };

  const verify = () => {
    captchaRef?.current?.getResponse().then((res) => {
      setCaptchaToken(res);
    });
  };

  const localePl = locale === "pl-PL";

  useDidMount(() => {
    if (token) {
      navigate(localePath({ route: HOME_PAGE }));
    }
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerSchema(t)}
      onSubmit={handleRegister}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form className={styles.container}>
        {submitting && <Loader className={styles.loader} />}
        <Typography className={classNames(styles.title, { [styles.titlePl]: localePl })} variant="h2">
          {t("register.registerTitle")}
        </Typography>
        <div className={styles.content}>
          {registerFields(t).map((field) => (
            <div key={field.name} className={styles.inputBox}>
              <Typography ml="10px" mb="-10px" fontFamily="Chillax-Regular">
                {field.label}
              </Typography>
              <FormTextField {...field} label="" />
            </div>
          ))}

          <Reaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            size="normal"
            theme="light"
            ref={captchaRef}
            onVerify={verify}
          />

          <div className={styles.agreements}>
            <FormCheckbox name="seaeasy_agreement" label={t("register.agreement")} className={styles.checkBox} />
            <FormCheckbox
              name="third_party_agreement"
              label={t("register.third_party_agreement")}
              className={styles.checkBox}
            />
          </div>

          <div className={styles.submit}>
            <Typography className={styles.policy} sx={{ fontSize: 14, mt: 3, mb: 2, color: "#fff" }}>
              {t("register.info")}{" "}
              <Link className={styles.link} target="_blank" to={localePath({ route: PRIVACY_POLICY_PAGE })}>
                {t("register.privacyPolicy")}
              </Link>
              ,{" "}
              <Link className={styles.link} target="_blank" to={localePath({ route: COOKIES_POLICY_PAGE })}>
                {t("register.cookiesPolicy")}
              </Link>{" "}
              {t("register.and")}{" "}
              <Link className={styles.link} target="_blank" to={localePath({ route: TERMS_OF_USE_PAGE })}>
                {t("register.termsOfUse")}
              </Link>
            </Typography>
            <Button type="submit" className={styles.button} variant="contained" disabled={submitting}>
              {t("register.register")}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
