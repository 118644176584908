import { TFunction } from "i18next";

import { ServicesItemsData } from "./services.types";

export type ModalData = "captain" | "instructor" | "additional-services" | "extra-charges";

export const servicesData = (t: TFunction): ServicesItemsData[] => [
  {
    label: t("createOffer.services.captain"),
    type: t("createOffer.services.option"),
    name: "captain",
    tooltipContent: t("createOffer.services.tooltip.captain"),
  },
  {
    label: t("createOffer.services.instructor"),
    type: t("createOffer.services.option"),
    name: "instructor",
    tooltipContent: t("createOffer.services.tooltip.instructor"),
  },
  {
    label: t("createOffer.services.additionalServices"),
    type: t("createOffer.services.option"),
    name: "additional-services",
    tooltipContent: t("createOffer.services.tooltip.additionalServices"),
  },
  {
    label: t("createOffer.services.extraCharges"),
    type: t("createOffer.services.option"),
    name: "extra-charges",
    tooltipContent: t("createOffer.services.tooltip.extraCharges"),
  },
];
