export const account = {
  account: "Konto",
  personalInfo: "Twoje dane",
  paymentsPayouts: "Płatności",
  preferences: "Preferencje",
  helpCenter: "Centrum pomocy",

  // account
  goToProfile: "Przejdź do profilu",
  personalInfoContent: "Dodaj informacje o tym jak możemy sie z tobą skontaktować",
  preferencesContent:
    "Ustaw swój domyślny język, walutę czy strefę czasową. Zarządzaj powiadomieniania i udostepnianymi informacjami",
  paymentsPayoutsContent: "Sprawdź płatności, wypłaty czy podatki.",
  needDeactivate: "Chcesz usunąć konto?",
  deactivateNow: "Usuń teraz",

  // personal info
  legalName: "Imię",
  dateOfBirth: "Data urodzenia",
  email: "Adres email",
  address: "Adres",
  phoneNumber: "Telefon",
  password: "Hasło",
  changeYourProfile: "Zmień swoje zdjęcie profilowe",
  editDetails: "Które informacje mogę edytować?",
  editDetailsContent:
    "Informacje używane przez SeaEasy w celu weryfikacji tożsamości nie mogą być zmienione. Pozostałe informacje mogą być edytowane, jednak gdy kolejny raz będziesz dodawać lub rezerwować ofertę możesz zostać poproszony o weryfikację tożsamości.",
  sharedInfo: "Jakie informacje są udostępniane?",
  sharedInfoContent:
    "SeaEasy udostępnia informacje kontaktowe Gości i Właścicieli sprzętu dopiero po potwierdzeniu rezerwacji.",

  // payments
  yourPayments: "Twoje płatności",
  yourPaymentsContent: "Przeglądaj swoje płatności i zwroty",
  managePayments: "Zarządzaj płatnościami",
  paymentMethods: "Metody płatności",
  paymentMethodsContent: "Dodawaj i zarządzaj swoimi metodami płatnościami",
  addPaymentMethod: "Dodaj metodę płatności",
  payouts: "Wypłaty",
  payoutsConent: "Dodaj przynajmniej jeden sposób wypłaty",
  setUpPayouts: "Dodaj sposób wypłaty",
  vat: "VAT",
  vatContent:
    "Jeśli jesteś zarejestrowanym podatnikiem VAT lub jeśli twoja wyprawa jest służbowa, możesz wnioskować o zwrot VAT z opłaty serwisowej SeaEasy",
  addVatId: "Dodaj NIP",
  makeAllPayments: "Wykonuj wszystkie płatności przez SeaEasy",
  makeAllPaymentsContent:
    "Wszystkie płatności i wiadomości przesyłaj przez SeaEasy aby zapewnić zgodność z Regulaminem",
  makeAllPaymentsContentRest: "Polityka rezygnacji oraz pozostałe wsparcie.",
  needHelp: "Potrzebujesz pomocy?",
  checkOur: "Sprawdź nasze",
  needHelpContent: " gdzie możesz znaleźć listę często zadawanych pytań.",
  termsOfUse: "Regulamin",
  learnMore: "Dowiedz się więcej",

  // payments modal
  addCardDetails: "Dodaj dane karty",
  countryRegion: "Kraj / Region",
  countryRegionRequired: "Kraj / Region jest wymagany",
  cardNumber: "Numer karty",
  cardNumberRequired: "Numer karty jest wymagany",
  addCardNumber: "Dodaj numer karty",
  addCardNumberRequired: "Add Card Number is required", // ?????
  nameOnRegistration: "Imię i Nazwisko / Nazwa",
  nameOnRegistrationRequired: "Imię i Nazwisko / Nazwa jest wymagana",
  cvv: "CVV",
  cvvRequired: "CVV jest wymagane",
  zipCode: "Kod pocztowy",
  zipCodeRequired: "Kod pocztowy jest wymagany",

  // VAT modal
  addVATNumber: "Dodaj NIP",
  vatModalHeader:
    "Jeśli jesteś zarejestrowany w UE, weryfikacja może potrwać do 48 godzin. Po więcej informacji sprawdź nasze",
  VATId: "NIP",
  VATIdRequired: "NIP jest wymagany",
  addVATId: "NIP jest wymagany",
  addVATIdRequired: "NIP jest wymagany", // ????
  name: "Nazwa",
  address1: "Adres",
  address1Required: "Adres jest wymagany",
  addressLine2: "Adres - dodatkowe informacje",
  addressLine2Optiona: "Adres - dodatkowe informacje (opcjonalne)",
  city: "Miasto",
  cityRequired: "Miasto jest wymagane",
  cityName: "Miasto", // ????
  provinceRegion: "Województwo",
  provinceRegionRequired: "Województwo jest wymagane",

  // payments history
  checkHelpCenter: "Sprawdź nasze Centrum Pomocy gdzie znajdziesz listę często zadawanych pytań.",

  // preferences
  preferredLanguage: "Domyślny język",
  preferredCurrency: "Domyślna waluta",
  timeZone: "Strefa czasowa",
  reminders: "Przypomnienia",
  activitySharing: "Udostępnienia aktywności",
  dataSharing: "Udostępnianie danych",
  getMessages: "Gdzie chciałbyś otrzymać wiadomość tekstową?",
  getMessagesContent:
    "Poprzez zaznaczenie opcji SMS zgadzasz się na otrzymywanie wiadomości promocyjnych od SeaEasy oraz partnerów na numer:",
  moreInfo:
    "Po więcej informacji wyślij SMS o treści POMOC na XXXXX. Aby wyłączyć powiadomienia odpowiedz STOP na XXXXX. Cennik zgodnie z taryfą operatora.",
  choosePhoneNumber: "Wybierz numer telefonu",

  // help center
  howHelp: "Jak możemy Ci pomóc?",
  whatIsSeaEasy: "Czym jest SeaEasy?",
  howDoesSeasEasyWork: "Jak działa SeaEasy?",
  doesSeaEasyOfferInsurance: "Czy SeaEasy oferuje ubezpieczenie?",
  whyShouldICommunicateThrought: "Czemu powienenem komunikować się przez SeaEasy?",
  doINeedInsuranceToRentABoat: "Czy potrzebuję ubezpieczenia by wynająć łódź?",
  isYourWebsiteSecure: "Czy wasza strona jest bezpieczna?",
  howToStartSharingMyEquipment: "Jak zacząć udostępniać mój sprzęt?",
  howToStartUsingSeaEasy: "Jak zacząć korzystać z SeaEasy?",
  needToGetInTouch: "Potrzebujesz skontaktować się z SeaEasy?",
  needToGetInTouchContent: "Zaczniemy od kilku pytań by poprowadzić cię w odpowiednie miejsce.",
  giveFeedback: "Mozesz też podzielić się swoją opinią",

  // offers
  yourOffers: "Twoje oferty",
  createNewOffer: "Dodaj ofertę",
  listing: "Oferta",
  category: "Kategoria",
  location: "Lokalizacja",
  price: "Cena",
};
