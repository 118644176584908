import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useTranslation } from "react-i18next";

import { SectionsData } from "./availability.types";
import { sectionsData } from "./availability.constants";
import { useQueryParams } from "hooks";
import { Offers } from "./offers/offers";
import { Hosts } from "./hosts/hosts";
import { Modal } from "components";
import { TimeOffModal } from "./time-off-modal/time-off-modal";
import { AvailabilityModal } from "./availability-moda/availability-modal";

import styles from "./availability.module.scss";

export const Availability = () => {
  const { t } = useTranslation();
  const { updateQueryParams } = useQueryParams();

  const [activeSection, setActiveSection] = useState<SectionsData>(sectionsData.offers);
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState<boolean>(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState<boolean>(false);

  const handleTimeOffModal = (isOpen: boolean) => () => setIsTimeOffModalOpen(isOpen);
  const handleAvailabilityModal = (isOpen: boolean) => () => setIsAvailabilityModalOpen(isOpen);

  const checkActiveSection = (section: SectionsData) => activeSection === section;
  const changeSections = (section: SectionsData) => () => setActiveSection(section);

  useDidUpdate(
    () => {
      updateQueryParams({ section: activeSection });
    },
    [activeSection],
    true,
  );

  return (
    <div className={styles.container}>
      <Typography className={styles.availability}>{t("availability.buttons.availability")}</Typography>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <Button
              className={classNames(styles.button, {
                [styles.activeButton]: checkActiveSection(sectionsData.offers),
              })}
              onClick={changeSections(sectionsData.offers)}
            >
              {t("availability.buttons.offers")}
            </Button>
            <Button
              className={classNames(styles.button, {
                [styles.activeButton]: checkActiveSection(sectionsData.hosts),
              })}
              onClick={changeSections(sectionsData.hosts)}
            >
              {t("availability.buttons.hosts")}
            </Button>
            <Button
              className={classNames(styles.button, {
                [styles.activeButton]: checkActiveSection(sectionsData.assets),
              })}
              onClick={changeSections(sectionsData.assets)}
            >
              {t("availability.buttons.assets")}
            </Button>
            <Button
              className={classNames(styles.button, {
                [styles.activeButton]: checkActiveSection(sectionsData.locations),
              })}
              onClick={changeSections(sectionsData.locations)}
            >
              {t("availability.buttons.locations")}
            </Button>
          </div>
          <div className={styles.actions}>
            <Button onClick={handleTimeOffModal(true)} className={styles.actionButton}>
              {t("availability.buttons.timeOff")}
            </Button>
            <Button onClick={handleAvailabilityModal(true)} className={styles.actionButton}>
              {t("availability.buttons.changeAvailability")}
            </Button>
          </div>
        </div>

        <div className={styles.content}>
          {activeSection === sectionsData.offers && <Offers />}
          {activeSection === sectionsData.hosts && <Hosts />}
        </div>
      </div>

      <Modal open={isTimeOffModalOpen}>
        <TimeOffModal close={handleTimeOffModal(false)} />
      </Modal>

      <Modal open={isAvailabilityModalOpen}>
        <AvailabilityModal close={handleAvailabilityModal(false)} />
      </Modal>
    </div>
  );
};
