import React from "react";
import classNames from "classnames";

import styles from "./content.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Content: React.FC<Props> = ({ children, className }) => {
  return <main className={classNames(styles.content, className)}>{children}</main>;
};
