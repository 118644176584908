import { TFunction } from "i18next";
import { object, SchemaOf, string } from "yup";

import { ForgotPasswordData } from "server/auth/auth.types";

export const initialValues: ForgotPasswordData = {
  email: "",
};

export const forgotPasswordSchema = (t: TFunction): SchemaOf<ForgotPasswordData> =>
  object().shape({
    email: string().email(t("login.invalidEmail")).required(t("login.emailRequired")),
  });

export const sx = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "none" },
  },
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "15px",
    background: "#fff",
    border: "2px solid #474CAE",
  },
};
