import React, { useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

import { SubmitButtonsProps } from "./submit-buttons.types";
import { RentalData } from "server/organization/organization.types";
import { rentalInitialValues } from "../create-rental.constants";
import { useRoutesPath } from "hooks";
import { HOME_PAGE } from "constants/routes.constants";
import { Modal } from "components";
import { ExitModal } from "./exit-modal/exit-modal";

import styles from "./submit-buttons.module.scss";

export const SubmitButtons: React.FC<SubmitButtonsProps> = ({
  isLastStep,
  showPrevButton,
  handlePrevPage,
  submitting,
  step,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { values } = useFormikContext<RentalData>();
  const { localePath } = useRoutesPath();

  const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false);

  const openHomePage = () => {
    if (values === rentalInitialValues) return navigate(localePath({ route: HOME_PAGE }));
    setIsExitModalOpen(true);
  };

  const submitButtonText = isLastStep ? t("rental.submitButtons.addPayments") : t("common.next");
  const showHomePageButton = step === 1;

  return (
    <div className={styles.container}>
      {!showHomePageButton && showPrevButton && (
        <Button className={styles.button} onClick={handlePrevPage}>
          {t("common.back")}
        </Button>
      )}
      {showHomePageButton && (
        <Button onClick={openHomePage} className={styles.button}>
          {t("rental.submitButtons.leave")}
        </Button>
      )}

      <Button type="submit" className={classNames(styles.button, styles.nextButton)} disabled={submitting}>
        {!submitting ? submitButtonText : t("common.submitting")}
      </Button>

      <Modal open={isExitModalOpen} className={styles.modal}>
        <ExitModal setIsExitModalOpen={setIsExitModalOpen} />
      </Modal>
    </div>
  );
};
