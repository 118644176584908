import { builder } from "server/builder";
import { OrganizationModel } from "models";
import { UserOrganizationData, OrganizationData, CreateOrganizationResponse } from "./organization.types";

export const createOrganization = builder.createCommand<CreateOrganizationResponse, OrganizationData>()({
  endpoint: "/app/organization",
  method: "POST",
  options: {
    timeout: 30000,
  },
});

export const getUserOrganization = builder.createCommand<UserOrganizationData[]>()({
  endpoint: "/app/user/organization",
  method: "GET",
  retry: 5,
});

export const getOrganization = builder.createCommand<OrganizationModel>()({
  endpoint: "/app/organization",
  method: "GET",
});
