import React, { useState } from "react";
import { Typography } from "@mui/material";
import { LatLng } from "leaflet";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { useTranslation } from "react-i18next";

import { Map } from "components";
import { LocationProps } from "./location.types";

import styles from "./location.module.scss";

export const Location: React.FC<LocationProps> = ({ latitude, longtitude, boatPlace, isSimpleOffer, rentalPlace }) => {
  const { t } = useTranslation();

  const [markers, setMarkers] = useState<LatLng | null>(null);

  const position = new LatLng(Number(latitude), Number(longtitude));

  useDidMount(() => {
    setMarkers(position);
  });

  return (
    <div className={styles.container}>
      <Typography fontFamily="Chillax-Semibold" className={styles.title} fontSize={20} pl="20px">
        {t("offerPage.map.map")}
      </Typography>
      {markers && <Map markers={markers} position={position} className={styles.map} disablePin />}
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <Typography fontFamily="Chillax-semibold">{t("offerPage.map.boatPlace")}</Typography>
          <Typography fontFamily="Chillax-regular">
            {boatPlace.address}, {boatPlace.postal_code} {boatPlace.city}, {boatPlace.region}, {boatPlace.country}
          </Typography>
        </div>
        {!isSimpleOffer && (
          <div className={styles.column}>
            <Typography fontFamily="Chillax-semibold">{t("offerPage.map.rentalPlace")}</Typography>
            <Typography fontFamily="Chillax-regular">
              {rentalPlace.address}, {rentalPlace.postal_code} {rentalPlace.city}, {rentalPlace.region},{" "}
              {rentalPlace.country}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
