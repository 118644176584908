import { CalendarViewContext } from "./calendar-view.context";
import { SlotsViewOwnContext } from "./slots-view-own.context";
import { CalendarViewOwnContext } from "./calendar-view-own.context";
import { SlotsViewContext } from "./slots-view.context";

export const getViewContext = (isOwnReservation: boolean, isCalendarView: boolean) => {
  if (isOwnReservation && isCalendarView) {
    return CalendarViewOwnContext;
  }
  if (!isOwnReservation && isCalendarView) {
    return CalendarViewContext;
  }
  if (isOwnReservation && !isCalendarView) {
    return SlotsViewOwnContext;
  }
  if (!isOwnReservation && !isCalendarView) {
    return SlotsViewContext;
  }
};
