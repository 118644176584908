import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { CreateOfferContext } from "../create-offer.context";
import { steps } from "../create-offer.constants";
import { useWindowSize } from "hooks";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import styles from "./navigation.module.scss";

export const Navigation = () => {
  const { isMenuOpen, setIsMenuOpen, step, sportType, useOrganizationImages } = useContext(CreateOfferContext);
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isDesktop = width > 992;

  const showMenu = () => {
    return true;
  };

  const showOpenMenu = () => {
    return isMenuOpen;
  };

  const show = isMenuOpen || !isDesktop;

  return (
    <div className={styles.container}>
      {showMenu() && (
        <div className={styles.header}>
          {show && (
            <div className={styles.wrapper}>
              <Typography style={{ textTransform: "uppercase" }}>
                {steps(t, sportType, useOrganizationImages)[step].name}
              </Typography>
            </div>
          )}
          {!isDesktop && (
            <IconButton
              onClick={toggleMenu}
              className={classNames(styles.arrowButtonMobile, isMenuOpen && styles.arrowButtonOpenMobile)}
            >
              <ArrowIcon />
            </IconButton>
          )}
        </div>
      )}

      {isDesktop && (
        <IconButton
          onClick={toggleMenu}
          className={classNames(styles.arrowButton, isMenuOpen && styles.arrowButtonOpen)}
        >
          <ArrowIcon />
        </IconButton>
      )}

      {showOpenMenu() && (
        <div className={styles.menuContent}>
          {steps(t, sportType, useOrganizationImages).map(({ name }) => {
            const isActiveStep = name === steps(t, sportType, useOrganizationImages)[step].name;

            return (
              <div key={name} className={classNames(styles.menuItem, isActiveStep && styles.menuItemActiveStep)}>
                <Typography className={styles.name} component="p">
                  {name}
                </Typography>
                <div className={classNames(styles.dot, isActiveStep && styles.dotActive)} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
