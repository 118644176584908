import React, { useState } from "react";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { ArticleBox } from "../article-box/article-box";
import { StrapiArticleModel } from "models";
import { getArticles } from "server";
import { useShowContent } from "hooks";
import { Loader } from "components";

import styles from "../knowledge-center.module.scss";

export const Lifestyle: React.FC = () => {
  const [articles, setArticles] = useState<null | StrapiArticleModel[]>(null);

  const articlesData = useFetch(getArticles.setQueryParams({ populate: "*" }));
  const { showLoader, showContent } = useShowContent(articlesData);
  const { onSuccess } = articlesData;
  onSuccess((response) => {
    setArticles(response.response.data.filter((article) => article.id >= 2 && article.id <= 4));
  });

  return (
    <div className={styles.content}>
      {showLoader && <Loader className={styles.loader} />}
      {showContent &&
        articles &&
        articles.map((article) => (
          <ArticleBox
            key={article.id}
            title={article.attributes.title}
            slug={article.attributes.slug}
            image={article.attributes.image?.data[0].attributes.url || ""}
            coverMobile={article.attributes.coverMobile.data.attributes.url}
            coverDesktop={article.attributes.coverDesktop.data.attributes.url}
          />
        ))}
    </div>
  );
};
