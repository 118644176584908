import { builder } from "server/builder";
import { LocationData } from "./location.types";
import { LocationModel } from "models/location.models";

export const getLocations = builder.createCommand<LocationModel[]>()({
  endpoint: "/app/location",
  method: "GET",
});

export const getLocation = builder.createCommand<LocationModel>()({
  endpoint: "/app/location",
  method: "GET",
});

export const getUserLocation = builder.createCommand<LocationModel[]>()({
  endpoint: "/app/user/location",
  method: "GET",
  retry: 5,
});

export const createLocation = builder.createCommand<LocationModel, LocationData>()({
  endpoint: "/app/location",
  method: "POST",
});
