import React, { useState } from "react";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { IconButton } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { GalleryProps } from "./gallery.types";
import { ImagesModel } from "models";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import styles from "./gallery.module.scss";

export const Gallery: React.FC<GalleryProps> = ({ offerImages, rentalImages }) => {
  const navigate = useNavigate();

  const [images, setImages] = useState<null | ImagesModel[]>(null);
  const [galleryOpen, setGalleryOpen] = useState<boolean>(false);

  const openPrevPage = () => {
    navigate(-1);
  };

  const toggleGallery = (isOpen: boolean) => () => setGalleryOpen(isOpen);

  useDidMount(() => {
    if (offerImages.length) return setImages(offerImages);
    setImages(rentalImages.filter((image) => image.is_avatar !== true));
  });

  return (
    <div className={styles.container}>
      {images && (
        <Carousel
          infiniteLoop
          autoPlay
          useKeyboardArrows
          emulateTouch
          showThumbs={false}
          showStatus={false}
          onClickItem={toggleGallery(true)}
        >
          {images.map((image) => (
            <div
              key={image.image_url}
              style={{ backgroundImage: `url(${image.image_url})` }}
              className={styles.image}
            />
          ))}
        </Carousel>
      )}
      {images && galleryOpen && (
        <Carousel
          infiniteLoop
          autoPlay
          useKeyboardArrows
          showThumbs={false}
          showStatus={false}
          emulateTouch
          selectedItem={3}
          className={styles.gallery}
          onClickItem={toggleGallery(false)}
        >
          {images.map((image) => (
            <div
              key={image.image_url}
              style={{ backgroundImage: `url(${image.image_url})` }}
              className={styles.galleryImage}
            />
          ))}
        </Carousel>
      )}

      <IconButton className={styles.iconButton} onClick={openPrevPage}>
        <ArrowBackIosNewIcon />
      </IconButton>
    </div>
  );
};
