import React from "react";
import { Input, Select } from "antd";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { AssetsData } from "../../../assets.types";
import { sizeUnits } from "./details.constants";

import styles from "./details.module.scss";

export const Details = () => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<AssetsData>();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const { value } = e.target;

    setFieldValue(fieldName, value);
  };

  const handleSelect = (value: string) => {
    setFieldValue("sizeUnit", value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldWrapper}>
        <Typography>{t("assets.addAssetModal.name")}</Typography>
        <Input className={styles.input} value={values.type} onChange={handleInput} name="type" />
      </div>
      <div className={styles.fieldWrapper}>
        <Typography>{t("assets.addAssetModal.quantity")}</Typography>
        <Input className={styles.input} value={values.quantity} onChange={handleInput} name="quantity" type="number" />
      </div>
      <div className={styles.fieldWrapper}>
        <Typography>{t("assets.addAssetModal.description")}</Typography>
        <Input className={styles.input} value={values.description} onChange={handleInput} name="description" />
      </div>
      <div className={styles.fieldWrapper}>
        <Typography>{t("assets.addAssetModal.size")}</Typography>
        <Input className={styles.input} value={values.size} onChange={handleInput} name="size" type="number" />
      </div>
      <div className={styles.fieldWrapper}>
        <Typography>{t("assets.addAssetModal.sizeUnit")}</Typography>
        <Select
          className={styles.select}
          options={sizeUnits}
          onChange={handleSelect}
          popupClassName={styles.popup}
          value={values.sizeUnit}
        />
      </div>
    </div>
  );
};
