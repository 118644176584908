import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useRoutesPath, useWindowSize } from "hooks";
import { reviews } from "./section.constants";
import { ReviewBox } from "./review-box/review-box";
import { CREATE_OFFER_PAGE } from "constants/routes.constants";

import sectionBackgroundMobile from "assets/images/Landing_Bot_Mobile.webp";
import sectionBackgroundTablet from "assets/images/Landing_Bot_Tablet.webp";
import sectionBackgroundDesktop from "assets/images/Landing_Bot_Desktop.webp";

import styles from "./section.module.scss";

export const Section = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { localePath } = useRoutesPath();

  const [currentReview, setCurrentReview] = useState(0);

  const openOffersPage = () => {
    navigate(localePath({ route: CREATE_OFFER_PAGE }));
  };

  const isMobile = width <= 900;
  const isTablet = width <= 1550;
  const background = isMobile ? sectionBackgroundMobile : sectionBackgroundTablet;

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentReview((prev) => (prev + 1) % reviews(t).length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isMobile, t]);

  const nextReview = () => {
    setCurrentReview((prev) => (prev + 1) % reviews(t).length);
  };

  const prevReview = () => {
    setCurrentReview((prev) => (prev - 1 + reviews(t).length) % reviews(t).length);
  };

  return (
    <div className={styles.container}>
      <img src={isTablet ? background : sectionBackgroundDesktop} alt="" className={styles.background} />
      <div className={styles.wrapper}>
        <div className={styles.owner}>
          <Typography className={styles.title} fontFamily="Chillax-Semibold">
            {t("home.section.areYouAnOwner")}
          </Typography>
          <Typography fontFamily="Chillax-Regular">{t("home.section.createYourOffer")}</Typography>
          <Button onClick={openOffersPage} className={styles.button}>
            {t("home.section.start")}
          </Button>
        </div>
        <div className={styles.reviews}>
          <Typography className={styles.reviewsTitle}>{t("home.section.ourHappyCustomers")}</Typography>
          <div className={styles.reviewsWrapper}>
            {isMobile && (
              <>
                <Button onClick={prevReview} className={styles.navButton}>
                  {"<"}
                </Button>
                <ReviewBox {...reviews(t)[currentReview]} />
                <Button onClick={nextReview} className={styles.navButton}>
                  {">"}
                </Button>
              </>
            )}
            {!isMobile && reviews(t).map((review) => <ReviewBox key={review.name} {...review} />)}
          </div>
        </div>
      </div>
    </div>
  );
};
