import { combineReducers, configureStore } from "@reduxjs/toolkit";

import auth from "./reducers/auth/auth.redux";
import app from "./reducers/app/app.redux";
import organization from "./reducers/organization/organization.redux";
import watersports from "./reducers/watersports/watersports.redux";

const reducer = combineReducers({
  auth,
  app,
  organization,
  watersports,
});

const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof reducer>;

export { store };

export * from "./reducers/auth";
export * from "./reducers/app";
export * from "./reducers/organization";
export * from "./reducers/watersports";
