export const seo = {
  // Knowledge Center
  knowledgeCenter: "Centrum Wiedzy",
  companyNews: "Wiadomości Firmowe",
  lifestyle: "Lifestyle",
  destinations: "Podróże",
  guides: "Poradniki",
  safety: "Bezpieczeństwo",
  faq: "Bezpieczeństwo",

  // article
  moreFromSeaEasy: "Więcej od SeaEasy",
  subscribeNewsletter: "Zasubskrybuj nasz newsletter",

  // newsletter
  subscribeToSeaNewsletter: "Zasubskrybuj newsletter SeaEasy ",
};
