import React, { useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { useRoutesPath, useWindowSize } from "hooks";
import { OFFERS_PAGE } from "../../../constants/routes.constants";

import bannerBackgroundDesktop from "assets/images/hero-desktop.webp";
import bannerBackgroundTabel from "assets/images/hero-tablet.webp";
import bannerBackgroundMobile from "assets/images/hero-mobile.webp";
import { ReactComponent as BannerTextPl } from "assets/images/banner-text-pl.svg";
import { ReactComponent as BannerTextEn } from "assets/images/banner-text-en.svg";

import styles from "./banner.module.scss";

gsap.registerPlugin(ScrollTrigger);

export const Banner = () => {
  const navigate = useNavigate();
  const tl = useRef<null | GSAPTimeline>(null);
  const bannerRef = useRef<null | HTMLDivElement>(null);
  const imageRef = useRef<null | HTMLDivElement>(null);

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { localePath, locale } = useRoutesPath();

  const handleBook = () => {
    navigate(localePath({ route: OFFERS_PAGE }));
  };

  const isMobile = width <= 920;
  const isTabel = width <= 1650;
  const background = isMobile ? bannerBackgroundMobile : bannerBackgroundTabel;

  useDidUpdate(
    () => {
      const scaleValue = (): number => {
        if (width <= 920) return 15;
        if (width <= 1650) return 30;

        return 30;
      };

      if (imageRef.current) {
        gsap.fromTo(
          imageRef.current,
          { transform: "scale(1)" },
          {
            duration: 2,
            transform: `translateY(-${scaleValue()}%)`,
            ease: "power2",
            scrollTrigger: {
              trigger: imageRef.current,
              start: "20% top",
              scrub: 1,
            },
          },
        );
      }
    },
    [],
    true,
  );

  useDidUpdate(
    () => {
      if (bannerRef.current) {
        tl.current = gsap.timeline({
          delay: 1,
        });

        gsap.set(bannerRef.current?.children[0].children, { autoAlpha: 0, y: "+=150px" });
        gsap.set(bannerRef.current?.children[1], { autoAlpha: 0, y: "+=150px" });
        gsap.set(bannerRef.current?.children[2], { autoAlpha: 0, y: "+=150px" });

        tl.current
          .to(bannerRef.current?.children[0].children, {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
          })
          .to(bannerRef.current?.children[1], { autoAlpha: 1, y: 0 })
          .to(bannerRef.current?.children[2], { autoAlpha: 1, y: 0 });
      }
    },
    [],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} ref={bannerRef}>
        <div className={styles.bannerText}>
          {locale === "pl-PL" && <BannerTextPl className={styles.bannerTextIcon} />}
          {locale === "en-US" && <BannerTextEn className={styles.bannerTextIcon} />}

          <Typography variant="h1">
            {t("home.baner.rentals")} {t("home.baner.waterSport")}
          </Typography>
        </div>

        <Button onClick={handleBook} className={styles.button}>
          {t("home.baner.button")}
        </Button>

        <div className={styles.detailsWrapper}>
          <div className={styles.info}>
            <Typography>600+</Typography>
            <div>
              <Typography>{t("home.baner.customers")}</Typography>
              <Typography>{t("home.baner.served")}</Typography>
            </div>
          </div>
          <div className={styles.info}>
            <Typography>100%</Typography>
            <div>
              <Typography>{t("home.baner.surf")}</Typography>
              <Typography>{t("home.baner.vibes")}</Typography>
            </div>
          </div>
          <div className={styles.info}>
            <Typography>99%</Typography>
            <div>
              <Typography>{t("home.baner.positive")}</Typography>
              <Typography>{t("home.baner.feedback")}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${isTabel ? background : bannerBackgroundDesktop})` }}
        className={styles.background}
        ref={imageRef}
      />
    </div>
  );
};
