import React, { useState } from "react";

import { OfferModel } from "../../models";
import { calendarViewRateType } from "./reservations-modal.constants";

type ReservationsContextType = {
  offer: OfferModel | null;
  isCalendarView: boolean;
  isOwnReservation: boolean;
  handleDeleteOffer: () => void;
  setOffer: React.Dispatch<React.SetStateAction<OfferModel | null>>;
  modalClose: () => void;
  refreshReservations?: () => void;
};

export const ReservationsContext = React.createContext<ReservationsContextType>({
  offer: null,
  isCalendarView: false,
  isOwnReservation: false,
  handleDeleteOffer: () => {},
  setOffer: () => {},
  modalClose: () => {},
  refreshReservations: () => {},
});

interface Props {
  children: React.ReactNode;
  modalClose: () => void;
  offer: OfferModel | null;
  isOwnReservation: boolean;
  refreshReservations?: () => void;
}

export const ReservationsContextProvider: React.FC<Props> = ({
  children,
  offer: propsOffer,
  isOwnReservation,
  modalClose,
  refreshReservations,
}) => {
  const [offer, setOffer] = useState<OfferModel | null>(propsOffer || null);

  const handleDeleteOffer = () => {
    setOffer(null);
  };

  if (offer && !isOwnReservation) {
    const isCalendarView = calendarViewRateType.includes(offer.rate_type);

    return (
      <ReservationsContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          offer,
          isCalendarView,
          isOwnReservation,
          handleDeleteOffer,
          setOffer,
          modalClose,
        }}
      >
        {children}
      </ReservationsContext.Provider>
    );
  }

  if (isOwnReservation) {
    const isCalendarView = calendarViewRateType.includes(offer?.rate_type as string);

    return (
      <ReservationsContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          offer,
          isCalendarView,
          isOwnReservation,
          handleDeleteOffer,
          setOffer,
          modalClose,
          refreshReservations,
        }}
      >
        {children}
      </ReservationsContext.Provider>
    );
  }

  return null;
};
