import React, { useContext, useRef, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { SubmitButtons } from "./submit-buttons/submit-buttons";
import { mapOfferData, mapOfferToEditData, offerInitialValues, steps } from "./create-offer.constants";
import { OfferData } from "./create-offer.types";
import { CreateOfferContext } from "./create-offer.context";
import { createLocation, createOffer, deleteImage, editOffer, getOffer, uploadImage } from "server";
import { OFFER_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";
import { Navigation } from "./navigation/navigation";
import { LocationData } from "server/location/location.types";
import { refreshOffersData, RootState } from "store";
import { Loader } from "components";

import styles from "./create-offer.module.scss";

export const CreateOfferForm: React.FC = () => {
  const stepWrapperRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const createOfferContext = useContext(CreateOfferContext);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { offerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { localePathParams } = useRoutesPath();
  const { offers } = useSelector((state: RootState) => state.organization);

  const [formValues, setFormValues] = useState<OfferData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [editOfferFormValues, setEditOfferFormValues] = useState<null | OfferData>(null);

  const {
    submit: submitEditOffer,
    onSubmitSuccess: onSubmitSuccessEditOffer,
    onSubmitError: onSubmitErrorEditOffer,
  } = useSubmit(editOffer.setParams({ id: offerId || 0 }));
  onSubmitSuccessEditOffer(async ({ response }) => {
    if (formValues && formValues.keyDetails.photos) {
      const imageUploadPromises = response.presigned_urls.map((presignedUrl, index) =>
        uploadImage(presignedUrl, formValues.keyDetails.photos[index]),
      );

      const photosToDeletePromises = createOfferContext.photosToDelete.map((imageId) => deleteImage(imageId));

      try {
        await Promise.all([imageUploadPromises, photosToDeletePromises]);
        enqueueSnackbar(t("createOffer.editSuccess"), { variant: "success" });
        setIsSubmitting(false);
        navigate(
          localePathParams({
            route: OFFER_PAGE,
            params: {
              offerId: response.id,
              watersport: "any",
              location: "any",
            },
          }),
        );
        dispatch(refreshOffersData());
      } catch (error) {
        enqueueSnackbar(t("createOffer.createError"), { variant: "error" });
        setIsSubmitting(false);
      }
    }
  });
  onSubmitErrorEditOffer(() => {
    setIsSubmitting(false);
  });

  const { submit, onSubmitError, onSubmitSuccess } = useSubmit(createOffer);
  onSubmitError(({ response }) => {
    setIsSubmitting(false);
    enqueueSnackbar(response?.message || t("createOffer.createError"), { variant: "error" });
  });

  onSubmitSuccess(async ({ response }) => {
    if (formValues && formValues.keyDetails.photos) {
      const imageUploadPromises = response.presigned_urls.map((presignedUrl, index) =>
        uploadImage(presignedUrl, formValues.keyDetails.photos[index]),
      );

      try {
        await Promise.all(imageUploadPromises);
        enqueueSnackbar(t("createOffer.createSuccess"), { variant: "success" });
        navigate(
          localePathParams({
            route: OFFER_PAGE,
            params: {
              offerId: response.id,
              watersport: "any",
              location: "any",
            },
          }),
        );
        dispatch(refreshOffersData());
      } catch (error) {
        enqueueSnackbar(t("createOffer.createError"), { variant: "error" });
        setIsSubmitting(false);
      }
    }
  });

  const handleSubmitData = (values: OfferData) => {
    if (values && createOfferContext.organization?.id) {
      const data = mapOfferData({
        data: values,
        organizationId: createOfferContext.organization.id,
        watersportId: createOfferContext.sportTypeId,
        useOrganizationImages: createOfferContext.useOrganizationImages,
        projectId: createOfferContext.organization.timerise_project_id,
      });

      submit({ data }).then();
    }
  };

  const handleEditOffer = (values: OfferData) => {
    setIsSubmitting(true);

    if (offerId && values && createOfferContext.organization?.id) {
      const data = mapOfferData({
        data: values,
        organizationId: createOfferContext.organization.id,
        watersportId: createOfferContext.sportTypeId,
        useOrganizationImages: createOfferContext.useOrganizationImages,
        projectId: createOfferContext.organization.timerise_project_id,
      });

      data.image_names = [];
      data.new_image_names = [...values.keyDetails.photos.map((image) => image.name)];
      data.images_to_delete = createOfferContext.photosToDelete;

      submitEditOffer({ data }).then();
    }
  };

  const locationData = useSubmit(createLocation);
  const {
    submit: submitLocation,
    onSubmitSuccess: onSubmitSuccessLocation,
    onSubmitError: onSubmitErrorLocation,
  } = locationData;
  onSubmitSuccessLocation((res) => {
    if (!offerId) {
      setIsSubmitting(false);

      const values: OfferData = {
        ...formValues,
        location: {
          location_id: res.response.id || 0,
          locationData: {} as LocationData,
          temporary_id: null,
        },
      } as OfferData;

      setFormValues(values);
      handleSubmitData(values);
    }
  });
  onSubmitErrorLocation(async () => {
    const data = createOfferContext.locations?.find(
      (item) => item.temporaryId === formValues?.location.temporary_id,
    ) as LocationData;
    await submitLocation({ data });
  });

  const handlePrevPage = () => createOfferContext.setStep(createOfferContext.step - 1);
  const handleNextPage = () => createOfferContext.setStep(createOfferContext.step + 1);

  const checkPhotos = (): number => {
    if (createOfferContext.numOfPhotos > 5) return 0;

    return 5 - createOfferContext.numOfPhotos;
  };

  const formSteps = steps(t, createOfferContext.sportType, createOfferContext.useOrganizationImages, checkPhotos());
  const isLastStep = createOfferContext.step === formSteps.length - 1;

  const handleSubmit = async (values: OfferData, helpers: FormikHelpers<OfferData>) => {
    if (isLastStep) {
      setIsSubmitting(true);
      setFormValues(values);

      if (offerId) {
        if (values.location.temporary_id) {
          const data = createOfferContext.locations?.find(
            (item) => item.temporaryId === values.location.temporary_id,
          ) as LocationData;

          submitLocation({ data }).then((res) => {
            const editOfferValues: OfferData = {
              ...values,
              location: {
                location_id: res[0]?.id || 0,
                locationData: {} as LocationData,
                temporary_id: null,
              },
            } as OfferData;

            setFormValues(editOfferValues);
            handleEditOffer(editOfferValues);
          });
        } else {
          handleEditOffer(values);
        }
      }
      if (!offerId) {
        if (values.location.temporary_id) {
          const data = createOfferContext.locations?.find(
            (item) => item.temporaryId === values.location.temporary_id,
          ) as LocationData;
          await submitLocation({ data });
        } else {
          await handleSubmitData(values);
        }
      }
    } else {
      handleNextPage();
      helpers.setTouched({});
      setIsSubmitting(false);
    }
  };

  const showPrevButton = createOfferContext.step > 0;
  const showLandingButton = createOfferContext.step === 0;

  const {
    data: offerToEditData,
    submit: submitOfferToEdit,
    onSubmitSuccess: onSubmitSuccessOfferToEdit,
  } = useSubmit(getOffer.setParams({ id: offerId || 0 }));
  onSubmitSuccessOfferToEdit((res) => {
    const data = mapOfferToEditData(res.response);
    setEditOfferFormValues(data);
  });

  const checkIsUserOffer = (): boolean => {
    if (offerId) {
      return offers.some((offer) => offer.id === parseFloat(offerId));
    }
    return false;
  };

  useDidMount(() => {
    if (offerId && checkIsUserOffer()) {
      submitOfferToEdit().then();
    }
  });

  useDidUpdate(() => {
    stepWrapperRef.current?.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [createOfferContext.step]);

  useDidMount(() => {
    if (offerId) {
      createOfferContext.setSportTypeId(offers.find(({ id }) => id === parseFloat(offerId))?.watersport.id || 1);
    }
  });

  return (
    <>
      {!offerId && (
        <Formik<OfferData>
          initialValues={offerInitialValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={formSteps[createOfferContext.step].schema}
        >
          <Form className={classNames(styles.wrapper, createOfferContext.isMenuOpen && styles.wrapperOpenMenu)}>
            <Navigation />
            <div
              ref={stepWrapperRef}
              className={classNames(styles.stepWrapper, createOfferContext.isMenuOpen && styles.stepWrapperMenuOpen)}
            >
              <Typography className={styles.formStepName}>
                {t("createOffer.steps.step")} {createOfferContext.step + 1}: {formSteps[createOfferContext.step].name}
              </Typography>
              {formSteps[createOfferContext.step].component}
            </div>
            <SubmitButtons
              isLastStep={isLastStep}
              showPrevButton={showPrevButton}
              showLandingButton={showLandingButton}
              handlePrevPage={handlePrevPage}
              submitting={isSubmitting}
            />
          </Form>
        </Formik>
      )}

      {offerId && editOfferFormValues && (
        <Formik<OfferData>
          initialValues={editOfferFormValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={formSteps[createOfferContext.step].schema}
        >
          <Form className={classNames(styles.wrapper, createOfferContext.isMenuOpen && styles.wrapperOpenMenu)}>
            <Navigation />
            {offerToEditData && (
              <div
                className={classNames(styles.stepWrapper, createOfferContext.isMenuOpen && styles.stepWrapperMenuOpen)}
              >
                <Typography className={styles.formStepName}>
                  {t("createOffer.steps.step")} {createOfferContext.step + 1}: {formSteps[createOfferContext.step].name}
                </Typography>
                {formSteps[createOfferContext.step].component}
              </div>
            )}
            {!offerToEditData && <Loader height="100%" />}
            <SubmitButtons
              isLastStep={isLastStep}
              showPrevButton={showPrevButton}
              showLandingButton={showLandingButton}
              handlePrevPage={handlePrevPage}
              submitting={isSubmitting}
            />
          </Form>
        </Formik>
      )}
    </>
  );
};
