import { useTranslation } from "react-i18next";
import { Context, useContext } from "react";
import moment from "moment";

import { ReservationsContext } from "../../../reservations-modal.context";
import { getViewContext } from "../../../wrapper/wrapper.constants";
import { CalendarViewContextType } from "../../../wrapper/calendar-view.context";

import styles from "./selected-range.module.scss";

export const SelectedRange = () => {
  const { t } = useTranslation();
  const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);
  const { selectedRange } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>,
  );

  function findFirstAndLast(dates: string[]): { dateTimeFrom: string; dateTimeTo: string } {
    dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const firstDate = dates[0];
    const lastDate = dates[dates.length - 1];

    return { dateTimeFrom: firstDate, dateTimeTo: lastDate };
  }

  const { dateTimeFrom, dateTimeTo } = findFirstAndLast(selectedRange);

  return (
    <div className={styles.container}>
      <p className={styles.selectedSlots}>{t("reservationsModal.summary.selectedSlots")}</p>
      <p className={styles.dates}>
        {moment(dateTimeFrom).format("DD.MM")} - {moment(dateTimeTo).format("DD.MM.YYYY")}
      </p>
    </div>
  );
};
