import React, { Context, useContext, useState } from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { ReservationsContext } from "../../reservations-modal.context";
import { CalendarViewContextType } from "../../wrapper/calendar-view.context";
import { ServicesModel } from "models";
import { Service } from "./service/service";
import { getViewContext } from "../../wrapper/wrapper.constants";

import styles from "./details.module.scss";

export const Details: React.FC = () => {
  const { t } = useTranslation();
  const { offer, isOwnReservation, isCalendarView } = useContext(ReservationsContext);

  const { addGuest, removeGuest, guests, selectedServices } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>,
  );

  const [servicesToSelect, setServicesToSelect] = useState<ServicesModel[]>([]);

  const offerTitle = offer?.title || offer?.organization.name;
  const showServices = offer?.services.length !== 0 || false;

  useDidUpdate(
    () => {
      const selectedServiceIds = selectedServices?.map((service) => service.id);
      const availableServices = offer?.services.filter((service) => !selectedServiceIds.includes(service.id));

      setServicesToSelect(availableServices as ServicesModel[]);
    },
    [selectedServices],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.detailsWrapper}>
          {t("reservationsModal.details.offer")} <span>{offerTitle}</span>
        </p>
        <div className={styles.guests}>
          <p>{t("reservationsModal.details.guests")}</p>
          <div className={styles.counter}>
            <Button onClick={removeGuest} className={styles.button}>
              -
            </Button>
            <p>{guests}</p>
            <Button onClick={addGuest} className={styles.button}>
              +
            </Button>
          </div>
        </div>

        {showServices && (
          <div className={styles.services}>
            {selectedServices.length > 0 && (
              <div className={styles.servicesWrapper}>
                <p>{t("reservationsModal.details.selectedServices")}</p>
                <div className={styles.servicesList}>
                  {selectedServices.map((selectedService) => (
                    <Service service={selectedService} />
                  ))}
                </div>
              </div>
            )}

            {servicesToSelect.length !== 0 && (
              <div className={styles.servicesWrapper}>
                <p>{t("reservationsModal.details.additionalServices")}</p>
                {servicesToSelect.length !== 0 && (
                  <div className={styles.servicesList}>
                    {servicesToSelect.map((serviceToSelect) => (
                      <Service service={serviceToSelect} />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
