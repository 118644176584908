import { builder } from "../builder";
import { DeleteUserFromOrganizationData, TeamGetData, TeamPostData } from "./team.types";

export const teamPost = builder.createCommand<never, TeamPostData>()({
  endpoint: "/app/invite_member",
  method: "POST",
});

export const teamGet = builder.createCommand<TeamGetData>()({
  endpoint: "/app/organization/user",
  method: "GET",
});

export const deleteMembersFromOrganization = builder.createCommand<null, DeleteUserFromOrganizationData>()({
  endpoint: "/app/user/organization",
  method: "DELETE",
});
