import React, { useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";

import { Frame } from "./frame/frame";
import {
  checkStripeData,
  getOffers,
  getUserLocation,
  getUserOrganization,
  getWatersports,
  WatersportsData,
} from "./server";
import { PageRoute } from "components";
import { routes } from "config/routes.config";
import { HOME_PAGE } from "./constants/routes.constants";
import { NotFoundPage } from "./pages";
import { useRoutesPath } from "./hooks";
import {
  RootState,
  setActivePayment,
  setDataFetching,
  setLocale,
  setLocations,
  setOffers,
  setOrganizationData,
  setWatersports,
} from "./store";
import { UserOrganizationData } from "./server/organization/organization.types";
import { STORAGE_FIELDS } from "./constants/storage.constants";
import { SportType } from "./pages/offers/create";

import yachtIcon from "assets/icons/yacht-watersport-icon.svg";
import "./assets/styles/app.css";

const USER_NOT_ASSOCIATED = "User is not associated with any organization";

export function App() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { localePath } = useRoutesPath();
  const { token } = useSelector((state: RootState) => state.auth);
  const { organizationDataRefresher, offersDataRefresher } = useSelector((state: RootState) => state.organization);

  const [userOrganization, setUserOrganization] = useState<UserOrganizationData | null>(null);
  const [stripeDataFetched, setStripeDataFetched] = useState<boolean>(false);

  const locationsData = useSubmit(getUserLocation);
  const { submit: submitLocations, onSubmitSuccess: onSubmitSuccessLocations, data: dataLocations } = locationsData;
  onSubmitSuccessLocations((res) => {
    dispatch(setLocations(res.response));
  });

  const offersData = useSubmit(getOffers);
  const { submit: submitOffers, onSubmitSuccess: onSubmitSuccessOffers, data: dataOffers } = offersData;
  onSubmitSuccessOffers((res) => {
    const userOffers = res.response.filter((offer) => offer.organization.id === userOrganization?.id);
    dispatch(setOffers(userOffers));
  });

  const stripeData = useSubmit(checkStripeData.setParams({ organization_id: userOrganization?.id || 0 }));
  const {
    submit: submitStripeData,
    onSubmitSuccess: onSubmitSuccessStripeData,
    onSubmitError: onSubmitErrorStripeData,
  } = stripeData;
  onSubmitSuccessStripeData((res) => {
    dispatch(setActivePayment(res.response));
    setStripeDataFetched(false);
  });

  //
  onSubmitErrorStripeData(() => {
    dispatch(setActivePayment(true));
    setStripeDataFetched(true);
  });

  const organizationData = useSubmit(getUserOrganization);
  const {
    submit: submitOrganization,
    onSubmitSuccess: onSubmitSuccessOrganization,
    onSubmitError: onSubmitErrorOrganization,
  } = organizationData;
  onSubmitSuccessOrganization((res) => {
    dispatch(setOrganizationData(res.response[0]));
    setUserOrganization(res.response[0]);
    dispatch(setDataFetching(false));
  });
  onSubmitErrorOrganization((res) => {
    if (res.response?.message === USER_NOT_ASSOCIATED) {
      dispatch(setOrganizationData(null));
      setUserOrganization(null);
      dispatch(setDataFetching(false));
    }
  });

  const watersportsData = useFetch(getWatersports);
  const { onSuccess: onSuccessWatersports } = watersportsData;
  onSuccessWatersports((res) => {
    const watersports = res.response;

    const yachtWatersport: WatersportsData = {
      name: "YACHT",
      id: 0,
      deleted_at: null,
      description: "",
      thumbnail_url: yachtIcon,
    };

    const othersIndex = watersports.findIndex((item) => item.name === ("OTHER" as SportType));
    const others = watersports[othersIndex];
    delete watersports[othersIndex];
    watersports.push(yachtWatersport);
    watersports.push(others);

    dispatch(setWatersports(watersports));
  });

  const getOrganizationData = async () => {
    await submitOrganization();
  };

  const storageToken = localStorage.getItem(STORAGE_FIELDS.token);
  const isAuthenticated = Boolean(token) && Boolean(storageToken);

  useDidUpdate(
    () => {
      if (dataLocations && dataOffers && stripeDataFetched) {
        dispatch(setDataFetching(false));
      }
    },
    [dataLocations, dataOffers, stripeDataFetched],
    true,
  );

  useDidUpdate(() => {
    if (userOrganization) {
      submitStripeData().then();
      submitOffers().then();
      submitLocations().then();
    }
  }, [userOrganization]);

  useDidUpdate(
    () => {
      if (isAuthenticated) {
        getOrganizationData().then();
      }
    },
    [isAuthenticated, organizationDataRefresher],
    true,
  );

  useDidUpdate(() => {
    if (isAuthenticated) {
      submitOffers().then();
    }
  }, [offersDataRefresher]);

  useDidUpdate(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  useDidMount(() => {
    if (!localStorage.language) {
      const browserLanguage = window.navigator.language;

      const getLocale = (): string => {
        switch (browserLanguage) {
          case "en":
            return "en-US";

          case "en-US":
            return "en-US";

          case "pl":
            return "pl-PL";

          case "pl-PL":
            return "pl-PL";

          default:
            return "en-US";
        }
      };

      dispatch(setLocale(getLocale()));
      localStorage.setItem(STORAGE_FIELDS.language, getLocale());
    }
  });

  return (
    <div className="App" style={{ backgroundColor: "#F8EDE5", minHeight: "100vh" }}>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />

        <Route path="/pl/web/frame" element={<Frame />} />

        {routes.map((route) => {
          const pathPl = route.path.pl;
          const pathEn = route.path.en;

          return (
            <>
              <Route path="/" element={<Navigate to={localePath({ route: HOME_PAGE })} />} />
              <Route key={`pl/${route.name}`} path={pathPl} element={<PageRoute {...route} />} />
              <Route key={`en/${route.name}`} path={pathEn} element={<PageRoute {...route} />} />
            </>
          );
        })}
      </Routes>
    </div>
  );
}
