import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { HOME_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";
import { ExitModalProps } from "./exit-modal.types";

import styles from "./exit-modal.module.scss";

export const ExitModal: React.FC<ExitModalProps> = ({ setIsExitModalOpen }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const handleExitModal = () => navigate(localePath({ route: HOME_PAGE }));
  const handleStay = () => setIsExitModalOpen(false);

  return (
    <div>
      <div className={styles.exitModal}>
        <Typography>{t("rental.submitButtons.close")}</Typography>

        <div className={styles.exitModalButtons}>
          <Button className={styles.modalExitButton} onClick={handleExitModal}>
            {t("rental.submitButtons.yes")}
          </Button>
          <Button className={styles.modalStayButton} onClick={handleStay}>
            {t("rental.submitButtons.no")}
          </Button>
        </div>
      </div>
    </div>
  );
};
