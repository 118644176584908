import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useNavigate } from "react-router";

import { FormTextField, Loader } from "components";
import { initialValues, forgotPasswordSchema } from "./forgot-password-form.constants";
import { LOGIN_PAGE } from "constants/routes.constants";
import { forgotPassword } from "server";
import { ForgotPasswordData } from "server/auth/auth.types";
import { inputProps, sx } from "pages/auth/login/login-form/login-form.constants";
import { useRoutesPath } from "hooks";

import styles from "./forgot-password-form.module.scss";

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { localePath } = useRoutesPath();

  const { submit, submitting, onSubmitSuccess, onSubmitError } = useSubmit(forgotPassword);
  onSubmitSuccess(() => {
    enqueueSnackbar(t("login.forgotPasswordSuccess"), { variant: "success" });
    navigate(localePath({ route: LOGIN_PAGE }));
  });
  onSubmitError(() => {
    enqueueSnackbar(t("login.forgotPasswordError"), { variant: "error" });
  });

  const handleForgotPassword = async (
    values: ForgotPasswordData,
    { setSubmitting }: FormikHelpers<ForgotPasswordData>,
  ) => {
    setSubmitting(true);
    await submit({ data: values });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordSchema(t)}
      onSubmit={handleForgotPassword}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form className={styles.container}>
        {submitting && <Loader className={styles.loader} />}
        <Typography className={styles.title}>{t("login.forgotPasswordTitle")}</Typography>
        <div className={styles.content}>
          <div className={styles.inputBox}>
            <Typography ml="10px" mb="-10px" fontFamily="Chillax-Regular">
              {t("login.emailLabel")}
            </Typography>
            <FormTextField name="email" type="email" variant="outlined" sx={sx} inputProps={inputProps} />
            <Typography ml="10px" mb="-10px" fontFamily="Chillax-Regular">
              {t("login.forgotPasswordInfo")}
            </Typography>
          </div>
          <div className={styles.submit}>
            <Button type="submit" className={styles.button} variant="contained" disabled={submitting}>
              {t("login.forgotPassword")}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
