import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FetchingErrorProps } from "./fetching-error.types";

import { ReactComponent as Pineapple } from "assets/icons/pineapple-icon.svg";

import styles from "./fetching-error.module.scss";

export const FetchingError: React.FC<FetchingErrorProps> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Pineapple className={styles.icon} />
      <div className={styles.content}>
        <Typography className={styles.text}>{t("offersList.somethingWentWrong")}</Typography>
        <Button className={styles.button} onClick={handleClick}>
          {t("offersList.refresh")}
        </Button>
      </div>
    </div>
  );
};
