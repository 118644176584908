import { TFunction } from "i18next";

export const monthsOptions = (t: TFunction) => [
  {
    value: "january",
    label: t("dates.months.january"),
  },
  {
    value: "february",
    label: t("dates.months.february"),
  },
  {
    value: "march",
    label: t("dates.months.march"),
  },
  {
    value: "april",
    label: t("dates.months.april"),
  },
  {
    value: "may",
    label: t("dates.months.may"),
  },
  {
    value: "june",
    label: t("dates.months.june"),
  },
  {
    value: "july",
    label: t("dates.months.july"),
  },
  {
    value: "august",
    label: t("dates.months.august"),
  },
  {
    value: "september",
    label: t("dates.months.september"),
  },
  {
    value: "october",
    label: t("dates.months.october"),
  },
  {
    value: "november",
    label: t("dates.months.november"),
  },
  {
    value: "december",
    label: t("dates.months.december"),
  },
];

export const daysOptions = (t: TFunction) => [
  {
    value: "monday",
    label: t("dates.days.monday"),
  },
  {
    value: "tuesday",
    label: t("dates.days.tuesday"),
  },
  {
    value: "wednesday",
    label: t("dates.days.wednesday"),
  },
  {
    value: "thursday",
    label: t("dates.days.thursday"),
  },
  {
    value: "friday",
    label: t("dates.days.friday"),
  },
  {
    value: "saturday",
    label: t("dates.days.saturday"),
  },
  {
    value: "sunday",
    label: t("dates.days.sunday"),
  },
];
