import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import classNames from "classnames";

import styles from "./payouts-item.module.scss";

export const PayoutsItem = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography>21/07/2023</Typography>
        <Typography>2200 EUR</Typography>
        <Typography>{t("insights.clientBookings.paidWithCash")}</Typography>
        <IconButton className={classNames(styles.button, { [styles.buttonOpen]: isOpen })} onClick={handleOpen}>
          <ArrowDropUpIcon />
        </IconButton>
      </div>

      {isOpen && (
        <div className={styles.content}>
          <div className={styles.contentItem}>
            <Typography>{t("insights.clientBookings.individual")}</Typography>
            <Typography>220 eur - 8h</Typography>
          </div>
          <div className={styles.contentItem}>
            <Typography>{t("insights.clientBookings.individual")}</Typography>
            <Typography>220 eur - 8h</Typography>
          </div>
          <div className={styles.contentItem}>
            <Typography>{t("insights.clientBookings.individual")}</Typography>
            <Typography>220 eur - 8h</Typography>
          </div>
        </div>
      )}
    </div>
  );
};
