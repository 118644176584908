import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useNavigate } from "react-router-dom";

import { RootState } from "store";
import { checkStripeData, generateStripeLink } from "server";
import { useRoutesPath } from "hooks";
import { HOME_PAGE, MANAGEMENT_PAGE } from "constants/routes.constants";
import { sections } from "../../rental/management/management.constants";

import { ReactComponent as Logo } from "assets/icons/logo-blue.svg";

import styles from "./payments-check.module.scss";

export const PaymentsCheckPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { organizationData } = useSelector((state: RootState) => state.organization);
  const { localePath, localePathParams } = useRoutesPath();

  const [paymentActive, setPaymentActive] = useState<boolean | null>(null);

  const stripeData = useSubmit(checkStripeData.setParams({ organization_id: organizationData?.id || 0 }));
  const { submit, onSubmitSuccess, submitting, status } = stripeData;
  onSubmitSuccess((res) => {
    setPaymentActive(res.response);
  });

  const stripeLinkData = useSubmit(generateStripeLink.setParams({ organization_id: organizationData?.id || 1 }));
  const {
    submit: submitStripeLink,
    onSubmitSuccess: onSubmitSuccessStripeLink,
    submitting: submittingStripeLink,
  } = stripeLinkData;
  onSubmitSuccessStripeLink((res) => {
    window.open(res.response.stripe_url, "_self");
  });

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  const openManagement = () => {
    navigate(localePathParams({ route: MANAGEMENT_PAGE, params: { section: sections(t)[0].name } }));
  };

  const addStripeLink = () => {
    submitStripeLink().then();
  };

  const submitSuccess = status === 200;
  const paymentAdded = t("paymentsCheck.addedSuccessfully");
  const paymentNotAdded = t("paymentsCheck.addedIncorrectly");
  const loading = t("paymentsCheck.loading");
  const manage = t("paymentsCheck.manage");
  const homePage = t("paymentsCheck.homePage");
  const addStripe = t("paymentsCheck.addStripe");

  useDidUpdate(() => {
    if (organizationData) {
      submit().then();
    }
  }, [organizationData]);

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />

      {!submitSuccess && <Typography className={styles.text}>{loading}</Typography>}
      {submitSuccess && (
        <Typography className={styles.text}>{paymentActive ? paymentAdded : paymentNotAdded}</Typography>
      )}

      <div className={styles.buttons}>
        {!submitSuccess && <Button disabled={submitting || !organizationData} className={styles.leftButton} />}
        {submitSuccess && (
          <Button
            disabled={submittingStripeLink}
            onClick={paymentActive ? openManagement : addStripeLink}
            className={styles.leftButton}
          >
            {paymentActive ? manage : addStripe}
          </Button>
        )}
        <Button onClick={openHomePage} className={styles.rightButton}>
          {homePage}
        </Button>
      </div>
    </div>
  );
};
