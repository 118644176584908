import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Navbar } from "components";
import { LoginForm } from "./login-form/login-form";
import { HOME_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-name.svg";

import styles from "./login.module.scss";

export const LoginPage = () => {
  const navigate = useNavigate();
  const { localePath } = useRoutesPath();

  const openHomePage = () => navigate(localePath({ route: HOME_PAGE }));

  return (
    <div className={styles.container}>
      <Navbar className={styles.mobile} />
      <div className={classNames(styles.banner, styles.desktop)}>
        <Logo onClick={openHomePage} className={styles.logo} />
      </div>
      <div className={styles.content}>
        <LoginForm />
      </div>
    </div>
  );
};
