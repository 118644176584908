import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { Form, Formik, FormikHelpers } from "formik";
import { useSelector } from "react-redux";

import { FormInitialData, NavbarProps, WatersportsPathProps } from "./navbar.types";
import { useQueryParams, useRoutesPath, useWindowSize } from "hooks";
import { OFFERS_PAGE } from "constants/routes.constants";
import { StrapiTopDestinationsModels } from "models";
import { getSearchBarData, getTopDestinations } from "server";
import { Logo } from "./logo/logo";
import { DestinationForm } from "./form/destination-form";
import { BurgerMenu } from "../burger-menu/burger-menu";
import { MobileMenu } from "./mobile-menu/mobile-menu";
import { LanguageSelect } from "components";
import { OffersDesktop } from "./offers-desktop/offers-desktop";
import { RootState } from "store";
import { bmStrapiDestinationsPath } from "utils/route-params";

import styles from "./navbar.module.scss";

export const Navbar: React.FC<NavbarProps> = ({ className, isGreen }) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const { localePathParams, localePath, locale } = useRoutesPath();
  const { watersports } = useSelector((state: RootState) => state.watersports);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [topDestinations, setTopDestinations] = useState<null | StrapiTopDestinationsModels[]>(null);
  const [locations, setLocations] = useState<string[]>([]);

  const topOffersData = useFetch(getTopDestinations.setQueryParams({ populate: "*" }));
  const { onSuccess: onSuccessOffers } = topOffersData;
  onSuccessOffers((response) => {
    setTopDestinations(response.response.data);
  });

  const locationsDataFetch = useFetch(getSearchBarData);
  const { onSuccess: onSuccessLocations, data: locationsData } = locationsDataFetch;
  onSuccessLocations((res) => {
    setLocations([...res.response.cities.map((item) => item.value)]);
  });

  const destinationsPath = (destination: StrapiTopDestinationsModels): string => {
    const {
      attributes: { filterCountry, dateEnd, dateStart, isBmOffer, location: strapiLocation },
    } = destination;

    if (isBmOffer) {
      const queryParamsData = bmStrapiDestinationsPath({ filterCountry, dateEnd, dateStart });
      return localePathParams({ route: OFFERS_PAGE, queryParams: queryParamsData });
    }

    return localePath({
      route: OFFERS_PAGE,
      queryParams: `?watersport=YACHT&location=${strapiLocation?.toUpperCase()}`,
    });
  };

  const watersportsPath = ({ slugEn }: WatersportsPathProps): string => {
    let path = "";

    if (location.pathname !== "/en/water-sport") {
      path = localePath({
        route: OFFERS_PAGE,
        queryParams: `?watersport=${slugEn.toUpperCase()}`,
      });
    } else {
      queryParams.updateQueryParams({ ...queryParams.query, watersport: slugEn });
    }

    return path;
  };

  const handleSubmit = (data: FormInitialData, helpers: FormikHelpers<FormInitialData>) => {
    const offersPagePathPl = OFFERS_PAGE.path.pl.replace(/:locale/g, "pl");
    const offersPagePathEn = OFFERS_PAGE.path.en.replace(/:locale/g, "en");

    const currentLocalePath = locale === "pl-PL" ? offersPagePathPl : offersPagePathEn;

    if (location.pathname !== currentLocalePath) {
      navigate(
        localePath({
          route: OFFERS_PAGE,
          queryParams: `?location=${data.destination}`,
        }),
      );
    } else {
      queryParams.updateQueryParams({ ...queryParams.query, location: data.destination });
    }

    helpers.resetForm();
  };

  const isMobile = width < 900;
  const color = isGreen ? "#4BBBB7" : "#474CAE";

  useDidUpdate(
    () => {
      if (isMobileMenuOpen) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "unset";
    },
    [isMobileMenuOpen],
    true,
  );

  const initialValues: FormInitialData = {
    destination: "",
  };

  useDidUpdate(() => {
    if (!isMobile) setIsMobileMenuOpen(false);
  }, [isMobile]);

  useDidUpdate(
    () => {
      setIsMobileMenuOpen(false);
      return () => {
        setIsMobileMenuOpen(false);
        document.body.style.overflow = "unset";
      };
    },
    [pathname],
    true,
  );

  return (
    <header className={classNames(styles.navbar, className, { [styles.navbarOpenMenu]: isMobileMenuOpen })}>
      <Logo isGreen={isGreen} isMobile={isMobile} color={color} className={styles.logo} />

      {locations.length > 0 && locationsData?.cities && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form className={styles.form}>
            <DestinationForm color={color} isGreen={isGreen} locationsData={locationsData?.cities} />
          </Form>
        </Formik>
      )}

      {!isMobile && (
        <div className={styles.offers}>
          <OffersDesktop
            color={color}
            watersports={watersports}
            destinations={topDestinations}
            destinationsPath={destinationsPath}
            watersportsPath={watersportsPath}
            isGreen={Boolean(isGreen)}
          />
        </div>
      )}

      <div className={styles.userMenu}>
        {!isMobile && <LanguageSelect isGreen={isGreen} />}
        {isMobile && (
          <MobileMenu
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            color={color}
            watersports={watersports}
            destinations={topDestinations}
            destinationsPath={destinationsPath}
            watersportsPath={watersportsPath}
            isGreen={Boolean(isGreen)}
          />
        )}
        {!isMobile && <BurgerMenu isGreen={isGreen} />}
      </div>
    </header>
  );
};
