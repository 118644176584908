import React from "react";
import { Form, Formik } from "formik";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";

import { RemoveAssetData, RemovePersonModalProps } from "./remove-asset-modal.types";
import { initialValues } from "./remove-asset-modal.constants";
import { DeleteAssetForm } from "./delete-asset-form/delete-asset-form";
import { deleteAsset } from "server";
import { RootState } from "store";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./remove-asset-modal.module.scss";

export const RemoveAssetModal: React.FC<RemovePersonModalProps> = ({ handleClose, refreshAssets }) => {
  const { t } = useTranslation();
  const { organizationData } = useSelector((state: RootState) => state.organization);

  const deleteAssetData = useSubmit(deleteAsset.setParams({ organizationId: organizationData?.id || 0 }));

  const handleSubmit = async (values: RemoveAssetData) => {
    const data = {
      asset_ids: [...values.assets],
    };

    await deleteAssetData.submit({ data });
    refreshAssets();
    handleClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form className={styles.container}>
        <div className={styles.header}>
          <Typography className={styles.modalTitle}>{t("assets.removeAssetModal.removeAsset")}</Typography>
          <IconButton onClick={handleClose} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>

        <DeleteAssetForm />

        <Button className={styles.sendButton} type="submit">
          {t("assets.removeAssetModal.remove")}
        </Button>
      </Form>
    </Formik>
  );
};
