export const pathParams = {
  slug: {
    windsurfing: "windsurfing",
    wakeboard: "wakeboard",
    surfing: "surfing",
    sup: "sup",
    sailing: "sailing",
    rowingBoat: "rowing-boat",
    pedalo: "pedalo",
    other: "other",
    motorboat: "motorboat",
    kitesurfing: "kitesurfing",
    kayaks: "kayaks",
    diving: "diving",
    efoil: "efoil",
    wingfoil: "wing-foil",
    jetski: "jet-ski",
  },
};
