import { DEFAULT_LOCALE } from "constants/app.constants";

export const mapLocale = (locale?: string | null) => {
  if (locale === "pl") return "pl-PL";
  return locale || DEFAULT_LOCALE;
};

type PaginationParams = { page: number | string; pageSize: number | string };

export const getPaginationParams = ({ page, pageSize }: PaginationParams) => {
  return {
    "pagination[page]": page,
    "pagination[pageSize]": pageSize,
  };
};

type SubstringTextData = {
  string: string;
  maxLength: number;
  cutAtChart: number;
  replace?: string;
};

export const substringText = ({ string, maxLength, cutAtChart, replace = "..." }: SubstringTextData) =>
  string.length > maxLength ? string.substring(0, cutAtChart).concat(replace) : string;
