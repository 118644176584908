import { ServerErrorType } from "server/server.types";
import { builder } from "server/builder";
import {
  TokensResponse,
  LoginData,
  RegisterData,
  RefreshTokenData,
  ResetPasswordData,
  ForgotPasswordData,
} from "./auth.types";
import { ResetPasswordParams } from "pages/auth/reset-password/reset-password.types";

export const login = builder.createCommand<TokensResponse, LoginData>()({
  endpoint: "/api/token/",
  method: "POST",
});

export const register = builder.createCommand<TokensResponse, RegisterData>()({
  endpoint: "/app/register",
  method: "POST",
  options: { timeout: 0 },
});

export const forgotPassword = builder.createCommand<TokensResponse, ForgotPasswordData>()({
  endpoint: "/app/reset-password",
  method: "POST",
  options: { timeout: 0 },
});

export const validateRestPasswordToken = builder.createCommand<void, void, ServerErrorType, ResetPasswordParams>()({
  endpoint: "/app/reset-password",
  method: "GET",
});

export const resetPassword = builder.createCommand<
  TokensResponse,
  ResetPasswordData,
  ServerErrorType,
  ResetPasswordParams
>()({
  endpoint: "/app/reset-password",
  method: "PATCH",
  options: { timeout: 0 },
});

export const renewToken = builder.createCommand<TokensResponse, RefreshTokenData>()({
  endpoint: "/api/token/refresh/",
  method: "POST",
});

export const logout = builder.createCommand()({
  endpoint: "/api/token/blacklist/",
  method: "POST",
});
