export const insightsData = {
  name: "Malayka",
  bookings: {
    today: 20,
    tomorrow: 21,
    wee: 124,
  },
};

interface InsightsApiData {
  month: Record<string, never>;
  quarter: Record<string, never>;
  year: Record<string, never>;
}

export const insightsApiData: InsightsApiData = {
  month: {},
  quarter: {},
  year: {},
};

interface DateRange {
  date_from: string;
  date_to: string;
}

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getDateRangeFromDays = (daysBack: number): DateRange => {
  const currentDate = new Date();
  const pastDate = new Date(currentDate);
  pastDate.setDate(currentDate.getDate() - daysBack);

  return {
    date_from: formatDate(pastDate),
    date_to: formatDate(currentDate),
  };
};

export const getDateRangeFromMonths = (monthsBack: number): DateRange => {
  const currentDate = new Date();
  const pastDate = new Date(currentDate);
  pastDate.setMonth(currentDate.getMonth() - monthsBack);

  return {
    date_from: formatDate(pastDate),
    date_to: formatDate(currentDate),
  };
};
