export const login = {
  loginTitle: "Zaloguj się",
  or: "lub",
  emailLabel: "Email",
  passwordLabel: "Hasło",
  registerLabel: "Nie masz konta?",
  forgotPasswordTitle: "Zmień hasło",
  forgotPasswordLabel: "Zapomniałeś/aś hasła?",
  forgotPasswordInfo: "Link do zmiany hasła zostanie wysłany emailem",
  forgotPassword: "Wyślij",
  createAccount: "Stwórz konto",
  externalGoogle: "Kontynuuj z Google",
  externalFacebook: "Kontynuuj z Facebook",
  externalApple: "Kontynuuj z Apple",
  login: "Zaloguj",

  invalidEmail: "Adres email jest niepoprawny",
  emailRequired: "Email jest wymagany",
  passwordRequired: "Hasło jest wymagane",

  loginError: "Błąd podczas logowania", // updated
  forgotPasswordError: "Błąd podczas próby zmiany hasła",
  forgotPasswordSuccess: "Wysłano wiadomość dotyczącą zmiany hasła",
};
