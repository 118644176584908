import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ExtraChargesProps } from "./extra-charges.types";
import { substringText } from "utils";

import styles from "./extra-charges.module.scss";

export const ExtraCharges: React.FC<ExtraChargesProps> = ({ extraCharge, currency, priceValue }) => {
  const { t } = useTranslation();
  const { isRequired, price, name, isFixed } = extraCharge;

  return (
    <div className={styles.container}>
      <div>
        <Typography fontSize="20px" fontFamily="Chillax-semibold">
          {substringText({ string: name, maxLength: 12, cutAtChart: 12 })}
        </Typography>
      </div>
      <div>
        <Typography fontSize="20px" fontFamily="Chillax-semibold">
          {price} {currency}
        </Typography>
        {isFixed && (
          <Typography fontSize="14px" fontFamily="Chillax-medium">
            {t("createOffer.services.fixedPrice")}
          </Typography>
        )}
        {!isFixed && (
          <Typography fontSize="14px" fontFamily="Chillax-medium">
            {priceValue}
          </Typography>
        )}
      </div>

      {isRequired && (
        <Typography fontSize="14px" fontFamily="Chillax-regular">
          *{t("createOffer.services.isRequired")}
        </Typography>
      )}
    </div>
  );
};
