import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Filters } from "../../insights/filters/filters";
import { clientBookingsData } from "../../insights/client-bookings/client-bookings.constants";
import { EmployeesBox } from "./employees-box/employees-box";
import { Employee } from "./employee/employee";

import styles from "./employees-details.module.scss";

export const EmployeesDetails = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <Filters />
        <div className={styles.guestsList}>
          <div className={styles.bookings}>
            <Typography>{t("insights.guestsDetails.clientBookings")}</Typography>
            <Typography>5484 {t("insights.guestsDetails.hours")}</Typography>
          </div>
          {clientBookingsData.map((item) => {
            return <EmployeesBox {...item} />;
          })}
        </div>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.buttons}>
          <Button className={styles.button}>{t("insights.guestsDetails.editData")}</Button>
          <Button className={styles.button}>{t("insights.guestsDetails.exportData")}</Button>
          <Button className={styles.button}>{t("insights.guestsDetails.newClient")}</Button>
          <Button className={styles.button}>{t("insights.guestsDetails.search")}</Button>
        </div>
        <Employee />
      </div>
    </div>
  );
};
