import React from "react";

import { Hero } from "./hero/hero";
import { Section } from "./section/section";
import { Crew } from "./crew/crew";

import styles from "./about.module.scss";

export const AboutPage = () => {
  return (
    <div className={styles.container}>
      <Hero />

      <Section />

      <Crew />
    </div>
  );
};
