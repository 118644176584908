import { TFunction } from "i18next";
import { boolean, object, SchemaOf, string } from "yup";

import { regex } from "utils";
import { TextInputProps } from "components/form/form-text-field/form-text-field.types";
import { RegisterData } from "server/auth/auth.types";

export const initialValues: RegisterData = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  password: "",
  seaeasy_agreement: false,
  third_party_agreement: false,
};

export const registerSchema = (t: TFunction): SchemaOf<RegisterData> =>
  object().shape({
    first_name: string().required(t("register.firstNameRequired")),
    last_name: string().required(t("register.lastNameRequired")),
    phone_number: string()
      .matches(regex.phone, t("register.invalidPhoneNumber"))
      .required(t("register.phoneNumberRequired")),
    email: string().email(t("register.invalidEmail")).required(t("register.emailRequired")),
    password: string().required(t("register.passwordRequired")).min(6, t("register.minimumLength")),
    seaeasy_agreement: boolean().required(),
    third_party_agreement: boolean().required(),
  });

export const sx = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "none" },
  },
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "15px",
    background: "#fff",
    border: "2px solid #474CAE",
  },
};

export const registerFields = (t: TFunction): TextInputProps[] => [
  {
    name: "email",
    type: "email",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.emailLabel"),
  },
  {
    name: "first_name",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.firstNameLabel"),
  },
  {
    name: "last_name",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.lastNameLabel"),
  },
  {
    name: "phone_number",
    type: "number",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.phoneLabel"),
  },
  {
    name: "password",
    type: "password",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.passwordLabel"),
    helperText: t("register.passwordRequirements"),
    FormHelperTextProps: {
      color: "green",
    },
  },
];
