import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { CREATE_OFFER_PAGE, CREATE_RENTAL_PAGE, HOME_PAGE } from "constants/routes.constants";
import { getUserOrganization, checkStripeData, generateStripeLink } from "server";
import { useRoutesPath } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-icon.svg";

import styles from "./rental-payment.module.scss";

export const RentalPaymentPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const [organizationID, setOrganizationID] = useState<null | number>(null);
  const [message, setMessage] = useState<string>(t("rental.rentalPayment.checking"));
  const [status, setStatus] = useState<boolean | null>(null);
  const [organizationRefresher, setOrganizationRefresher] = useState<number>(0);

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(
    checkStripeData.setParams({ organization_id: organizationID || "0" }),
  );
  onSubmitSuccess(({ response }) => {
    setStatus(response);
    if (response) return setMessage(t("rental.rentalPayment.everythingIsOk"));
    setMessage(t("rental.rentalPayment.completeDetails"));
  });

  const {
    submit: submitStripeLink,
    onSubmitSuccess: onSubmitSuccessStripeLink,
    onSubmitError: onSubmitErrorStripeLink,
  } = useSubmit(generateStripeLink.setParams({ organization_id: organizationID || "0" }));
  onSubmitSuccessStripeLink(({ response }) => {
    window.open(response.stripe_url, "_self");
  });

  const refresh = () => setOrganizationRefresher((prevState) => prevState + 1);

  const { onSuccess: onUserOrganizationSuccess, onError: onUserOrganizationError } = useFetch(getUserOrganization, {
    dependencies: [organizationRefresher],
  });
  onUserOrganizationSuccess(({ response }) => setOrganizationID(response[0].id));
  onUserOrganizationError(() => navigate(localePath({ route: CREATE_RENTAL_PAGE })));

  onSubmitError(() => {
    refresh();
  });
  onSubmitErrorStripeLink(() => {
    refresh();
  });

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };
  const handleGenerateStripeLink = async () => {
    await submitStripeLink();
  };
  const openOfferPage = () => navigate(localePath({ route: CREATE_OFFER_PAGE }));

  useDidUpdate(() => {
    if (organizationID) submit().then();
  }, [organizationID]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Logo className={styles.logo} onClick={openHomePage} />
        <div className={styles.content}>
          <Typography fontSize="30px" fontFamily="Chillax-Regular">
            {t("rental.rentalPayment.paymentDetails")}
          </Typography>
          <Typography fontFamily="Chillax-Regular">{message}</Typography>
          {status === false && (
            <Button className={styles.button} onClick={handleGenerateStripeLink}>
              {t("rental.rentalPayment.goToDataCompletion")}
            </Button>
          )}
          {status && (
            <Button className={styles.button} onClick={openOfferPage}>
              {t("rental.rentalPayment.createOffer")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
