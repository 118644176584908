import React from "react";
import classNames from "classnames";
import { LatLng, LeafletMouseEvent, MapOptions } from "leaflet";
import { MapContainer } from "react-leaflet";

import { position as positionDefault } from "./map.constants";
import { MapContent } from "./map-content/map-content";
import { MarkersData } from "pages/offers/list/offers-list.types";
import { Loader } from "components";

import styles from "./map.module.css";

interface Props extends MapOptions {
  markers: LatLng | LatLng[] | MarkersData[] | MarkersData;
  position?: LatLng;
  handleClick?: (event: LeafletMouseEvent) => void;
  className?: string;
  showLoader?: boolean;
  disablePin?: boolean;
}

export const Map: React.FC<Props> = ({ className, position, showLoader, disablePin = false, ...props }) => {
  const key = position?.lat || showLoader ? null : undefined;

  return (
    <MapContainer
      zoom={10}
      center={position || positionDefault}
      className={classNames(styles.map, className)}
      key={key}
    >
      <MapContent {...props} disablePin={disablePin} />

      {showLoader && <Loader className={styles.mapLoader} showTransparency />}
    </MapContainer>
  );
};
