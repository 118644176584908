import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useRoutesPath } from "hooks";
import { HOME_PAGE } from "../../constants/routes.constants";

import { ReactComponent as Pineapple } from "assets/icons/pineapple-icon.svg";

import styles from "./not-found.module.scss";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <Pineapple className={styles.icon} />
        <div className={styles.content}>
          <Typography className={styles.text}>{t("notFoundPage.error")}</Typography>
          <Button className={styles.button} onClick={openHomePage}>
            {t("notFoundPage.homePage")}
          </Button>
        </div>
      </div>
    </div>
  );
};
