export const common = {
  submit: "Prześlij",
  save: "Zapisz",
  delete: "Usuń",
  add: "Dodaj",
  remove: "Usuń",
  back: "Cofnij",
  next: "Dalej",
  publish: "Opublikuj",
  preview: "Podgląd",
  yes: "Tak",
  no: "Nie",
  seeMore: "Sprawdź więcej",
  edit: "Edytuj",
  contactUs: "Skontaktuj się z nami",
  cancel: "Anuluj",
  looksGood: "Wygląda dobrze",
  completed: "Zakończone",
  fillAllFields: "Wypełnij wszystkie wymagane pola",
  thereIsNothingHere: "Przepraszamy, tu nic nie ma",
  pl: "Polski",
  en: "English",
  homePage: "strona główna",
  submitting: "Publikowanie",
  loading: "Ładowanie",

  refresh: "Odśwież",
  fetchingError: "Błąd pobierania",
  fetchingErrorDescription: "Nie udało sie pobrać danych",

  lorem:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
};
