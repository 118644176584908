import { TFunction } from "i18next";
import { object, ref, SchemaOf, string } from "yup";

import { TextInputProps } from "components/form/form-text-field/form-text-field.types";
import { ResetPasswordFormData } from "./reset-password-form.types";

export const initialValues: ResetPasswordFormData = {
  password: "",
  passwordConfirmation: "",
};

export const resetPasswordSchema = (t: TFunction): SchemaOf<ResetPasswordFormData> =>
  object().shape({
    password: string().required(t("register.passwordRequired")).min(6, t("register.minimumLength")),
    passwordConfirmation: string()
      .required(t("register.passwordsMatch"))
      .oneOf([ref("password")], t("register.passwordsMatch")),
  });

export const sx = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "none" },
  },
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "15px",
    background: "#fff",
    border: "2px solid #474CAE",
  },
};

export const resetPasswordFields = (t: TFunction): TextInputProps[] => [
  {
    name: "password",
    type: "password",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.passwordLabel"),
    helperText: t("register.passwordRequirements"),
    FormHelperTextProps: {
      color: "green",
    },
  },
  {
    name: "passwordConfirmation",
    type: "password",
    sx,
    InputProps: inputProps,
    size: "small",
    label: t("register.repeatPasswordLabel"),
    helperText: t("register.passwordsMatch"),
    FormHelperTextProps: {
      color: "green",
    },
  },
];
