import { builder } from "server/builder";
import { UserModel } from "models";

export const getUser = builder.createCommand<UserModel, null>()({
  method: "GET",
  endpoint: "/app/user",
});

export const editUser = builder.createCommand<UserModel>()({
  method: "PUT",
  endpoint: "/app/user",
});
