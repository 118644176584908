import React from "react";
import { icon, LatLng, LeafletMouseEvent } from "leaflet";
import { Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { useTranslation } from "react-i18next";

import { MarkersData } from "pages/offers/list/offers-list.types";
import { PopupContent } from "./popup/popup";
import { watersportsData } from "../../../../utils/watersports.utils";

import mapIcon from "assets/images/pin-icon.png";
import "../map.css";

import styles from "./map-content.module.scss";

interface Props {
  markers: LatLng | LatLng[] | MarkersData | MarkersData[];
  handleClick?: (event: LeafletMouseEvent) => void;
  disablePin?: boolean;
}

export const MapContent: React.FC<Props> = ({ markers, handleClick, disablePin }) => {
  useMapEvents({
    click: handleClick,
  });

  const { t } = useTranslation();

  const markersToDisplay: MarkersData[] = Array.isArray(markers as MarkersData)
    ? (markers as MarkersData[])
    : [markers as MarkersData];

  const getMarkerIcon = (marker: MarkersData): string =>
    watersportsData(t).find((item) => item.id === marker.watersportId)?.pin || mapIcon;

  return (
    <div className={styles.container}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {markersToDisplay.map((marker) => {
        const MarkerIcon = icon({ iconUrl: getMarkerIcon(marker), iconSize: [40, 50] });

        return (
          <Marker key={marker.lat} position={marker} icon={MarkerIcon}>
            {!disablePin && (
              <Popup className={styles.popup}>
                <PopupContent offerId={marker?.id} />
              </Popup>
            )}
          </Marker>
        );
      })}
    </div>
  );
};
