import { TFunction } from "react-i18next";
import { object, SchemaOf, string } from "yup";

import { NewsletterFormData } from "./newsletter-form.types";

export const initialValues: NewsletterFormData = {
  email: "",
};

export const newsletterSchema = (t: TFunction): SchemaOf<NewsletterFormData> =>
  object().shape({
    email: string().email(t("login.invalidEmail")).required(t("login.emailRequired")),
  });

export const inputStyles = {
  color: "#fff",
  width: "100%",
  input: { paddingLeft: 2, "&::placeholder": { opacity: 1 } },
};

export const inputProps = {
  style: {
    color: "#fff",
    border: `1px solid rgba(213, 146, 103, 1)`,
    borderRadius: "40px",
  },
  disableUnderline: true,
};
