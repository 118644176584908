import React from "react";
import { IconButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Input, Select } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { ModalProps } from "../base-info.types";
import { OfferData } from "../../create-offer.types";
import { currencySelectOptions, pricePerSelectOptions } from "../../create-offer.constants";

import "./pricing-modal.css";

import styles from "./pricing-modal.module.scss";

export const PricingModal: React.FC<ModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<OfferData>();

  const handleSelect = (value: string, name: string) => {
    const fieldName = `baseInfo.pricing[${name}]`;

    setFieldValue(fieldName, value);
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
    const name = e?.currentTarget?.name;
    const value = e?.currentTarget?.value;

    const fieldName = `baseInfo.pricing[${name}]`;

    setFieldValue(fieldName, parseInt(value, 10));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.modalName}>{t("createOffer.baseInfo.modal.pricing.addPricing")}</Typography>
        <div className={styles.headerButtons}>
          <IconButton onClick={close} className={styles.iconButton}>
            <CheckIcon />
          </IconButton>
          <IconButton className={styles.iconButton} onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.pricing.basePrice")}</Typography>
          <Input name="basePrice" type="number" value={values.baseInfo.pricing.basePrice} onChange={handleInput} />
        </div>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.pricing.rateType")}</Typography>
          <Select
            popupClassName={styles.popup}
            className={styles.select}
            options={pricePerSelectOptions(t)}
            onChange={(e) => handleSelect(e, "rateType")}
            value={values.baseInfo.pricing.rateType}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.pricing.maxGuests")}</Typography>
          <Input name="maxGuests" type="number" value={values.baseInfo.pricing.maxGuests} onChange={handleInput} />
        </div>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.pricing.currency")}</Typography>
          <Select
            popupClassName={styles.popup}
            className={styles.select}
            options={currencySelectOptions}
            onChange={(e) => handleSelect(e, "currency")}
            value={values.baseInfo.pricing.currency}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Typography>{t("createOffer.baseInfo.modal.pricing.deposit")}</Typography>
          <Input name="deposit" type="number" value={values.baseInfo.pricing.deposit} onChange={handleInput} />
        </div>
      </div>
    </div>
  );
};
