import React from "react";
import ReactDOM from "react-dom";
import { registerLicense, loadCldr, L10n } from "@syncfusion/ej2-base";

import { App } from "app";
import { Providers } from "components";
import { reportWebVitals } from "reportWebVitals";
import * as numberingSystems from "./culture-files/numberingSystems.json";
import * as gregorian from "./culture-files/ca-gregorian.json";
import * as numbers from "./culture-files/numbers.json";
import * as timeZoneNames from "./culture-files/timeZoneNames.json";
import { environment } from "./config/environment.config";
import { pl } from "config/syncfusion.config";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

registerLicense(environment.syncfusionKey);
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({ pl });

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app; pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
