import React from "react";
import { ListItem, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { useRoutesPath } from "hooks";
import { getSeaTalk } from "server";
import { SeaTalkProps } from "./sea-talk.types";

import stylesMain from "../home.module.scss";
import styles from "./sea-talk.module.scss";

export const SeaTalk: React.FC<SeaTalkProps> = ({ refElement }) => {
  const { t } = useTranslation();
  const { locale } = useRoutesPath();

  const getSeaTalkData = useFetch(
    getSeaTalk.setQueryParams({
      populate: "*",
    }),
  );

  const { data } = getSeaTalkData;

  const handleUrl = (url: string) => window.open(url, "_blank");

  return (
    <div ref={refElement} className={classNames(stylesMain.itemContainer, styles.container)}>
      <Typography variant="h4" className={classNames(stylesMain.itemSubtitle, styles.subTitle)}>
        {t("home.seaTalk")}
      </Typography>
      <ul className={styles.content}>
        {data?.data.map((item) => {
          const title = locale === "pl-PL" ? item.attributes.titlePl : item.attributes.titleEn;

          return (
            <ListItem onClick={() => handleUrl(item.attributes.url)} className={styles.itemWrapper}>
              <img src={item.attributes.background.data.attributes.url} alt={item.attributes.titleEn} />

              <Typography>{title}</Typography>
            </ListItem>
          );
        })}
      </ul>
    </div>
  );
};
