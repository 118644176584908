import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { RegisterForm } from "./register-form/register-form";
import { HOME_PAGE } from "constants/routes.constants";
import { Navbar } from "components";
import { useRoutesPath } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-name.svg";

import styles from "./register.module.scss";

export const RegisterPage = () => {
  const navigate = useNavigate();

  const { localePath } = useRoutesPath();

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  return (
    <div className={styles.container}>
      <Navbar className={styles.mobile} />
      <div className={classNames(styles.banner, styles.desktop)}>
        <Logo onClick={openHomePage} className={styles.logo} />
      </div>
      <div className={styles.content}>
        <RegisterForm />
      </div>
    </div>
  );
};
