import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import classNames from "classnames";

import { ChooseBoatBox } from "./choose-boat-box/choose-boat-box";
import { RootState } from "store";
import { useWindowSize } from "hooks";
import { Loader } from "components";
import { responsive } from "./chose-boat.constants";
import { ChooseBoatProps } from "./choose-boat.types";
import { getWatersportTranslation } from "utils/watersports.utils";

import stylesMain from "../home.module.scss";
import styles from "./choose-boat.module.scss";

export const ChooseBoat: React.FC<ChooseBoatProps> = ({ refElement }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { watersports } = useSelector((state: RootState) => state.watersports);

  const isDesktop = width > 992;

  return (
    <div className={classNames(stylesMain.itemContainer, styles.container)} ref={refElement}>
      <div className={stylesMain.itemHeader}>
        <Typography variant="h2" className={stylesMain.itemTitle}>
          {t("home.chooseWatersport.chooseWatersport")}
        </Typography>
        <Typography variant="h3" className={stylesMain.itemSubtitle}>
          {t("home.chooseWatersport.content")}
        </Typography>
      </div>
      <div className={styles.content}>
        {!watersports && <Loader />}
        {watersports &&
          isDesktop &&
          watersports.map((watersport) => {
            const watersportName = getWatersportTranslation(t, watersport.name);
            const isBmOffer = false;

            return (
              <ChooseBoatBox
                key={watersport.id}
                label={watersportName}
                icon={watersport.thumbnail_url}
                isBmOffer={isBmOffer}
                name={watersport.name}
              />
            );
          })}
        {!isDesktop && watersports && (
          <Carousel
            responsive={responsive}
            swipeable
            rewind
            focusOnSelect
            infinite
            partialVisible
            renderDotsOutside
            arrows={false}
            autoPlay
            draggable
            containerClass={styles.carouselContainer}
            sliderClass={styles.carousel}
            itemClass={styles.item}
          >
            {watersports?.map((watersport) => {
              const watersportName = getWatersportTranslation(t, watersport.name);
              const isBmOffer = false;

              return (
                <ChooseBoatBox
                  key={watersport.id}
                  label={watersportName}
                  icon={watersport.thumbnail_url}
                  isBmOffer={isBmOffer}
                  name={watersport.name}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};
