import React, { useState } from "react";
import { useFetch } from "@better-typed/react-hyper-fetch";
import ReactMarkdown from "react-markdown";

import { Loader } from "components";
import { getArticle } from "server";
import { useShowContent } from "hooks";
import { StrapiArticleModel } from "models";
import { PRIVACY_POLICY_ID } from "../privacy.constants";

import styles from "../policy.module.scss";

export const PrivacyPolicyPage = () => {
  const [article, setArticle] = useState<null | StrapiArticleModel>(null);

  const articleData = useFetch(getArticle.setParams({ articleId: PRIVACY_POLICY_ID }));
  const { showContent, showLoader } = useShowContent(articleData);
  const { onSuccess } = articleData;
  onSuccess((response) => {
    setArticle(response.response.data);
  });

  return (
    <div className={styles.container}>
      {showLoader && <Loader />}
      {showContent && article && (
        <div className={styles.content}>
          <ReactMarkdown className={styles.title}>{article.attributes.title}</ReactMarkdown>
          <ReactMarkdown className={styles.text}>{article.attributes.text}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};
