import React from "react";
import { MenuItem, MenuList, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { navigationData } from "./navigation.constants";
import { useQueryParams } from "hooks";
import { NavigationProps } from "./navigation.types";

import styles from "./navigation.module.scss";

export const Navigation: React.FC<NavigationProps> = ({ className }) => {
  const { t } = useTranslation();
  const {
    updateQueryParams,
    query: { section },
  } = useQueryParams();

  const handleChangeSection = (sectionToChange: string) => () => {
    updateQueryParams({ section: sectionToChange });
  };

  return (
    <MenuList className={classNames(styles.container, className)}>
      {navigationData(t).map(({ value, label }) => {
        const isActiveSection = section === value;

        return (
          <MenuItem
            onClick={handleChangeSection(value)}
            className={classNames(styles.menuItem, { [styles.activeMenuItem]: isActiveSection })}
          >
            <Typography>{label}</Typography>
          </MenuItem>
        );
      })}
    </MenuList>
  );
};
