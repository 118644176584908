export const article = {
  section: {
    contents: "Contents:",
    otherArticles: "Other Articles",
  },

  moreArticles: {
    sailSmart: "Read more",
    seeMore: "See more",
  },
};
