import { TFunction } from "i18next";
import { object, SchemaOf, string } from "yup";

import { FormTypes } from "./form-component.types";

import employee from "assets/icons/employee.svg";
import yacht from "assets/icons/yacht-blue.svg";
import service from "assets/icons/service-icon.svg";

export const initialValues: FormTypes = {
  topic: "",
  email: "",
  message: "",
};

export const inputStyles = {
  paddingLeft: 1,
  borderRadius: "10px",
  input: { color: "#000", "&::placeholder": { fontSize: 15 } },
  width: "100%",
  height: "45px",
  background: "#fff",
  margin: 0,
};

export const textAreaStyles = {
  paddingLeft: 1,
  borderRadius: "10px",
  input: { color: "#000", "&::placeholder": { fontSize: 15 } },
  width: "100%",
  height: "150px",
  background: "#fff",
  margin: 0,
};

export const inputProps = {
  style: {
    color: "#000",
    borderRadius: "40px",
    padding: "5px",
    paddingTop: "8px",
  },
  disableUnderline: true,
};

export const contactSchema = (t: TFunction): SchemaOf<FormTypes> =>
  object().shape({
    topic: string().required(t("management.yourBusiness.form.validation.topicIsRequired")),
    email: string()
      .email(t("management.yourBusiness.form.validation.valueMustBeEmail"))
      .required(t("management.yourBusiness.form.validation.emailIsRequired")),
    message: string().required(t("management.yourBusiness.form.validation.messageIsRequired")),
  });

export const formData = (t: TFunction) => ({
  "recruit-employee": {
    title: t("management.yourBusiness.form.recruitEmployee"),
    icon: employee,
    description: t("management.yourBusiness.form.recruitEmployeeDescription"),
  },
  "order-equipment": {
    title: t("management.yourBusiness.form.orderEquipment"),
    icon: yacht,
    description: t("management.yourBusiness.form.orderEquipmentDescription"),
  },
  "request-service": {
    title: t("management.yourBusiness.form.requestService"),
    icon: service,
    description: t("management.yourBusiness.form.requestServiceDescription"),
  },
});
