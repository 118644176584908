import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { Description } from "./description/description";
import { Details } from "./details/details";
import { Rules } from "./rules/rules";
import { Footer, Loader, ReservationsModal } from "components";
import { RentalPlace } from "./rental-place/rental-place";
import { Location } from "./location/location";
import { useQueryParams, useRoutesPath, useShowContent, useWindowSize } from "hooks";
import { getOffer, getOffers } from "server/offer/offer.server";
import { OfferDescription } from "./offer-description/offer-description";
import { getTopOfferBySlug } from "server";
import { OFFER_PAGE } from "constants/routes.constants";
import { FetchingError } from "./fetching-error/fetching-error";
import { OfferModel } from "models";
import { Gallery } from "./gallery/gallery";
import { Cart } from "./cart/cart";

import styles from "./offer.module.scss";

export const OfferPage = () => {
  const navigate = useNavigate();

  const { deleteQueryParams } = useQueryParams();
  const { slug = null } = useParams();
  const { offerId, location } = useParams<{ offerId: string; location: string }>();
  const { width } = useWindowSize();
  const { localePathParams, locale } = useRoutesPath();

  const [id, setId] = useState<null | number>(null);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const [offerRefresher, setOfferRefresher] = useState<number>(0);
  const [, setSimilarOffers] = useState<OfferModel[]>([]);

  const setParams = () => {
    if (slug) return id || "";
    return offerId || "";
  };

  const offerData = useSubmit(getOffer.setParams({ id: setParams() }));
  const { data: offer, error, submitting, submit: submitOffer } = offerData;
  const { showContent, showError, showLoader } = useShowContent({ error, data: offer, loading: submitting });

  const topOfferData = useSubmit(
    getTopOfferBySlug.setQueryParams({
      "filters[slug]": slug || "",
      populate: "*",
      locale: locale === "pl-PL" ? "pl-PL" : "en",
    }),
  );
  const { submit, onSubmitSuccess } = topOfferData;
  onSubmitSuccess((response) => {
    setId(response.response.data[0].attributes.SeaEasyOffer.offerId);
  });

  const similarOffersData = useSubmit(getOffers.setQueryParams({ location }));
  const { submit: submitSimilarOffers, onSubmitSuccess: onSubmitSuccessSimilarOffers } = similarOffersData;
  onSubmitSuccessSimilarOffers((res) => {
    if (res.response.length >= 5) {
      const offersArray = res.response;
      offersArray.length = 5;

      setSimilarOffers(offersArray);
    }
  });

  const toggleModal = (isOpen: boolean) => () => setIsCartOpen(isOpen);
  const refresh = () => setOfferRefresher((prevState) => prevState + 1);

  useDidUpdate(
    () => {
      submitSimilarOffers().then();
    },
    [offer],
    true,
  );

  useDidUpdate(() => {
    navigate(localePathParams({ route: OFFER_PAGE, params: { offerId: offerId || "" } }));
  }, [locale]);

  useDidUpdate(() => {
    if (id) submitOffer().then();
  }, [id]);

  useEffect(() => {
    if (width >= 1280) return toggleModal(false);
  }, [width]);

  const isMobileModal = width < 1280;

  useDidUpdate(() => {
    submitOffer().then();
  }, [offerRefresher]);

  useDidUpdate(
    () => {
      if (slug) submit().then();
      if (!slug) submitOffer().then();
    },
    [],
    true,
  );

  useDidUpdate(() => {
    return () => {
      deleteQueryParams();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        {showLoader && <Loader className={styles.loader} />}
        {showError && <FetchingError handleClick={refresh} />}
        {showContent && offer && (
          <>
            <Gallery offerImages={offer.offer_images} rentalImages={offer.organization.organization_images} />

            <Description
              className={styles.mobile}
              title={offer?.title || offer.organization.name}
              city={offer?.location.city}
              region={offer?.location.region}
              country={offer?.location.country}
            />

            <section className={styles.wrapper}>
              <div className={styles.sectionContainer}>
                <Details offer={offer} />
                {!offer.is_simple_offer && <Rules rules={offer.organization.rules} />}
                <OfferDescription
                  offerDescription={offer.description}
                  rentalDescription={offer.organization.description}
                />
                {offer.title && !offer.is_simple_offer && <RentalPlace organization={offer.organization} />}
              </div>

              <div className={styles.floatingSection}>
                <Description
                  title={offer?.title || offer.organization.name}
                  city={offer?.location.city}
                  region={offer?.location.region}
                  country={offer?.location.country}
                />
                <Cart offer={offer} setIsCartOpen={setIsCartOpen} />
              </div>
            </section>

            {isMobileModal && <Cart offer={offer} setIsCartOpen={setIsCartOpen} />}

            <Location
              latitude={offer.location.latitude}
              longtitude={offer.location.longitude}
              boatPlace={offer.location}
              rentalPlace={offer.organization?.location}
              isSimpleOffer={offer.is_simple_offer}
            />
            {/* {similarOffers.length >= 4 && !offer.is_simple_offer && <SimilarOffers offers={similarOffers} />} */}

            <Footer />
          </>
        )}

        {offer && <ReservationsModal open={isCartOpen} close={toggleModal(false)} />}
      </div>
    </div>
  );
};
