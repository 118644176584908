export const navbar = {
  yachts: "Yachts",
  watersports: "Water sports",
  allWatersports: "All",
  allYachts: "All yachts",
  destinations: "Top destinations",
  topDestinations: "World",
  inputPlaceholder: "Where do you want to go?",
  inputPlaceholderMobile: "Anywhere",
};
