import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Pie, PieChart } from "recharts";

import { useQueryParams } from "hooks";
import { clientBookingsData } from "../../../insights/client-bookings/client-bookings.constants";
import { PayoutsItem } from "./payouts-item/payouts-item";
import { ActivityItem } from "./activity-item/activity-item";

import styles from "./employee.module.scss";

const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
];

export const Employee = () => {
  const { t } = useTranslation();
  const {
    query: { id },
  } = useQueryParams();

  const activeGuestIndex = clientBookingsData.findIndex((item) => item.id === parseInt(String(id), 10));
  const activeGuest = clientBookingsData[activeGuestIndex];

  return (
    <div className={styles.container}>
      <div className={styles.overview}>
        <Typography className={styles.newClient}>{t("insights.clientBookings.newClient")}</Typography>
        <div>
          <Typography className={styles.name}>{activeGuest.name}</Typography>
          <div className={styles.time}>
            <Typography>
              252h
              <br /> {t("insights.clientBookings.soFar")}
            </Typography>
            <Typography>
              28h <br /> {t("insights.clientBookings.remaining")}
            </Typography>
          </div>
          <div className={styles.progress}>
            <div />
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <Typography className={styles.detailsName}>{t("insights.clientBookings.aboutClient")}</Typography>
        <div className={styles.detailsAbout}>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.telephone")}</Typography>
            <Typography>{activeGuest.details.phoneNumber}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.email")}</Typography>
            <Typography>{activeGuest.details.email}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.gender")}</Typography>
            <Typography>{activeGuest.details.gender}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.dateOfBirth")}</Typography>
            <Typography>{activeGuest.details.dateOfBirth}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.weight")}</Typography>
            <Typography>{activeGuest.details.weight}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.size")}</Typography>
            <Typography>{activeGuest.details.size}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>Level: </Typography>
            <Typography>{activeGuest.details.level}</Typography>
          </div>
          <div className={styles.detailsWrapper}>
            <Typography>{t("insights.clientBookings.notes")}</Typography>
            <Typography>{activeGuest.details.notes}</Typography>
          </div>
        </div>
      </div>
      <div className={styles.payouts}>
        <div className={styles.titleWrapper}>
          <Typography className={styles.detailsName}>{t("insights.clientBookings.lastPayouts")}:</Typography>
          <Button className={styles.paymentsSectionButton}>{t("insights.clientBookings.individual")}</Button>
          <Button className={styles.paymentsSectionButton}>{t("insights.clientBookings.groups")}</Button>
          <Button className={styles.paymentsSectionButton}>{t("insights.clientBookings.extras")}</Button>
          <Button className={styles.addPayoutsButton}>{t("insights.clientBookings.addPayouts")}</Button>
        </div>
        <div className={styles.payoutsWrapper}>
          <PayoutsItem />
          <PayoutsItem />
        </div>
        <PieChart className={styles.chart} width={150} height={150}>
          <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
        </PieChart>
      </div>
      <div className={styles.currentPackage}>
        <div className={styles.currentPackageLeft}>
          <Typography className={styles.detailsName}>{t("insights.guestsDetails.currentPackage")}</Typography>
          <Button className={styles.editButton}>{t("insights.guestsDetails.editPackage")}</Button>
        </div>
        <div>
          <div className={styles.time}>
            <Typography>
              252h
              <br /> {t("insights.clientBookings.soFar")}
            </Typography>
            <Typography>
              28h <br /> {t("insights.clientBookings.remaining")}
            </Typography>
          </div>
          <div className={styles.progress}>
            <div />
          </div>
        </div>
      </div>

      <div className={styles.details}>
        <Typography className={styles.detailsName}>{t("insights.clientBookings.aboutClient")}</Typography>
        <div className={styles.detailsAbout}>
          <ActivityItem />
          <ActivityItem />
        </div>
      </div>
    </div>
  );
};
