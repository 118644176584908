import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { clientBookingsData } from "./client-bookings.constants";
import { ItemBox } from "./item-box/item-box";
import { useWindowSize } from "hooks";
import { ItemBoxDesktop } from "./item-box-desktop/item-box-desktop";
import { ManagementContext } from "../../management.context";

import styles from "./client-bookings.module.scss";
import stylesDesktop from "./client-bookings-desktop.module.scss";

export const ClientBookings = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { openGuestsDetails } = useContext(ManagementContext);

  const handleAllGuests = () => openGuestsDetails(1);

  const isMobile = width < 992;

  if (isMobile)
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography className={styles.teamHours}>{t("insights.clientBookings.clientBookings")}</Typography>
          <Typography className={styles.hours}>3272 {t("insights.clientBookings.hours")}</Typography>
        </div>

        <div className={styles.content}>
          {clientBookingsData.map((item) => (
            <ItemBox {...item} />
          ))}
        </div>
        <Button className={styles.seeAllButton}>{t("insights.clientBookings.seeAll")}</Button>
      </div>
    );

  return (
    <div className={stylesDesktop.container}>
      <div className={stylesDesktop.header}>
        <Typography className={styles.teamHours}>{t("insights.clientBookings.clientBookings")}</Typography>
        <Typography className={styles.hours}>3272 {t("insights.clientBookings.hours")}</Typography>
      </div>

      <div className={stylesDesktop.content}>
        {clientBookingsData.map((item) => (
          <ItemBoxDesktop {...item} />
        ))}
      </div>
      <Button className={styles.seeAllButton} onClick={handleAllGuests}>
        {t("insights.clientBookings.seeAll")}
      </Button>
    </div>
  );
};
