import React from "react";
import { Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, pl } from "date-fns/locale";

import { MoreArticlesProps } from "./more-articles.types";
import { ARTICLE_PAGE, KNOWLEDGE_CENTER_PAGE } from "constants/routes.constants";
import { useRoutesPath, useWindowSize } from "hooks";

import styles from "./more-articles.module.scss";

export const MoreArticles: React.FC<MoreArticlesProps> = ({ articles }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { locale, localePath, localePathParams } = useRoutesPath();

  const openArticles = () => {
    navigate(localePath({ route: KNOWLEDGE_CENTER_PAGE }));
  };

  const isMobile = width < 992;
  const dateFormat = "dd MM yyyy";
  const dateLocale = locale === "pl-PL" ? pl : enUS;

  return (
    <div className={styles.moreArticles}>
      <Typography variant="h2" className={styles.sectionName}>
        {t("article.moreArticles.sailSmart")}
      </Typography>
      <div className={styles.wrapper}>
        {articles.map(({ attributes }) => {
          const slugs = {
            pl: attributes.slug,
            en: attributes.slug,
          };

          const coverMobile = attributes.coverMobile.data.attributes.url;
          const coverDesktop = attributes.coverDesktop.data.attributes.url;

          return (
            <Link
              to={localePathParams({ route: ARTICLE_PAGE, localeParams: { slug: slugs } })}
              className={styles.articleBox}
              key={attributes.title}
            >
              <img src={isMobile ? coverMobile : coverDesktop} alt="article" />
              <Typography variant="h4">
                {format(new Date(attributes.publishedAt), dateFormat, {
                  locale: dateLocale,
                })}
              </Typography>
              <Typography variant="h3">{attributes.title}</Typography>
            </Link>
          );
        })}
      </div>
      <Button onClick={openArticles} className={styles.button}>
        {t("article.moreArticles.seeMore")}
      </Button>
    </div>
  );
};
