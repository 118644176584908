import React from "react";
import { IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { HeaderProps } from "./header.types";
import { Loader } from "components";
import { useWindowSize } from "hooks";

import avatarIcon from "assets/icons/avatar-rental.svg";

import styles from "./header.module.scss";

export const Header: React.FC<HeaderProps> = ({ userData, openEditModal, className }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const handleOpenEditModal = () => openEditModal(true);

  const color = "#474CAE";
  const isDesktop = width > 992;

  return (
    <div className={classNames(styles.container, className)}>
      {!userData && <Loader className={styles.loader} color={color} backgroundColor={color} />}
      {userData && (
        <>
          <div className={styles.profilePicture}>
            <img src={avatarIcon} alt="SeaEasy logo" className={styles.avatar} />
          </div>

          {isDesktop && (
            <Typography className={styles.changePicture}>{t("profile.header.changeProfilePicture")}</Typography>
          )}

          {isDesktop && <div className={styles.separator} />}

          <div className={styles.data}>
            <Typography>
              {userData.first_name} {userData.last_name}
            </Typography>
            <Typography>{userData.email}</Typography>
            {isDesktop && <Typography>{userData.phone_number}</Typography>}
          </div>

          {!isDesktop && (
            <IconButton className={styles.iconButton} onClick={handleOpenEditModal}>
              <EditIcon />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
};
