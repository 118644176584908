import { builder } from "../builder";
import { AssetsDataGetResponse, AssetsDataPost } from "../../pages/rental/management/assets/assets.types";

export const postAsset = builder.createCommand<AssetsDataGetResponse, AssetsDataPost>()({
  endpoint: "/app/organization/:organizationId/asset",
  method: "POST",
});

export const getAssets = builder.createCommand<AssetsDataGetResponse[]>()({
  endpoint: "/app/organization/:organizationId/asset",
  method: "GET",
});

export const deleteAsset = builder.createCommand()({
  endpoint: "/app/organization/:organizationId/asset",
  method: "DELETE",
});
