import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ChooseBoatBoxProps } from "./choose-boat-box.types";
import { OFFERS_PAGE } from "constants/routes.constants";
import { useRoutesPath } from "hooks";

import styles from "./choose-boat-box.module.scss";

export const ChooseBoatBox: React.FC<ChooseBoatBoxProps> = ({ icon, name, label }) => {
  const { localePathParams } = useRoutesPath();

  const languagePath = (): string => {
    return localePathParams({ route: OFFERS_PAGE, queryParams: `?watersport=${name}` });
  };

  return (
    <Link to={languagePath()} className={styles.container}>
      <img src={icon} alt="" className={styles.icon} />
      <Typography fontFamily="Chillax-semibold" color="#FDAF96" fontSize={24}>
        {label}
      </Typography>
    </Link>
  );
};
