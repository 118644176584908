import { TFunction } from "i18next";

export const sectionData = (t: TFunction) => [
  {
    title: t("about.section.slides.find"),
    content: t("about.section.slides.findContent"),
  },
  {
    title: t("about.section.slides.bookIt"),
    content: t("about.section.slides.bookItContent"),
  },
  {
    title: t("about.section.slides.enjoy"),
    content: t("about.section.slides.enjoyContent"),
  },
];
