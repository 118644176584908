import React, { Context, useContext, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { InfoDisplay } from "../info-display/info-display";
import { GuestsProps } from "./guests.types";
import { CalendarViewContextType } from "../../../wrapper/calendar-view.context";
import { ReservationsContext } from "../../../reservations-modal.context";
import { getViewContext } from "components/reservations/wrapper/wrapper.constants";

import styles from "./guests.module.scss";

export const Guests: React.FC<GuestsProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);

  const { formData, guestsData, setStep, guests } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>,
  );

  const [selectedGuestIndex, setSelectedGuestIndex] = useState<number | null>(0);

  const handleEditGuest = () => {
    setStep(4);
  };

  const handleSelectGuest = (index: number | null) => {
    setSelectedGuestIndex(index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <InfoDisplay label={t("reservationsModal.summary.guests.guests")} value={guests} />
        {/*<Button onClick={handleIsOpen}>open</Button>*/}
      </div>

      {isOpen && (
        <div className={styles.buttons}>
          <Button
            style={{
              background: selectedGuestIndex === null ? "#474CAE" : "#fff",
              color: selectedGuestIndex === null ? "#fff" : "#474CAE",
              transition: "0.3s",
            }}
            key={`${formData?.name}`}
            onClick={() => handleSelectGuest(null)}
          >
            Contact person
          </Button>
          {guestsData.map((guest, index) => {
            const isActive = index === selectedGuestIndex;

            return (
              <Button
                style={{
                  background: isActive ? "#474CAE" : "#fff",
                  color: isActive ? "#fff" : "#474CAE",
                  transition: "0.3s",
                }}
                key={`${guest.name},${guest.surname}`}
                onClick={() => handleSelectGuest(index)}
              >
                {guest.name || "Name"} {guest.surname || "Surname"}
              </Button>
            );
          })}
        </div>
      )}

      {isOpen && selectedGuestIndex !== null && (
        <div className={styles.open}>
          <div className={styles.guestDetail}>
            <InfoDisplay label="Name" value={guestsData[selectedGuestIndex].name || ""} fontSize={14} />
            <InfoDisplay label="Surname" value={guestsData[selectedGuestIndex].surname || ""} fontSize={14} />
            <InfoDisplay label="Phone number" value={guestsData[selectedGuestIndex].phoneNumber || ""} fontSize={14} />
            <InfoDisplay label="Email" value={guestsData[selectedGuestIndex].email || ""} fontSize={14} />
            <InfoDisplay label="Weight" value={guestsData[selectedGuestIndex].weight || ""} fontSize={14} />
            <InfoDisplay label="Height" value={guestsData[selectedGuestIndex].height || ""} fontSize={14} />
            <InfoDisplay label="Size" value={guestsData[selectedGuestIndex].size || ""} fontSize={14} />
            <Button onClick={handleEditGuest} className={styles.editButton}>
              edit
            </Button>
          </div>
        </div>
      )}

      {isOpen && selectedGuestIndex === null && (
        <div className={styles.open}>
          <div className={styles.guestDetail}>
            <InfoDisplay label="Name" value={formData?.name || ""} fontSize={14} />
            <InfoDisplay label="Telephone" value={formData?.phoneNumber || ""} fontSize={14} />
            <InfoDisplay label="Email" value={formData?.email || ""} fontSize={14} />
            <Button onClick={handleEditGuest} className={styles.editButton}>
              edit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
