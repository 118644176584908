import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { sections } from "./sections.constants";
import { useRoutesPath, useWindowSize } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-blue.svg";

import styles from "./sections.module.scss";

type SectionsProps = {
  color: string;
  isGreen: boolean;
};

export const Sections: React.FC<SectionsProps> = ({ color, isGreen }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { localePath } = useRoutesPath();

  const isMobile = width < 996;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {sections.map((section) => (
          <section key={section.name} className={classNames(styles.section, { [styles.sectionGreen]: isGreen })}>
            <Typography variant="body1" color={color} fontSize={20} fontWeight={700} className={styles.sectionName}>
              {t(section.name)}
            </Typography>
            {section.options.map((option) => {
              return (
                <Link
                  key={option.path.name}
                  to={localePath({ route: option.path, queryParams: option.section || null })}
                  className={styles.link}
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={300}
                    color={color}
                    className={styles.optionName}
                  >
                    {t(option.component)}
                  </Typography>
                </Link>
              );
            })}
          </section>
        ))}
        {isMobile && <Logo className={classNames(styles.logo, { [styles.logoGreen]: isGreen })} />}
      </div>
    </div>
  );
};
