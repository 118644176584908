export const paymentsCheck = {
  addedSuccessfully: "Stripe payments added successfully! Do you want to start managing your business?",

  addedIncorrectly: "Connection with Stripe payments was not registered. Do you want to add payments via Stripe now?",

  manage: "Manage",
  homePage: "Home page",
  addStripe: "Add stripe",
  loading: "Loading...",
};
