import { TFunction } from "i18next";

import { CREATE_OFFER_PAGE, LOGIN_PAGE, REGISTER_PAGE } from "constants/routes.constants";

export const colors = {
  green: "#4BBBB7",
  purple: "#474CAE",
  paperBackgroundColor: "#FFF8F2",
};

export const paperStyle = (color: string) => ({
  width: "250px",
  backgroundColor: colors.paperBackgroundColor,
  borderRadius: "10px",
  border: `1px solid ${color}`,
  marginTop: "53px",
  boxShadow: "0px 7px 24px -16px rgba(66, 68, 90, 1)",
});

export const menuItems = (t: TFunction) => ({
  notAuthenticated: [
    {
      path: LOGIN_PAGE.path,
      value: t("burgerMenu.login"),
    },
    {
      path: REGISTER_PAGE.path,
      value: t("burgerMenu.register"),
    },
  ],
  authenticated: [
    {
      path: CREATE_OFFER_PAGE.path,
      value: t("burgerMenu.createOffer"),
    },
  ],
});
