import { TFunction } from "i18next";

import { TableData } from "./booking-list.types";

export const ACCEPTED_COLOR = "#049798";
export const REJECTED_COLOR = "#F58B74";
export const ACCEPTED_STATUS = "PENDING_ACCEPTANCE";

export const createData = ({ offer, guests, totalProfit, services, bookedBy, status, id, dates }: TableData) => {
  return { offer, guests, totalProfit, services, bookedBy, status, id, dates };
};

export const statusesData = (t: TFunction) => [
  {
    status: "CREATED",
    label: t("ownerReservations.statuses.created"),
  },
  {
    status: "PENDING_ACTIVATION",
    label: t("ownerReservations.statuses.pendingActivation"),
  },
  {
    status: "PENDING_ACCEPTANCE",
    label: t("ownerReservations.statuses.pendingAcceptance"),
  },
  {
    status: "ACCEPTED",
    label: t("ownerReservations.statuses.accepted"),
  },
  {
    status: "REJECTED",
    label: t("ownerReservations.statuses.rejected"),
  },
  {
    status: "REPORTED",
    label: t("ownerReservations.statuses.reported"),
  },
  {
    status: "FAILED",
    label: t("ownerReservations.statuses.failed"),
  },
  {
    status: "CLOSED",
    label: t("ownerReservations.statuses.closed"),
  },
];
