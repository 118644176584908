import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import CEO from "assets/images/CEO.webp";
import CTO from "assets/images/CTO.webp";
import TechnicalTeam from "assets/images/technical-team.svg";
import MarketingTeam from "assets/images/marketing-team.svg";
import aboutOlsztyn from "assets/images/about-olsztyn.webp";

import styles from "./crew.module.scss";

export const Crew = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.crew}>
      <div className={styles.box}>
        <div className={styles.boxHeader}>
          <Typography className={styles.title} variant="h2">
            {t("about.crew.ourCrew")}
          </Typography>
          <Typography className={styles.content} variant="h3">
            {t("about.crew.content")}
          </Typography>
        </div>
        <div className={styles.images}>
          <div>
            <img src={CEO} alt="SeaEasy CEO" />
            <Typography variant="h3">CEO</Typography>
            <Typography variant="h4">
              Szymon <br /> Wojsz
            </Typography>
          </div>
          <div>
            <img src={CTO} alt="SeaEasy CTO" />
            <Typography variant="h3">CTO</Typography>
            <Typography variant="h4">
              Tomasz <br /> Kiełczewski
            </Typography>
          </div>
        </div>
        <div className={classNames(styles.images, styles.crewImages)}>
          <div>
            <img src={TechnicalTeam} alt="SeaEasy CEO" />
            <Typography variant="h4">
              {t("about.crew.technicalTeam")} <br /> {t("about.crew.team")}
            </Typography>
          </div>
          <div>
            <img src={MarketingTeam} alt="SeaEasy CTO" />
            <Typography variant="h4">
              {t("about.crew.salesTeam")} <br /> {t("about.crew.marketing")}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.boxHeader}>
          <Typography className={styles.title} variant="h2">
            Olsztyn
          </Typography>
          <Typography className={styles.content} variant="h3">
            {t("about.crew.content1")}
          </Typography>
        </div>
        <img src={aboutOlsztyn} className={styles.image} alt="Olsztyn" />
      </div>
    </div>
  );
};
