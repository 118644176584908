import React, { useState } from "react";
import { useFormikContext } from "formik";
import { LatLng, LeafletMouseEvent } from "leaflet";
import { useSelector } from "react-redux";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { RentalData } from "server/organization/organization.types";
import { RootState } from "store";
import { FormSelect, FormTextField, Map } from "components";
import { DEFAULT_POSITION, inputStyles, labelProps, locationFields, select } from "./location.constants";
import { COUNTRIES_EN, COUNTRIES_PL } from "constants/countries.constants";
import { createRentalProfileSteps } from "../create-rental.constants";

import stylesMain from "../create-rental.module.scss";
import styles from "./location.module.scss";

export const Location: React.FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<RentalData>();
  const { locale } = useSelector((state: RootState) => state.app);

  const [isMapOpen, setIsMapOpen] = useState(true);

  const onMapClick = (event: LeafletMouseEvent) => {
    const x = event.latlng.lat.toFixed(6);
    const y = event.latlng.lng.toFixed(6);
    setFieldValue("location.latitude", x);
    setFieldValue("location.longitude", y);
    setIsMapOpen(true);
  };

  const marker =
    values.location?.latitude && values.location?.longitude
      ? [new LatLng(+values.location.latitude, +values.location.longitude)]
      : [];

  const toggleMap = (value: boolean) => () => {
    setIsMapOpen(value);
  };

  const showForm = values.location?.latitude && values.location?.longitude;
  const showMap = showForm && isMapOpen;

  return (
    <div className={classNames(stylesMain.itemContainer, styles.container)}>
      <Typography ml="8px" className={classNames(stylesMain.header, stylesMain.desktop)}>
        {t("rental.steps.step")} 2: {createRentalProfileSteps(t)[2].name}
      </Typography>
      <div className={styles.item}>
        {!showMap && (
          <div className={styles.mapContainer}>
            <Typography ml="8px" className={styles.text}>
              {t("rental.location.addYourLocation")}
            </Typography>
            <Map markers={marker} handleClick={onMapClick} center={DEFAULT_POSITION} className={styles.map} />
          </div>
        )}

        {showMap && (
          <div className={styles.form}>
            <div className={styles.formHeader}>
              <IconButton className={styles.icon} onClick={toggleMap(false)}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <Typography>{t("rental.location.confirmYourAddress")}</Typography>
            </div>
            <div className={styles.formContent}>
              {locationFields(t).map((field) => (
                <FormTextField
                  key={field.name}
                  {...field}
                  sx={inputStyles}
                  InputLabelProps={labelProps}
                  InputProps={{ disableUnderline: true }}
                  variant="standard"
                />
              ))}
              <FormSelect
                name="location.country"
                label={t("rental.location.country")}
                options={locale === "pl-PL" ? COUNTRIES_PL : COUNTRIES_EN}
                variant="standard"
                sx={select.styles}
                InputLabelProps={labelProps}
                InputProps={{ disableUnderline: true }}
                SelectProps={select.props}
              />
            </div>
          </div>
        )}
      </div>
      <Typography className={classNames(stylesMain.header, stylesMain.desktop, styles.emptyTag)}>a</Typography>
    </div>
  );
};
