import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { teamHoursData } from "./team-hours.constants";
import { ItemBox } from "./item-box/item-box";
import { useWindowSize } from "../../../../../hooks";
import { ItemBoxDesktop } from "./item-box-desktop/item-box-desktop";
import { ManagementContext } from "../../management.context";

import styles from "./team-hours.module.scss";

export const TeamHours = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { openTeamHours } = useContext(ManagementContext);

  const handleClick = () => openTeamHours(1);

  const isMobile = width < 992;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.teamHours}>{t("insights.teamHours.teamHours")}</Typography>
        <Typography className={styles.hours}>3272 {t("insights.teamHours.hours")}</Typography>
      </div>

      {isMobile && (
        <div className={styles.content}>
          {teamHoursData.map((item) => (
            <ItemBox {...item} />
          ))}
        </div>
      )}
      {!isMobile && (
        <div className={styles.content}>
          {teamHoursData.map((item) => (
            <ItemBoxDesktop {...item} />
          ))}
        </div>
      )}
      <Button className={styles.seeAllButton} onClick={handleClick}>
        {t("insights.teamHours.seeAll")}
      </Button>
    </div>
  );
};
