import React from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";

import { partnersData, responsive } from "./partners.constants";
import { PartnersProps } from "./partners.types";

import stylesMain from "../home.module.scss";
import styles from "./partners.module.scss";

export const Partners: React.FC<PartnersProps> = ({ refElement }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(stylesMain.itemContainer, styles.container)} ref={refElement}>
      <Typography variant="h4" className={classNames(stylesMain.itemSubtitle, styles.subTitle)}>
        {t("home.partners")}
      </Typography>
      <div className={styles.content}>
        <Carousel
          responsive={responsive}
          arrows={false}
          infinite
          swipeable
          sliderClass={styles.carousel}
          itemClass={styles.item}
          autoPlay
        >
          {partnersData.map((partnerImage) => (
            <img src={partnerImage} alt="partner" key={partnerImage} className={styles.image} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
