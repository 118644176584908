import React from "react";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { CartProps } from "./cart.types";
import { pricePerSelectOptionsNumber } from "../../create/create-offer.constants";

import styles from "./cart.module.scss";

export const Cart: React.FC<CartProps> = ({ offer, setIsCartOpen }) => {
  const { t } = useTranslation();
  const toggleModal = () => {
    setIsCartOpen((prevState) => !prevState);
  };

  const pricePerValue = pricePerSelectOptionsNumber(t).find((rateType) => rateType.value === offer.rate_type)?.label;

  // useDidUpdate(
  //   () => {
  //     let totalPriceCounter = 0;
  //     // let servicesPrice = 0;
  //
  //     const millisecondDiff = rateTypeMillisecondDiff[offer.rate_type as RateTypeNoPerson];
  //
  //     const rateMultiplier = Math.ceil(millisecondDiff / DIVISOR_TO_MINUTES / minutesByRateType[offer.rate_type]);
  //
  //     totalPriceCounter += rateMultiplier * offer.price;
  //     if (offer.rate_per_person) totalPriceCounter *= 1;
  //
  //     services.forEach((service) => {
  //       let servicePrice = 0;
  //
  //       if (service.fixed_price) servicePrice = service.price;
  //       else {
  //         servicePrice = rateMultiplier * service.price;
  //         if (offer.rate_per_person) servicePrice *= 1;
  //       }
  //       // servicesPrice += servicePrice;
  //       totalPriceCounter += servicePrice;
  //     });
  //
  //     setPrice(totalPriceCounter);
  //   },
  //   [services],
  //   true,
  // );

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.copy}>
          <Typography className={styles.priceSummary}>{t("offerPage.cart.howMuchWillIPay")}</Typography>
          <Typography className={styles.priceSummary}>
            {t("offerPage.cart.from")}{" "}
            <span>
              {offer.price} {offer.currency}
            </span>{" "}
            {t("offerPage.cart.for")} {pricePerValue?.toLowerCase()}
          </Typography>
        </div>

        {/*<div className={styles.summaryWrapper}>*/}
        {/*  <Typography className={styles.price}>{t("offerPage.cart.totalFrom")}</Typography>*/}
        {/*  <div>*/}
        {/*    <Typography className={styles.price}>*/}
        {/*      {price} {offer.currency}*/}
        {/*    </Typography>*/}
        {/*    <Typography className={styles.priceValue}>{pricePerValue}</Typography>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <Button onClick={toggleModal} className={classNames(styles.button, styles.bookButton)}>
          {t("offerPage.cart.book")}
        </Button>
        <Typography sx={{ textAlign: "center", fontFamily: "Chillax-light" }}>
          {t("offerPage.cart.youWontBeCharged")}
        </Typography>
      </div>
    </div>
  );
};
