import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AdditionalServicesProps } from "./additional-services.types";

import styles from "./additional-services.module.scss";
import stylesMain from "../details.module.scss";

export const AdditionalServices: React.FC<AdditionalServicesProps> = ({ services }) => {
  const { t } = useTranslation();

  const unique = new Set();

  const uniqueServices = services.filter((service) => {
    const isDuplicate = unique.has(service.title);

    unique.add(service.title);

    return !isDuplicate;
  });

  const handleTitle = (title: string) => {
    switch (title) {
      case "captain":
        return t("offerPage.details.additionalServices.captain");

      case "instructor":
        return t("offerPage.details.additionalServices.instructor");

      default:
        return title;
    }
  };

  return (
    <div className={styles.container}>
      <Typography className={stylesMain.title}>
        {t("offerPage.details.rentalDetails.areThereAnyAdditionalServices")}
      </Typography>
      <div className={stylesMain.detailBox}>
        {uniqueServices.map((service) => {
          return (
            <div key={service.title} className={stylesMain.detailContent}>
              <Typography>{service.price}</Typography>
              <Typography sx={{ textTransform: "capitalize" }}>{handleTitle(service.title)}</Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};
