import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";

import {
  contactSchema,
  formData,
  initialValues,
  inputProps,
  inputStyles,
  textAreaStyles,
} from "./form-component.constants";
import { FormTextField } from "components";
import { useQueryParams, useRoutesPath, useWindowSize } from "hooks";
import { FormComponentProps, FormTypes } from "./form-component.types";
import { FormData } from "../your-business.types";
import { COOKIES_POLICY_PAGE, PRIVACY_POLICY_PAGE, TERMS_OF_USE_PAGE } from "constants/routes.constants";
import { contactForm } from "server";
import { ContactFormData } from "server/contact/contact.types";

import { ReactComponent as CloseIcon } from "assets/icons/close-form-icon.svg";

import styles from "./form-component.module.scss";

export const FormComponent: React.FC<FormComponentProps> = ({ openForm, user }) => {
  const { width } = useWindowSize();
  const {
    deleteQueryParams,
    query: { form },
  } = useQueryParams();
  const { t } = useTranslation();
  const { localePath } = useRoutesPath();
  const { enqueueSnackbar } = useSnackbar();

  const formPost = useSubmit(contactForm);
  const { submit, onSubmitSuccess, onSubmitError, submitting } = formPost;
  onSubmitSuccess(() => {
    enqueueSnackbar(t("contact.sentSuccessfully"), { variant: "success" });
  });
  onSubmitError(() => {
    enqueueSnackbar(t("contact.sendError"), { variant: "error" });
  });

  const handleSubmitForm = async (values: FormTypes, formikHelpers: FormikHelpers<FormTypes>) => {
    const data: ContactFormData = {
      email: values.email,
      text_data: values.message,
      name: user?.first_name || "",
    };

    await submit({ data }).then((res) => {
      if (res[2] === 200) formikHelpers.resetForm();
    });
  };

  const closeForm = () => {
    deleteQueryParams();
    openForm(false);
  };

  const isDesktop = width >= 992;
  const formDataValues = formData(t);
  const value: FormData = (form as FormData) || "recruit-employee";

  return (
    <Formik initialValues={initialValues} validationSchema={contactSchema(t)} onSubmit={handleSubmitForm}>
      <Form className={styles.container}>
        {isDesktop && (
          <Typography variant="h4" className={styles.title}>
            {formDataValues[value].title}
          </Typography>
        )}
        <div className={styles.header}>
          <div className={styles.formDescription}>
            {!isDesktop && <Typography variant="h4">{formDataValues[value].title}</Typography>}
            <div className={styles.iconBox}>
              <img src={formDataValues[value].icon} alt="form icon" />
            </div>
            <Typography variant="h5">{formDataValues[value].description}</Typography>
          </div>
          {!isDesktop && (
            <IconButton onClick={closeForm} className={styles.closeForm}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        {isDesktop && (
          <IconButton onClick={closeForm} className={styles.closeForm}>
            <CloseIcon />
          </IconButton>
        )}
        <div className={styles.formWrapper}>
          <div className={styles.inputWrapper}>
            <Typography className={styles.label}>{t("management.yourBusiness.form.topic")}</Typography>
            <FormTextField
              name="topic"
              variant="standard"
              rows={1}
              sx={inputStyles}
              InputProps={inputProps}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Typography className={styles.label}>{t("management.yourBusiness.form.email")}</Typography>
            <FormTextField
              name="email"
              variant="standard"
              rows={1}
              sx={inputStyles}
              InputProps={inputProps}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: 50 }}
              className={styles.label}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Typography className={styles.label}>{t("management.yourBusiness.form.message")}</Typography>
            <FormTextField
              name="message"
              variant="standard"
              multiline
              rows={6}
              sx={textAreaStyles}
              InputProps={inputProps}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: 5000 }}
              className={styles.label}
            />
          </div>
          <div className={styles.submit}>
            <div className={styles.submitCopy}>
              <Typography className={styles.copy}>{t("management.yourBusiness.form.bySendingThisMessage")}</Typography>
              <Link className={styles.link} to={localePath({ route: PRIVACY_POLICY_PAGE })}>
                {t("management.yourBusiness.form.privacyPolicy")}
              </Link>
              <Link className={styles.link} to={localePath({ route: COOKIES_POLICY_PAGE })}>
                {t("management.yourBusiness.form.cookies")}
              </Link>
              <Typography className={styles.copy}>{t("management.yourBusiness.form.and")}</Typography>
              <Link className={styles.link} to={localePath({ route: TERMS_OF_USE_PAGE })}>
                {t("management.yourBusiness.form.termsOfUse")}
              </Link>
            </div>

            <Button type="submit" disabled={submitting} className={styles.submitButton}>
              {t("management.yourBusiness.form.send")}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
