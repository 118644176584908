import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";

import { YourBusinessItemsProps } from "../your-business.types";
import { Loader } from "components";
import { substringText } from "utils";

import styles from "./rental-details.module.scss";

export const RentalDetails: React.FC<YourBusinessItemsProps> = ({ className, user, organization }) => {
  const avatar = organization?.organization_images.filter((img) => img.is_avatar)[0];

  return (
    <div className={classNames(className, styles.container)}>
      {!user && (
        <Loader className={styles.loader} isWhiteLoader height="100%" backgroundColor="#595EB6" color="#595EB6" />
      )}

      {user && (
        <>
          <div className={styles.wrapper}>
            <img src={avatar?.image_url} className={styles.avatar} alt="organization avatar" />
            <Typography className={styles.organizationName}>{organization?.name}</Typography>
          </div>

          <div className={styles.separator} />

          <Typography className={styles.organizationDescription}>
            {substringText({
              string: organization?.description as string,
              maxLength: 300,
              cutAtChart: 300,
            })}
          </Typography>
          {/* <Typography className={styles.organizationDescription}>{organization?.description}</Typography> */}

          {/* <div className={classNames(styles.wrapper, styles.contact)}> */}
          {/*  <Typography className={styles.contactDetails}> */}
          {/*    {t("management.yourBusiness.rentalDetails.contactDetails")} */}
          {/*  </Typography> */}

          {/*  <div className={styles.contactData}> */}
          {/*    <Typography className={styles.contactText}> */}
          {/*      {user.first_name} {user.last_name} */}
          {/*    </Typography> */}
          {/*    <Typography className={styles.contactText}>{user.email}</Typography> */}
          {/*    <Typography className={styles.contactText}>{user.phone_number}</Typography> */}
          {/*  </div> */}
          {/* </div> */}
        </>
      )}
    </div>
  );
};
