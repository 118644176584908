import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Typography } from "@mui/material";

import { LogoProps } from "./logo.types";
import { useRoutesPath } from "hooks";
import { HOME_PAGE } from "constants/routes.constants";

import { ReactComponent as LogoSvg } from "assets/icons/logo-icon.svg";

import styles from "./logo.module.scss";

export const Logo: React.FC<LogoProps> = ({ isGreen, className, color, isMobile }) => {
  const { localePath } = useRoutesPath();

  return (
    <Link to={localePath({ route: HOME_PAGE })} className={classNames(styles.container, className)}>
      <div className={styles.row}>
        <LogoSvg className={classNames(styles.logo, !isGreen && styles.purpleIcon)} />
        {!isMobile && (
          <Typography fontFamily="Chillax-Medium" variant="h6" color={color}>
            Sea
            <span style={{ color }}>Easy</span>
          </Typography>
        )}
      </div>
    </Link>
  );
};
