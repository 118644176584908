import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

import { ItemBoxProps } from "./item-box.types";
import { ManagementContext } from "../../../management.context";

import styles from "./item-box.module.scss";

export const ItemBox: React.FC<ItemBoxProps> = ({ name, city, profit, hours, picture, id }) => {
  const { t } = useTranslation();
  const { openTeamHours } = useContext(ManagementContext);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={picture} className={styles.image} alt={name} />
        <div className={styles.wrapper}>
          <Typography className={styles.bold}>{name}</Typography>
          <Typography className={styles.light}>{city}</Typography>
        </div>
        <div className={styles.wrapper}>
          <Typography className={styles.bold}>{hours}</Typography>
          <Typography className={styles.light}>{t("insights.teamHours.hours")}</Typography>
        </div>
        <div className={styles.wrapper}>
          <Typography className={styles.boldOrange}>{profit}</Typography>
          <Typography className={styles.light}>{t("insights.teamHours.profit")}</Typography>
        </div>
        <IconButton onClick={() => openTeamHours(id)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
    </div>
  );
};
