export const profile = {
  navigation: {
    personalInfo: "Personal info",
    payments: "Payments & payouts",
  },

  header: {
    changeProfilePicture: "Change your profile picture",
  },

  personalInfo: {
    legalName: "Legal name",
    edit: "Edit",
    email: "Email address",
    phoneNumber: "Phone number",
  },

  payments: {
    inProgress: "In progress",
    completed: "Completed",
  },

  info: {
    personalInfo: "What info is shared with others?",
    personalInfoContent:
      "SeaEasy only releases contact information for Owners and Guests after a reservation is confirmed",

    paymentsInfo1: "Make all payments through SeaEasy",
    paymentsInfoCopy1:
      "Always pay and communicate through SeaEasy to ensure you’re protected under our Terms of Use, cancellation and other safeguards.",

    paymentsInfo2: "Need help?",
    paymentsInfoCopy2: "Check our Help Center where you can find a list of frequently asked questions.",
  },

  modal: {
    firstName: "First name",
    lastName: "Last name",
    email: "Email address",
    phoneNumber: "Phone number",
    save: "Save",
  },

  schema: {
    numberRequired: "Number is required",
    emailRequired: "Email is required",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
  },
};
