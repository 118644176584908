import React, { Context, forwardRef, useContext } from "react";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { Input } from "antd";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useTranslation } from "react-i18next";

import { ContactPersonProps, FormValues } from "./contact-person.types";
import { validationSchema } from "./contact-person.constants";
import { SlotsViewContextType } from "../../wrapper/slots-view.context";
import { ReservationsContext } from "../../reservations-modal.context";
import { getViewContext } from "../../wrapper/wrapper.constants";

import styles from "./contact-person.module.scss";

const UpdateCalendarContext = ({ formikValues }: { formikValues: FormValues }) => {
  const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);

  const { setFormData } = useContext(getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>);

  useDidUpdate(
    () => {
      setFormData(formikValues);
    },
    [formikValues, setFormData],
    true,
  );

  return null;
};

export const ContactPerson = forwardRef<FormikProps<FormValues>, ContactPersonProps>(
  ({ onFormSubmissionSuccess }, ref) => {
    const { isCalendarView, isOwnReservation } = useContext(ReservationsContext);
    const { t } = useTranslation();

    const { formData } = useContext(getViewContext(isOwnReservation, isCalendarView) as Context<SlotsViewContextType>);

    const initialValues: FormValues = {
      name: formData?.name || "",
      surname: formData?.surname || "",
      email: formData?.email || "",
      phoneNumber: formData?.phoneNumber || "",
      additionalRequests: formData?.additionalRequests || "",
    };

    return (
      <Formik<FormValues>
        innerRef={ref}
        initialValues={initialValues}
        validationSchema={() => validationSchema(t)}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          onFormSubmissionSuccess(values);
        }}
      >
        {({ values }) => (
          <Form className={styles.container}>
            <>
              <UpdateCalendarContext formikValues={values} />
              <p className={styles.headerTitle}>{t("reservationsModal.contactPerson.contactPerson")}</p>
              <div className={styles.inputWrapper} style={{ gridColumn: "1/2" }}>
                <p className={styles.label}>{t("reservationsModal.contactPerson.name")}</p>
                <Field
                  className={styles.field}
                  name="name"
                  id="name"
                  as={Input}
                  placeholder={t("reservationsModal.contactPerson.name")}
                  prefix={<UserOutlined />}
                />
                <ErrorMessage className={styles.errorMessage} name="name" component="div" />
              </div>

              <div className={styles.inputWrapper} style={{ gridColumn: "2/3" }}>
                <p className={styles.label}>{t("reservationsModal.contactPerson.surname")}</p>
                <Field
                  className={styles.field}
                  name="surname"
                  id="surname"
                  as={Input}
                  placeholder={t("reservationsModal.contactPerson.surname")}
                  prefix={<UserOutlined />}
                />
                <ErrorMessage className={styles.errorMessage} name="surname" component="div" />
              </div>
              <div className={styles.inputWrapper}>
                <p className={styles.label}>E-mail</p>
                <Field
                  className={styles.field}
                  id="email"
                  name="email"
                  as={Input}
                  placeholder="E-mail"
                  prefix={<MailOutlined />}
                />{" "}
                <ErrorMessage className={styles.errorMessage} name="email" component="div" />
              </div>
              <div className={styles.inputWrapper}>
                <p className={styles.label}>{t("reservationsModal.contactPerson.telephone")}</p>
                <Field
                  id="phoneNumber"
                  className={styles.field}
                  name="phoneNumber"
                  as={Input}
                  placeholder={t("reservationsModal.contactPerson.telephone")}
                  prefix={<PhoneOutlined />}
                />{" "}
                <ErrorMessage className={styles.errorMessage} name="phoneNumber" component="div" />
              </div>

              {/*<div className={styles.inputWrapper} style={{ gridColumn: "1/3" }}>*/}
              {/*  <p className={styles.label}>Additional requests</p>*/}
              {/*  <Field*/}
              {/*    className={styles.field}*/}
              {/*    id="additionalRequests"*/}
              {/*    name="additionalRequests"*/}
              {/*    as={Input.TextArea}*/}
              {/*    placeholder="Additional Requests"*/}
              {/*  />*/}
              {/*</div>*/}
            </>
          </Form>
        )}
      </Formik>
    );
  },
);
