import React, { useContext, useRef } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { Navigation } from "./navigation/navigation";
import { useQueryParams, useRoutesPath } from "hooks";
import { RootState } from "store";
import { ManagementContext } from "./management.context";
import { Loader } from "components";
import { CREATE_RENTAL_PAGE } from "constants/routes.constants";
import { FullScreenSection } from "./full-screen-section/full-screen-section";

import styles from "./management.module.scss";

export const Management = () => {
  const navigate = useNavigate();
  const sectionWrapperRef = useRef<null | HTMLDivElement>(null);

  const {
    query: { form },
  } = useQueryParams();
  const { organizationData, dataFetching } = useSelector((state: RootState) => state.organization);
  const { localePath } = useRoutesPath();
  const { isNavigationOpen, activeSection, isFullScreenSectionOpen } = useContext(ManagementContext);

  const showLoader = dataFetching;
  const showData = !dataFetching && organizationData;

  useDidUpdate(
    () => {
      if (!organizationData && !dataFetching) navigate(localePath({ route: CREATE_RENTAL_PAGE }));
    },
    [dataFetching],
    true,
  );

  useDidUpdate(() => {
    sectionWrapperRef.current?.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [form]);

  return (
    <>
      {showLoader && <Loader height="87vh" />}
      {showData && (
        <div className={classNames(styles.container, isNavigationOpen && styles.containerNavOpen)}>
          <Navigation />
          <div
            ref={sectionWrapperRef}
            className={classNames(styles.sectionWrapper, isNavigationOpen && styles.sectionWrapperNavOpen)}
          >
            {activeSection.component}
          </div>
        </div>
      )}

      {isFullScreenSectionOpen && <FullScreenSection />}
    </>
  );
};
