import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { ProfileItemProps } from "../profile.types";
import { FormTextField } from "components";
import { inputProps, inputStyles } from "./profile-name.constants";
import { RentalData } from "server/organization/organization.types";

import styles from "./profile-name.module.scss";
import mainStyles from "../profile.module.scss";

export const ProfileName: React.FC<ProfileItemProps> = ({ className }) => {
  const { t } = useTranslation();
  const {
    values: { name },
  } = useFormikContext<RentalData>();

  return (
    <div className={classNames(className, styles.container)}>
      <Typography className={mainStyles.text}>{t("rental.profile.createYourName")}</Typography>
      <FormTextField
        name="name"
        className={styles.textField}
        variant="standard"
        multiline
        rows={4}
        sx={inputStyles}
        InputProps={inputProps}
        placeholder={t("rental.profile.placeholders.name")}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength: 100 }}
      />
      <Typography className={mainStyles.counter}>{name.length}/100</Typography>
    </div>
  );
};
