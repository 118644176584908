import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { Banner } from "./banner/banner";
import { Destinations } from "./destinations/destinations";
import { Offers } from "./offers/offers";
import { ChooseBoat } from "./choose-boat/choose-boat";
import { Section } from "./section/section";
import { Footer } from "components";
import { HomeHelmet } from "./home.helmet";
import { Partners } from "./partners/partners";
import { Summary } from "./summary/summary";
import { Articles } from "./articles/articles";
import { Gallery } from "./gallery/gallery";
import { SeaTalk } from "./sea-talk/sea-talk";

import { ReactComponent as FlowersIcon } from "assets/icons/flowers-icon.svg";
import { ReactComponent as PineappleIcon } from "assets/icons/pineapple-icon.svg";

import styles from "./home.module.scss";

gsap.registerPlugin(ScrollTrigger);

// TODO strapi models refactor

export const HomePage = () => {
  const destinationsRef = useRef<null | HTMLDivElement>(null);
  const offersRef = useRef<null | HTMLDivElement>(null);
  const partnersRef = useRef<null | HTMLDivElement>(null);
  const propositionRef = useRef<null | HTMLDivElement>(null);
  const watersportsRef = useRef<null | HTMLDivElement>(null);
  const articlesRef = useRef<null | HTMLDivElement>(null);
  const seaTalkRef = useRef<null | HTMLDivElement>(null);

  useDidUpdate(
    () => {
      // if (seaTalkRef.current) {
      //   gsap.fromTo(
      //     seaTalkRef.current.children,
      //     { x: "-=150%", autoAlpha: 0 },
      //     {
      //       x: 0,
      //       autoAlpha: 1,
      //       duration: 1,
      //       ease: "power3",
      //       stagger: 0.4,
      //       scrollTrigger: {
      //         trigger: seaTalkRef.current,
      //         start: "top 70%",
      //       },
      //     },
      //   );
      // }

      if (partnersRef.current) {
        gsap.fromTo(
          partnersRef.current.children,
          { x: "+=150%", autoAlpha: 0 },
          {
            x: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power3",
            stagger: 0.4,
            scrollTrigger: {
              trigger: partnersRef.current,
              start: "top 70%",
            },
          },
        );
      }

      if (propositionRef.current) {
        gsap.fromTo(
          propositionRef.current.children,
          { x: "-=150%", autoAlpha: 0 },
          {
            x: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power3",
            stagger: 0.4,
            scrollTrigger: {
              trigger: propositionRef.current,
              start: "top 70%",
            },
          },
        );
      }

      if (destinationsRef.current) {
        gsap.fromTo(
          destinationsRef.current.children,
          { x: "-=150%", autoAlpha: 0 },
          {
            x: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power3",
            stagger: 0.4,
            scrollTrigger: {
              trigger: destinationsRef.current,
              start: "top 70%",
            },
          },
        );
      }

      if (offersRef.current) {
        gsap.fromTo(
          offersRef.current.children,
          { x: "+=150%", autoAlpha: 0 },
          {
            x: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power3",
            stagger: 0.4,
            scrollTrigger: {
              trigger: offersRef.current,
              start: "top 70%",
            },
          },
        );
      }

      if (watersportsRef.current) {
        gsap.fromTo(
          watersportsRef.current.children,
          { x: "-=150%", autoAlpha: 0 },
          {
            x: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power3",
            stagger: 0.4,
            scrollTrigger: {
              trigger: watersportsRef.current,
              start: "top 70%",
            },
          },
        );
      }
    },
    [],
    true,
  );

  return (
    <div className={styles.container}>
      <HomeHelmet />
      <Banner />
      <div className={styles.wrapper}>
        <div className={styles.companyWrapper}>
          <SeaTalk refElement={seaTalkRef} />
          <Partners refElement={partnersRef} />
          <Summary refElement={propositionRef} />
        </div>
        <PineappleIcon className={styles.icon} />
        <Destinations refElement={destinationsRef} />
        <FlowersIcon className={styles.flowersIcon} />
        <Offers refElement={offersRef} />
      </div>

      <Gallery />

      <div />
      <div className={styles.wrapper}>
        <ChooseBoat refElement={watersportsRef} />
      </div>
      <Section />
      <div className={styles.wrapper}>
        <Articles refElement={articlesRef} />
      </div>
      <Footer />
    </div>
  );
};
