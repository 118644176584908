import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { OfferBoxProps } from "./offer-box.types";
import { OFFER_PAGE, OFFERS_PAGE } from "constants/routes.constants";
import { substringText } from "utils";
import { MetaData } from "components";
import { useQueryParams, useRoutesPath } from "hooks";

import styles from "./offers-box.module.scss";

export const OffersBox: React.FC<OfferBoxProps> = ({ offer }) => {
  const { localePathParams, locale } = useRoutesPath();
  const { stringify } = useQueryParams();
  const {
    attributes: {
      SeaEasyOffer: { offerId: seaEasyOfferId, watersport, cityPl, cityEn },
      bmParams: { view, baseId, dateTo, dateFrom, yachtId },
      frontApp: { title, addressPl, addressEn, year, dates, price, pricePerPl, pricePerEn, discount },
      image: strapiImage,
      meta,
    },
  } = offer;

  const offerPath = (): string => {
    const locationLocale = locale === "pl-PL" ? cityPl?.toLowerCase() : cityEn?.toLowerCase();

    if (seaEasyOfferId) {
      return localePathParams({
        route: OFFER_PAGE,
        params: {
          offerId: seaEasyOfferId,
          watersport: watersport?.toLowerCase() || "",
          location: locationLocale || "",
        },
      });
    }
    const queryParams = stringify({ watersport: "YACHT", view, baseId, dateTo, dateFrom, yachtId });
    return localePathParams({ route: OFFERS_PAGE, queryParams });
  };

  const date = `${dates} ${year}`;
  const image = strapiImage?.data.attributes.url || "";

  return (
    <Link to={offerPath()} className={styles.container}>
      {meta[0] && <MetaData meta={meta[0]} />}

      <div className={styles.imageWrapper}>
        <img src={image} className={styles.image} alt="boat" />
      </div>

      <div className={styles.content}>
        <div className={styles.row}>
          <Typography className={styles.title} variant="h2">
            {substringText({
              string: title,
              maxLength: 16,
              cutAtChart: 16,
            })}
          </Typography>
          <Typography className={styles.discount}>{discount ? `-${discount}%` : ""}</Typography>
        </div>
        <div className={styles.row}>
          <Typography className={styles.address} variant="h4">
            {locale === "pl-PL"
              ? substringText({
                  string: addressPl,
                  cutAtChart: 18,
                  maxLength: 18,
                })
              : substringText({ string: addressEn, cutAtChart: 16, maxLength: 16 })}
          </Typography>
          <Typography className={styles.price}>{price}</Typography>
        </div>
        <div className={styles.row}>
          <Typography className={styles.dates}>
            {substringText({
              string: date,
              cutAtChart: 18,
              maxLength: 18,
            })}
          </Typography>
          <Typography className={styles.pricePer}>{locale === "pl-PL" ? pricePerPl : pricePerEn}</Typography>
        </div>
      </div>
    </Link>
  );
};
