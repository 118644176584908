import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RentalDetailsProps } from "./rental-details.types";
import { watersportsData } from "../../../../../utils/watersports.utils";

import { ReactComponent as GuestsIcon } from "assets/icons/person-wide.svg";
import CaptainIcon from "assets/icons/captain-wide.svg";
import InstructorIcon from "assets/icons/instructor-wide.svg";

import styles from "./rental-detail.module.scss";
import stylesMain from "../details.module.scss";

export const RentalDetails: React.FC<RentalDetailsProps> = ({ guests, watersport, services }) => {
  const { t } = useTranslation();

  const icon = watersportsData(t).find((name) => name.id === watersport.id)?.iconWide;

  const captainServices = services.filter((service) => service.title === "captain");
  const instructorServices = services.filter((service) => service.title === "instructor");

  const showCaptain = captainServices.length > 0;
  const showInstructor = instructorServices.length > 0;
  const watersportTranslated =
    watersportsData(t)?.find((name) => name.value.toLowerCase() === watersport.name.toLowerCase())?.name || "sport";

  return (
    <div className={styles.container}>
      <Typography className={stylesMain.title}>{t("offerPage.details.rentalDetails.whatDoINeedToKnow")}</Typography>
      <div className={stylesMain.detailBox}>
        <div className={stylesMain.detailContent}>
          <img src={icon} alt="watersport type" className={styles.icon} />
          <Typography sx={{ textTransform: "capitalize" }}>{watersportTranslated}</Typography>
        </div>

        {showCaptain && (
          <div className={stylesMain.detailContent}>
            <img src={CaptainIcon} alt="captain icon" className={styles.icon} />
            <Typography>{t("offerPage.details.rentalDetails.captainAvailable")}</Typography>
          </div>
        )}

        {showInstructor && (
          <div className={stylesMain.detailContent}>
            <img src={InstructorIcon} alt="instructor icon" className={styles.icon} />
            <Typography>{t("offerPage.details.rentalDetails.instructorAvailable")}</Typography>
          </div>
        )}

        {guests !== 0 && (
          <div className={stylesMain.detailContent}>
            <div className={styles.guests}>
              <Typography className={styles.guestsNumber}>{guests}</Typography>
              <GuestsIcon />
            </div>
            <Typography className={styles.guestsIcon}>{t("offerPage.details.rentalDetails.guests")}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
