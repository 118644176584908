import { builder } from "../builder";
import { ReservationModel, ReservationStatusData, ReservationStatusResponse } from "./reservation.types";

export const getOwnerReservations = builder.createCommand<ReservationModel[]>()({
  endpoint: "/app/reservation/organization/:organizationID",
  method: "GET",
  options: { timeout: 0 },
  retry: 5,
});

export const putReservationStatus = builder.createCommand<ReservationStatusResponse, ReservationStatusData>()({
  endpoint: "/app/reservation/:id",
  method: "PATCH",
});
