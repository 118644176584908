import React, { useContext, useState, Context } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { InfoDisplay } from "../info-display/info-display";
import { ServicesProps } from "./services.types";
import { ReservationsContext } from "components/reservations/reservations-modal.context";
import { CalendarViewContextType } from "components/reservations/wrapper/calendar-view.context";
import { getViewContext } from "components/reservations/wrapper/wrapper.constants";

import styles from "./services.module.scss";

export const Services: React.FC<ServicesProps> = ({ isOpen, handleIsOpen }) => {
  const { t } = useTranslation();
  const { isCalendarView, isOwnReservation, offer } = useContext(ReservationsContext);

  const { selectedServices } = useContext(
    getViewContext(isOwnReservation, isCalendarView) as Context<CalendarViewContextType>,
  );

  const [selectedServiceIndex, setSelectedServiceIndex] = useState<number>(0);

  const handleSelectService = (index: number) => {
    setSelectedServiceIndex(index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <InfoDisplay label={t("reservationsModal.summary.services.services")} value={selectedServices.length} />
        <Button className={styles.button} onClick={handleIsOpen}>
          {t("reservationsModal.summary.services.moreDetails")}
        </Button>
      </div>

      {isOpen && (
        <div>
          {selectedServices.map((service, index) => {
            const isActive = index === selectedServiceIndex;

            return (
              <Button
                style={{
                  background: isActive ? "#474CAE" : "#fff",
                  color: isActive ? "#fff" : "#474CAE",
                  transition: "0.3s",
                }}
                key={service.title}
                onClick={() => handleSelectService(index)}
              >
                {service.title}
              </Button>
            );
          })}
        </div>
      )}

      {isOpen && (
        <div className={styles.open}>
          <div className={styles.serviceDetail}>
            <p>
              {t("reservationsModal.summary.services.title")} {selectedServices[selectedServiceIndex].title}
            </p>
            <p>
              {t("reservationsModal.summary.services.price")} {selectedServices[selectedServiceIndex].price}{" "}
              {offer?.currency}
            </p>
            <p>
              {t("reservationsModal.summary.services.required")}{" "}
              {selectedServices[selectedServiceIndex].required
                ? t("reservationsModal.summary.services.yes")
                : t("reservationsModal.summary.services.no")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
