import React from "react";
import { Button, Tooltip, Typography } from "@mui/material";

import { ServicesOptionProps } from "./service-option.types";
import { ModalData } from "../services.constatns";
import { tooltipConfig } from "../../create-offer.constants";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip.svg";

import styles from "./service-option.module.scss";

export const ServiceOption: React.FC<ServicesOptionProps> = ({ item, setIsModalOpen }) => {
  const { name, type, label, tooltipContent } = item;

  const handleOpenModal = () => {
    setIsModalOpen(name as ModalData);
  };

  return (
    <div className={styles.container}>
      <Typography component="h5">{label}</Typography>
      <Typography component="p">{type}</Typography>
      <Button onClick={handleOpenModal} className={styles.button}>
        +
      </Button>

      <Tooltip title={tooltipContent} enterTouchDelay={0} className={styles.tooltip} componentsProps={tooltipConfig}>
        <TooltipIcon />
      </Tooltip>
    </div>
  );
};
