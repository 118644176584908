export const sx = {
  input: {
    color: "#474CAE",
    fontFamily: "Chillax-semibold",
  },
  label: {
    color: "#474CAE",
    fontFamily: "Chillax-medium",
  },
};
