import { RouteConstant } from "types";

export const HOME_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/",
    en: "/:locale/",
  },
  name: "Home page",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  navbarOnContent: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Sporty Wodne 💙 Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 Rejsy i Czartery Jachtów ⛵`,
      en: "SeaEasy 🌊 Water Sports 💙 Schools & Water Sports Equipment Rentals 🏄 Cruises & Yacht Charters ⛵",
    },
    description: {
      pl: `Lubisz sporty wodne? ⚓ Zawsze chciałeś spróbować surfingu? 🏄 Chcesz wynająć jacht i wypłynąć w rejs życia? 🏴‍☠️ Wejdź na SeaEasy i rezerwuj sprzęt wodny! 🌍`,
      en: "You like water sports? ⚓ Always wanted to try surfing? 🏄 You want to rent a boat & start cruising? 🏴‍☠️ Check SeaEasy & book water sports equipment! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku,
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna,
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych,
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy,
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy,
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena,
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci,
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby,
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const LOGIN_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/login",
    en: "/:locale/login",
  },
  name: "2",
  auth: false,
  showNavigation: false,
  seo: {
    title: {
      pl: `SeaEasy 🌊 Logowanie ✨ Sporty Wodne 💙 Rejsy i Czartery Jachtów, Wypożyczalnie Sprzętu Wodnego 🚤`,
      en: "SeaEasy 🌊 Login ✨ Water Sports 💙 Water Sports Equipment Rentals, Cruises & Yacht Charters 🚤",
    },
    description: {
      pl: `Zaloguj się i dołącz do społeczności sportów wodnych! 💙 Chcesz spróbować kitesurfingu? 🏄 Chcesz wypłynąć w rejs życia? ⚓ Dołącz do nas na SeaEasy! 🌎`,
      en: "Login and join the water sports community! 💙 Want to learn kitesurfing? 🏄 Thinking how to rent a boat & start cruising? ⚓ Join us on SeaEasy! 🌎",
    },
    keywords: {
      pl: `sporty wodne,wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, skutery wodne, kajaki, nurkowanie, efoil, wingfoil`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, boat trips, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, water scooters , kayaks, diving, efoil, wingfoil`,
    },
  },
};

export const REGISTER_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/zarejestruj-sie",
    en: "/:locale/sign-up",
  },
  name: "3",
  auth: false,
  showNavigation: false,
  showNavbar: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Rejestracja ✔️ Społeczność Sportów Wodnych 💙 Podróże, Czartery Jachtów, Rejsy i Wypożyczalnie Sprzętu Wodnego 🛥️`,
      en: `SeaEasy 🌊 Register ✔️ Water Sports Community 💙 Trips, Water Sports Equipment Rentals, Cruises & Yacht Charters 🛥️`,
    },
    description: {
      pl: `Dołącz do najlepszej społeczności sportów wodnych! 💙 Chcesz nauczyć się windsurfingu? 🏄 Chcesz wypłynąć w rejs dookoła świata? ⚓ Dołącz do załogi na SeaEasy! 🌍`,
      en: "Register and join the best water sports community! 💙 Want to learn kitesurfing? 🏄 Thinking how to rent a boat & start cruising? ⚓ Join the crew on SeaEasy! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, skutery wodne, kajaki, nurkowanie, efoil, wingfoil`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, boat trips, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, water scooters , kayaks, diving, efoil, wingfoil`,
    },
  },
};

export const FORGOT_PASSWORD_PAGE: RouteConstant = {
  path: {
    en: "/:locale/forgot-password",
    pl: "/:locale/zapomniane-haslo",
  },
  name: "Forgot password",
  auth: false,
  showNavigation: false,
};

export const RESET_PASSWORD_PAGE: RouteConstant = {
  path: {
    en: "/:locale/reset-password",
    pl: "/:locale/resetuj-haslo",
  },
  name: "Reset password",
  auth: false,
  showNavigation: false,
};

export const CONTACT_PAGE: RouteConstant = {
  path: {
    en: "/:locale/contact",
    pl: "/:locale/kontakt",
  },
  name: "4",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  showFooter: true,

  seo: {
    title: {
      pl: "SeaEasy 🌊 Skontaktuj się z nami 💙 Czartery Jachtów i Wypożyczalnie Sprzętu Wodnego, Wyjazdy i Rejsy ⛵",
      en: "SeaEasy 🌊 Contact us 💙 Yacht Charters & Water Sports Equipment Rentals, Trips & Cruises ⛵",
    },
    description: {
      pl: "Chcesz zapytać o szczegóły wynajmu sprzętu wodnego? 🏄 Nie wiesz jaki jacht wybrać? ⛵ Skontaktuj się z SeaEasy, a nasz zespół dobierze najlepszą ofertę! 🌎",
      en: "Want to ask about water sports equipment rentals? 🏄 Don't know which yacht is best for you? ⛵ Contact SeaEasy and our team will help you find the best offer! 🌎",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, skutery wodne, kajaki, nurkowanie, efoil, wingfoil`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, boat trips, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, water scooters , kayaks, diving, efoil, wingfoil`,
    },
  },
};

export const ABOUT_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/o-nas",
    en: "/:locale/about-us",
  },
  name: "5",
  auth: false,
  showNavigation: false,
  showFooter: true,
  showNavbar: true,
  navbarOnContent: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 O Nas ⚓ Społeczność Sportów Wodnych 💙 Rejsy i Podróże ⛵ Czartery Jachtów i Wypożyczalnie Sprzętu Wodnego 🏄`,
      en: `SeaEasy 🌊 About Us ⚓ Water Sports Community 💙 Cruises & Trips ⛵ Yacht Charters & Water Sports Equipment Rentals 🏄`,
    },
    description: {
      pl: `SeaEasy skupia miłośników sportów wodnych z całego świata! 🌍 Chcesz wynająć jacht i wypłynąć w rejs? 🛥️ Chcesz nauczyć się surfingu? 🏄 Dołącz do SeaEasy! 💙`,
      en: "SeaEasy is a platform for water sports community! 🌍 Want to rent a yacht and start cruising? 🛥️ Want to learn how to surf? 🏄 Join SeaEasy! 💙",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, społeczność sportów wodnych, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, skutery wodne, kajaki, nurkowanie, efoil, wingfoil`,
      en: `water sports, water equipment rental, water sports community, water equipment rental, cruises, yacht charter, boat trips, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, water scooters , kayaks, diving, efoil, wingfoil`,
    },
  },
};

export const CREATE_OFFER_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/stworz-oferte",
    en: "/:locale/create-offer",
  },
  name: "6",
  auth: true,
  showNavbar: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Dodaj Ofertę Wynajmu Sprzętu Wodnego ⚓ Czartery jachtów i Rejsy ⛵ Szkoły Sportów Wodnych 🏄`,
      en: `SeaEasy 🌊 Add Your Water Sports Offer ⚓ Yacht Charters & Cruises ⛵ Water Sports Schools 🏄`,
    },
    description: {
      pl: `Za darmo publikuj oferty wynajmu sprzętu wodnego i dodatkowych usług! 🚤 Zarabiaj na wynajmie swojego sprzętu dzięki automatycznym rezerwacjom 🏄`,
      en: "Publish your water sports offer for free! 🚤 Earn money by renting your equipment thanks to our automated booking system 🏄",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, społeczność sportów wodnych, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, skutery wodne, kajaki, nurkowanie, efoil, wingfoil`,
      en: `water sports, water equipment rental, water sports community, water equipment rental, cruises, yacht charter, boat trips, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, water scooters , kayaks, diving, efoil, wingfoil`,
    },
  },
};

export const EDIT_OFFER_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/edytuj-oferte/:offerId",
    en: "/:locale/edit-offer/:offerId",
  },
  name: "Offer edit page",
  auth: true,
  showNavbar: true,
};

export const OFFERS_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/sporty-wodne",
    en: "/:locale/water-sports",
  },
  name: "7",
  auth: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 Rejsy i Czartery Jachtów ⛵ Sporty Wodne ⚓`,
      en: `SeaEasy 🌊 Schools & Water Sports Equipment Rentals 🏄 Cruises & Yacht Charters ⛵ Water Sports ⚓`,
    },
    description: {
      pl: `Interesują cię sporty wodne? 💙 Chcesz wynająć sprzęt wodny? 🚣 Szukasz czarteru jachtu? ⛵ Zawsze chciałeś nauczyć się surfingu? 🏄 Rezerwuj za darmo przez SeaEasy! 🌏`,
      en: "Interested in water sports? 💙 Want to rent water equipment? 🚣 Looking for yacht charter? ⛵ Always wanted to learn surfing? 🏄 Check SeaEasy & book for free! 🌏",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosobowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const OFFER_PAGE: RouteConstant = {
  path: {
    en: "/:locale/water-equipment/:location/:watersport/:offerId",
    pl: "/:locale/sprzet-wodny/:location/:watersport/:offerId",
  } as const,
  name: "8",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  preventReload: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Sporty Wodne 💙 Rejsy i Czartery Jachtów 🛥️ Szkoły i Wypożyczalnie Sprzętu Wodnego ⚓`,
      en: `SeaEasy 🌊 Water Sports 💙 Cruises & Yacht Charters 🛥️ Schools & Water Sports Equipment Rentals ⚓`,
    },
    description: {
      pl: `Chcesz wynająć sprzęt wodny? 🚤 Zawsze chciałeś nauczyć się windsurfingu? 🏄 Szukasz czarteru jachtu? ⛵ Rezerwuj za darmo przez SeaEasy! 🌍`,
      en: "Want to rent water sports equipment? 🚤 Always wanted to learn windsurfing? 🏄 Searching for yacht charter? ⛵ Check SeaEasy & book for free! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
} as const;

export const HELP_CENTER_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/centrum-pomocy",
    en: "/:locale/help-center",
  },
  name: "13",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Centrum Pomocy 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów 🛥️ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄`,
      en: `SeaEasy 🌊 Help Center 💙 Water Sports ⚓ Cruises & Yacht Charters 🛥️ Schools & Water Sports Equipment Rentals 🏄`,
    },
    description: {
      pl: `Szukasz pomocy? 💙 Zawsze chciałeś nauczyć się windsurfingu? 🏄 Szukasz czarteru jachtu? ⛵ Rezerwuj za darmo przez SeaEasy! 🌍`,
      en: "Looking for help? 💙 Always wanted to learn windsurfing? 🏄 Looking for boat charter? ⛵ Check SeaEasy & book for free! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const CREATE_RENTAL_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/stworz-organizacje",
    en: "/:locale/create-rental",
  },
  name: "17",
  auth: true,
  showNavigation: false,
  showNavbar: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Dodaj Oferty Szkoły i Wypożyczalni Sprzętu Wodnego 🏄 Rejsy i Czartery Jachtów ⛵ Sporty Wodne ⚓`,
      en: `SeaEasy 🌊 Add Schools & Water Sports Equipment Rental Offers 🏄 Cruises & Yacht Charters ⛵ Water Sports ⚓`,
    },
    description: {
      pl: `Za darmo dodaj ofertę swojej wypożyczalni sprzętu wodnego i zacznij zarabiać dzięki rezerwacjom z SeaEasy! ⚓ Sprawdź SeaEasy i odbieraj automatyczne rezerwacje! 💙`,
      en: "Add water sports equipment rental offers for free  🚣 Want to rent your sailboat? ⛵ Want to share surfing classes? 🏄 Check SeaEasy & receive your bookings! 🌏",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};
export const KNOWLEDGE_CENTER_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/centrum-wiedzy",
    en: "/:locale/knowledge-center",
  },
  name: "18",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  showFooter: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Centrum Wiedzy 💙 Sporty Wodne, Podróże, Poradniki, Rejsy, Bezpieczeństwo ⚓ `,
      en: `SeaEasy 🌊 Knowledge Center 💙 Water Sports, Trips, Guides, Cruises, Safety ⚓`,
    },
    description: {
      pl: `Sprawdź nasze darmowe centrum wiedzy 💙 Chcesz nauczyć się windsurfingu? 🏄 Szukasz poradników czarteru jachtu? ⛵ Sprawdź wiedzę dostępną na SeaEasy! 🌍`,
      en: "Check our free knowledge center 💙 Want to learn windsurfing? 🏄 Looking for yacht charter guides? ⛵ Check free knowledge & guides available on SeaEasy! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const ARTICLE_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/artykul/:slug",
    en: "/:locale/article/:slug",
  },
  name: "19",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  showFooter: true,
  navbarOnContent: true,
  preventReload: true,
} as const;

export const NEWSLETTER_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/newsletter",
    en: "/:locale/newsletter",
  },
  name: "20",
  auth: false,
  showNavigation: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Newsletter 💙 Sporty Wodne, Oferty Wynajmu i Czarteru, Podróże, Poradniki, Rejsy, Bezpieczeństwo ⚓ `,
      en: `SeaEasy 🌊 Newsletter 💙 Water Sports, Rental offers & Yacht Charters, Trips, Guides, Cruises, Safety ⚓`,
    },
    description: {
      pl: `Dołącz do naszego darmowego newslettera 💙 Chcesz śledzić co się dzieje w świecie sportów wodnych? ⚓ Szukasz najlepszych ofert czarteru jachtu? ⛵ Dołącz do newslettera SeaEasy! 🌍`,
      en: "Join our free newsletter 💙 Want to follow what`s going on in water sports world? 🏄 Looking for best yacht charter offers? ⛵ Join free SeaEasy newsletter! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const BOOKING_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/rezerwacja",
    en: "/:locale/booking",
  },
  name: "21",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Zarezerwuj sprzęt wodny ⚓ Sporty Wodne 💙 Rejsy i Czartery Jachtów 🚤 Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Book water sports equipment ⚓ Water Sports 💙 Boat Rental Offers & Yacht Charters 🚤 Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Zarezerwuj sprzęt wodny przez SeaEasy ⚓ Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? ⛵ Sprawdź oferty wypożyczalni sprzętu wodnego na SeaEasy! 🌍`,
      en: "Book water sports equipment ⚓ Want to lear how to surf? 🏄 Searching for yacht charter? ⛵ Check Water Sports Equipment Offers Available on SeaEasy! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const PRIVACY_POLICY_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/polityka-prywatnosci",
    en: "/:locale/privacy-policy",
  },
  name: "22",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  showFooter: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Polityka Prywatności 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Privacy Policy 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Zapoznaj się z naszą polityką prywatności 💙 Chcesz nauczyć się windsurfingu? 🏄 Szukasz czarteru jachtu? 🛥️ Sprawdź wypożyczalnie sprzętu wodnego na SeaEasy! 🌍`,
      en: "Check details of our privacy policy 💙 Want to learn windsurfing? 🏄 Searching for yacht charter? 🛥️ Check Water Sports Equipment Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,

      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const COOKIES_POLICY_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/polityka-ciasteczek",
    en: "/:locale/cookies-policy",
  },
  name: "23",
  auth: false,
  showNavigation: false,
  showNavbar: true,
  showFooter: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Polityka Cookies 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Cookies Policy 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Zapoznaj się z naszą polityką cookies 💙 Chcesz nauczyć się kitesurfingu? 🏄 Szukasz czarteru łodzi? 🛥️ Sprawdź wypożyczalnie sprzętu wodnego na SeaEasy! 🌍`,
      en: "Check details of our cookies policy 💙 Want to learn kitesurfing? 🏄 Searching for boat charter? 🛥️ Check Water Sports Equipment Offers Available on SeaEasy! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const OWNER_AGREEMENT_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/zgody-wlasciciela",
    en: "/:locale/owner-agreement",
  },
  name: "24",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Ogólne Warunki Umowy Dla Właścicieli 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Owner Terms of Use 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Zapoznaj się z naszymi ogólnymi warunkami umowy dla właścicieli 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🛥️ Sprawdź oferty na SeaEasy! 🌍`,
      en: "Check details of our terms of use for owners 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🛥️ Check Best Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const EMAIL_VERIFIED_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/potwierdz-email/:token",
    en: "/:locale/confirm-email/:token",
  },
  name: "25",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Potwierdzenie adresu e-mail 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Email verification 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Potwierdzenie twojego adresu e-mail 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🚤 Sprawdź oferty na SeaEasy! 🌍`,
      en: "Email verification 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🚤 Check Best Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const TERMS_OF_USE_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/warunki-korzystania",
    en: "/:locale/terms-of-use",
  },
  name: "27",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Regulamin 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Terms of Use 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Zapoznaj się z naszym regulaminem 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🛥️ Sprawdź oferty na SeaEasy! 🌍`,
      en: "Check details of our terms of use for users 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🛥️ Check Best Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const OWNER_RESERVATIONS_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/rezerwacje/lista",
    en: "/:locale/reservations/list",
  },
  name: "28",
  auth: true,
  showNavigation: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Twoje rezerwacje 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Your bookings 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Sprawdź swoje rezerwacje 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🚤 Sprawdź oferty na SeaEasy! 🌍`,
      en: "Check your bookings 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🚤 Check Best Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const BOOKING_SUCCESS_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/rezerwacja/sukces",
    en: "/:locale/booking/success",
  },
  name: "29",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Rezerwacja przesłana 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Booking success 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Rezerwacja potwierdzona 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🛥️ Sprawdź oferty na SeaEasy! 🌍`,
      en: "Booking succcess 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🛥️ Check Best Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const BOOKING_CANCEL_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/rezerwacja/odwolanie",
    en: "/:locale/booking/cancel",
  },
  name: "30",
  auth: false,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Rezerwacja anulowana 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Booking cancelled 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Rezerwacja anulowana 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🛥️ Sprawdź oferty na SeaEasy! 🌍`,
      en: "Booking cancelled 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🛥️ Check Best Offers Available on SeaEasy! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const RENTAL_PAYMENT_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/wypozyczalnia/platnosci",
    en: "/:locale/rental/payment",
  },
  name: "31",
  auth: true,
  showNavigation: false,
  showNavbar: true,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Płatności Za Rezerwacje 💙 Sporty Wodne ⚓ Rejsy i Czartery Jachtów ⛵ Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 `,
      en: `SeaEasy 🌊 Booking payments 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Płatności Za Rezerwacje 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🚤 Sprawdź oferty na SeaEasy! 🌍`,
      en: "Booking payments 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🚤 Check Best Offers Available on SeaEasy! 🌍",
    },

    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const RENTAL_PAYMENT_SUCCESS: RouteConstant = {
  path: {
    pl: "/:locale/wypozyczalnia/platnosci/sukces",
    en: "/:locale/rental/payment/success",
  },
  name: "32",
  auth: true,
  showNavigation: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 Potwierdzenie Płatności Za Rezerwacje 💙 Szkoły i Wypożyczalnie Sprzętu Wodnego 🏄 Rejsy i Czartery Jachtów ⛵`,
      en: `SeaEasy 🌊 Confirmed Booking Payments 💙 Water Sports ⚓ Boat Rental Offers & Yacht Charters ⛵ Check Water Sports Equipment Rentals 🏄 `,
    },
    description: {
      pl: `Potwierdzenie Płatności Za Rezerwacje 💙 Chcesz nauczyć się surfingu? 🏄 Szukasz czarteru jachtu? 🛥️ Sprawdź oferty na SeaEasy! 🌍`,
      en: "Confirmed Booking Payments 💙 Want to learn how to surf? 🏄 Searching for best yacht charter? 🛥️ Check Best Offers Available on SeaEasy! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const AUTHOR_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/autor/:authorId",
    en: "/:locale/author/:authorId",
  },
  name: "34",
  showNavbar: true,
  showFooter: true,
  auth: false,

  seo: {
    title: {
      pl: `SeaEasy 🌊 O Autorze 💙 Sporty Wodne, Oferty Wynajmu i Czarteru, Podróże, Poradniki, Rejsy, Bezpieczeństwo ⚓ `,
      en: `SeaEasy 🌊 About The Author 💙 Water Sports, Rental offers & Yacht Charters, Trips, Guides, Cruises, Safety ⚓`,
    },
    description: {
      pl: `Sprawdź artykuły autora tekstów 💙 Chcesz śledzić co się dzieje w świecie sportów wodnych? ⚓ Szukasz najlepszych ofert czarteru jachtu? ⛵ Dołącz do newslettera SeaEasy! 🌍`,
      en: "Check articles from the Author 💙 Want to follow what`s going on in water sports world? 🏄 Looking for best yacht charter offers? ⛵ Join free SeaEasy newsletter! 🌍",
    },
    keywords: {
      pl: `sporty wodne, wynajem sprzętu wodnego, wypożyczalnia sprzętu wodnego, rejsy, czarter jachtów, podróże, wynajem łodzi, jachting, żaglówki, motorówki, katamarany, łodzie wiosłowe, pontony, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kajaki, nurkowanie, efoil, wingfoil, sprzęt wodny, wypożyczalnia sprzętu pływającego, sporty wodne na desce, sporty wodne ekstremalny, wodne sporty, ekstremalne sporty wodne, sport wodny na desce, sporty wodne na jeziorze, sporty wodne nad morzem, sporty wodne na oceanie, rejs jachtem po bałtyku, 
  wycieczka jachtem, rejsy jachtem po morzu śródziemnym, rejs jachtem po zatoce gdańskiej, mikołajki rejs jachtem, nowe żeglarstwo, kursy żeglarskie, żeglarstwo na wesoło, motorówka, wypożyczenie motorówki, wypożycz motorówkę, motorówki kabinowe, motorówka elektryczna, motorówka wodna, 
  motorówka kabinowa, mała motorówka, motorówka bez uprawnień, czarter motorówek, motorówki pontonowe, rejsy motorówką, motorówka giżycko, motorówka luksusowa, kajaki, spływ kajakowy, spływy kajakowe, kajak dmuchany, kajak dwuosobowy, kajak wędkarski, kajaki jednoosobowe, kajaki 3 osobowe, kajak turystyczny, kajak roztocze, kajaki bory tucholskie, kajak sportowy, skuter wodny, skutery wodne, wypożyczalnie skuterów wodnych, 
  skuter wodny elektryczny, narty wodne, wyciąg nart wodnych, narty wodne dla dzieci, sup, deski sup, wypożyczalnia sup, sup z żaglem, wiosło sup, sup wypożyczalnia, sup dla dzieci, wypożyczalnia deski sup, deski sup f2, sup z siedziskiem, deski sup ceny, sup dla początkujących, sup dwuosobowy, 
  houseboat, houseboat ceny, houseboat kaszuby, houseboat czarter, houseboat mazury, pontony, ponton wędkarski, pontony wędkarskie, ponton z silnikiem, ponton dmuchany, pontony dmuchane, ponton z silnikiem elektrycznym, ponton wędkarski z twardym dnem, pontony na śnieg, ponton 2 osobowy, 
  ponton jednoosbowy, ponton z żaglem, ponton rybacki, surfing, fun surf, baltic surfing, surfing z latawcem, surfing z żaglem, surfing jurata, kitesurfing, deska kitesurfing, deski kite, kitesurfing kurs, kurs kitesurfingu, nauka kitesurfingu, kurs kite surfing, kite surfing cena, 
  windsurfing, deska windsurfingowa, deska do windsurfingu, obóz kitesurfing, sklepy windsurfingowe, deska do windsurfing, obozy windsurfingowe, deska windsurfing, polskie stowarzyszenie windsurfingu, deska surfingowa z żaglem, windsurfing krynica morska, kamizelki windsurfingowe,
  nurkowanie, maski do nurkowania, maska do nurkowania dla dzieci, kurs nurkowania, sklep nurkowy, okulary do nurkowania, zestaw do nurkowania, rurka do nurkowania, kurs nurka, płetwy do nurkowania, butla do nurkowania, zakrzówek nurkowanie, kurs płetwonurka, maski do nurkowania dla dzieci, 
  mini butla do nurkowania, fajka do nurkowania, zestaw do nurkowania z butlą, skafander nurkowy, koszulki nurkowe, maska dla nurków, komputery nurkowe, wakeboard, deska wakeboard, deski wakeboard, kamizelki wakeboard, rejsy jachtem olsztyn, rejsy jachtem chałupy, rejsy jachtem giżycko, rejsy jachtem olsztyn,
  rejsy jachtem grecja, rejsy jachtem chorwacja, rejsy jachtem bałtyk, rejsy jachtem wyspy kanaryjskie, rejsy jachtem karaiby, rejsy jachtem bahamy, rejsy jachtem tajlandia, rejsy jachtem turcja, rejsy jachtem adriatyk, rejsy jachtem hiszpania, rejsy jachtem portugalia, wynajem łodzi portugalia,
  wynajem łodzi hiszpania, wynajem łodzi grecja, wynajem łodzi tajlandia, wynajem łodzi karaiby, wynajem łodzi bahamy, wynajem łodzi seszele, wynajem jachtu seszele, wynajem katamaranu grecja, czarter katamaranu grecja, czarter katamaranu chorwacja, czarter katamaranu ibiza, czarter katamaranu karaiby, 
  czarter katamaranu seszele, katamaran wynajem, łódź wynajem, żaglówka wynajem, surfing bałtyk, surfing portugalia, surfing maroko, windsurfing bałtyk, windsurfing chałupy, windsurfing mazury, wynajem windsurfing kretowiny, wynajem rower wodny, lekcje windsurfingu, szkoła windsurfingu kretowiny`,
      en: `water sports, water equipment rental, water equipment rental, cruises, yacht charter, travels, boat rental, yachting, sailboats, motorboats, catamarans, rowing boats, pontoons, surfing, windsurfing, kitesurfing, wakeboarding, SUP, stand up paddle, kayaks, diving, efoil, wingfoil, water equipment, water equipment rental, board water sports, water sports extreme, water sports, extreme water sports, board water sport, lake water sports, sea water sports, ocean water sports, cruise a yacht in the Baltic Sea,
  yacht trip, yacht cruises in the Mediterranean Sea, yacht cruise on the Gdańsk Bay, Christmas yacht cruise, new sailing, sailing courses, sailing for fun, motor boat, rent a motor boat, rent a motor boat, cabin motor boats, electric motor boat, water boat,
  cabin motorboat, small motorboat, motorboat without authorization, motorboat charter, pontoon motorboats, motorboat cruises, Giżycko motorboat, luxury motor boat, kayaks, canoeing, kayaking, inflatable kayak, double kayak, fishing kayak, single kayaks, kayaks for 3 people tourist kayak, mite kayak, tuchola forest kayak, sports kayak, water scooter, water scooters, water scooter rentals,
  electric jet ski, water skis, water ski lift, water skis for children, sup, sup boards, sup rental, sup with a sail, sup paddle, sup rental, sup for children, sup board rental, sup board rental, sup f2 boards, sup boards with a seat, boards sup price, sup for beginners, sup for two,
  houseboat, houseboat prices, houseboat Kashubia, houseboat charter, houseboat Masuria, pontoons, fishing pontoon, fishing pontoons, motor pontoon, inflatable pontoon, inflatable pontoons, electric pontoon, hard bottom fishing pontoon, snow pontoon, 2 person pontoon ,
  single-person dinghy, dinghy with a sail, fishing dinghy, surfing, fun surf, baltic surfing, kite surfing, sailing with a sail, yurata surfing, kitesurfing, kitesurfing, kite boards, kitesurfing course, kitesurfing course, kitesurfing lessons, kite surfing course, kite surfing price,
  windsurfing, windsurfing board, windsurfing board, kitesurfing camp, windsurfing shops, windsurfing board, windsurfing camps, windsurfing board, Polish windsurfing association, surfing board with a sail, windsurfing sea spring, windsurfing vests,
  diving, diving masks, diving mask for children, diving course, diving shop, diving glasses, diving set, snorkel, diving course, diving fins, diving cylinder, snorkel diving, diving course, diving masks for children,
  mini diving cylinder, snorkel, scuba diving set, diving suit, diving shirts, mask for divers, diving computers, wakeboard, wakeboard, wakeboard boards, wakeboard vests, olsztyn yacht cruises, cottage yacht cruises, Giżycko yacht cruises yacht cruises in Olsztyn,
  yacht cruises Greece, yacht cruises Croatia, yacht cruises Baltic, yacht cruises Canary Islands, yacht cruises Caribbean, yacht cruises Bahamas, yacht cruises Thailand, yacht cruises Turkey, yacht cruises Adriatic, yacht cruises Spain, boat cruises Portugal, renting
  rent a boat spain, rent a boat greece, rent a boat thailand, rent a caribbean boat, rent a boat bahamas, rent a boat seychelles, rent a yacht seychelles, rent a catamaran greece, charter a catamaran greece, charter a catamaran croatia, charter a catamaran ibiza, charter a caribbean catamaran,
  catamaran charter Seychelles, catamaran rental, boat rental, sailboat rental, Baltic surfing, surfing portugal, surfing Morocco, windsurfing Baltic, windsurfing huts, windsurfing Masuria, rent windsurfing kretowiny, rent a pedalo, windsurfing lessons, windsurfing school kretowiny`,
    },
  },
};

export const MANAGEMENT_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/zarzadzanie/:section",
    en: "/:locale/management/:section",
  },
  name: "41",
  showNavigation: false,
  showNavbar: true,
  showFooter: false,
  auth: true,
};

export const PAYMENTS_CHECK_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/platnosci/sprawdzanie",
    en: "/:locale/payments/check",
  },
  name: "Payments check",
  showFooter: false,
  showNavbar: false,
  showNavigation: false,
  auth: true,
};

export const PROFILE_PAGE: RouteConstant = {
  path: {
    pl: "/:locale/profile",
    en: "/:locale/profile",
  },
  name: "User profile",
  auth: true,
  showNavbar: true,
};
