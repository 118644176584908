import React from "react";

import { CreateOfferProgressProps } from "./create-progress.types";

import styles from "./create-progress.module.scss";

export const CreateOfferProgress: React.FC<CreateOfferProgressProps> = ({ step, totalSteps }) => {
  const finishedPercentage = ((step + 1) / totalSteps) * 100;

  return (
    <div className={styles.wrapper}>
      <div className={styles.progress} style={{ width: `${finishedPercentage}%` }} />
    </div>
  );
};
