import { TFunction } from "i18next";
import React from "react";

export const chartLabels = (t: TFunction) => {
  return {
    revenue: t("insights.buttons.revenue"),
    profit: t("insights.buttons.profit"),
    bookings: t("insights.buttons.bookings"),
    "booking-rate": t("insights.buttons.bookingRate"),
  };
};

export const toolTipContentStyle: React.CSSProperties = {
  borderRadius: "32px",
  border: "3px solid #474CAE",
  fontFamily: "Chillax-semibold",
  textAlign: "center",
  boxShadow: "0px 0px 4px -3px rgba(255, 255, 255, 1)",
};
