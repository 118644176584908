import { TFunction } from "react-i18next";

export const AccordionItems = (t: TFunction) => [
  {
    title: t("account.whatIsSeaEasy"),
    content: t("common.lorem"),
  },
  {
    title: t("account.howDoesSeasEasyWork"),
    content: t("common.lorem"),
  },
  {
    title: t("account.doesSeaEasyOfferInsurance"),
    content: t("common.lorem"),
  },
  {
    title: t("account.whyShouldICommunicateThrought"),
    content: t("common.lorem"),
  },
  {
    title: t("account.doINeedInsuranceToRentABoat"),
    content: t("common.lorem"),
  },
  {
    title: t("account.isYourWebsiteSecure"),
    content: t("common.lorem"),
  },
  {
    title: t("account.howToStartSharingMyEquipment"),
    content: t("common.lorem"),
  },
  {
    title: t("account.howToStartUsingSeaEasy"),
    content: t("common.lorem"),
  },
];
