import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";

import { SocialMedia } from "./socials.types";
import { socialMedia, socialIcons } from "./socials.utils";

import styles from "./socials.module.scss";

type SocialsProps = {
  color: string;
};

export const Socials: React.FC<SocialsProps> = ({ color }) => {
  const { t } = useTranslation();

  const openSocial = (url: string) => () => {
    window.open(url, "_blank");
  };

  return (
    <div className={styles.content}>
      <Typography fontSize={20} color={color} textAlign="center">
        {t("components.findUs")}
      </Typography>
      <div className={styles.row}>
        {Object.entries(socialMedia).map(([key, { label, url }]) => (
          <IconButton
            key={key}
            aria-label={label}
            onClick={openSocial(url)}
            className={styles.button}
            color="primary"
            sx={{ "*": { fill: color } }}
          >
            {socialIcons[key as SocialMedia]}
          </IconButton>
        ))}
      </div>
    </div>
  );
};
