import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import classNames from "classnames";

import { Content } from "components";
import { useQueryParams } from "hooks";
import { sections } from "./knowledge-center.constants";
import { KnowledgeCenterSection, Query } from "./knowledge-center.types";

import styles from "./knowledge-center.module.scss";

export const KnowledgeCenterPage = () => {
  const { t } = useTranslation();
  const { query, updateQueryParams } = useQueryParams<Query>();

  const [currentSection, setCurrentSection] = useState<KnowledgeCenterSection | null>(null);

  const availableSections = sections(t);
  const defaultSection = availableSections[0];

  const sectionController = () => {
    if (query.section) {
      const foundSection = availableSections.find(({ value }) => value === query.section);

      if (foundSection) setCurrentSection(foundSection);
      if (!foundSection) {
        updateQueryParams({ section: defaultSection.value });
        setCurrentSection(defaultSection);
      }
    } else {
      updateQueryParams({ section: defaultSection.value });
      setCurrentSection(defaultSection);
    }
  };

  useDidUpdate(
    () => {
      sectionController();
    },
    [query],
    true,
  );

  const changeSection = (selectedSection: KnowledgeCenterSection) => () => {
    updateQueryParams({ section: selectedSection.value });
    setCurrentSection(selectedSection);
  };

  return (
    <Content className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography fontSize={24} fontFamily="Chillax-Medium">
            {t("seo.knowledgeCenter")}
          </Typography>
          <div className={styles.buttonsWrapper}>
            {availableSections.map((section) => (
              <Button
                key={section.value}
                className={classNames(styles.button, currentSection?.value === section.value && styles.active)}
                onClick={changeSection(section)}
              >
                {section.label}
              </Button>
            ))}
          </div>
        </div>
        {currentSection && currentSection.component}
        {!currentSection && defaultSection.component}
      </div>
    </Content>
  );
};
