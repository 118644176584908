import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { useRoutesPath } from "hooks";
import { HOME_PAGE } from "constants/routes.constants";
import { PaymentMethodProps } from "./payment-method.types";

import { ReactComponent as Logo } from "assets/icons/logo-blue.svg";

import styles from "./payment-method.module.scss";

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ stripeLink }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const openStripe = () => {
    if (stripeLink) {
      window.open(stripeLink, "_self");
    }
  };

  const openLanding = () => navigate(localePath({ route: HOME_PAGE }));

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />

      <Typography className={styles.addPayment}>{t("createOffer.beforeYouAddOffer")}</Typography>

      <div className={styles.content}>
        <Button
          onClick={openStripe}
          className={classNames(styles.button, styles.createOfferButton)}
          disabled={!stripeLink}
        >
          {stripeLink ? t("createOffer.addNow") : t("common.loading")}
        </Button>
        <Button onClick={openLanding} className={styles.button}>
          {t("createOffer.notNow")}
        </Button>
      </div>
    </div>
  );
};
