import React from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AddPersonModalProps } from "./add-person-modal.types";
import { TeamMemberForm } from "./team-member-form/team-member-form";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./add-person-modal.module.scss";

export const AddPersonModal: React.FC<AddPersonModalProps> = ({ handleClose, handleRefreshTeamData }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.modalTitle}>{t("team.addModal.addTeamMember")}</Typography>
        <IconButton onClick={handleClose} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>

      <TeamMemberForm handleClose={handleClose} handleRefreshTeamData={handleRefreshTeamData} />
    </div>
  );
};
