import { number, object, SchemaOf, string } from "yup";
import { TFunction } from "i18next";

import { EditData } from "./profile.types";

export const profileSchema = (t: TFunction): SchemaOf<EditData> =>
  object().shape({
    number: number().required(t("profile.schema.numberRequired")),
    email: string().required(t("profile.schema.emailRequired")),
    firstName: string().required(t("profile.schema.firstNameRequired")),
    lastName: string().required(t("profile.schema.lastNameRequired")),
  });
