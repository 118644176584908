import React from "react";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { pl, enUS } from "date-fns/locale";
import { useSelector } from "react-redux";

import { HeaderProps } from "./header.types";
import { RootState } from "store";
import { useWindowSize } from "hooks";

import styles from "./header.module.scss";

export const Header: React.FC<HeaderProps> = ({ article, author }) => {
  const { locale } = useSelector((state: RootState) => state.app);
  const { width } = useWindowSize();

  const isMobile = width < 992;
  const publishDate = new Date(article.attributes.updatedAt);
  const dateFormat = "dd-MM-yyyy";
  const dateLocale = locale === "pl-PL" ? pl : enUS;
  const authorName = article.attributes.author.data.attributes.Name || "";
  const authorDescription = article.attributes.author.data.attributes.Description || "";
  const authorProfile = author.attributes.Avatar.data.attributes.url;

  const backgroundImage = isMobile
    ? `url(${article.attributes.mobileImage.data.attributes.url})`
    : `url(${article.attributes.backgroundImage?.data.attributes.url})`;

  return (
    <header
      className={styles.header}
      style={{
        backgroundImage,
      }}
    >
      <div className={styles.wrapper}>
        <Typography variant="h3" fontSize="24px" className={styles.date}>
          {format(publishDate, dateFormat, { locale: dateLocale })}
        </Typography>
        <Typography variant="h1" fontSize="24px" className={styles.title}>
          {article.attributes.title}
        </Typography>
        <div className={styles.authorSection}>
          <img src={authorProfile} className={styles.profilePicture} alt={authorName} />
          <div className={styles.author}>
            <Typography variant="h3" fontSize="24px">
              {authorName}
            </Typography>
            <Typography variant="h4" fontSize="24px">
              {authorDescription}
            </Typography>
          </div>
        </div>
      </div>
    </header>
  );
};
