import React from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { Grid } from "@mui/material";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";

import { FilesDropzoneType } from "./files-dropzone.types";
import { Size } from "constants/size.constants";

export const FilesDropzone: React.FC<FilesDropzoneType> = ({
  name,
  supportedFormats = { "image/*": [".jpeg", ".png"] },
  maxFileCount,
  single = false,
  children,
}) => {
  const [{ value }, { error, touched }, { setValue }] = useField<File | File[] | null>({ name });

  const maxFiles = maxFileCount && Array.isArray(value) ? maxFileCount - value.length : undefined;

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxSize: Size.mb * 10,
    accept: supportedFormats,
    maxFiles,
    multiple: !single,
  });

  useDidUpdate(() => {
    if (Array.isArray(value)) {
      const idAcceptedFiles = acceptedFiles.map(
        (item) =>
          new File([item], `${uuid()}-${item.name}`, {
            type: item.type,
            lastModified: item.lastModified,
          }),
      );

      if (!maxFileCount) {
        setValue([...value, ...idAcceptedFiles]);
      } else {
        const filesToAddCounter = acceptedFiles.length - value.length;
        const files = acceptedFiles.slice(filesToAddCounter);
        setValue([...value, ...files]);
      }
    } else {
      setValue(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const deleteFile = (fileName: string) => () => {
    if (Array.isArray(value)) {
      const newFiles = value.filter((file) => file.name !== fileName);
      setValue(newFiles);
    } else {
      setValue(null);
    }
  };

  const fileMappedToArray = value && !Array.isArray(value) ? [value] : [];
  const valueToDisplay = Array.isArray(value) ? value : fileMappedToArray;

  const showError = Boolean(error && touched);

  return (
    <div>
      <Grid {...getRootProps()}>
        <input {...getInputProps()} />
        {children({ files: valueToDisplay, deleteFile, showError, error })}
      </Grid>
    </div>
  );
};
