import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getParameters } from "./boat-details.constants";
import { BoatDetailsProps } from "./boat-details.types";

import styles from "./boat-details.module.scss";
import stylesMain from "../details.module.scss";

export const BoatDetails: React.FC<BoatDetailsProps> = ({ parameters, currency }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Typography className={stylesMain.title}>{t("offerPage.details.rentalDetails.whatAreTheBoatDetails")}</Typography>
      <div className={stylesMain.detailBox}>
        {Object.entries(parameters).map(([key, value]) => {
          if (value === null) return;
          const unit = () => {
            switch (key) {
              case "length":
                return "m";

              case "deposit":
                return currency;

              default:
            }
          };
          return (
            <div className={stylesMain.detailContent}>
              <Typography>
                {value} {unit()}
              </Typography>

              <Typography>{getParameters(t).find((parameter) => parameter.value === key)?.label}</Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};
