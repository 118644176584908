import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ArticleBoxProps } from "./article-box.types";
import { ARTICLE_PAGE } from "constants/routes.constants";
import { useRoutesPath, useWindowSize } from "hooks";

import styles from "./article-box.module.scss";

export const ArticleBox: React.FC<ArticleBoxProps> = ({ title, slug, coverMobile, coverDesktop }) => {
  const { width } = useWindowSize();
  const { localePathParams } = useRoutesPath();

  const isMobile = width < 992;

  return (
    <Link to={localePathParams({ route: ARTICLE_PAGE, params: { slug } })} className={styles.container}>
      <img src={isMobile ? coverMobile : coverDesktop} alt="article" className={styles.image} />
      <div className={styles.content}>
        <Typography fontFamily="Chillax-regular">{title}</Typography>
      </div>
    </Link>
  );
};
