import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";

import { SportType } from "./create-offer.types";
import { useWindowSize } from "hooks";
import { UserOrganizationData } from "server/organization/organization.types";
import { LocationModel } from "models";
import { watersportsData } from "../../../utils/watersports.utils";

type CreateOfferContextType = {
  sportType: SportType;
  setSportType: (type: SportType) => void;
  sportTypeId: number;
  setSportTypeId: (type: number) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  step: number;
  setStep: (value: number) => void;
  isDesktop: boolean;
  organization: UserOrganizationData | null;
  icon: string;
  locations: LocationModel[];
  addUserLocation: (location: LocationModel) => void;
  isAddingLocation: boolean;
  setIsAddingLocation: React.Dispatch<React.SetStateAction<boolean>>;
  useOrganizationImages: boolean;
  setUseOrganizationImages: React.Dispatch<React.SetStateAction<boolean>>;
  photosToDelete: [] | number[];
  setPhotosToDelete: React.Dispatch<React.SetStateAction<[] | number[]>>;
  numOfPhotos: number;
  setNumOfPhotos: React.Dispatch<React.SetStateAction<number>>;
};

export const CreateOfferContext = React.createContext<CreateOfferContextType>({
  sportType: "WINDSURFING",
  setSportType: () => {},
  sportTypeId: 1,
  setSportTypeId: () => {},
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  step: 0,
  setStep: () => {},
  isDesktop: true,
  organization: {} as UserOrganizationData,
  icon: "",
  locations: [],
  addUserLocation: () => {},
  isAddingLocation: false,
  setIsAddingLocation: () => {},
  useOrganizationImages: false,
  setUseOrganizationImages: () => {},
  photosToDelete: [],
  setPhotosToDelete: () => {},
  numOfPhotos: 5,
  setNumOfPhotos: () => {},
});

interface Props {
  children: React.ReactNode;
  value: Pick<CreateOfferContextType, "organization" | "locations">;
}

export const CreateOfferContextProvider: React.FC<Props> = ({ children, value }) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const [sportType, setSportType] = useState<SportType>("WINDSURFING");
  const [sportTypeId, setSportTypeId] = useState<number>(1);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [organization, setOrganization] = useState<null | UserOrganizationData>(value.organization);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [step, setStep] = useState<number>(0);
  const [isAddingLocation, setIsAddingLocation] = useState<boolean>(false);
  const [useOrganizationImages, setUseOrganizationImages] = useState<boolean>(false);
  const [photosToDelete, setPhotosToDelete] = useState<number[] | []>([]);
  const [numOfPhotos, setNumOfPhotos] = useState<number>(5);

  const addUserLocation = (location: LocationModel) => {
    setLocations((prevState) => [...prevState, location]);
  };

  const icon = watersportsData(t).find((item) => item.id === sportTypeId)?.iconWhite || "";
  const isDesktop = width > 992;

  useDidMount(() => {
    setOrganization(value.organization);
    setLocations(value.locations);
  });

  return (
    <CreateOfferContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        sportType,
        setSportType,
        sportTypeId,
        setSportTypeId,
        isMenuOpen,
        setIsMenuOpen,
        step,
        setStep,
        isDesktop,
        organization,
        icon,
        locations,
        addUserLocation,
        isAddingLocation,
        setIsAddingLocation,
        useOrganizationImages,
        setUseOrganizationImages,
        photosToDelete,
        setPhotosToDelete,
        numOfPhotos,
        setNumOfPhotos,
      }}
    >
      {children}
    </CreateOfferContext.Provider>
  );
};
