import React from "react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

import { STORAGE_FIELDS } from "constants/storage.constants";
import { resources } from "translations/resources";
import { ProvidersProps } from "./providers.types";
import { store } from "store";
import { DEFAULT_LOCALE } from "constants/app.constants";

import { theme } from "assets/styles/theme";

import styles from "./providers.module.scss";

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem(STORAGE_FIELDS.language) || DEFAULT_LOCALE,
  })
  .then();

i18next.on("languageChanged", (lang) => {
  localStorage.setItem(STORAGE_FIELDS.language, lang);
});

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          classes={{
            variantSuccess: styles.success,
            variantWarning: styles.warning,
            variantError: styles.error,
            variantInfo: styles.info,
          }}
        >
          <BrowserRouter>{children}</BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};
