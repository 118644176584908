import {
  HomePage,
  ContactPage,
  AboutPage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  CreateOfferPage,
  OffersListPage,
  HelpCenterPage,
  KnowledgeCenterPage,
  ArticlePage,
  CreateRentalPage,
  NewsletterPage,
  BookingPage,
  TermsOfUsePage,
  PrivacyPolicyPage,
  EmailVerifiedPage,
  BookingSuccessPage,
  OwnerReservationsPage,
  BookingCancelPage,
  RentalPaymentPage,
  RentalPaymentSuccessPage,
  OfferPage,
  CookiesPolicyPage,
  OwnerAgreementPage,
  AuthorPage,
  PaymentsCheckPage,
  ProfilePage,
} from "pages";
import { RouteConfig } from "types";
import {
  HOME_PAGE,
  CONTACT_PAGE,
  ABOUT_PAGE,
  LOGIN_PAGE,
  REGISTER_PAGE,
  RESET_PASSWORD_PAGE,
  FORGOT_PASSWORD_PAGE,
  CREATE_OFFER_PAGE,
  OFFERS_PAGE,
  OFFER_PAGE,
  HELP_CENTER_PAGE,
  CREATE_RENTAL_PAGE,
  KNOWLEDGE_CENTER_PAGE,
  ARTICLE_PAGE,
  NEWSLETTER_PAGE,
  BOOKING_PAGE,
  TERMS_OF_USE_PAGE,
  PRIVACY_POLICY_PAGE,
  EMAIL_VERIFIED_PAGE,
  BOOKING_SUCCESS_PAGE,
  OWNER_RESERVATIONS_PAGE,
  BOOKING_CANCEL_PAGE,
  RENTAL_PAYMENT_PAGE,
  RENTAL_PAYMENT_SUCCESS,
  COOKIES_POLICY_PAGE,
  OWNER_AGREEMENT_PAGE,
  AUTHOR_PAGE,
  MANAGEMENT_PAGE,
  PAYMENTS_CHECK_PAGE,
  EDIT_OFFER_PAGE,
  PROFILE_PAGE,
} from "constants/routes.constants";
import { ManagementPage } from "../pages/rental/management/management.page";

export const routes: RouteConfig[] = [
  { ...HOME_PAGE, component: HomePage },
  { ...LOGIN_PAGE, component: LoginPage },
  { ...REGISTER_PAGE, component: RegisterPage },
  { ...RESET_PASSWORD_PAGE, component: ResetPasswordPage },
  { ...FORGOT_PASSWORD_PAGE, component: ForgotPasswordPage },
  { ...CONTACT_PAGE, component: ContactPage },
  { ...ABOUT_PAGE, component: AboutPage },
  { ...CREATE_OFFER_PAGE, component: CreateOfferPage },
  { ...EDIT_OFFER_PAGE, component: CreateOfferPage },
  { ...OFFERS_PAGE, component: OffersListPage },
  { ...OFFER_PAGE, component: OfferPage },
  { ...HELP_CENTER_PAGE, component: HelpCenterPage },
  { ...CREATE_RENTAL_PAGE, component: CreateRentalPage },
  { ...KNOWLEDGE_CENTER_PAGE, component: KnowledgeCenterPage },
  { ...ARTICLE_PAGE, component: ArticlePage },
  { ...NEWSLETTER_PAGE, component: NewsletterPage },
  { ...BOOKING_PAGE, component: BookingPage },
  { ...TERMS_OF_USE_PAGE, component: TermsOfUsePage },
  { ...PRIVACY_POLICY_PAGE, component: PrivacyPolicyPage },
  { ...EMAIL_VERIFIED_PAGE, component: EmailVerifiedPage },
  { ...BOOKING_SUCCESS_PAGE, component: BookingSuccessPage },
  { ...BOOKING_CANCEL_PAGE, component: BookingCancelPage },
  { ...OWNER_RESERVATIONS_PAGE, component: OwnerReservationsPage },
  { ...RENTAL_PAYMENT_PAGE, component: RentalPaymentPage },
  { ...RENTAL_PAYMENT_SUCCESS, component: RentalPaymentSuccessPage },
  { ...COOKIES_POLICY_PAGE, component: CookiesPolicyPage },
  { ...OWNER_AGREEMENT_PAGE, component: OwnerAgreementPage },
  { ...AUTHOR_PAGE, component: AuthorPage },
  { ...MANAGEMENT_PAGE, component: ManagementPage },
  { ...PAYMENTS_CHECK_PAGE, component: PaymentsCheckPage },
  { ...PROFILE_PAGE, component: ProfilePage },
];
