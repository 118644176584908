import { createTheme, ThemeOptions } from "@mui/material";

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      dark: "#18191C",
      main: "#202225",
      light: "#37393E",
    },
    secondary: {
      dark: "#18191C",
      main: "#202225",
      light: "#37393E",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
    },
    warning: {
      light: "#ffb74d",
      main: "#ffa726",
      dark: "#f57c00",
    },
    info: {
      light: "#4fc3f7",
      main: "#29b6f6",
      dark: "#0288d1",
    },
    success: {
      light: "#81c784",
      main: "#66bb6a",
      dark: "#388e3c",
    },
    text: {
      primary: "#000000",
      secondary: "rgba(0,0,0,0.6)",
      disabled: "#ccc",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#f6f6f6",
      default: "#fff",
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.5)",
      disabledBackground: "rgba(0, 0, 0, 0.1)",
      active: "#a5765f",
    },
    grey: {
      // (CONTRAST) from the greatest to the smallest
      // dark background = from white to dark grey
      // light background = from dark grey to white
      50: "#151515",
      100: "#2a2a2a",
      200: "#3f3f3f",
      300: "#545454",
      400: "#7f7f7f",
      500: "#a9a9a9",
      600: "#e1e1e1",
      700: "#eaeaea",
      800: "#f5f5f5",
      900: "#fafafa",
    },
    divider: "#e0e0e0",
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ["Comfortaa"].join(","),
  },
  spacing: 8,
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: "contain",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          padding: "0 5px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: "#a9a9a9",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Comfortaa, sans-serif",
        },
        notchedOutline: {
          borderColor: "#a9a9a9",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Comfortaa, sans-serif",
        },
        contained: {
          margin: "10px 0",
        },
        sizeMedium: {
          minWidth: "100px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Comfortaa, sans-serif",
        },
        h1: {
          fontSize: "42px",
          "@media (min-width:992px)": {
            fontSize: "61px",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: 400,
        },
      },
    },
  },
});
