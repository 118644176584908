import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ReservationsContext } from "../reservations-modal.context";
import { CalendarView } from "../calendar-view /calendar-view";
import { SlotsView } from "../slots-view/slots-view";
import { CalendarViewContextProvider } from "./calendar-view.context";
import { SlotsViewContextProvider } from "./slots-view.context";
import { SlotsViewOwnContextProvider } from "./slots-view-own.context";
import { SlotsViewOwn } from "../slots-view-own/slots-view-own";
import { CalendarViewOwnContextProvider } from "./calendar-view-own.context";
import { CalendarViewOwn } from "../calendar-view-own/calendar-view-own";
import { OfferModel } from "models";
import { OfferSelect } from "../components/offer-select/offer-select";

export const Wrapper = () => {
  const { t } = useTranslation();
  const { isCalendarView, isOwnReservation, offer } = useContext(ReservationsContext);

  const showView = () => {
    switch (true) {
      case isOwnReservation && isCalendarView:
        return (
          <CalendarViewOwnContextProvider offer={offer as OfferModel}>
            <CalendarViewOwn />
          </CalendarViewOwnContextProvider>
        );

      case !isOwnReservation && isCalendarView:
        return (
          <CalendarViewContextProvider>
            <CalendarView />
          </CalendarViewContextProvider>
        );
      case !isOwnReservation && !isCalendarView:
        return (
          <SlotsViewContextProvider>
            <SlotsView />
          </SlotsViewContextProvider>
        );

      case isOwnReservation && !isCalendarView:
        return (
          <SlotsViewOwnContextProvider offer={offer as OfferModel}>
            <SlotsViewOwn />
          </SlotsViewOwnContextProvider>
        );

      default:
        return <div>{t("reservationsModal.error")}</div>;
    }
  };

  if (!offer) {
    return <OfferSelect />;
  }

  return <>{showView()}</>;
};
