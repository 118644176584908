export const seo = {
  // Knowledge Center
  knowledgeCenter: "Knowledge Center",
  companyNews: "Company News",
  lifestyle: "Lifestyle",
  destinations: "Destinations",
  guides: "Guides",
  safety: "Safety",

  // article
  moreFromSeaEasy: "More from SeaEasy",
  subscribeNewsletter: "Subscribe to our newsletter",

  // newsletter
  subscribeToSeaNewsletter: "Subscribe to SeaEasy Newsletter ",
};
