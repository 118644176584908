export const watersports = {
  windsurfing: "Windsurfing",
  wakeboard: "Wakeboard",
  surfing: "Surfing",
  sup: "SUP",
  sailing: "Sailing",
  rowingBoat: "Rowing boat",
  pedalo: "Pedalo",
  other: "Other",
  motorboat: "Motorboat",
  kitesurfing: "Kitesurfing",
  kayak: "Kayak",
  diving: "Diving",
  efoil: "eFoil",
  wingfoil: "Wing foil",
  jetski: "Jet ski",
  rowing_boat: "Rowing boat",
  yacht: "Yachts",
  "rowing boat": "Rowing boat",
};
