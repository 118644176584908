export const insights = {
  insights: "Insights",
  filters: {
    days: "days",
    months: "months",
    filter: "Filter",
    month: "month",
    range: "Range",
  },
  chart: {
    noData: "No data",
  },
  buttons: {
    revenue: "revenue",
    profit: "profit",
    bookings: "bookings",
    bookingRate: "booking rate",
    reservationsTime: "booking time",
  },
  teamHours: {
    teamHours: "Team hours",
    hours: "hours",
    seeAll: "See all",
    details: "details",
    profit: "profit",
  },
  clientBookings: {
    newClient: "new client",
    nextBooking: "Next booking",
    clientBookings: "Client bookings",
    hours: "hours",
    seeAll: "see all",
    soFar: "so far",
    remaining: "remaining",
    aboutClient: "About client:",
    telephone: "Telephone: ",
    email: "Email: ",
    gender: "Gender: ",
    age: "Age: ",
    dateOfBirth: "Date of birth: ",
    weight: "Weight: ",
    size: "Size: ",
    level: "Level: ",
    notes: "NOTES: ",
    lastBooked: "Last booked: ",
    host: "Host: ",
    services: "Services: ",
    group: "Group: ",
    individual: "Individual",
    paidWithCash: "Paid with cash",
    groups: "Group",
    extras: "Extras",
    lastPayouts: "Last payouts",
    addPayouts: "Add payouts",
    instructors: "Instructors:",
    clients: "Clients:",
    instructor: "Instructor",
    addNotes: "Add notes",
  },
  guestsDetails: {
    hours: "hours",
    newClient: "New client",
    aboutClient: "About client:",
    currentPackage: "Current package:",
    lastBookings: "last bookings",
    editPackage: "Edit package",
    editData: "Edit data",
    exportData: "Export data",
    search: "Search",
    payment: "Payment",
    services: "Services",
    group: "Group:",
    notes: "Notes:",
    instructor: "Instructor",
    clientBookings: "Client bookings",

    editPackageModal: {
      offer: "Offer:",
      totalHours: "Total hours remaining:",
      totalValue: "Total value remaining:",
      newValue: "New value",
      newHours: "New hours",
      newService: "New Service",
      save: "Save",
    },
  },
};
