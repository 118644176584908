import { WatersportModel } from "models/watersport.models";
import { builder } from "server/builder";
import { WatersportData, WatersportsData } from "./watersport.types";

export const postWatersport = builder.createCommand<WatersportModel, WatersportData>()({
  endpoint: "/app/watersport",
  method: "POST",
});

export const getWatersports = builder.createCommand<WatersportsData[]>()({
  endpoint: "/app/watersport",
  method: "GET",
  retry: 5,
});

export const getWatersport = builder.createCommand<WatersportModel>()({
  endpoint: "/app/watersport/:id",
  method: "GET",
});
