import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Modal } from "components";
import { PricingModal } from "./pricing-modal/pricing-modal";
import { AvailabilityModal } from "./availability-modal/availability-modal";
import { IncludedModal } from "./included-modal/included-modal";
import { OfferData } from "../create-offer.types";
import { rateTypeTranslated } from "../create-offer.constants";
import { daysOptions, monthsOptions } from "./availability-modal/availability-modal.constants";

import styles from "./base-info.module.scss";

export const BaseInfo = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<OfferData>();

  const [isPricingModalOpen, setIsPricingModalOpen] = useState<boolean>(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState<boolean>(false);
  const [isIncludedModalOpen, setIsIncludedModalOpen] = useState<boolean>(false);

  const getMonthTranslated = (monthToTranslate: string, index: number) => {
    const monthName = monthsOptions(t).find((month) => month.value === monthToTranslate)?.label;

    if (index + 1 !== values.baseInfo.availability.months.length) return `${monthName}, `;

    return monthName;
  };

  const getDayTranslated = (dayToTranslate: string, index: number) => {
    const dayName = daysOptions(t).find((day) => day.value === dayToTranslate)?.label;

    if (index + 1 !== values.baseInfo.availability.days.length) return `${dayName}, `;

    return dayName;
  };

  const checkIncludedServices = (includedItem: string, index: number) => {
    if (index + 1 !== values.baseInfo.included.included.length) return `${includedItem}, `;

    return includedItem;
  };

  const checkNotIncludedServices = (notIncludedItem: string, index: number) => {
    if (index + 1 !== values.baseInfo.included.notIncluded.length) return `${notIncludedItem}, `;

    return notIncludedItem;
  };

  const formatHours = "HH:mm";
  const formatDate = "YYYY-MM-DD";
  const startTime = values.baseInfo.availability.startTime.format(formatHours);
  const endTime = values.baseInfo.availability.endTime.format(formatHours);
  const start = values.baseInfo.availability.start.format(formatDate);
  const end = values.baseInfo.availability.end.format(formatDate);

  const handlePricingModal = (isOpen: boolean) => () => setIsPricingModalOpen(isOpen);
  const handleAvailabilityModal = (isOpen: boolean) => () => setIsAvailabilityModalOpen(isOpen);
  const handleIncludedModal = (isOpen: boolean) => () => setIsIncludedModalOpen(isOpen);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.contentLeft}>
            <Typography className={styles.bold}>{t("createOffer.baseInfo.pricing.addPricing")}</Typography>
            <Typography className={styles.regular}>{t("createOffer.baseInfo.mandatory")}</Typography>
            <Button className={styles.button} onClick={handlePricingModal(true)}>
              {t("createOffer.baseInfo.pricing.editPricing")}
            </Button>
          </div>
          <div className={styles.contentRight}>
            <Typography className={classNames(styles.bold)}>
              {" "}
              {t("createOffer.baseInfo.pricing.currentPricing")}
            </Typography>

            <div className={styles.detailsWrapper}>
              <Typography className={styles.detailContent}>
                <span> {t("createOffer.baseInfo.pricing.basePrice")}</span>
                {values.baseInfo.pricing.basePrice}
              </Typography>
              <Typography className={styles.detailContent}>
                <span> {t("createOffer.baseInfo.pricing.currency")}</span>
                {values.baseInfo.pricing.currency}
              </Typography>
              <Typography className={styles.detailContent}>
                <span> {t("createOffer.baseInfo.pricing.rateType")}</span>
                {rateTypeTranslated(t, values.baseInfo.pricing.rateType)}
              </Typography>
              <Typography className={styles.detailContent}>
                <span> {t("createOffer.baseInfo.pricing.maxGuests")}</span>
                {values.baseInfo.pricing.maxGuests}
              </Typography>
              <Typography className={styles.detailContent}>
                <span> {t("createOffer.baseInfo.pricing.deposit")}</span>
                {values.baseInfo.pricing.deposit}
              </Typography>
              <Typography className={styles.detailContent}>
                <span> {t("createOffer.baseInfo.pricing.discount")}</span> -
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contentLeft}>
            <Typography className={styles.bold}>{t("createOffer.baseInfo.availability.addAvailability")}</Typography>
            <Typography className={styles.regular}>{t("createOffer.baseInfo.mandatory")}</Typography>
            <Button onClick={handleAvailabilityModal(true)} className={styles.button}>
              {t("createOffer.baseInfo.availability.editAvailability")}
            </Button>
          </div>
          <div className={styles.contentRight}>
            <Typography className={styles.bold}>
              {t("createOffer.baseInfo.availability.currentAvailability")}
            </Typography>

            <div className={styles.detailsWrapper}>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.availability.months")}</span>
                {values.baseInfo.availability.months.map((month, index) => getMonthTranslated(month, index))}
              </Typography>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.availability.days")}</span>
                {values.baseInfo.availability.days.map((day, index) => getDayTranslated(day, index))}
              </Typography>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.availability.startTime")}</span>
                {startTime}
              </Typography>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.availability.endTime")}</span>
                {endTime}
              </Typography>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.availability.startDate")}</span>
                {start}
              </Typography>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.availability.endDate")}</span>
                {end}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contentLeft}>
            <Typography className={styles.bold}>{t("createOffer.baseInfo.included.addWhatsIncluded")}</Typography>
            <Typography className={styles.regular}>{t("createOffer.baseInfo.optional")}</Typography>
            <Button onClick={handleIncludedModal(true)} className={styles.buttonOrange}>
              {t("createOffer.baseInfo.included.editIncluded")}
            </Button>
          </div>
          <div className={styles.contentRight}>
            <Typography className={styles.bold}>{t("createOffer.baseInfo.included.currentIncluded")}</Typography>

            <div className={styles.detailsWrapper}>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.included.included")}</span>
                {values.baseInfo.included.included.length === 0 && <Typography>-</Typography>}
                {values.baseInfo.included.included.length !== 0 &&
                  values.baseInfo.included.included.map((includedItem, index) =>
                    checkIncludedServices(includedItem, index),
                  )}
              </Typography>
              <Typography className={styles.detailContent}>
                <span>{t("createOffer.baseInfo.included.notIncluded")}</span>
                {values.baseInfo.included.notIncluded.length === 0 && <Typography>-</Typography>}
                {values.baseInfo.included.notIncluded.length !== 0 &&
                  values.baseInfo.included.notIncluded.map((includedItem, index) =>
                    checkNotIncludedServices(includedItem, index),
                  )}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <Modal open={isPricingModalOpen}>
        <PricingModal close={handlePricingModal(false)} />
      </Modal>
      <Modal open={isAvailabilityModalOpen}>
        <AvailabilityModal close={handleAvailabilityModal(false)} />
      </Modal>
      <Modal open={isIncludedModalOpen}>
        <IncludedModal close={handleIncludedModal(false)} />
      </Modal>
    </>
  );
};
