import React, { useRef, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { ClickAwayListener } from "@mui/base";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import gsap from "gsap";

import { OffersDesktopProps } from "./offers-desktop.types";
import { useRoutesPath } from "hooks";
import { OFFERS_PAGE } from "constants/routes.constants";
import { getWatersportIcon, getWatersportTranslation } from "utils/watersports.utils";

import { ReactComponent as GlobeGreen } from "assets/icons/globe-green.svg";
import { ReactComponent as GlobePurple } from "assets/icons/globe-purple.svg";
import DestinationsShadowIconGreen from "assets/images/destinations-shadow-green.webp";
import DestinationsShadowIconPurple from "assets/images/destinations-shadow-purple.webp";
import WatersportsShadowIconGreen from "assets/images/watersport-shadow-green.webp";
import WatersportsShadowIconPurple from "assets/images/watersports-shadow-purple.webp";
import watersportsIcon from "assets/icons/all-watersports-icon.svg";

// TODO index ts in assets/icons

import styles from "./offers-desktop.module.scss";

export const OffersDesktop: React.FC<OffersDesktopProps> = ({
  destinations,
  destinationsPath,
  watersportsPath,
  watersports,
  isGreen,
  color,
}) => {
  const destinationsRef = useRef(null);
  const watersportsRef = useRef(null);
  const destinationsButtonRef = useRef(null);
  const destinationsItemsRef = useRef<null | HTMLDivElement>(null);
  const watersportsItemsRef = useRef<null | HTMLDivElement>(null);
  const destinationsTl = useRef<null | GSAPTimeline>(null);
  const watersportsTl = useRef<null | GSAPTimeline>(null);

  const { t } = useTranslation();
  const { locale, localePath } = useRoutesPath();

  const [destinationsOpen, setDestinationsOpen] = useState<boolean>(false);
  const [watersportsOpen, setWatersportsOpen] = useState<boolean>(false);
  const [destinationsAnimated, setDestinationsAnimated] = useState<boolean>(false);
  const [watersportsAnimated, setWatersportsAnimated] = useState<boolean>(false);

  const toggleDestinations = () => {
    setDestinationsOpen((prevState) => !prevState);

    if (watersportsOpen) setWatersportsOpen(false);
  };

  const toggleWatersports = () => {
    setWatersportsOpen((prevState) => !prevState);

    if (destinationsOpen) setDestinationsOpen(false);
  };

  const handleClose = () => {
    setWatersportsOpen(false);
    setDestinationsOpen(false);
  };

  useDidUpdate(
    () => {
      if (destinationsItemsRef.current && !destinationsAnimated) {
        gsap.set(destinationsItemsRef.current?.children, { autoAlpha: 0, y: "+=150px" });

        destinationsTl.current = gsap.timeline({
          delay: 0.2,
        });

        destinationsTl.current
          .to(destinationsItemsRef.current?.children, {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
          })
          .to(destinationsItemsRef.current?.children, { autoAlpha: 1, y: 0 });

        setDestinationsAnimated(true);
      }
    },
    [destinationsItemsRef, destinationsOpen],
    true,
  );

  useDidUpdate(
    () => {
      if (watersportsItemsRef.current && !watersportsAnimated) {
        gsap.set(watersportsItemsRef.current?.children, { autoAlpha: 0, y: "+=150px" });

        watersportsTl.current = gsap.timeline({
          delay: 0.2,
        });

        watersportsTl.current
          .to(watersportsItemsRef.current?.children, {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
          })
          .to(watersportsItemsRef.current?.children, { autoAlpha: 1, y: 0 });

        setWatersportsAnimated(true);
      }
    },
    [watersportsItemsRef, watersportsOpen],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <IconButton ref={destinationsButtonRef} onClick={toggleDestinations} className={styles.iconButton}>
          <img
            alt="top destinations"
            className={styles.mainIcon}
            src={isGreen ? DestinationsShadowIconGreen : DestinationsShadowIconPurple}
          />

          <Typography style={{ color }}>{t("navbar.destinations")}</Typography>
        </IconButton>

        {destinationsOpen && (
          <ClickAwayListener onClickAway={() => setDestinationsOpen(false)}>
            <div
              ref={destinationsRef}
              className={classNames(styles.menuWrapper, { [styles.menuWrapperGreen]: isGreen })}
            >
              <div className={styles.menuContent} ref={destinationsItemsRef}>
                <Link
                  className={classNames(styles.menuItem, styles.allDestinations)}
                  to={localePath({ route: OFFERS_PAGE })}
                  onClick={handleClose}
                >
                  {isGreen && <GlobeGreen className={styles.icon} />}
                  {!isGreen && <GlobePurple className={styles.icon} />}

                  <Typography className={styles.itemName} color={color}>
                    {t("navbar.topDestinations")}
                  </Typography>
                </Link>

                {destinations
                  ?.filter((destination) => destination.attributes.isBmOffer)
                  .map((destination) => {
                    const map = destination.attributes.map?.data.attributes.url;
                    const mapPurple = destination.attributes.mapPurple?.data.attributes.url;

                    return (
                      <Link to={destinationsPath(destination)} className={styles.menuItem} onClick={handleClose}>
                        <img src={isGreen ? map : mapPurple} alt="country map" />

                        <Typography color={color} className={styles.itemName}>
                          {locale === "pl-PL" ? destination.attributes.locationPl : destination.attributes.locationEn}
                        </Typography>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>

      <div className={styles.wrapper}>
        <IconButton onClick={toggleWatersports} className={styles.iconButton}>
          <img
            alt="watersports"
            className={styles.mainIcon}
            src={isGreen ? WatersportsShadowIconGreen : WatersportsShadowIconPurple}
          />
          <Typography style={{ color }}>{t("navbar.watersports")}</Typography>
        </IconButton>

        {watersportsOpen && (
          <ClickAwayListener onClickAway={() => setWatersportsOpen(false)}>
            <div
              ref={watersportsRef}
              className={classNames(styles.menuWrapper, { [styles.menuWrapperGreen]: isGreen })}
            >
              <div className={styles.menuContent} ref={watersportsItemsRef}>
                <Link
                  className={classNames(styles.menuItem, styles.allDestinations)}
                  to={localePath({ route: OFFERS_PAGE })}
                  onClick={handleClose}
                >
                  <ReactSVG
                    src={watersportsIcon}
                    className={classNames(styles.watersportIcon, styles.allWatersports, {
                      [styles.watersportGreenIcon]: isGreen,
                    })}
                  />

                  <Typography className={styles.itemName} color={color}>
                    {t("navbar.allWatersports")}
                  </Typography>
                </Link>

                {watersports?.map((watersport) => {
                  const watersportName = getWatersportTranslation(t, watersport.name);
                  const watersportIcon = getWatersportIcon(watersport.id, t);

                  return (
                    <Link
                      to={watersportsPath({ slugEn: watersport.name })}
                      className={styles.menuItem}
                      onClick={handleClose}
                    >
                      <ReactSVG
                        src={watersportIcon}
                        className={classNames(styles.watersportIcon, { [styles.watersportGreenIcon]: isGreen })}
                      />

                      <Typography color={color} className={styles.itemName}>
                        {watersportName}
                      </Typography>
                    </Link>
                  );
                })}
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};
