import React, { useContext, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useWindowSize } from "hooks";
import { Loader, Modal } from "components";
import { AddAssetModal } from "./add-asset-modal/add-asset-modal";
import { RemoveAssetModal } from "./remove-asset-modal/remove-asset-modal";
import { AssetsBox } from "./assets-box/assts-box";
import { ManagementContext } from "../management.context";
import { RootState } from "store";

import styles from "./assets.module.scss";

export const Assets = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { assetsData, refreshAssetsData, loadingAssetsData } = useContext(ManagementContext);
  const { watersports } = useSelector((state: RootState) => state.watersports);

  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState<boolean>(false);
  const [isRemoveAssetModalOpen, setIsRemoveAssetModalOpen] = useState<boolean>(false);

  const handleAddAssetModal = (isOpen: boolean) => () => {
    setIsAddAssetModalOpen(isOpen);
  };

  const handleRemoveAssetModal = (isOpen: boolean) => () => {
    setIsRemoveAssetModalOpen(isOpen);
  };

  const showLoader = (assetsData?.length === 0 || !assetsData) && loadingAssetsData;
  const isMobile = width < 992;
  const noAssets = assetsData?.length === 0;
  const noWatersports = watersports.length === 0;

  return (
    <div className={styles.container}>
      <Typography className={styles.team}>{t("assets.asset")}</Typography>
      <div className={styles.wrapper}>
        <div className={styles.buttons}>
          {!noWatersports && (
            <div className={styles.button}>
              <Typography>{t("assets.add")}</Typography>
              <IconButton disabled={showLoader} onClick={handleAddAssetModal(true)}>
                <AddIcon />
              </IconButton>
            </div>
          )}
          <div className={styles.button}>
            <Typography>{t("assets.remove")}</Typography>
            <IconButton disabled={showLoader} onClick={handleRemoveAssetModal(true)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.content}>
          {showLoader && <Loader color="#fff" backgroundColor="#fff" height="100%" />}

          {noAssets && (
            <div className={styles.noAssets}>
              <Typography>{t("assets.addFirstAsset")}</Typography>
            </div>
          )}

          {isMobile && !showLoader && <Typography className={styles.person}>{t("assets.asset")}</Typography>}

          {isMobile && !showLoader && !noAssets && (
            <div className={styles.teamList}>
              {assetsData?.map((asset) => {
                return <AssetsBox asset={asset} />;
              })}
            </div>
          )}

          {!isMobile && !showLoader && !noAssets && (
            <div className={styles.teamList}>
              <div className={styles.itemWrapper}>
                <Typography className={styles.person}>{t("assets.asset")}</Typography>

                <div className={styles.dataWrapper}>
                  {assetsData?.map((item) => (
                    <div className={styles.nameWrapper}>
                      <Typography className={styles.itemTitle}>{item.type}</Typography>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.itemWrapper}>
                <Typography className={styles.person}>{t("assets.quantity")}</Typography>

                <div className={styles.dataWrapper}>
                  {assetsData?.map((item) => (
                    <Typography className={styles.itemTitle}>{item.quantity}</Typography>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal open={isAddAssetModalOpen}>
        <AddAssetModal handleClose={handleAddAssetModal(false)} refreshAssets={refreshAssetsData} />
      </Modal>

      <Modal open={isRemoveAssetModalOpen}>
        <RemoveAssetModal handleClose={handleRemoveAssetModal(false)} refreshAssets={refreshAssetsData} />
      </Modal>
    </div>
  );
};
