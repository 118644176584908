import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { useWindowSize } from "hooks";
import { RentalDetails } from "./rental-details/rental-details";
import { Offers } from "./offers/offers";
import { FormBox } from "./form-box/form-box";
import { NeedHelp } from "./need-help/need-help";
import { FormComponent } from "./form-component/form-component";
import { RootState } from "store";
import { getUser } from "server";
import { UserModel } from "models";

import employee from "assets/icons/employee.svg";
import yacht from "assets/icons/yacht-blue.svg";
import service from "assets/icons/service-icon.svg";

import styles from "./your-businnes.module.scss";

export const YourBusinnes = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { organizationData, offers } = useSelector((state: RootState) => state.organization);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const [user, setUser] = useState<null | UserModel>(null);

  const userData = useFetch(getUser);
  const { onSuccess } = userData;
  onSuccess((res) => {
    setUser(res.response);
  });

  const isDesktop = width >= 992;

  return (
    <div className={styles.container}>
      {!isFormOpen && (
        <>
          {organizationData && <RentalDetails user={user} organization={organizationData} className={styles.item} />}
          <Offers userOffers={offers} className={styles.item} />
          {!isDesktop && (
            <>
              <FormBox
                className={styles.item}
                name={t("management.yourBusiness.form.recruitEmployee")}
                icon={employee}
                queryName="recruit-employee"
                handleForm={setIsFormOpen}
              />
              <FormBox
                className={styles.item}
                name={t("management.yourBusiness.form.orderEquipment")}
                icon={yacht}
                queryName="order-equipment"
                handleForm={setIsFormOpen}
              />
              <FormBox
                className={styles.item}
                name={t("management.yourBusiness.form.requestService")}
                icon={service}
                queryName="request-service"
                handleForm={setIsFormOpen}
              />
              <NeedHelp className={styles.item} />
            </>
          )}
          <div className={styles.formBoxDesktop}>
            <FormBox
              className={styles.item}
              name={t("management.yourBusiness.form.recruitEmployee")}
              icon={employee}
              queryName="recruit-employee"
              handleForm={setIsFormOpen}
            />
            <FormBox
              className={styles.item}
              name={t("management.yourBusiness.form.orderEquipment")}
              icon={yacht}
              queryName="order-equipment"
              handleForm={setIsFormOpen}
            />
            <FormBox
              className={styles.item}
              name={t("management.yourBusiness.form.requestService")}
              icon={service}
              queryName="request-service"
              handleForm={setIsFormOpen}
            />
            <NeedHelp />
          </div>
        </>
      )}
      {isFormOpen && <FormComponent openForm={setIsFormOpen} user={user} />}
    </div>
  );
};
