import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

import { EditPackageModalProps } from "./edit-package-modal.types";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./edit-package-modal.module.scss";

export const EditPackageModal: React.FC<EditPackageModalProps> = ({ close, activeGuest }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography>{t("availability.timeOffModal.editTimeOff")}</Typography>
        <IconButton className={styles.closeIcon} onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.content}>
        <Typography className={styles.bold}>{activeGuest.name}</Typography>
        <div className={styles.textWrapper}>
          <Typography className={styles.bold}>{t("insights.guestsDetails.editPackageModal.offer")}</Typography>
          <Typography className={styles.light}>Lolo</Typography>
        </div>
        <div className={styles.textWrapper}>
          <Typography className={styles.bold}>{t("insights.guestsDetails.editPackageModal.totalHours")}</Typography>
          <Typography className={styles.light}>27h</Typography>
        </div>
        <div className={styles.textWrapper}>
          <Typography className={styles.bold}>{t("insights.guestsDetails.editPackageModal.totalValue")}</Typography>
          <Typography className={styles.light}>2700PLN</Typography>
        </div>

        <div className={styles.inputsContainer}>
          <div className={styles.inputWrapper} style={{ gridColumn: "1/3" }}>
            <Typography className={styles.bold}>{t("insights.guestsDetails.editPackageModal.newValue")}</Typography>
            <Input />
          </div>
          <div className={styles.inputWrapper} style={{ gridColumn: "3/6" }}>
            <Typography className={styles.bold}>{t("insights.guestsDetails.editPackageModal.newHours")}</Typography>
            <Input />
          </div>
          <div className={styles.inputWrapper} style={{ gridColumn: "6/10" }}>
            <Typography className={styles.bold}>{t("insights.guestsDetails.editPackageModal.newService")}</Typography>
            <Input />
          </div>
        </div>
      </div>

      <Button className={styles.button}>{t("insights.guestsDetails.editPackageModal.save")}</Button>
    </div>
  );
};
