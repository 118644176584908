import { TFunction } from "react-i18next";

import photo1 from "assets/images/home-review-profile1.jpg";
import photo2 from "assets/images/home-review-profile2.jpg";
import photo3 from "assets/images/home-review-profile3.jpg";

export const reviews = (t: TFunction) => [
  {
    name: t("home.section.review.review1.name"),
    guest: t("home.section.review.review1.guest"),
    content: t("home.section.review.review1.content"),
    photo: photo1,
  },
  {
    name: t("home.section.review.review3.name"),
    guest: t("home.section.review.review3.guest"),
    content: t("home.section.review.review3.content"),
    photo: photo3,
    // className: styles.desktop,
  },
  {
    name: t("home.section.review.review2.name"),
    guest: t("home.section.review.review2.guest"),
    content: t("home.section.review.review2.content"),
    photo: photo2,
    // className: styles.desktop,
  },
];
