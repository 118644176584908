import React, { useContext, useRef, useState } from "react";
import { Box, Button, Grid, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FormikErrors, useFormikContext } from "formik";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";

import { FilesDropzone } from "components";
import { CreateOfferContext } from "../../create-offer.context";
import { PhotosProps } from "./photos.types";
import { tooltipConfig } from "../../create-offer.constants";
import { OfferData } from "../../create-offer.types";
import { useWindowSize } from "hooks";
import { switchSx } from "../../services/captain-modal/captain-modal.constants";

import { ReactComponent as TooltipIcon } from "assets/icons/tooltip.svg";
import { ReactComponent as EmptyPhoto } from "assets/icons/empty-photo.svg";

import styles from "./photos.module.scss";

// TODO refactor

export const Photos: React.FC<PhotosProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const { offerId } = useParams();
  const {
    isDesktop,
    organization,
    useOrganizationImages,
    setUseOrganizationImages,
    setPhotosToDelete,
    photosToDelete,
    setNumOfPhotos,
  } = useContext(CreateOfferContext);
  const { errors, values, setFieldValue } = useFormikContext<OfferData>();
  const { width } = useWindowSize();
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [organizationImages, setOrganizationImages] = useState<string[]>([]);
  const [photosError, setPhotosError] = useState<boolean | string | string[] | FormikErrors<File>[] | undefined>(false);

  const handleUseOrganizationImages = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
    setUseOrganizationImages(checked);

  const { offerPhotos, photos } = values.keyDetails;

  const handleDeleteOfferPhoto = (id: number) => {
    const filteredPhotos = offerPhotos?.filter((item) => item.id !== id);

    const photosIds = [...photosToDelete, id];
    setPhotosToDelete(photosIds);

    setFieldValue("keyDetails.offerPhotos", filteredPhotos);
  };

  const itemWidth = 130;
  const wrapperWidth = wrapperRef.current?.clientWidth;

  useDidMount(() => {
    if (organization?.organization_images) {
      const avatarNotExists = ({ is_avatar }: { is_avatar: boolean }) => !is_avatar;

      setOrganizationImages(
        organization?.organization_images.filter(avatarNotExists).map(({ image_url }) => image_url),
      );
    }
  });

  const numOfOfferPhotos = offerPhotos?.length || 0;
  const numOfNewPhotos = photos.length;
  const totalPhotos = numOfNewPhotos + numOfOfferPhotos;

  useDidUpdate(
    () => {
      setNumOfPhotos(totalPhotos);
    },
    [totalPhotos],
    true,
  );

  useDidUpdate(
    () => {
      if (useOrganizationImages) return setPhotosError(false);
      if (errors.keyDetails?.photos) return setPhotosError(errors.keyDetails.photos);
      setPhotosError(false);
    },
    [useOrganizationImages, errors],
    true,
  );

  useDidUpdate(
    () => {
      if (wrapperWidth) {
        const numbers = wrapperWidth / itemWidth;
        if (numberOfElements !== numbers) setNumberOfElements(Math.floor(numbers) - 5);
      }
    },
    [wrapperWidth, width],
    true,
  );

  return (
    <div className={classNames(styles.container, className)} ref={wrapperRef}>
      <div className={styles.wrapper}>
        <Typography className={styles.title}>{t("createOffer.details.addPictures")}</Typography>
        <Tooltip
          title={t("createOffer.details.tooltip.pictures")}
          enterTouchDelay={0}
          className={styles.tooltip}
          componentsProps={tooltipConfig}
        >
          <TooltipIcon />
        </Tooltip>
      </div>

      <div className={classNames(styles.switchWrapper, styles.mobile)}>
        <Typography>{t("createOffer.details.organizationPhotos")}</Typography>
        <Switch
          sx={switchSx}
          value={useOrganizationImages}
          checked={useOrganizationImages}
          onChange={(e, checked) => handleUseOrganizationImages(e, checked)}
        />
      </div>

      <div className={styles.imagesWrapper}>
        <FilesDropzone name="keyDetails.photos">
          {({ files, deleteFile }) => {
            if (files.length) {
              return useOrganizationImages ? (
                <Box className={styles.photosWrapper}>
                  {organizationImages.map((file) => (
                    <Grid container className={styles.imageContainer} key={file}>
                      <img key={file} alt={file} src={file} />
                    </Grid>
                  ))}
                </Box>
              ) : (
                <Box className={styles.photosWrapper}>
                  <div className={styles.imageBackground}>
                    <Button className={styles.button}>+</Button>
                  </div>
                  {files.map((file) => (
                    <Grid container className={styles.imageContainer} key={file.name}>
                      <img key={file.name} alt={file.name} src={URL.createObjectURL(file)} />
                      <IconButton
                        className={styles.iconButton}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          deleteFile(file.name)();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  ))}
                  {offerPhotos?.map((file) => (
                    <Grid container className={styles.imageContainer} key={file.id}>
                      <img key={file.id} alt="alt" src={file.image_url} />
                      <IconButton
                        className={styles.iconButton}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  ))}
                </Box>
              );
            }

            return (
              <div className={styles.photosWrapperEmpty}>
                {!isDesktop && !useOrganizationImages && !offerId && (
                  <>
                    <div className={styles.imageBackground}>
                      <Button className={styles.button}>+</Button>
                    </div>
                    <div className={styles.imageBackground}>
                      <EmptyPhoto className={styles.emptyPhoto} />
                    </div>
                  </>
                )}

                {offerId && offerPhotos && (
                  <>
                    <div className={styles.imageBackground}>
                      <Button className={styles.button}>+</Button>
                    </div>
                    {offerPhotos?.map((file) => (
                      <Grid container className={styles.imageContainer} key={file.id}>
                        <img key={file.id} alt="alt" src={file.image_url} />
                        <IconButton
                          className={styles.iconButton}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleDeleteOfferPhoto(file.id);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    ))}
                  </>
                )}
                {!isDesktop &&
                  useOrganizationImages &&
                  organizationImages.map((organizationImage) => (
                    <Grid key={organizationImage} container className={styles.imageContainer}>
                      <img key={organizationImage} alt={organizationImage} src={organizationImage} />
                    </Grid>
                  ))}

                {isDesktop && numberOfElements && !useOrganizationImages && offerPhotos?.length === 0 && (
                  <>
                    <div className={styles.imageBackground}>
                      <Button className={styles.button}>+</Button>
                    </div>
                    {Array(numberOfElements)
                      .fill(0)
                      .map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={styles.imageBackground} key={index}>
                          <EmptyPhoto className={styles.emptyPhoto} />
                        </div>
                      ))}
                  </>
                )}

                {isDesktop &&
                  numberOfElements &&
                  useOrganizationImages &&
                  organizationImages.map((organizationImage) => (
                    <Grid container className={styles.imageContainer} key={organizationImage}>
                      <img key={organizationImage} alt={organizationImage} src={organizationImage} />
                    </Grid>
                  ))}
              </div>
            );
          }}
        </FilesDropzone>
      </div>

      <div className={classNames(styles.switchWrapper, styles.desktop)}>
        <Typography>{t("createOffer.details.organizationPhotos")}</Typography>
        <Switch
          sx={switchSx}
          value={useOrganizationImages}
          checked={useOrganizationImages}
          onChange={(e, checked) => handleUseOrganizationImages(e, checked)}
        />
      </div>

      {!!photosError && <Typography className={styles.error}>{photosError}</Typography>}
    </div>
  );
};
