import React, { useContext, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";
import SaveIcon from "@mui/icons-material/Save";
import { Select } from "antd";
import { useSnackbar } from "notistack";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { OfferAvailabilityModalProps } from "./offer-links-modal.types";
import { ManagementContext } from "../../../management.context";
import {
  deleteServiceAsset,
  deleteServiceHost,
  getServices,
  serviceAssetPost,
  serviceHostsPost,
} from "server/services/services.server";
import { Loader } from "components";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close-icon.svg";

import styles from "./offer-links-modal.module.scss";

export const OfferLinksModal: React.FC<OfferAvailabilityModalProps> = ({ close, offer }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { assetsData, teamData } = useContext(ManagementContext);

  const [selectedAssetsService, setSelectedAssetsService] = useState<{ assetId: number[]; serviceId: number }[]>([]);
  const [selectedHostsService, setSelectedHostsService] = useState<{ hostId: number[]; serviceId: number }[]>([]);
  const [defaultAssetsValue, setDefaultAssetsValue] = useState<{ label: string; value: number }[][]>([]);
  const [defaultHostsValue, setDefaultHostsValue] = useState<{ label: string; value: number }[][]>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const getOfferServices = useFetch(getServices.setParams({ offerId: offer.id }));
  getOfferServices.onSuccess(() => {
    setDataFetched(true);
  });
  const postServiceAsset = useSubmit(serviceAssetPost);
  const postServiceHost = useSubmit(serviceHostsPost);
  const deleteService = useSubmit(deleteServiceAsset);
  const deleteHost = useSubmit(deleteServiceHost);

  const handleChangeAsset = (id: unknown, serviceId: number) => {
    const currentState = selectedAssetsService.filter((item) => item.serviceId !== serviceId);

    currentState.push({ assetId: id as number[], serviceId });

    setSelectedAssetsService(currentState);
  };

  const handleChangeHost = (id: unknown, serviceId: number) => {
    const currentState = selectedHostsService.filter((item) => item.serviceId !== serviceId);
    currentState.push({ hostId: id as number[], serviceId });

    setSelectedHostsService(currentState);
  };

  const assetsOptions = assetsData?.map((asset) => {
    return {
      label: asset.type,
      value: asset.id,
    };
  });

  const hostsOptions = teamData?.map((host) => {
    const displayLabel = host.first_name ? `${host.first_name} ${host.last_name}` : host.email;

    return {
      label: displayLabel,
      value: host.id,
    };
  });

  const handleSubmit = async () => {
    const connectAssetsService = selectedAssetsService.map((item, index) => {
      const assetsId = item.assetId;

      const filteredArray = assetsId.filter((number) => !defaultAssetsValue[index].some((obj) => obj.value === number));

      const data = {
        service_id: item.serviceId,
        asset_ids: filteredArray,
      };

      return postServiceAsset.submit({ data });
    });

    const connectHostService = selectedHostsService.map((item, index) => {
      const { hostId } = item;

      const filteredArray = hostId.filter((number) => !defaultHostsValue[index].some((obj) => obj.value === number));

      const data = {
        service_id: item.serviceId,
        user_ids: filteredArray,
      };

      return postServiceHost.submit({ data });
    });

    try {
      await Promise.all([connectAssetsService, connectHostService]);
      enqueueSnackbar("success", { variant: "success" });
      getOfferServices.setData([]);
      getOfferServices.revalidate();
      close();
    } catch (error) {
      enqueueSnackbar("error", { variant: "error" });
    }
  };

  const handleDeselectAsset = async (assetId: unknown, serviceId: number) => {
    const data = {
      asset_id: assetId,
      service_id: serviceId,
    };

    await deleteService.submit({ data });
    getOfferServices.revalidate();
  };

  const handleDeselectHost = async (hostId: unknown, serviceId: number) => {
    const data = {
      user_id: hostId,
      service_id: serviceId,
    };

    await deleteHost.submit({ data });
    getOfferServices.revalidate();
  };

  const showLoader = !dataFetched;

  useDidUpdate(
    () => {
      if (getOfferServices.data !== null && getOfferServices.status === 200) {
        const assetsValue: { label: string; value: number }[][] = [];

        getOfferServices.data.forEach((item) => {
          const arr: { label: string; value: number }[] = [];

          item.service_assets.forEach((serviceAsset) => {
            arr.push({
              value: serviceAsset.id,
              label: serviceAsset.type,
            });
          });

          assetsValue.push(arr);
        });

        setDefaultAssetsValue(assetsValue);
      }
    },
    [getOfferServices.data],
    true,
  );

  useDidUpdate(
    () => {
      if (getOfferServices.data !== null && getOfferServices.status === 200) {
        const hostsValue: { label: string; value: number }[][] = [];

        getOfferServices.data.forEach((item) => {
          const arr: { label: string; value: number }[] = [];

          item.service_users.forEach((serviceUser) => {
            const label = serviceUser.first_name
              ? `${serviceUser.first_name} ${serviceUser.last_name}`
              : serviceUser.email;

            arr.push({
              value: serviceUser.id,
              label,
            });
          });

          hostsValue.push(arr);
        });

        setDefaultHostsValue(hostsValue);
      }
    },
    [getOfferServices.data],
    true,
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography>{t("availability.offerLinksModal.completeServices")}</Typography>
        <div className={styles.icons}>
          <IconButton onClick={close} className={styles.icon}>
            <CloseIcon />
          </IconButton>
          <IconButton onClick={handleSubmit} className={styles.icon}>
            <SaveIcon className={styles.saveIcon} />
          </IconButton>
        </div>
      </div>

      {dataFetched && (
        <div className={styles.data}>
          <Typography>{t("availability.offerLinksModal.service")}</Typography>
          <Typography className={styles.center}>{t("availability.offerLinksModal.asset")}</Typography>
          <Typography className={styles.center}>{t("availability.offerLinksModal.host")}</Typography>
        </div>
      )}

      <div className={styles.content}>
        {showLoader && <Loader color="#fcf9f6" backgroundColor="#fcf9f6" />}

        {dataFetched &&
          getOfferServices.data?.map((item, index) => {
            return (
              <>
                <div className={styles.servicesWrapper} key={item.service.id}>
                  <Typography className={styles.serviceTitle}>{item.service.title}</Typography>

                  <Select
                    loading={Boolean(!dataFetched)}
                    className={styles.select}
                    onChange={(id) => handleChangeAsset(id, item.service.id)}
                    options={assetsOptions}
                    mode="multiple"
                    popupClassName={styles.popup}
                    placeholder={t("availability.offerLinksModal.selectAsset")}
                    defaultValue={defaultAssetsValue[index]}
                    onDeselect={(value) => handleDeselectAsset(value, item.service.id)}
                  />

                  <Select
                    loading={Boolean(!dataFetched)}
                    className={styles.select}
                    onChange={(id) => handleChangeHost(id, item.service.id)}
                    options={hostsOptions}
                    mode="multiple"
                    popupClassName={styles.popup}
                    placeholder={t("availability.offerLinksModal.selectHost")}
                    defaultValue={defaultHostsValue[index]}
                    onDeselect={(value) => handleDeselectHost(value, item.service.id)}
                  />
                </div>
                <div className={styles.separator} />
              </>
            );
          })}
      </div>
    </div>
  );
};
