import React, { useState } from "react";
import { Button, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useTranslation } from "react-i18next";

import { ReservationsModalProps } from "./reservations-modal.types";
import { Modal } from "../layout";
import { ReservationsContextProvider } from "./reservations-modal.context";
import { OfferModel } from "../../models";
import { Loader } from "../client";
import { getOffer } from "../../server";
import { Wrapper } from "./wrapper/wrapper";

import styles from "./reservations-modal.module.scss";

export const ReservationsModal: React.FC<ReservationsModalProps> = ({
  close,
  open,
  offer: offerFromProps,
  offerId: offerIdFromProps,
  isOwnReservation = false,
  refreshReservations,
}) => {
  const { t } = useTranslation();
  const [offer, setOffer] = useState<OfferModel | null>(null);

  const { offerId: offerIdFromParams } = useParams();

  const fetchOffer = useSubmit(getOffer.setParams({ id: offerIdFromProps || offerIdFromParams || 0 }));

  useDidUpdate(
    () => {
      if (offerFromProps) {
        setOffer(offerFromProps);
      }
      if (!offerFromProps) {
        const offerId = offerIdFromProps || offerIdFromParams;

        if (offerId) {
          fetchOffer.submit().then((res) => {
            setOffer(res[0]);
          });
        }
      }
    },
    [offerIdFromParams, offerFromProps],
    true,
  );

  if (!isOwnReservation) {
    return (
      <Modal open={open} className={styles.modal}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Typography className={styles.title}>{t("reservationsModal.booking")}</Typography>
            <Button className={styles.closeButton} onClick={close}>
              x
            </Button>
          </div>

          {!offer && <Loader backgroundColor="#fcf9f6" color="#fcf9f6" />}

          {offer && (
            <ReservationsContextProvider modalClose={close} offer={offer} isOwnReservation={isOwnReservation}>
              <Wrapper />
            </ReservationsContextProvider>
          )}
        </div>
      </Modal>
    );
  }

  // own reservations
  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography className={styles.title}>{t("reservationsModal.booking")}</Typography>
          <Button className={styles.closeButton} onClick={close}>
            x
          </Button>
        </div>

        <ReservationsContextProvider
          modalClose={close}
          offer={offer}
          isOwnReservation={isOwnReservation}
          refreshReservations={refreshReservations}
        >
          <Wrapper />
        </ReservationsContextProvider>
      </div>
    </Modal>
  );
};
