import partner1 from "assets/images/footer-partner-1.webp";
import partner2 from "assets/images/footer-partner-2.webp";
import partner3 from "assets/images/footer-partner-3.webp";
import partner4 from "assets/images/footer-partner-4.webp";
import partner5 from "assets/images/footer-partner-5.webp";
import partner6 from "assets/images/footer-partner-6.webp";

type Partners = {
  name: string;
  image: string;
};

export const partners: Partners[] = [
  {
    name: "Europejskie Fundusze - Polska Wschodnia",
    image: partner1,
  },
  {
    name: "Rzeczpospolita Polska",
    image: partner2,
  },
  {
    name: "Unia Europejska - Europejski Fundusz Rozwoju Regionalnego",
    image: partner3,
  },
  {
    name: "PARP - Grupa PFR",
    image: partner4,
  },
  {
    name: "PLATFORMY STARTOWE",
    image: partner5,
  },
  {
    name: "STARTUP HEROES",
    image: partner6,
  },
];
