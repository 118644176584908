import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useDispatch, useSelector } from "react-redux";

import { createRentalProfileSteps, mapOrganizationData, rentalInitialValues } from "./create-rental.constants";
import { SubmitButtons } from "./submit-buttons/submit-buttons";
import { CreateOfferProgress } from "./create-progress/create-progress";
import { RentalData } from "server/organization/organization.types";
import { createLocation, createOrganization, generateStripeLink, uploadImage } from "server";
import { Navigation } from "./navigation/navigation";
import { useWindowSize } from "hooks";
import { refreshOrganizationData, RootState } from "store";
import { Loader } from "components";

import styles from "./create-rental.module.scss";

export const CreateRentalPage = () => {
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationData, dataFetching } = useSelector((state: RootState) => state.organization);

  const [step, setStep] = useState<number>(0);
  const [formValues, setFormValues] = useState<RentalData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<null | number>(null);

  const stripeData = useSubmit(generateStripeLink.setParams({ organization_id: organizationId || 0 }));
  const { submit: submitStripe, onSubmitSuccess: onSubmitSuccessStripe } = stripeData;
  onSubmitSuccessStripe((res) => {
    window.open(res.response.stripe_url, "_self");
  });

  const {
    submit: postOrganization,
    onSubmitSuccess: onOrganizationSuccess,
    onSubmitError: onOrganizationError,
  } = useSubmit(createOrganization);
  onOrganizationSuccess(async ({ response }) => {
    if (formValues && formValues.avatar) {
      const avatarUploadPromise = uploadImage(response.avatar_presigned_url, formValues.avatar);
      const imagesUploadPromises = response.presigned_urls.map((presignedUrl, index) =>
        uploadImage(presignedUrl, formValues.pictures[index]),
      );

      const uploadPromises = [avatarUploadPromise, ...imagesUploadPromises];
      try {
        await Promise.all(uploadPromises);
        setOrganizationId(response.id);
        dispatch(refreshOrganizationData());
      } catch (error) {
        enqueueSnackbar(t("rental.createOrganizationError"), { variant: "error" });
      }
    }
  });
  onOrganizationError(() => {
    setIsSubmitting(false);
    enqueueSnackbar(t("rental.createOrganizationError"), { variant: "error" });
  });

  const {
    submit: postLocation,
    onSubmitSuccess: onLocationSuccess,
    onSubmitError: onLocationError,
  } = useSubmit(createLocation);
  onLocationSuccess(async ({ response }) => {
    if (formValues) {
      const data = mapOrganizationData(formValues, response.id || 0);
      await postOrganization({ data });
    }
  });
  onLocationError(() => {
    setIsSubmitting(false);
    enqueueSnackbar(t("rental.createLocationError"), { variant: "error" });
  });

  const handlePrevPage = () => setStep((prevStep) => prevStep - 1);
  const handleNextPage = () => setStep((prevStep) => prevStep + 1);
  const steps = createRentalProfileSteps(t);

  const isLastStep = step === steps.length - 1;
  const showPrevButton = step > 0;
  const isDesktop = width >= 992;
  const isFirstStep = step === 0;

  const handleSubmit = async (values: RentalData, { setTouched }: FormikHelpers<RentalData>) => {
    if (isLastStep) {
      setIsSubmitting(true);
      setFormValues(values);
      if (values.location) {
        const data = values.location;

        if (data.region?.length === 0) {
          delete data.region;
        }

        if (data.description?.length === 0) {
          delete data.description;
        }

        await postLocation({ data });
      }
    } else {
      handleNextPage();
      setTouched({});
      setIsSubmitting(false);
    }
  };

  const showForm = !organizationData && !dataFetching;
  const showLoader = dataFetching;

  useDidUpdate(() => {
    if (organizationId) submitStripe().then();
  }, [organizationId]);

  useDidUpdate(
    () => {
      if (isDesktop) setIsMenuOpen(false);
      else setIsMenuOpen(false);
    },
    [isDesktop, isFirstStep],
    true,
  );

  useDidUpdate(
    () => {
      if (isFirstStep) setIsMenuOpen(false);
    },
    [isFirstStep],
    true,
  );

  return (
    <>
      {showLoader && <Loader height="87vh" />}
      {showForm && (
        <section className={styles.container}>
          <Formik<RentalData>
            initialValues={rentalInitialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validationSchema={steps[step].schema}
            validateOnChange={false}
          >
            {showForm && (
              <Form className={classNames(styles.formWrapper, { [styles.formWrapperMenuOpen]: isMenuOpen })}>
                {!isFirstStep && (
                  <Navigation
                    isOpen={isMenuOpen}
                    steps={steps}
                    step={step}
                    setStep={setStep}
                    setIsOpen={setIsMenuOpen}
                    openNavigation={setIsMenuOpen}
                    isDesktop={isDesktop}
                  />
                )}
                <div className={classNames(styles.step, { [styles.stepMenuOpen]: isMenuOpen })}>
                  {steps[step].component}
                </div>
                {!isFirstStep && (
                  <div className={styles.progress}>
                    <CreateOfferProgress step={step} totalSteps={steps.length} />
                    <SubmitButtons
                      handlePrevPage={handlePrevPage}
                      showPrevButton={showPrevButton}
                      isLastStep={isLastStep}
                      submitting={isSubmitting}
                      step={step}
                    />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </section>
      )}
    </>
  );
};
