import { TFunction } from "i18next";

import { FieldsData } from "./availability-modal.types";

export const months = (t: TFunction) => [
  {
    value: "january",
    label: t("availability.months.january"),
  },
  {
    value: "february",
    label: t("availability.months.february"),
  },
  {
    value: "march",
    label: t("availability.months.march"),
  },
  {
    value: "april",
    label: t("availability.months.april"),
  },
  {
    value: "may",
    label: t("availability.months.may"),
  },
  {
    value: "june",
    label: t("availability.months.june"),
  },
  {
    value: "july",
    label: t("availability.months.july"),
  },
  {
    value: "august",
    label: t("availability.months.august"),
  },
  {
    value: "september",
    label: t("availability.months.september"),
  },
  {
    value: "october",
    label: t("availability.months.october"),
  },
  {
    value: "november",
    label: t("availability.months.november"),
  },
  {
    value: "december",
    label: t("availability.months.december"),
  },
];

export const days = (t: TFunction) => [
  {
    value: "monday",
    label: t("availability.days.monday"),
  },
  {
    value: "tuesday",
    label: t("availability.days.tuesday"),
  },
  {
    value: "wednesday",
    label: t("availability.days.wednesday"),
  },
  {
    value: "thursday",
    label: t("availability.days.thursday"),
  },
  {
    value: "friday",
    label: t("availability.days.friday"),
  },
  {
    value: "saturday",
    label: t("availability.days.saturday"),
  },
  {
    value: "sunday",
    label: t("availability.days.sunday"),
  },
];

export const fields: { [key: string]: FieldsData } = {
  months: "months",
  days: "days",
  startTime: "start time",
  endTime: "end time",
  duration: "duration",
  brake: "brake",
};
