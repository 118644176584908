import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CREATE_OFFER_PAGE, HOME_PAGE } from "constants/routes.constants";
import { Navbar } from "components";
import { useRoutesPath } from "hooks";

import { ReactComponent as Logo } from "assets/icons/logo-icon.svg";

import styles from "./rental-payment-success.module.scss";

export const RentalPaymentSuccessPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { localePath } = useRoutesPath();

  const openHomePage = () => {
    navigate(localePath({ route: HOME_PAGE }));
  };

  const openAddOfferPage = () => {
    navigate(localePath({ route: CREATE_OFFER_PAGE }));
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.wrapper}>
        <Logo className={styles.logo} onClick={openHomePage} />
        <div className={styles.content}>
          <Typography fontSize="30px" fontFamily="Chillax-Regular">
            {t("rental.rentalPayment.success.paymentAdded")}
          </Typography>
          <Typography fontFamily="Chillax-Regular">{t("rental.rentalPayment.success.addNewOffers")}</Typography>
          <Button className={styles.button} onClick={openAddOfferPage}>
            {t("rental.rentalPayment.success.addOffer")}
          </Button>
        </div>
      </div>
    </div>
  );
};
