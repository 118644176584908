import React, { useState } from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useFetch, useSubmit } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";

import { Header } from "./header/header";
import { useQueryParams } from "hooks";
import { navigationData } from "./navigation/navigation.constants";
import { PersonalInfo } from "./personal-info/personal-info";
import { Payments } from "./payments/payments";
import { editUser, getUser } from "server";
import { RootState, setUser } from "store";
import { Loader, Modal } from "components";
import { EditModal } from "./edit-modal/edit-modal";
import { EditData } from "./profile.types";
import { profileSchema } from "./profile.constants";
import { Navigation } from "./navigation/navigation";

import styles from "./profile.module.scss";

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { organizationData } = useSelector((state: RootState) => state.organization);
  const {
    query: { section },
    setQueryParams,
  } = useQueryParams();

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [userRefresher, setUserRefresher] = useState<number>(0);

  const userDataFetch = useFetch(getUser, {
    dependencies: [organizationData, userRefresher],
  });
  const { onSuccess, data: userData } = userDataFetch;
  onSuccess((res) => {
    setUser(res.response);
  });

  const editUserData = useSubmit(editUser);
  editUserData.onSubmitSuccess(() => {
    setIsEditModalOpen(false);
    setUserRefresher((prevState) => prevState + 1);
  });
  const handleEditData = (data: EditData) => {
    const editData = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.number,
    };

    editUserData.submit({ data: editData }).then();
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const sectionsData = navigationData(t);

  const initialEditValues: EditData = {
    email: userData?.email as string,
    firstName: userData?.first_name as string,
    number: userData?.phone_number as string,
    lastName: userData?.last_name as string,
  };

  useDidUpdate(
    () => {
      if (!section) {
        setQueryParams({ section: sectionsData[0].value });
      }
    },
    [],
    true,
  );

  return (
    <div className={styles.container}>
      <Header userData={userData} className={styles.header} openEditModal={setIsEditModalOpen} />

      <Navigation className={styles.navigation} />

      {!userData && <Loader className={styles.loader} />}

      {sectionsData[0].value === section && userData && (
        <PersonalInfo userData={userData} openEditModal={setIsEditModalOpen} className={styles.content} />
      )}

      {sectionsData[1].value === section && userData && <Payments className={styles.content} />}

      <div className={styles.info}>
        {sectionsData[0].value === section && (
          <>
            <Typography variant="h3">{t("profile.info.personalInfo")}</Typography>
            <Typography>{t("profile.info.personalInfoContent")}</Typography>
          </>
        )}

        {sectionsData[1].value === section && (
          <>
            <Typography variant="h3">{t("profile.info.paymentsInfo1")}</Typography>
            <Typography>{t("profile.info.paymentsInfoCopy1")}</Typography>
            <Typography variant="h3"> {t("profile.info.paymentsInfo2")}</Typography>
            <Typography> {t("profile.info.paymentsInfoCopy2")}</Typography>
          </>
        )}
      </div>

      <Modal open={isEditModalOpen}>
        <Formik initialValues={initialEditValues} onSubmit={handleEditData} validationSchema={profileSchema(t)}>
          <Form className={styles.form}>
            <IconButton className={styles.closeButton} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
            <EditModal />
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};
