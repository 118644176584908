import React from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ARTICLE_PAGE } from "constants/routes.constants";
import { SectionProps } from "./section.types";
import { useRoutesPath } from "hooks";

import stylesMain from "../article.module.scss";
import styles from "./section.module.scss";

export const Section: React.FC<SectionProps> = ({ articles, article }) => {
  const { t } = useTranslation();

  const { localePathParams } = useRoutesPath();

  return (
    <div className={classNames(styles.section, stylesMain.desktop)}>
      <div className={styles.content}>
        <Typography variant="h5">{t("article.section.contents")}</Typography>
        {article.attributes.content.map((item, index) => (
          <Typography key={item}>
            {index + 1}. <span>{item}</span>
          </Typography>
        ))}
      </div>

      <div className={styles.otherArticles}>
        <Typography variant="h5">{t("article.section.otherArticles")}</Typography>
        {articles?.map(({ attributes }) => (
          <Link
            key={attributes.title}
            to={localePathParams({
              route: ARTICLE_PAGE,
              localeParams: {
                slug: {
                  pl: attributes.slug,
                  en: attributes.slug,
                },
              },
            })}
          >
            <Typography>{attributes.title}</Typography>
          </Link>
        ))}
      </div>
    </div>
  );
};
