import { OfferModel } from "models";
import { builder } from "server/builder";
import { CreateOfferData, CreateOfferDataResponse } from "./offer.types";
import { ServerErrorType } from "server/server.types";

export const getOffers = builder.createCommand<
  OfferModel[],
  void,
  ServerErrorType,
  { watersport?: string; location?: string }
>()({
  endpoint: "/app/offer",
  method: "GET",
  options: { timeout: 0 },
});

export const getOffer = builder.createCommand<OfferModel, void>()({
  endpoint: "/app/offer/:id",
  method: "GET",
  retry: 5,
});

export const createOffer = builder.createCommand<CreateOfferDataResponse, CreateOfferData>()({
  endpoint: "/app/offer",
  method: "POST",
  options: {
    timeout: 25000,
  },
});

export const deleteOffer = builder.createCommand<OfferModel, number>()({
  endpoint: "/app/offer/:id",
  method: "DELETE",
});

export const editOffer = builder.createCommand<CreateOfferDataResponse, number>()({
  endpoint: "/app/offer/:id",
  method: "PUT",
});
