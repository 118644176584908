import React from "react";
import { IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { ServiceItemProps } from "./service-item.types";
import {
  AdditionalServicesData,
  CaptainData,
  ExtraChargesData,
  InstructorData,
  OfferData,
} from "../../create-offer.types";
import { Captain } from "./captain/captain";
import { Instructor } from "./instructor/instructor";
import { ExtraCharges } from "./extra-charges/extra-charges";
import { AdditionalService } from "./additional-service/additional-service";
import { rateTypeTranslated } from "../../create-offer.constants";

import styles from "./service-item.module.scss";

export const ServiceItem: React.FC<ServiceItemProps> = ({
  captain,
  additionalService,
  instructor,
  extraCharge,
  number,
}) => {
  const { values, setFieldValue } = useFormikContext<OfferData>();
  const { t } = useTranslation();

  const { currency } = values.baseInfo.pricing;

  const isCaptain = !!captain;
  const isInstructor = !!instructor;
  const isExtraCharge = !!extraCharge;
  const isAdditionalService = !!additionalService;

  const handleDeleteItem = () => {
    if (isCaptain) {
      const filteredCaptains = values.services.captains.filter((item) => item !== captain);
      setFieldValue("services.captains", filteredCaptains);
    }

    if (isInstructor) {
      const filteredInstructors = values.services.instructors.filter((item) => item !== instructor);
      setFieldValue("services.instructors", filteredInstructors);
    }

    if (isExtraCharge) {
      const filteredExtraCharges = values.services.extraCharges.filter((item) => item !== extraCharge);
      setFieldValue("services.extraCharges", filteredExtraCharges);
    }

    if (isAdditionalService) {
      const filteredAdditionalServices = values.services.additionalServices.filter(
        (item) => item !== additionalService,
      );
      setFieldValue("services.additionalServices", filteredAdditionalServices);
    }
  };

  const handleDuplicateItem = () => {
    if (isCaptain) {
      const itemToDuplicate: CaptainData = {
        id: uuid(),
        price: captain?.price,
        isFixed: captain?.isFixed,
        guests: captain?.guests,
        isRequired: captain?.isRequired,
      };
      const duplicatedItemCaptains = [...values.services.captains, itemToDuplicate];
      setFieldValue("services.captains", duplicatedItemCaptains);
    }

    if (isInstructor) {
      const itemToDuplicate: InstructorData = {
        id: uuid(),
        price: instructor?.price,
        isFixed: instructor?.isFixed,
        guests: instructor?.guests,
        isRequired: instructor?.isRequired,
      };
      const duplicatedItemInstructors = [...values.services.instructors, itemToDuplicate];
      setFieldValue("services.instructors", duplicatedItemInstructors);
    }

    if (isExtraCharge) {
      const itemToDuplicate: ExtraChargesData = {
        id: uuid(),
        price: extraCharge?.price,
        isRequired: extraCharge?.isRequired,
        isFixed: extraCharge?.isFixed,
        name: extraCharge?.name,
      };
      const duplicatedItemExtraCharge = [...values.services.extraCharges, itemToDuplicate];
      setFieldValue("services.extraCharges", duplicatedItemExtraCharge);
    }

    if (isAdditionalService) {
      const itemToDuplicate: AdditionalServicesData = {
        id: uuid(),
        price: additionalService?.price,
        isRequired: additionalService?.isRequired,
        isFixed: additionalService?.isFixed,
        name: additionalService?.name,
      };
      const duplicatedItemAdditionalServices = [...values.services.additionalServices, itemToDuplicate];
      setFieldValue("services.additionalServices", duplicatedItemAdditionalServices);
    }
  };

  const priceValue = rateTypeTranslated(t, values.baseInfo.pricing.rateType);

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <IconButton className={styles.iconButton} onClick={handleDeleteItem}>
          <ClearIcon />
        </IconButton>
        <IconButton className={styles.iconButton} onClick={handleDuplicateItem}>
          <FolderCopyIcon />
        </IconButton>
      </div>
      {isCaptain && <Captain captain={captain} number={number} currency={currency} priceValue={priceValue} />}
      {isInstructor && (
        <Instructor instructor={instructor} currency={currency} number={number} priceValue={priceValue} />
      )}
      {isExtraCharge && (
        <ExtraCharges extraCharge={extraCharge} currency={currency} number={number} priceValue={priceValue} />
      )}
      {isAdditionalService && (
        <AdditionalService
          additionalService={additionalService}
          currency={currency}
          number={number}
          priceValue={priceValue}
        />
      )}
    </div>
  );
};
