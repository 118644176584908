export const account = {
  account: "Account",
  personalInfo: "Personal info",
  paymentsPayouts: "Payments & payouts",
  preferences: "Preferences",
  helpCenter: "Help center",

  // account
  goToProfile: "Go to profile",
  personalInfoContent: "Provide personal details and how we can reach you",
  preferencesContent:
    "Set your default language, currency and timezone. Control notifications and what you share with others.",
  paymentsPayoutsContent: "Review payments, payouts, coupons, gift cards and taxes",
  needDeactivate: "Need to deactivate your account?",
  deactivateNow: "Deactivate now",

  // personal info
  legalName: "Legal name",
  dateOfBirth: "Date of birth",
  email: "Email address",
  address: "Address",
  phoneNumber: "Phone number",
  password: "Password",
  changeYourProfile: "Change your profile picture",
  editDetails: "Which details can be edited?",
  editDetailsContent:
    "Details SeaEasy uses to verify your identity can’t be changed. Contact info and some personal details can be edited, but we may ask you to verify your identity the next time you book or create a listing.",
  sharedInfo: "What info is shared with others?",
  sharedInfoContent: "SeaEasy only releases contact information for Owners and Guests after a reservation is confirmed",

  // payments
  yourPayments: "Your payments",
  yourPaymentsContent: "Keep track of all your payments and refunds",
  managePayments: "Manage payments",
  paymentMethods: "Payment methods",
  paymentMethodsContent: "Add and manage your payment methods using our secure payment system.",
  addPaymentMethod: "Add payment method",
  payouts: "Payouts",
  payoutsConent: "Add at least one payout method so we know where to send your money",
  setUpPayouts: "Set up payouts",
  vat: "VAT",
  vatContent:
    "If you are registered for VAT or your trip is for business, you may not be charged VAT on SeaEasy service fees",
  addVatId: "Add VAT ID Number",
  makeAllPayments: "Make all payments through SeaEasy",
  makeAllPaymentsContent: "Always pay and communicate through SeaEasy to ensure you’re protected under our ",
  makeAllPaymentsContentRest: " cancellation and other safeguards. ",
  needHelp: "Need help?",
  checkOur: "Check our ",
  needHelpContent: " where you can find a list of frequently asked questions.",
  termsOfUse: "Terms of Use",
  learnMore: "Learn more.",

  // payments modal
  addCardDetails: "Add card details",
  countryRegion: "Country / Region",
  countryRegionRequired: "Country / Region is required",
  cardNumber: "Card Number",
  cardNumberRequired: "Card Number is required",
  addCardNumber: "Add Card Number",
  addCardNumberRequired: "Add Card Number is required",
  nameOnRegistration: "Name on registration",
  nameOnRegistrationRequired: "Name on registration is required",
  cvv: "CVV",
  cvvRequired: "CVV is required",
  zipCode: "Zip code",
  zipCodeRequired: "Zip code is required",

  // VAT modal
  addVATNumber: "Add VAT ID Number",
  vatModalHeader:
    "If you are registered with European Commission, verification may take up to 48 hours. For more info check our ",
  VATId: "VAT ID",
  VATIdRequired: "VAT ID is required",
  addVATId: "Add VAT ID Number",
  addVATIdRequired: "Add VAT ID Number is required",
  name: "Name",
  address1: "Address line 1",
  address1Required: "Address line 1 is required",
  addressLine2: "Address line 2",
  addressLine2Optiona: "Address line 2 (optional)",
  city: "City",
  cityRequired: "City is required",
  cityName: "City name",
  provinceRegion: "Province / Region",
  provinceRegionRequired: "Province / Region is required",

  // payments history
  checkHelpCenter: "Check our Help Center where you can find a list of frequently asked questions.",

  // preferences
  preferredLanguage: "Preferred language",
  preferredCurrency: "Preferred currency",
  timeZone: "Time zone",
  reminders: "Reminders",
  activitySharing: "Activity sharing",
  dataSharing: "Data sharing",
  getMessages: "Where would you like to get text messages?",
  getMessagesContent:
    "By checking an SMS box, you agree to receive an autodialed promotional texts from SeaEasy and SeaEasy’s partners at:",
  moreInfo:
    "For more info, text HELP to XXXXXX. To cancel mobile notifications, reply STOP to XXXXXX. Message and data rates may apply.",
  choosePhoneNumber: "Choose a phone number",

  // help center
  howHelp: "How can we help?",
  whatIsSeaEasy: "What is SeaEasy?",
  howDoesSeasEasyWork: "How does SeaEasy work?",
  doesSeaEasyOfferInsurance: "Does SeaEasy offer insurance?",
  whyShouldICommunicateThrought: "Why should I communicate through SeaEasy?",
  doINeedInsuranceToRentABoat: "Do I need insurance to rent a boat?",
  isYourWebsiteSecure: "Is your website secure?",
  howToStartSharingMyEquipment: "How to start sharing my equipment?",
  howToStartUsingSeaEasy: "How to start using SeaEasy?",
  needToGetInTouch: "Need to get in touch?",
  needToGetInTouchContent: "We’ll start with some questions and get you to the right place.",
  giveFeedback: "You can also give us feedback",

  // offers
  yourOffers: "Your offers",
  createNewOffer: "Create new offer",
  listing: "Listing",
  category: "Category",
  location: "Location",
  price: "Price",
};
