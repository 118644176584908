import { TFunction } from "i18next";

import seaMore from "assets/icons/sea-more.svg";
import weveGot from "assets/icons/we-got.svg";
import price from "assets/icons/price.svg";
import realReviews from "assets/icons/real-reviews.svg";

export const summaryData = (t: TFunction) => [
  {
    title: t("home.summary.seaMore"),
    subTitle: t("home.summary.seaMoreContent"),
    icon: seaMore,
  },
  {
    title: t("home.summary.weveGot"),
    subTitle: t("home.summary.weveGotContent"),
    icon: weveGot,
  },
  {
    title: t("home.summary.price"),
    subTitle: t("home.summary.priceContent"),
    icon: price,
  },
  {
    title: t("home.summary.reviews"),
    subTitle: t("home.summary.reviewsContent"),
    icon: realReviews,
  },
];

export const responsive = {
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
